import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  /** 
 * Javascript Date() 단일 인자 방식과 동일하게 작동하는 scalar
   * 
   * number로 전달 시 1970년 1월 1일 0시 0분 0초부터 지금까지 흐른 밀리초 (UNIX epoch)
   * 
   * string으로 전달 시 Timestamp string (experimental)
   * 
   * 참조: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date
 **/
  Date: Date,
  Object: any,
  /** The `Upload` scalar type represents a file upload. */
  Upload: any,
};



/**  댓글 채택 리워드 지급 알림  */
export type AcceptedMentorCommentRewardNotification = SimpleNotification & {
   __typename?: 'AcceptedMentorCommentRewardNotification',
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  deepLinkUrl?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  linkUrl: Scalars['String'],
  title?: Maybe<Scalars['String']>,
  unread: Scalars['Boolean'],
  updatedAt?: Maybe<Scalars['Date']>,
};

export type ActivateChannelPayload = {
   __typename?: 'ActivateChannelPayload',
  channel?: Maybe<Channel>,
};

export type ActivitiesDeletePayload = {
   __typename?: 'ActivitiesDeletePayload',
  activities?: Maybe<Array<Maybe<Activity>>>,
};

export type ActivitiesStatusUpdateInput = {
  ids: Array<Scalars['ID']>,
  refusalReason?: Maybe<Scalars['String']>,
  status?: Maybe<ActivityStatus>,
};

export type ActivitiesStatusUpdatePayload = {
   __typename?: 'ActivitiesStatusUpdatePayload',
  activities?: Maybe<Array<Maybe<Activity>>>,
  count?: Maybe<Scalars['Int']>,
};

export type ActivitiesUpdateInput = {
  ids: Array<Scalars['ID']>,
  refusalReason?: Maybe<Scalars['String']>,
  status?: Maybe<ActivityStatus>,
};

/** 공고 */
export type Activity = {
   __typename?: 'Activity',
  id: Scalars['ID'],
  organizationName?: Maybe<Scalars['String']>,
  activityTypeID?: Maybe<Scalars['Int']>,
  title?: Maybe<Scalars['String']>,
  chatRoom?: Maybe<ChatRoom>,
  activityEndAt?: Maybe<Scalars['Date']>,
  activityReference?: Maybe<ActivityReference>,
  activityStartAt?: Maybe<Scalars['Date']>,
  activityType?: Maybe<ActivityType>,
  additionalBenefit?: Maybe<Scalars['String']>,
  /** 주소 */
  addresses: Array<Maybe<ActivityAddress>>,
  advertisement?: Maybe<Advertisement>,
  applicationFiles?: Maybe<Array<Maybe<ActivityFile>>>,
  /** 지원 절차 */
  applicationProcedure: Array<Maybe<ActivityApplicationProcedure>>,
  applyDetail?: Maybe<Scalars['String']>,
  applyTypes?: Maybe<Array<Maybe<ApplyType>>>,
  awards?: Maybe<Scalars['String']>,
  benefits?: Maybe<Array<Maybe<Benefit>>>,
  categories?: Maybe<Array<Maybe<Category>>>,
  cost?: Maybe<Scalars['Int']>,
  country?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  dateRepresentation?: Maybe<DateRepresentationType>,
  deadlineStatus?: Maybe<ActivityDeadlineStatus>,
  detailText?: Maybe<ActivityText>,
  distinctInternTypes?: Maybe<Array<ActivityInternType>>,
  duties: DutyConnection,
  /** 학력 */
  education?: Maybe<ActivityQualificationEducation>,
  facetimeDay?: Maybe<Scalars['String']>,
  facetimePeriod?: Maybe<Scalars['String']>,
  facetimeTime?: Maybe<Scalars['String']>,
  facetimeWeekday?: Maybe<Scalars['String']>,
  files?: Maybe<Array<Maybe<ActivityFile>>>,
  hasQuestionTemplate: Scalars['Boolean'],
  homepageURL?: Maybe<Scalars['String']>,
  /** 업종 */
  industries: Array<Maybe<Industry>>,
  integerTypes?: Maybe<Array<Maybe<ActivityIntegerType>>>,
  integers?: Maybe<Array<Maybe<ActivityInteger>>>,
  interests?: Maybe<Array<Maybe<Interest>>>,
  isAd?: Maybe<Scalars['Boolean']>,
  isAllSameQuestion?: Maybe<Scalars['Boolean']>,
  /** 지원 절차 노출 여부 */
  isApplicationProcedureVisible?: Maybe<Scalars['Boolean']>,
  isApplied: Scalars['Boolean'],
  isConfirmed: Scalars['Boolean'],
  isDeleted: Scalars['Boolean'],
  /** 졸업예정자 지원 가능 여부 */
  isExpectedGraduateAllowed?: Maybe<Scalars['Boolean']>,
  isHidden?: Maybe<Scalars['Boolean']>,
  isManagerAuthed: Scalars['Boolean'],
  isManagerEmailVisible: Scalars['Boolean'],
  isManagerPhoneNumberVisible: Scalars['Boolean'],
  /** 면접 후 급여 결정 */
  isSalaryDecidedAfterInterview?: Maybe<Scalars['Boolean']>,
  /** 회사 내규에 따른 급여 지급 */
  isSalaryDecidedByCompanyPolicy?: Maybe<Scalars['Boolean']>,
  isScrapped: Scalars['Boolean'],
  isSponsored?: Maybe<Scalars['Boolean']>,
  /** 면접 후 근무 시간 결정 */
  isWorkHourDecidedAfterInterview?: Maybe<Scalars['Boolean']>,
  jobTypes?: Maybe<Array<JobType>>,
  logoImage?: Maybe<ActivityFile>,
  manager?: Maybe<User>,
  /** 담당자 부서 */
  managerDepartment?: Maybe<Scalars['String']>,
  managerEmail?: Maybe<Scalars['String']>,
  managerName?: Maybe<Scalars['String']>,
  managerOneline: Scalars['String'],
  managerPhoneNumber?: Maybe<Scalars['String']>,
  managerReplies?: Maybe<Array<Maybe<Reply>>>,
  /** 최대 급여 */
  maxSalary?: Maybe<Scalars['Int']>,
  /** 최소 급여 */
  minSalary?: Maybe<Scalars['Int']>,
  onoffline?: Maybe<Onoffline>,
  onofflineId?: Maybe<Scalars['ID']>,
  ordinal: Scalars['Int'],
  organizationType?: Maybe<Scalars['String']>,
  organizationTypeID?: Maybe<Scalars['ID']>,
  originManagerEmail?: Maybe<Scalars['String']>,
  originManagerPhoneNumber?: Maybe<Scalars['String']>,
  outLinks?: Maybe<Array<Maybe<ActivityOutLink>>>,
  percent: Scalars['Int'],
  posterImage?: Maybe<ActivityFile>,
  questionType?: Maybe<QuestionType>,
  recruitCloseAt?: Maybe<Scalars['Date']>,
  /** 모집 정보 */
  recruitInformations: Array<Maybe<RecruitInformation>>,
  recruitScale?: Maybe<Scalars['String']>,
  recruitStartAt?: Maybe<Scalars['Date']>,
  recruitType: ActivityRecruitType,
  refusalReason: Scalars['String'],
  regionDistricts?: Maybe<Array<Maybe<RegionDistrict>>>,
  regions?: Maybe<Array<Maybe<Region>>>,
  relatedContentUpdatedAt?: Maybe<Scalars['Date']>,
  replies?: Maybe<Array<Maybe<Reply>>>,
  replyCount?: Maybe<Scalars['Int']>,
  rootCategories: Array<Category>,
  /** 급여 유형 */
  salaryType?: Maybe<ActivityWorkingConditionSalarySalaryType>,
  scrapCount?: Maybe<Scalars['Int']>,
  scrapUsers: Array<User>,
  skills?: Maybe<Array<Maybe<Skill>>>,
  status?: Maybe<ActivityStatus>,
  statusUpdatedAt?: Maybe<Scalars['Date']>,
  targets?: Maybe<Array<Maybe<ActivityTarget>>>,
  tenThousandUnitOfReward?: Maybe<Scalars['Int']>,
  texts?: Maybe<Array<Maybe<ActivityText>>>,
  thumbnailImage?: Maybe<ActivityFile>,
  type?: Maybe<Scalars['Int']>,
  userScraps?: Maybe<Array<Maybe<UserScrap>>>,
  viewCount: Scalars['Int'],
  /** 복리 후생 */
  welfareBenefits: Array<Maybe<WelfareBenefit>>,
  /** 근무 종료 시간 */
  workEndTime?: Maybe<Scalars['String']>,
  /** 근무 시간 유형 */
  workHourType?: Maybe<ActivityWorkingConditionWorkHourType>,
  /** 근무 시작 시간 */
  workStartTime?: Maybe<Scalars['String']>,
  /** 근무 시간 */
  workingHours?: Maybe<Scalars['String']>,
  youtubeURL?: Maybe<Scalars['String']>,
};


/** 공고 */
export type ActivityAdvertisementArgs = {
  adType: AdType
};


/** 공고 */
export type ActivityDutiesArgs = {
  after?: Maybe<Scalars['String']>,
  filterBy?: Maybe<DutyFeedFilters>,
  first?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<DutyOrder>
};

/** 근무 지역 */
export type ActivityAddress = {
   __typename?: 'ActivityAddress',
  /** 주소 */
  address: Scalars['String'],
  /** 법정동/법정리 코드 */
  bcode: Scalars['String'],
  /** 법정동/법정리 */
  bname: Scalars['String'],
  /** 상세 주소 */
  detailAddress?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  /** 해외 지역 */
  isAbroad: Scalars['Boolean'],
  /** 재택근무 가능 여부 */
  isPossibleWorkingFromHome: Scalars['Boolean'],
  /** 도/시 */
  sido: Scalars['String'],
  /** 시/군/구 */
  sigungu: Scalars['String'],
  /** 시/군/구 코드 */
  sigunguCode: Scalars['String'],
  /** 우편번호 */
  zoneCode: Scalars['String'],
};

/** 공고 지원 절차 */
export type ActivityApplicationProcedure = {
   __typename?: 'ActivityApplicationProcedure',
  /** 생성일 */
  createdAt: Scalars['Date'],
  /** 절차 설명 */
  description: Scalars['String'],
  id: Scalars['ID'],
  /** 절차 순서 */
  procedureOrder: Scalars['Int'],
  /** 절차 유형 */
  procedureType: Scalars['String'],
};

/** 공고 지원 절차 - 절차 유형 */
export enum ActivityApplicationProcedureProcedureType {
  APTITUDE = 'APTITUDE',
  DOCUMENT = 'DOCUMENT',
  INTERVIEW = 'INTERVIEW',
  OTHERS = 'OTHERS',
  REFERENCE = 'REFERENCE'
}

/**  공고 승인 알림  */
export type ActivityApprovedNotification = SimpleNotification & {
   __typename?: 'ActivityApprovedNotification',
  activity?: Maybe<Activity>,
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  deepLinkUrl?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  linkUrl: Scalars['String'],
  title?: Maybe<Scalars['String']>,
  unread: Scalars['Boolean'],
  updatedAt?: Maybe<Scalars['Date']>,
};

export type ActivityCalendar = {
   __typename?: 'ActivityCalendar',
  end: ActivityCalendarConnection,
  start: ActivityCalendarConnection,
};

export type ActivityCalendarConnection = {
   __typename?: 'ActivityCalendarConnection',
  nodes: Array<ActivityCalendarMap>,
  totalCount: Scalars['Int'],
};

export type ActivityCalendarEntry = {
   __typename?: 'ActivityCalendarEntry',
  /** 
 * 입력된 날짜의 00시 00분 ~ 23시 59분 사이의 데이터를 조회.
   * 예) Thu Sep 08 2022 15:38:17 -> 22년 9월 8일 00시 ~ 23시 59분 사이에 모집 시작/마감하는 공고 조회
 **/
  date: Scalars['Date'],
  /** ActivityCalendarEntry.date 해당 일에 모집을 마감하는 공고 */
  end?: Maybe<ActivityConnection>,
  /** 
 * 스크랩한 공고 중 해당일에 모집을 마감하는 공고
   * 로그인 하지 않고 요청시 빈 배열을 리턴
   * { totalCount: 0, nodes: [] }
 **/
  scrapEnd?: Maybe<ActivityConnection>,
  /** 
 * 스크랩한 공고 중 해당일에 모집을 시작하는 공고
   * 로그인 하지 않고 요청시 빈 배열을 리턴
   * { totalCount: 0, nodes: [] }
 **/
  scrapStart?: Maybe<ActivityConnection>,
  /** 
 * 스크랩한 공고 중 해당일에 모집을 시작/마감하는 공고
   * 로그인 하지 않고 요청시 빈 배열을 리턴
   * { totalCount: 0, nodes: [] }
 **/
  scrapStartAndEnd?: Maybe<ActivityConnection>,
  /** ActivityCalendarEntry.date 해당 일에 모집을 시작하는 공고 */
  start?: Maybe<ActivityConnection>,
  /** 
 * ActivityCalendarEntry.date 해당 일에 모집을 시작/마감하는 공고
   * 시작공고 -> 마감공고 순으로 오름차순 정렬
 **/
  startAndEnd?: Maybe<ActivityConnection>,
};


export type ActivityCalendarEntryEndArgs = {
  filterBy?: Maybe<ActivityFilter>,
  pagination?: Maybe<Pagination>
};


export type ActivityCalendarEntryScrapEndArgs = {
  filterBy?: Maybe<ScrapActivityFilter>,
  pagination?: Maybe<Pagination>
};


export type ActivityCalendarEntryScrapStartArgs = {
  filterBy?: Maybe<ScrapActivityFilter>,
  pagination?: Maybe<Pagination>
};


export type ActivityCalendarEntryScrapStartAndEndArgs = {
  filterBy?: Maybe<ScrapActivityFilter>,
  pagination?: Maybe<Pagination>
};


export type ActivityCalendarEntryStartArgs = {
  filterBy?: Maybe<ActivityFilter>,
  pagination?: Maybe<Pagination>
};


export type ActivityCalendarEntryStartAndEndArgs = {
  filterBy?: Maybe<ActivityFilter>,
  pagination?: Maybe<Pagination>
};

export type ActivityCalendarEntryConnection = {
   __typename?: 'ActivityCalendarEntryConnection',
  nodes: Array<ActivityCalendarEntry>,
  /** 
 * 요청한 기간의 일수
   * 예) from: 2022-05-01, to: 2022-05-31 => totalCount는 31
 **/
  totalCount: Scalars['Int'],
};

export enum ActivityCalendarGroupByField {
  RECRUIT_CLOSEAT = 'RECRUIT_CLOSEAT',
  RECRUIT_STARTAT = 'RECRUIT_STARTAT'
}

export type ActivityCalendarMap = {
   __typename?: 'ActivityCalendarMap',
  date: Scalars['String'],
  items: Array<Activity>,
};

export type ActivityCardNewsListInput = {
  activityID: Scalars['ID'],
};

export type ActivityCardNewsListPayload = {
   __typename?: 'ActivityCardNewsListPayload',
  cardNews?: Maybe<Array<ActivityFile>>,
  count: Scalars['Int'],
};

export type ActivityConnection = {
   __typename?: 'ActivityConnection',
  nodes?: Maybe<Array<Activity>>,
  totalCount: Scalars['Int'],
};

export type ActivityCountsPayload = {
   __typename?: 'ActivityCountsPayload',
  activity: Scalars['Int'],
  club: Scalars['Int'],
  contest: Scalars['Int'],
  education: Scalars['Int'],
  intern: Scalars['Int'],
  recruit: Scalars['Int'],
};

export enum ActivityDeadlineStatus {
  ALL = 'ALL',
  CLOSE = 'CLOSE',
  OPEN = 'OPEN',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED'
}

export type ActivityDeletePayload = {
   __typename?: 'ActivityDeletePayload',
  activity?: Maybe<Activity>,
};

export type ActivityFile = {
   __typename?: 'ActivityFile',
  activity?: Maybe<Activity>,
  attachment?: Maybe<Attachment>,
  filename?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  type?: Maybe<ActivityFileType>,
  url?: Maybe<Scalars['String']>,
};

export type ActivityFileAttachmentInput = {
  attachmentID?: Maybe<Scalars['ID']>,
  id?: Maybe<Scalars['ID']>,
  name?: Maybe<Scalars['String']>,
  size?: Maybe<Scalars['Int']>,
  url?: Maybe<Scalars['String']>,
};

export type ActivityFileCreateInput = {
  activityID?: Maybe<Scalars['ID']>,
  attachmentID?: Maybe<Scalars['ID']>,
  fileTypeID: Scalars['ID'],
  filename?: Maybe<Scalars['String']>,
  url?: Maybe<Scalars['String']>,
};

export type ActivityFileDeletePayload = {
   __typename?: 'ActivityFileDeletePayload',
  activityFile?: Maybe<ActivityFile>,
};

export type ActivityFileType = {
   __typename?: 'ActivityFileType',
  id: Scalars['ID'],
  name: Scalars['String'],
};

export type ActivityFilter = {
  /** Use activityTypeIDs field */
  activityTypeID?: Maybe<Scalars['ID']>,
  activityTypeIDs?: Maybe<Array<Scalars['ID']>>,
  benefitIDs?: Maybe<Array<Scalars['ID']>>,
  categoryIDs?: Maybe<Array<Scalars['ID']>>,
  cost?: Maybe<Array<EducationCost>>,
  createdAtTerm?: Maybe<CreatedAtTerm>,
  excludedStatus?: Maybe<ActivityStatus>,
  interestIDs?: Maybe<Array<Scalars['ID']>>,
  internTypeIds?: Maybe<Array<Scalars['ID']>>,
  isAd?: Maybe<Scalars['Boolean']>,
  isDeleted?: Maybe<Scalars['Boolean']>,
  isHidden?: Maybe<Scalars['Boolean']>,
  jobTypes?: Maybe<Array<Maybe<JobType>>>,
  onofflineId?: Maybe<Scalars['ID']>,
  orgTypeIDs?: Maybe<Array<Scalars['ID']>>,
  organizationName?: Maybe<Scalars['String']>,
  /** Use orgTypeIDs field */
  organizationTypeIDs?: Maybe<Array<RecruitOrganizationType>>,
  /** Use orgTypeIDs field */
  organizationTypes?: Maybe<Array<Scalars['ID']>>,
  period?: Maybe<ActivityPeriod>,
  q?: Maybe<Scalars['String']>,
  regionIDs?: Maybe<Array<Scalars['ID']>>,
  reward?: Maybe<ActivityReward>,
  status?: Maybe<ActivityStatus>,
  targetIDs?: Maybe<Array<Scalars['ID']>>,
  withClosed?: Maybe<Scalars['Boolean']>,
};

export type ActivityFilterCollection = {
   __typename?: 'ActivityFilterCollection',
  benefits: Array<Benefit>,
  categories: Array<Category>,
  interests: Array<Interest>,
  organizationTypes: Array<OrganizationType>,
  periods: Array<Period>,
  rewards: Array<Reward>,
};

export type ActivityFilterItem = ActivityFilterItemById | ActivityFitlerItemByRange;

export type ActivityFilterItemBase = {
  name: Scalars['String'],
};

export type ActivityFilterItemById = ActivityFilterItemBase & {
   __typename?: 'ActivityFilterItemByID',
  id: Scalars['ID'],
  name: Scalars['String'],
};

/** 공고 필터 입력 */
export type ActivityFilterListInput = {
  activityTypeID: Scalars['Int'],
};

/** 공고 필터 */
export type ActivityFilterListItem = {
   __typename?: 'ActivityFilterListItem',
  duplicatable?: Maybe<Scalars['Boolean']>,
  items?: Maybe<Array<Maybe<ActivityFilterItem>>>,
  type: Scalars['String'],
};

export enum ActivityFilterType {
  BENEFIT = 'BENEFIT',
  CATEGORY = 'CATEGORY',
  INTEREST = 'INTEREST',
  ORGANIZATION = 'ORGANIZATION',
  PERIOD = 'PERIOD',
  PRIZE = 'PRIZE',
  REGION = 'REGION',
  TARGET = 'TARGET'
}

export type ActivityFitlerItemByRange = ActivityFilterItemBase & {
   __typename?: 'ActivityFitlerItemByRange',
  from?: Maybe<Scalars['Int']>,
  name: Scalars['String'],
  to?: Maybe<Scalars['Int']>,
};

export type ActivityForRelatedContentFilter = {
  activityIDs?: Maybe<Array<Scalars['ID']>>,
  activityTypeID?: Maybe<Scalars['ID']>,
  createdAtTerm?: Maybe<CreatedAtTerm>,
  organizationName?: Maybe<Scalars['String']>,
  status?: Maybe<ActivityStatus>,
  title?: Maybe<Scalars['String']>,
};

export type ActivityInteger = {
   __typename?: 'ActivityInteger',
  activity: Activity,
  id: Scalars['ID'],
  integer: Scalars['String'],
  type: ActivityIntegerType,
};

export type ActivityIntegerType = {
   __typename?: 'ActivityIntegerType',
  activityType?: Maybe<ActivityType>,
  id: Scalars['ID'],
  name: Scalars['String'],
  unit: Scalars['String'],
};

export type ActivityInternType = {
   __typename?: 'ActivityInternType',
  id: Scalars['ID'],
  name: Scalars['String'],
};

/** 담당자 정보 불러오기용 프리셋 */
export type ActivityManagerPreset = {
   __typename?: 'ActivityManagerPreset',
  /** 담당자 부서 */
  department: Scalars['String'],
  /** 담당자 이메일 */
  email: Scalars['String'],
  id: Scalars['ID'],
  /** 담당자 이름 */
  name: Scalars['String'],
  /** 담당자 전화번호 */
  phoneNumber: Scalars['String'],
};

export type ActivityManagerPresetOffsetConnection = {
   __typename?: 'ActivityManagerPresetOffsetConnection',
  nodes: Array<ActivityManagerPreset>,
  totalCount: Scalars['Int'],
};

export type ActivityOrder = {
  direction: OrderDirection,
  field: ActivityOrderField,
};

export enum ActivityOrderField {
  CREATED_AT = 'CREATED_AT',
  DEAD_LINE = 'DEAD_LINE',
  RECENT = 'RECENT',
  RECRUIT_CLOSE_AT = 'RECRUIT_CLOSE_AT',
  REPLY_COUNT = 'REPLY_COUNT',
  SCRAP_COUNT = 'SCRAP_COUNT',
  STATUS_UPDATED_AT = 'STATUS_UPDATED_AT',
  VIEW_COUNT = 'VIEW_COUNT'
}

export type ActivityOrganizationLogoInput = {
  /** preset의 logo id */
  attachmentId?: Maybe<Scalars['ID']>,
  /** 새로 등록할 이미지 */
  logoFile?: Maybe<Scalars['Upload']>,
};

/** 기업 정보 불러오기용 프리셋 */
export type ActivityOrganizationPreset = {
   __typename?: 'ActivityOrganizationPreset',
  /** 홈페이지 url */
  homepageURL?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  /** 업종 */
  industries: Array<Industry>,
  logo: Attachment,
  /** 기업 이름 */
  name: Scalars['String'],
  /** 기업구분 */
  organizationType: OrganizationType,
  /** 복리후생 */
  welfareBenefits: Array<WelfareBenefit>,
};

export type ActivityOrganizationPresetOffsetConnection = {
   __typename?: 'ActivityOrganizationPresetOffsetConnection',
  nodes: Array<ActivityOrganizationPreset>,
  totalCount: Scalars['Int'],
};

export type ActivityOutLink = {
   __typename?: 'ActivityOutLink',
  activity: Activity,
  clickedCount: Scalars['Int'],
  id: Scalars['ID'],
  link: Scalars['String'],
  type: ActivityOutLinkType,
};

export enum ActivityOutLinkType {
  APPLY_FILE = 'APPLY_FILE',
  APPLY_SITE = 'APPLY_SITE',
  BLOG = 'BLOG',
  CAFE = 'CAFE',
  FACEBOOK = 'FACEBOOK',
  SITE = 'SITE',
  YOUTUBE_CHANNEL = 'YOUTUBE_CHANNEL'
}

export type ActivityPeriod = {
  from?: Maybe<Scalars['Int']>,
  to?: Maybe<Scalars['Int']>,
};

/** 공고 자격 조건 - 학력 */
export enum ActivityQualificationEducation {
  COLLEGE_GRADUATE_2_3_YEARS = 'COLLEGE_GRADUATE_2_3_YEARS',
  COLLEGE_GRADUATE_4_YEARS = 'COLLEGE_GRADUATE_4_YEARS',
  DOCTOR_GRADUATE = 'DOCTOR_GRADUATE',
  HIGH_SCHOOL_GRADUATE = 'HIGH_SCHOOL_GRADUATE',
  MASTER_GRADUATE = 'MASTER_GRADUATE',
  NO_LIMIT = 'NO_LIMIT'
}

/** 자격 조건 */
export type ActivityQualificationInput = {
  /** 학력 */
  education: ActivityQualificationEducation,
  /** 졸업 예정자 허용 여부 */
  isExpectedGraduateAllowed: Scalars['Boolean'],
};

export type ActivityRange = {
  recruitCloseAt?: Maybe<DateRange>,
  recruitStartAt?: Maybe<DateRange>,
};

export enum ActivityRecruitType {
  ALWAYS = 'ALWAYS',
  ASAP = 'ASAP',
  NORMAL = 'NORMAL',
  SCHEDULED = 'SCHEDULED'
}

export type ActivityReference = {
   __typename?: 'ActivityReference',
  activity: Activity,
  activityType?: Maybe<ActivityType>,
  createdAt: Scalars['Date'],
  id: Scalars['ID'],
  isConfirmed: Scalars['Boolean'],
  organization?: Maybe<Organization>,
  title: Scalars['String'],
};

export type ActivityReferenceUpdateInput = {
  id: Scalars['ID'],
  title?: Maybe<Scalars['String']>,
};

export type ActivityReferenceUpdatePayload = {
   __typename?: 'ActivityReferenceUpdatePayload',
  activityReference?: Maybe<ActivityReference>,
};

/**  공고 승인 거절 알림  */
export type ActivityRefusedNotification = SimpleNotification & {
   __typename?: 'ActivityRefusedNotification',
  activity?: Maybe<Activity>,
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  deepLinkUrl?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  linkUrl: Scalars['String'],
  title?: Maybe<Scalars['String']>,
  unread: Scalars['Boolean'],
  updatedAt?: Maybe<Scalars['Date']>,
};

export type ActivityReward = {
  from?: Maybe<Scalars['Int']>,
  to?: Maybe<Scalars['Int']>,
};

export type ActivityScrapUpdateInput = {
  activityID: Scalars['ID'],
  referer: Scalars['String'],
};

export type ActivityScrapUpdatePayload = {
   __typename?: 'ActivityScrapUpdatePayload',
  activity?: Maybe<Activity>,
  scrapped?: Maybe<Scalars['Boolean']>,
  userScrap?: Maybe<UserScrap>,
};

export type ActivityScrapUserListConnection = {
   __typename?: 'ActivityScrapUserListConnection',
  nodes: Array<User>,
  totalCount: Scalars['Int'],
};

export type ActivityScrapUserListInput = {
  activityID: Scalars['ID'],
};

export type ActivitySearchConnection = {
   __typename?: 'ActivitySearchConnection',
  nodes: Array<ActivitySearchResult>,
  totalCount: Scalars['Int'],
};

export type ActivitySearchFilter = {
  isClosed?: Maybe<Scalars['Boolean']>,
  query?: Maybe<Scalars['String']>,
};

export type ActivitySearchOrder = {
  direction?: Maybe<OrderDirection>,
  field?: Maybe<ActivitySearchOrderType>,
};

export enum ActivitySearchOrderType {
  CREATED_AT = 'CREATED_AT',
  RECRUIT_CLOSEAT = 'RECRUIT_CLOSEAT',
  RELEVANCE = 'RELEVANCE',
  SCRAP_COUNT = 'SCRAP_COUNT',
  VIEW_COUNT = 'VIEW_COUNT'
}

export type ActivitySearchResult = {
   __typename?: 'ActivitySearchResult',
  score?: Maybe<Scalars['Float']>,
  source?: Maybe<Activity>,
};

export type ActivitySelfIntroductionStatistic = {
   __typename?: 'ActivitySelfIntroductionStatistic',
  activity?: Maybe<Activity>,
  createdAt: Scalars['Date'],
  date: Scalars['String'],
  id: Scalars['ID'],
  selfIntroductionCount: Scalars['Int'],
  selfIntroductionUserCount: Scalars['Int'],
};

export type ActivitySelfIntroductionStatisticConnection = {
   __typename?: 'ActivitySelfIntroductionStatisticConnection',
  nodes: Array<ActivitySelfIntroductionStatistic>,
  totalCount: Scalars['Int'],
};

export type ActivitySelfIntroductionStatisticFilters = {
  dateRange?: Maybe<DateRange>,
};

export type ActivitySelfIntroductionStatisticOrder = {
  direction: OrderDirection,
  field: ActivitySelfIntroductionStatisticOrderField,
};

export enum ActivitySelfIntroductionStatisticOrderField {
  SELF_INTRODUCTION_COUNT = 'SELF_INTRODUCTION_COUNT',
  SELF_INTRODUCTION_USER_COUNT = 'SELF_INTRODUCTION_USER_COUNT'
}

export enum ActivityStatus {
  OPEN = 'OPEN',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  REJECTED_ETC = 'REJECTED_ETC',
  TEMP = 'TEMP'
}

export type ActivityTarget = {
   __typename?: 'ActivityTarget',
  id: Scalars['ID'],
  name: Scalars['String'],
  seq?: Maybe<Scalars['Int']>,
};

export type ActivityText = {
   __typename?: 'ActivityText',
  activity: Activity,
  id: Scalars['ID'],
  text: Scalars['String'],
  type: ActivityTextType,
};

export type ActivityTextType = {
   __typename?: 'ActivityTextType',
  activityType?: Maybe<ActivityType>,
  id: Scalars['ID'],
  name: Scalars['String'],
};

export type ActivityType = {
   __typename?: 'ActivityType',
  id: Scalars['ID'],
  name: Scalars['String'],
};

/** 
 * 채팅방 타입
 * 
 * - 대외활동
 * - 동아리
 * - 공모전
 * - 인턴
 * - 공고
 **/
export enum ActivityTypeId {
  ACTIVITY = 'ACTIVITY',
  CLUB = 'CLUB',
  CONTEST = 'CONTEST',
  INTERN = 'INTERN',
  RECRUIT = 'RECRUIT',
  EDUCATION = 'EDUCATION'
}

export type ActivityUpdateInput = {
  id: Scalars['ID'],
  refusalReason?: Maybe<Scalars['String']>,
  status?: Maybe<ActivityStatus>,
  title?: Maybe<Scalars['String']>,
};

export type ActivityUpdatePayload = {
   __typename?: 'ActivityUpdatePayload',
  activity?: Maybe<Activity>,
};

export type ActivityViewCountUpdatePayload = {
   __typename?: 'ActivityViewCountUpdatePayload',
  activity?: Maybe<Activity>,
};

/** 공고 근무 조건 - 급여 */
export enum ActivityWorkingConditionSalarySalaryType {
  DAILY = 'DAILY',
  HOURLY = 'HOURLY',
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY',
  YEARLY = 'YEARLY'
}

/** 공고 근무 조건 - 근무시간 */
export enum ActivityWorkingConditionWorkHourType {
  FIVE_DAYS_A_WEEK = 'FIVE_DAYS_A_WEEK',
  FLEXIBLE = 'FLEXIBLE',
  OTHERS = 'OTHERS',
  SIX_DAYS_A_WEEK = 'SIX_DAYS_A_WEEK',
  THREE_DAYS_A_WEEK = 'THREE_DAYS_A_WEEK'
}

export type Actor = Channel | User;

export type ActorConnection = {
   __typename?: 'ActorConnection',
  edges: Array<ActorEdge>,
  nodes: Array<Maybe<Actor>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type ActorEdge = {
   __typename?: 'ActorEdge',
  cursor: Scalars['String'],
  node: Actor,
};

export type Ad = {
   __typename?: 'Ad',
  activity?: Maybe<Activity>,
  adPlacement?: Maybe<AdPlacement>,
  advertiser?: Maybe<Scalars['String']>,
  campaign?: Maybe<Campaign>,
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  id: Scalars['ID'],
  image?: Maybe<Attachment>,
  order: Scalars['Int'],
  relatedId?: Maybe<Scalars['ID']>,
  relationType?: Maybe<AdRelationType>,
  title?: Maybe<Scalars['String']>,
  updatedAt?: Maybe<Scalars['Date']>,
};

export type AdConnection = {
   __typename?: 'AdConnection',
  nodes: Array<Ad>,
  totalCount: Scalars['Int'],
};

export type AdCreateInput = {
  adPlacementID: Scalars['ID'],
  campaignID: Scalars['ID'],
  imageID: Scalars['ID'],
};

export type AddBookmarkPayload = {
   __typename?: 'AddBookmarkPayload',
  newsItem?: Maybe<NewsItem>,
};

export type AddCommunityBookmarkPayLoad = {
   __typename?: 'addCommunityBookmarkPayLoad',
  bookmarkable?: Maybe<CommunityBookmarkable>,
};

export type AddCommunityMenuBookmarkPayLoad = {
   __typename?: 'addCommunityMenuBookmarkPayLoad',
  bookmarkable?: Maybe<CommunityBookmarkable>,
};

export type AddLikePayload = {
   __typename?: 'AddLikePayload',
  newsItem?: Maybe<NewsItem>,
};

export type AddressId = {
  districtID?: Maybe<Scalars['ID']>,
  regionID?: Maybe<Scalars['ID']>,
};

export type AddTypeByOrgNameRegexInput = {
  orgNameRegex: Scalars['String'],
  type: CoverLetterType,
};

export type AddTypeByOrgNameRegexPayload = {
   __typename?: 'AddTypeByOrgNameRegexPayload',
  updatedCoverLetters?: Maybe<Array<Maybe<CoverLetter>>>,
};

export type AdminCampaignConnection = {
   __typename?: 'AdminCampaignConnection',
  nodes: Array<Campaign>,
  totalCount: Scalars['Int'],
};

export type AdminCampaignFilters = {
  adPlacementIds: Array<Scalars['ID']>,
  dateRange?: Maybe<DateRange>,
  name?: Maybe<Scalars['String']>,
  status: AdStatus,
};

export enum AdminCampaignOrderField {
  END_AT = 'END_AT',
  START_AT = 'START_AT'
}

export type AdminCampaignOrders = {
  direction: OrderDirection,
  field: AdminCampaignOrderField,
};

export type AdPlacement = {
   __typename?: 'AdPlacement',
  ads: Array<Ad>,
  code: Scalars['String'],
  createdAt: Scalars['Date'],
  defaultImage?: Maybe<Attachment>,
  defaultLandingURL?: Maybe<Scalars['String']>,
  height: Scalars['Int'],
  id: Scalars['ID'],
  isActive?: Maybe<Scalars['Boolean']>,
  name: Scalars['String'],
  updatedAt?: Maybe<Scalars['Date']>,
  width: Scalars['Int'],
};

export type AdPlacementClickLog = {
   __typename?: 'AdPlacementClickLog',
  adPlacement?: Maybe<AdPlacement>,
  adPlacementImressionLog?: Maybe<AdPlacementImpressionLog>,
  campaign?: Maybe<Campaign>,
  createdAt?: Maybe<Scalars['Date']>,
  createdDate?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  user?: Maybe<User>,
};

export type AdPlacementConnection = {
   __typename?: 'AdPlacementConnection',
  nodes: Array<AdPlacement>,
  totalCount: Scalars['Int'],
};

export type AdPlacementCreateInput = {
  code: Scalars['String'],
  defaultImageID?: Maybe<Scalars['ID']>,
  defaultLandingURL?: Maybe<Scalars['String']>,
  height: Scalars['Int'],
  isActive: Scalars['Boolean'],
  name: Scalars['String'],
  width: Scalars['Int'],
};

export type AdPlacementCreatePayload = {
   __typename?: 'AdPlacementCreatePayload',
  adPlacement?: Maybe<AdPlacement>,
};

export type AdPlacementFilters = {
  isActive?: Maybe<Scalars['Boolean']>,
  q?: Maybe<Scalars['String']>,
};

export type AdPlacementImpressionLog = {
   __typename?: 'AdPlacementImpressionLog',
  adPlacement?: Maybe<AdPlacement>,
  campaign?: Maybe<Campaign>,
  createdAt?: Maybe<Scalars['Date']>,
  createdDate?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  user?: Maybe<User>,
};

export type AdPlacementLandingUrl = {
   __typename?: 'AdPlacementLandingURL',
  landingURL?: Maybe<Scalars['String']>,
};

export type AdPlacementOrder = {
  direction: OrderDirection,
  field: AdPlacementOrderField,
};

export enum AdPlacementOrderField {
  CREATED_AT = 'CREATED_AT',
  HEIGHT = 'HEIGHT',
  ID = 'ID',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT',
  WIDTH = 'WIDTH'
}

export type AdPlacementsByCampaignIdPayload = {
   __typename?: 'AdPlacementsByCampaignIDPayload',
  adPlacements: Array<AdPlacement>,
};

export type AdPlacementUpdateInput = {
  code?: Maybe<Scalars['String']>,
  defaultImageID?: Maybe<Scalars['ID']>,
  defaultLandingURL?: Maybe<Scalars['String']>,
  isActive?: Maybe<Scalars['Boolean']>,
  name?: Maybe<Scalars['String']>,
};

export type AdPlacementUpdatePayload = {
   __typename?: 'AdPlacementUpdatePayload',
  adPlacement?: Maybe<AdPlacement>,
};

export enum AdRelationType {
  ACTIVITY = 'ACTIVITY'
}

export enum AdStatus {
  CLOSED = 'CLOSED',
  OPEN = 'OPEN',
  RESERVED = 'RESERVED'
}

export enum AdType {
  HOTLINE_BANNER = 'HOTLINE_BANNER',
  LIVE_ACTIVITY_INFO = 'LIVE_ACTIVITY_INFO',
  LIVE_POPULAR_RECRUIT = 'LIVE_POPULAR_RECRUIT',
  POPULAR_ACTIVITY = 'POPULAR_ACTIVITY',
  RECOMMEND_A_BANNER = 'RECOMMEND_A_BANNER',
  RECOMMEND_S_BANNER = 'RECOMMEND_S_BANNER'
}

export type AdUpsertInput = {
  adPlacementID?: Maybe<Scalars['ID']>,
  campaignID?: Maybe<Scalars['ID']>,
  id?: Maybe<Scalars['ID']>,
  imageID?: Maybe<Scalars['ID']>,
};

export type Advertisement = {
   __typename?: 'Advertisement',
  activity?: Maybe<Activity>,
  activityID?: Maybe<Scalars['ID']>,
  adType?: Maybe<AdType>,
  content?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['Date']>,
  deletedAt?: Maybe<Scalars['Date']>,
  endAt?: Maybe<Scalars['Date']>,
  id: Scalars['ID'],
  mobileImage?: Maybe<Scalars['String']>,
  outLink?: Maybe<Scalars['String']>,
  pcImage?: Maybe<Scalars['String']>,
  seq?: Maybe<Scalars['Int']>,
  startAt?: Maybe<Scalars['Date']>,
  status?: Maybe<AdStatus>,
  title?: Maybe<Scalars['String']>,
  updatedAt?: Maybe<Scalars['Date']>,
};

export type AdvertisementBulkDeletePayload = {
   __typename?: 'AdvertisementBulkDeletePayload',
  advertisements?: Maybe<Array<Advertisement>>,
};

export type AdvertisementCreateInput = {
  activityID?: Maybe<Scalars['ID']>,
  adType: AdType,
  endAt: Scalars['Date'],
  mobileImage?: Maybe<Scalars['String']>,
  outLink?: Maybe<Scalars['String']>,
  pcImage?: Maybe<Scalars['String']>,
  seq: Scalars['Int'],
  startAt: Scalars['Date'],
  title?: Maybe<Scalars['String']>,
};

export type AdvertisementCreatePayload = {
   __typename?: 'AdvertisementCreatePayload',
  advertisement?: Maybe<Advertisement>,
};

export type AdvertisementDeletePayload = {
   __typename?: 'AdvertisementDeletePayload',
  advertisement?: Maybe<Advertisement>,
};

export type AdvertisementFilter = {
  adType: AdType,
  overlappingTerm?: Maybe<AdvertisementTerm>,
  seq?: Maybe<Scalars['Int']>,
  status?: Maybe<AdStatus>,
};

export type AdvertisementListPayload = {
   __typename?: 'AdvertisementListPayload',
  activities?: Maybe<Array<Activity>>,
  advertisements?: Maybe<Array<Advertisement>>,
  totalCount?: Maybe<Scalars['Int']>,
};

export type AdvertisementOrder = {
  direction?: Maybe<OrderDirection>,
  field?: Maybe<AdvertisementOrderField>,
};

export enum AdvertisementOrderField {
  ID = 'ID',
  SEQ = 'SEQ'
}

export type AdvertisementTerm = {
  endAt: Scalars['Date'],
  startAt: Scalars['Date'],
};

export type AdvertisementUpdateInput = {
  activityID?: Maybe<Scalars['ID']>,
  adType: AdType,
  endAt: Scalars['Date'],
  id: Scalars['ID'],
  mobileImage?: Maybe<Scalars['String']>,
  outLink?: Maybe<Scalars['String']>,
  pcImage?: Maybe<Scalars['String']>,
  seq: Scalars['Int'],
  startAt: Scalars['Date'],
  title?: Maybe<Scalars['String']>,
};

export type AdvertisementUpdatePayload = {
   __typename?: 'AdvertisementUpdatePayload',
  advertisement?: Maybe<Advertisement>,
};

export type ApplicationProcedureInput = {
  /** 절차 설명 */
  description?: Maybe<Scalars['String']>,
  /** 절차 유형 */
  procedureType: ActivityApplicationProcedureProcedureType,
};

export type ApplyFile = {
   __typename?: 'ApplyFile',
  filename?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  url?: Maybe<Scalars['String']>,
};

export type ApplyType = {
   __typename?: 'ApplyType',
  id: Scalars['ID'],
  name: Scalars['String'],
  seq?: Maybe<Scalars['Int']>,
};

export type Attachment = {
   __typename?: 'Attachment',
  id: Scalars['ID'],
  name: Scalars['String'],
  path?: Maybe<Scalars['String']>,
  size: Scalars['Int'],
  type: Scalars['String'],
  url: Scalars['String'],
};

export type AttachmentCompleteUploadPayload = {
   __typename?: 'AttachmentCompleteUploadPayload',
  attachment?: Maybe<Attachment>,
};

export type AttachmentConnection = {
   __typename?: 'AttachmentConnection',
  nodes: Array<Maybe<Attachment>>,
  totalCount: Scalars['Int'],
};

export type AttachmentDeletePayload = {
   __typename?: 'AttachmentDeletePayload',
  attachment?: Maybe<Attachment>,
};

export type AttachmentPrepareUploadPayload = {
   __typename?: 'AttachmentPrepareUploadPayload',
  attachment: Attachment,
  signedURL: Scalars['String'],
};

export type AuthenticateEmailCallbackInput = {
  authenticationToken: Scalars['String'],
  emailCode: Scalars['String'],
};

export type AuthenticateEmailCallbackPayload = {
   __typename?: 'AuthenticateEmailCallbackPayload',
  confirmToken: Scalars['String'],
  email: Scalars['String'],
};

export type AuthenticateEmailInput = {
  duplicateCheck?: Maybe<Scalars['Boolean']>,
  email: Scalars['String'],
  ttl?: Maybe<Scalars['Int']>,
};

export type AuthenticateEmailPayload = {
   __typename?: 'AuthenticateEmailPayload',
  authenticationToken: Scalars['String'],
};

/** 
 * 차단된 유저 검색 필드
 * 
 * - CHAT_NICKNAME: 채팅 메시지를 보낸 유저의 닉네임
 * - USER_ID: 채팅 메시지를 보낸 유저의 아이디
 **/
export enum BannedUserSearchField {
  CHAT_NICKNAME = 'CHAT_NICKNAME',
  USER_ID = 'USER_ID'
}

/** 활동 정지 유저 검색 조건 */
export type BannedUserSearchInput = {
  /** 검색 필드 */
  searchField: BannedUserSearchField,
  /** 검색 키워드 */
  searchKeyword: Scalars['String'],
};

/** 활동 정지 사유&기간 */
export type BanUserInChatInput = {
  /** 활동 정지 사유 */
  reason: Scalars['String'],
  /** 활동 정지 기간 */
  until: Scalars['Date'],
};

/** 활동 정지 후, 생성된 정지 로그 */
export type BanUserInChatPayload = {
   __typename?: 'BanUserInChatPayload',
  /** 생성된 정지 로그 */
  userBan: UserBanInChat,
};

export type Benefit = {
   __typename?: 'Benefit',
  activityType?: Maybe<ActivityType>,
  id: Scalars['ID'],
  name: Scalars['String'],
  seq?: Maybe<Scalars['Int']>,
  users?: Maybe<Array<Maybe<User>>>,
};

export enum BestNewsItemType {
  COMMENT = 'COMMENT',
  LIKE = 'LIKE',
  RECENT = 'RECENT',
  SCRAP = 'SCRAP'
}

export type BizActivityCountByStatusPayload = {
   __typename?: 'BizActivityCountByStatusPayload',
  count?: Maybe<Scalars['Int']>,
  status?: Maybe<ActivityDeadlineStatus>,
};

export type BizActivityFilter = {
  activityTypeID?: Maybe<Scalars['ID']>,
  deadLineStatus?: Maybe<ActivityDeadlineStatus>,
  status?: Maybe<ActivityStatus>,
  title?: Maybe<Scalars['String']>,
};

export type BizActivityOrder = {
  direction: OrderDirection,
  field: BizActivityOrderField,
};

export enum BizActivityOrderField {
  CREATED_AT = 'CREATED_AT',
  ID = 'ID'
}

export type BizCreateActivityInput = {
  activityTypeID: Scalars['ID'],
  title: Scalars['String'],
};

export type BizCreateActivityPayload = {
   __typename?: 'BizCreateActivityPayload',
  activity?: Maybe<Activity>,
};

export type BizFindLoginInput = {
  managerEmail: Scalars['String'],
  managerName: Scalars['String'],
};

export type BizFindLoginPayload = {
   __typename?: 'BizFindLoginPayload',
  createdAt?: Maybe<Scalars['Date']>,
  id: Scalars['ID'],
  login: Scalars['String'],
};

export type BizResetPasswordInput = {
  emailConfirmToken: Scalars['String'],
  login: Scalars['String'],
  managerEmail: Scalars['String'],
  password: Scalars['String'],
};

export type BizResetPasswordPayload = {
   __typename?: 'BizResetPasswordPayload',
  user: User,
};

/** 기업회원 회원가입시 보내야 하는 객체 */
export type BizSignupInput = {
  email: Scalars['String'],
  emailConfirmToken: Scalars['String'],
  licenseImages?: Maybe<Array<Scalars['Upload']>>,
  login: Scalars['String'],
  managerName: Scalars['String'],
  organizationName?: Maybe<Scalars['String']>,
  organizationTypeID?: Maybe<Scalars['ID']>,
  password: Scalars['String'],
  phoneNumber?: Maybe<Scalars['String']>,
  registrationNumber?: Maybe<Scalars['String']>,
  representative?: Maybe<Scalars['String']>,
  subscribeEmail?: Maybe<Scalars['Boolean']>,
  subscribeSMS?: Maybe<Scalars['Boolean']>,
};

export type BizUpdateActivityInput = {
  activityEndAt?: Maybe<Scalars['Date']>,
  activityID: Scalars['ID'],
  activityStartAt?: Maybe<Scalars['Date']>,
  activityTypeID: Scalars['ID'],
  additionalBenefit?: Maybe<Scalars['String']>,
  applicationFileAttachmentIDs?: Maybe<Array<Scalars['ID']>>,
  applyDetail?: Maybe<Scalars['String']>,
  applyTypeIDs?: Maybe<Array<Scalars['String']>>,
  awards?: Maybe<Scalars['String']>,
  benefits?: Maybe<Array<Scalars['ID']>>,
  categories?: Maybe<Array<Scalars['ID']>>,
  country?: Maybe<Scalars['String']>,
  dateRepresentation?: Maybe<DateRepresentationType>,
  detailText?: Maybe<Scalars['String']>,
  detailTextID?: Maybe<Scalars['ID']>,
  emailConfirmToken?: Maybe<Scalars['String']>,
  facetimeDay?: Maybe<Scalars['String']>,
  facetimePeriod?: Maybe<Scalars['String']>,
  facetimeTime?: Maybe<Scalars['String']>,
  facetimeWeekday?: Maybe<Scalars['String']>,
  interests?: Maybe<Array<Scalars['ID']>>,
  isManagerEmailVisible?: Maybe<Scalars['Boolean']>,
  isManagerPhoneNumberVisible?: Maybe<Scalars['Boolean']>,
  isSubmit?: Maybe<Scalars['Boolean']>,
  managerEmail?: Maybe<Scalars['String']>,
  managerName?: Maybe<Scalars['String']>,
  managerPhoneNumber?: Maybe<Scalars['String']>,
  organizationName: Scalars['String'],
  organizationTypeID?: Maybe<Scalars['ID']>,
  outlink?: Maybe<Scalars['String']>,
  percent?: Maybe<Scalars['Int']>,
  posterAttachmentID?: Maybe<Scalars['ID']>,
  recruitCloseAt?: Maybe<Scalars['Date']>,
  recruitScale?: Maybe<Scalars['String']>,
  recruitStartAt?: Maybe<Scalars['Date']>,
  recruitType?: Maybe<ActivityRecruitType>,
  regionDistricts?: Maybe<Array<Scalars['ID']>>,
  regions?: Maybe<Array<Scalars['ID']>>,
  skills?: Maybe<Array<Scalars['ID']>>,
  targets?: Maybe<Array<Scalars['ID']>>,
  thumbnailAttachmentID?: Maybe<Scalars['ID']>,
  title: Scalars['String'],
  youtubeURL?: Maybe<Scalars['String']>,
};

export type BizUpdateActivityPayload = {
   __typename?: 'BizUpdateActivityPayload',
  activity?: Maybe<Activity>,
};

export type BizUpdateRecruitCloseAtInput = {
  activityID: Scalars['ID'],
  recruitCloseAt: Scalars['Date'],
};

export type BizUpdateRecruitCloseAtPayload = {
   __typename?: 'BizUpdateRecruitCloseAtPayload',
  activity?: Maybe<Activity>,
};

export type BizUpdateRecruitInput = {
  activityID: Scalars['ID'],
  applicationFileAttachmentIDs?: Maybe<Array<Scalars['String']>>,
  applyDetail?: Maybe<Scalars['String']>,
  applyTypeID?: Maybe<Scalars['ID']>,
  categories?: Maybe<Array<Scalars['ID']>>,
  detailText?: Maybe<Scalars['String']>,
  detailTextID?: Maybe<Scalars['ID']>,
  emailConfirmToken?: Maybe<Scalars['String']>,
  isManagerEmailVisible?: Maybe<Scalars['Boolean']>,
  isManagerPhoneNumberVisible?: Maybe<Scalars['Boolean']>,
  isSubmit?: Maybe<Scalars['Boolean']>,
  jobTypes?: Maybe<Array<JobType>>,
  logo?: Maybe<ActivityFileAttachmentInput>,
  managerEmail?: Maybe<Scalars['String']>,
  managerName?: Maybe<Scalars['String']>,
  managerPhoneNumber?: Maybe<Scalars['String']>,
  organizationName?: Maybe<Scalars['String']>,
  organizationTypeID?: Maybe<Scalars['ID']>,
  outlink?: Maybe<Scalars['String']>,
  recruitCloseAt?: Maybe<Scalars['Date']>,
  recruitScale?: Maybe<Scalars['String']>,
  recruitStartAt?: Maybe<Scalars['Date']>,
  recruitType?: Maybe<ActivityRecruitType>,
  regionDistricts?: Maybe<Array<Scalars['ID']>>,
  regions?: Maybe<Array<Scalars['ID']>>,
  title: Scalars['String'],
};

export type BizUpdateRecruitPayload = {
   __typename?: 'BizUpdateRecruitPayload',
  activity?: Maybe<Activity>,
};

/** 기업회원 정보 수정시 보내야 하는 객체 */
export type BizUpdateUserInput = {
  alias?: Maybe<Scalars['String']>,
  /** 삭제하려는 이미지 아이디 배열 */
  deleteLicenseImageIds?: Maybe<Array<Scalars['ID']>>,
  email?: Maybe<Scalars['String']>,
  emailConfirmToken?: Maybe<Scalars['String']>,
  licenseImages?: Maybe<Array<Scalars['Upload']>>,
  managerName?: Maybe<Scalars['String']>,
  newPassword?: Maybe<Scalars['String']>,
  oldPassword?: Maybe<Scalars['String']>,
  organizationName?: Maybe<Scalars['String']>,
  organizationTypeID?: Maybe<Scalars['ID']>,
  phoneNumber?: Maybe<Scalars['String']>,
  registrationNumber?: Maybe<Scalars['String']>,
  representative?: Maybe<Scalars['String']>,
  subscribeEmail?: Maybe<Scalars['Boolean']>,
  subscribeSMS?: Maybe<Scalars['Boolean']>,
};

export type BizUpdateUserPayload = {
   __typename?: 'BizUpdateUserPayload',
  user?: Maybe<User>,
};

export type BizVerifyLoginInput = {
  login: Scalars['String'],
  managerEmail: Scalars['String'],
};

export type BizVerifyLoginPayload = {
   __typename?: 'BizVerifyLoginPayload',
  user: User,
};

export enum BlankType {
  WITHOUT_BLANK = 'WITHOUT_BLANK',
  WITH_BLANK = 'WITH_BLANK'
}

/** 유저 차단 결과 */
export type BlockUserInChatPayload = {
   __typename?: 'BlockUserInChatPayload',
  /** 차단된 유저 */
  user: User,
};

export type Board = {
   __typename?: 'Board',
  id: Scalars['ID'],
  title?: Maybe<Scalars['String']>,
};

export type BookmarkedConnection = {
   __typename?: 'BookmarkedConnection',
  edges: Array<BookmarkedEdge>,
  nodes: Array<Maybe<NewsItem>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type BookmarkedEdge = {
   __typename?: 'BookmarkedEdge',
  bookmarkedAt?: Maybe<Scalars['Date']>,
  cursor: Scalars['String'],
  node: NewsItem,
};

export type BookmarkerConnection = {
   __typename?: 'BookmarkerConnection',
  edges: Array<BookmarkerEdge>,
  nodes: Array<Maybe<Actor>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type BookmarkerEdge = {
   __typename?: 'BookmarkerEdge',
  bookmarkedAt?: Maybe<Scalars['Date']>,
  cursor: Scalars['String'],
  node?: Maybe<Actor>,
};

/**  소식 스크랩 알림  */
export type BookmarkToNewsItemNotification = SimpleNotification & {
   __typename?: 'BookmarkToNewsItemNotification',
  /**  북마크한 유저  */
  bookmaker?: Maybe<Actor>,
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  deepLinkUrl?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  linkUrl: Scalars['String'],
  newsItem?: Maybe<NewsItem>,
  title?: Maybe<Scalars['String']>,
  unread: Scalars['Boolean'],
  updatedAt?: Maybe<Scalars['Date']>,
};

export enum CacheControlScope {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE'
}

export type Campaign = {
   __typename?: 'Campaign',
  ads: Array<Ad>,
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  deletedAt?: Maybe<Scalars['Date']>,
  endAt: Scalars['Date'],
  id: Scalars['ID'],
  isActive: Scalars['Boolean'],
  isLandingDisabled: Scalars['Boolean'],
  landingURL: Scalars['String'],
  name: Scalars['String'],
  schedules: Array<CampaignSchedule>,
  startAt: Scalars['Date'],
  status: AdStatus,
  updatedAt?: Maybe<Scalars['Date']>,
  user?: Maybe<User>,
};

export type CampaignAdCreateInput = {
  adPlacementID: Scalars['ID'],
  imageID: Scalars['ID'],
};

export type CampaignConnection = {
   __typename?: 'CampaignConnection',
  nodes: Array<Campaign>,
  totalCount: Scalars['Int'],
};

export type CampaignCreateInput = {
  ads: Array<CampaignAdCreateInput>,
  content?: Maybe<Scalars['String']>,
  endAt: Scalars['Date'],
  isActive: Scalars['Boolean'],
  isLandingDisabled?: Maybe<Scalars['Boolean']>,
  landingURL: Scalars['String'],
  name: Scalars['String'],
  schedules: Array<CampaignScheduleInput>,
  startAt: Scalars['Date'],
};

export type CampaignCreatePayload = {
   __typename?: 'CampaignCreatePayload',
  campaign?: Maybe<Campaign>,
};

export type CampaignDeletePayload = {
   __typename?: 'CampaignDeletePayload',
  campaign?: Maybe<Campaign>,
};

export type CampaignFilters = {
  endAt?: Maybe<Scalars['Date']>,
  isActive?: Maybe<Scalars['Boolean']>,
  q?: Maybe<Scalars['String']>,
  startAt?: Maybe<Scalars['Date']>,
  status?: Maybe<AdStatus>,
};

export type CampaignOrder = {
  direction: OrderDirection,
  field: CampaignOrderField,
};

export enum CampaignOrderField {
  CREATE_AT = 'CREATE_AT',
  END_AT = 'END_AT',
  ID = 'ID',
  NAME = 'NAME',
  START_AT = 'START_AT',
  UPDATE_AT = 'UPDATE_AT'
}

export type CampaignSchedule = {
   __typename?: 'CampaignSchedule',
  createdAt?: Maybe<Scalars['Date']>,
  dayOfWeek?: Maybe<Scalars['String']>,
  endTime?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  startTime?: Maybe<Scalars['String']>,
};

export type CampaignScheduleInput = {
  campaignID?: Maybe<Scalars['ID']>,
  dayOfWeek: Scalars['String'],
  endTime: Scalars['String'],
  startTime: Scalars['String'],
};

export type CampaignStatistic = {
   __typename?: 'CampaignStatistic',
  adPlacement?: Maybe<AdPlacement>,
  campaign?: Maybe<Campaign>,
  clickCount: Scalars['Int'],
  createdAt: Scalars['Date'],
  ctr: Scalars['Float'],
  date: Scalars['Date'],
  id: Scalars['ID'],
  impressionCount: Scalars['Int'],
};

export type CampaignStatisticConnection = {
   __typename?: 'CampaignStatisticConnection',
  nodes: Array<CampaignStatistic>,
  totalClickCount: Scalars['Int'],
  totalCount: Scalars['Int'],
  totalImpressionCount: Scalars['Int'],
};

export type CampaignStatisticFilters = {
  adPlacementIDs?: Maybe<Array<Scalars['ID']>>,
  campaignID?: Maybe<Scalars['ID']>,
};

export type CampaignUpdateInput = {
  content?: Maybe<Scalars['String']>,
  deleteAdIDs: Array<Scalars['ID']>,
  endAt?: Maybe<Scalars['Date']>,
  isActive?: Maybe<Scalars['Boolean']>,
  isLandingDisabled?: Maybe<Scalars['Boolean']>,
  landingURL?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  schedules: Array<CampaignScheduleInput>,
  startAt?: Maybe<Scalars['Date']>,
  upsertAds: Array<AdUpsertInput>,
};

export type CampaignUpdatePayload = {
   __typename?: 'CampaignUpdatePayload',
  campaign?: Maybe<Campaign>,
};

export type CareerAdvantageCondition = {
   __typename?: 'CareerAdvantageCondition',
  /** 커리어 프로필 */
  careerProfile?: Maybe<CareerProfile>,
  /** 생성일 */
  createdAt: Scalars['Date'],
  employmentSupportTargets: Array<CareerEmploymentSupportTarget>,
  /** 장애 등급 */
  handicapLevel?: Maybe<Scalars['Int']>,
  id: Scalars['ID'],
  /** 고용 지원금 대상 여부 */
  isEmploymentSupportTarget?: Maybe<Scalars['Boolean']>,
  /** 장애 여부 */
  isHandicap?: Maybe<Scalars['Boolean']>,
  /** 병역 대상 여부 */
  isMilitary?: Maybe<Scalars['Boolean']>,
  /** 공개 여부 */
  isPublic: Scalars['Boolean'],
  /** 보훈 대상 여부 */
  isVeteran?: Maybe<Scalars['Boolean']>,
  /** 작성 여부 */
  isWritten: Scalars['Boolean'],
  /** 전역 년월 */
  militaryEndYearMonth?: Maybe<Scalars['Int']>,
  /** 군 계급 */
  militaryRank?: Maybe<MilitaryRank>,
  /** 입대 년월 */
  militaryStartYearMonth?: Maybe<Scalars['Int']>,
  /** 전역 사유 */
  militaryStatus?: Maybe<MilitaryStatus>,
  /** 병역 대상 */
  militaryTarget?: Maybe<MilitaryTarget>,
  /** 군별 */
  militaryType?: Maybe<MilitaryType>,
  /** 수정일 */
  updatedAt?: Maybe<Scalars['Date']>,
  /** 보훈 대상 사유 */
  veteranReason?: Maybe<Scalars['String']>,
};

export type CareerAward = {
   __typename?: 'CareerAward',
  /** 커리어 프로필 */
  careerProfile?: Maybe<CareerProfile>,
  /** 생성일 */
  createdAt: Scalars['Date'],
  id: Scalars['ID'],
  /** 공개 여부 */
  isPublic: Scalars['Boolean'],
  /** 작성 여부 */
  isWritten: Scalars['Boolean'],
  items: Array<CareerAwardItem>,
  /** 수정일 */
  updatedAt?: Maybe<Scalars['Date']>,
};

export type CareerAwardItem = {
   __typename?: 'CareerAwardItem',
  /** 수여일 (yyyy.MM.dd) */
  acquiredDate?: Maybe<Scalars['Date']>,
  award: CareerAward,
  /** 수상내용 */
  content?: Maybe<Scalars['String']>,
  /** 생성일 */
  createdAt: Scalars['Date'],
  id: Scalars['ID'],
  /** 수여기관명 */
  institutionName?: Maybe<Scalars['String']>,
  /** 수상명 */
  name?: Maybe<Scalars['String']>,
  /** 순서 */
  seq: Scalars['Int'],
  /** 수정일 */
  updatedAt?: Maybe<Scalars['Date']>,
};

/** 고용지원금대상 객체 */
export type CareerEmploymentSupportTarget = {
   __typename?: 'CareerEmploymentSupportTarget',
  id: Scalars['ID'],
  /** 이름 */
  name: Scalars['String'],
};

export type CareerExperience = {
   __typename?: 'CareerExperience',
  /** 커리어 프로필 */
  careerProfile?: Maybe<CareerProfile>,
  /** 생성일 */
  createdAt: Scalars['Date'],
  id: Scalars['ID'],
  /** 공개 여부 */
  isPublic: Scalars['Boolean'],
  /** 작성 여부 */
  isWritten: Scalars['Boolean'],
  items: Array<CareerExperienceItem>,
  /** 수정일 */
  updatedAt?: Maybe<Scalars['Date']>,
};

export type CareerExperienceItem = {
   __typename?: 'CareerExperienceItem',
  /** 생성일 */
  createdAt: Scalars['Date'],
  /** 담당 직무 */
  duties: Array<Category>,
  /** 퇴사년월 */
  endYearMonth?: Maybe<Scalars['Int']>,
  id: Scalars['ID'],
  /** 재직 여부 */
  inOffice?: Maybe<Scalars['Boolean']>,
  /** 외부 데이터 여부 */
  isExternal: Scalars['Boolean'],
  /** 회사명 비공개 여부 */
  isOrganizationNamePrivate: Scalars['Boolean'],
  /** 직책 */
  jobPosition?: Maybe<JobPosition>,
  /** 직급 */
  jobRank?: Maybe<JobRank>,
  /** 회사 */
  organization?: Maybe<CareerOrganization>,
  /** 연봉 */
  pay?: Maybe<Scalars['Int']>,
  /** 담당업무 */
  performancePart?: Maybe<Scalars['String']>,
  /** 입사년월 */
  startYearMonth?: Maybe<Scalars['Int']>,
  /** 수정일 */
  updatedAt?: Maybe<Scalars['Date']>,
};

export type CareerExternship = {
   __typename?: 'CareerExternship',
  /** 커리어 프로필 */
  careerProfile?: Maybe<CareerProfile>,
  /** 생성일 */
  createdAt: Scalars['Date'],
  id: Scalars['ID'],
  /** 공개 여부 */
  isPublic: Scalars['Boolean'],
  /** 작성 여부 */
  isWritten: Scalars['Boolean'],
  items: Array<CareerExternshipItem>,
  /** 수정일 */
  updatedAt?: Maybe<Scalars['Date']>,
};

export type CareerExternshipItem = {
   __typename?: 'CareerExternshipItem',
  /** 활동 내용 */
  content?: Maybe<Scalars['String']>,
  /** 생성일 */
  createdAt: Scalars['Date'],
  /** 종료년월 */
  endYearMonth?: Maybe<Scalars['Int']>,
  id: Scalars['ID'],
  /** 진행중 여부 */
  inProgress: Scalars['Boolean'],
  /** 기관/장소 이름 */
  institutionName?: Maybe<Scalars['String']>,
  /** 순서 */
  seq: Scalars['Int'],
  /** 시작년월 */
  startYearMonth?: Maybe<Scalars['Int']>,
  /** 인턴/대외활동/프로젝트 타입 */
  type?: Maybe<CareerExternshipType>,
  /** 수정일 */
  updatedAt?: Maybe<Scalars['Date']>,
  /** URL */
  url?: Maybe<Scalars['String']>,
};

export enum CareerExternshipType {
  CLUB_ACTIVITY = 'CLUB_ACTIVITY',
  EXTERNAL_ACTIVITY = 'EXTERNAL_ACTIVITY',
  INTERNSHIP = 'INTERNSHIP',
  ON_CAMPUS_ACTIVITY = 'ON_CAMPUS_ACTIVITY',
  OVERSEAS_TRAINING = 'OVERSEAS_TRAINING',
  PART_TIME_JOB = 'PART_TIME_JOB',
  PROJECT = 'PROJECT',
  SOCIAL_ACTIVITY = 'SOCIAL_ACTIVITY',
  VOLUNTEER = 'VOLUNTEER'
}

export type CareerIntroduction = {
   __typename?: 'CareerIntroduction',
  /** 한 줄 소개 */
  briefContent?: Maybe<Scalars['String']>,
  /** 커리어 프로필 */
  careerProfile?: Maybe<CareerProfile>,
  /** 자기소개 */
  content?: Maybe<Scalars['String']>,
  /** 생성일 */
  createdAt: Scalars['Date'],
  id: Scalars['ID'],
  /** 공개 여부 */
  isPublic: Scalars['Boolean'],
  /** 작성 여부 */
  isWritten: Scalars['Boolean'],
  /** 수정일 */
  updatedAt?: Maybe<Scalars['Date']>,
};

export type CareerJobPreference = {
   __typename?: 'CareerJobPreference',
  /** 커리어 프로필 */
  careerProfile?: Maybe<CareerProfile>,
  /** 생성일 */
  createdAt: Scalars['Date'],
  /** 연봉 협상 면접 후 결정하는지에 대한 플래그 */
  decidedAfterInterview: Scalars['Boolean'],
  /** 연봉 협상 회사 내규에 따르는지에 대한 플래그 */
  decidedByOrganizationPolicy: Scalars['Boolean'],
  /** 희망 직무 리스트 */
  duties: Array<Category>,
  id: Scalars['ID'],
  /** 희망 업종 리스트 */
  industries: Array<Industry>,
  /** 공개 여부 */
  isPublic: Scalars['Boolean'],
  /** 작성 여부 */
  isWritten: Scalars['Boolean'],
  /** 희망 연봉 (단위 만원) */
  pay?: Maybe<Scalars['Int']>,
  /** 희망 근무 지역 리스트 */
  regionDistricts: Array<RegionDistrict>,
  /** 수정일 */
  updatedAt?: Maybe<Scalars['Date']>,
};

export type CareerLanguage = {
   __typename?: 'CareerLanguage',
  /** 커리어 프로필 */
  careerProfile?: Maybe<CareerProfile>,
  /** 생성일 */
  createdAt: Scalars['Date'],
  id: Scalars['ID'],
  /** 공개 여부 */
  isPublic: Scalars['Boolean'],
  /** 작성 여부 */
  isWritten: Scalars['Boolean'],
  items: Array<CareerLanguageItem>,
  /** 수정일 */
  updatedAt?: Maybe<Scalars['Date']>,
};

export type CareerLanguageConversation = CareerLanguageItem & {
   __typename?: 'CareerLanguageConversation',
  /** 생성일 */
  createdAt: Scalars['Date'],
  id: Scalars['ID'],
  language: CareerLanguage,
  /** 언어명 */
  name?: Maybe<Scalars['String']>,
  /** 순서 */
  seq: Scalars['Int'],
  /** 회화 능력 */
  status?: Maybe<CareerLanguageConversationStatus>,
  /** 구분 */
  type: CareerLanguageType,
};

export enum CareerLanguageConversationStatus {
  BUSINESS = 'BUSINESS',
  DAILY = 'DAILY',
  NATIVE = 'NATIVE'
}

export type CareerLanguageItem = {
  id: Scalars['ID'],
  language: CareerLanguage,
  /** 순서 */
  seq: Scalars['Int'],
  /** 구분 */
  type: CareerLanguageType,
};

export type CareerLanguageTest = CareerLanguageItem & {
   __typename?: 'CareerLanguageTest',
  /** 취득일 (yyyy.MM.dd) */
  acquiredDate?: Maybe<Scalars['Date']>,
  /** 생성일 */
  createdAt: Scalars['Date'],
  id: Scalars['ID'],
  language: CareerLanguage,
  /** 급수 */
  level?: Maybe<Scalars['Int']>,
  /** 시험명 */
  name?: Maybe<Scalars['String']>,
  /** 점수 */
  score?: Maybe<Scalars['Int']>,
  /** 순서 */
  seq: Scalars['Int'],
  /** 구분 */
  type: CareerLanguageType,
};

export enum CareerLanguageType {
  CONVERSATION = 'CONVERSATION',
  TEST = 'TEST'
}

export type CareerLicense = {
   __typename?: 'CareerLicense',
  /** 커리어 프로필 */
  careerProfile?: Maybe<CareerProfile>,
  /** 생성일 */
  createdAt: Scalars['Date'],
  id: Scalars['ID'],
  /** 공개 여부 */
  isPublic: Scalars['Boolean'],
  /** 작성 여부 */
  isWritten: Scalars['Boolean'],
  items: Array<CareerLicenseItem>,
  /** 수정일 */
  updatedAt?: Maybe<Scalars['Date']>,
};

export type CareerLicenseItem = {
   __typename?: 'CareerLicenseItem',
  /** 취득일 (yyyy.MM.dd) */
  acquiredDate?: Maybe<Scalars['Date']>,
  /** 생성일 */
  createdAt: Scalars['Date'],
  id: Scalars['ID'],
  /** 발행기관명 */
  institutionName?: Maybe<Scalars['String']>,
  /** 외부 데이터 여부 */
  isExternal: Scalars['Boolean'],
  /** 커리어 자격증 정보 */
  license: CareerLicense,
  /** 자격증명 */
  name?: Maybe<Scalars['String']>,
  /** 순서 */
  seq: Scalars['Int'],
  /** 수정일 */
  updatedAt?: Maybe<Scalars['Date']>,
};

export type CareerOrganization = {
   __typename?: 'CareerOrganization',
  id: Scalars['ID'],
  name: Scalars['String'],
};

export type CareerPortfolio = {
   __typename?: 'CareerPortfolio',
  /** 커리어 프로필 */
  careerProfile?: Maybe<CareerProfile>,
  /** 생성일 */
  createdAt: Scalars['Date'],
  /** 파일 리스트 */
  files: Array<CareerPortfolioFile>,
  id: Scalars['ID'],
  /** 이미지 리스트 */
  images: Array<CareerPortfolioImage>,
  /** 공개 여부 */
  isPublic: Scalars['Boolean'],
  /** 작성 여부 */
  isWritten: Scalars['Boolean'],
  /** 수정일 */
  updatedAt?: Maybe<Scalars['Date']>,
  /** URL 리스트 */
  urls: Array<CareerPortfolioUrl>,
};

export type CareerPortfolioFile = {
   __typename?: 'CareerPortfolioFile',
  createdAt: Scalars['Date'],
  /** 파일 */
  file?: Maybe<Attachment>,
  id: Scalars['ID'],
};

export type CareerPortfolioImage = {
   __typename?: 'CareerPortfolioImage',
  createdAt: Scalars['Date'],
  id: Scalars['ID'],
  /** 이미지 */
  image?: Maybe<Attachment>,
  seq: Scalars['Int'],
};

export type CareerPortfolioUrl = {
   __typename?: 'CareerPortfolioUrl',
  createdAt: Scalars['Date'],
  id: Scalars['ID'],
  /** URL 타입 */
  type: CareerPortfolioUrlType,
  /** URL */
  url: Scalars['String'],
};

export enum CareerPortfolioUrlType {
  BEHANCE = 'BEHANCE',
  BRUNCH = 'BRUNCH',
  GITHUB = 'GITHUB',
  INSTAGRAM = 'INSTAGRAM',
  LINK = 'LINK',
  LINKEDIN = 'LINKEDIN',
  NOTION = 'NOTION',
  YOU_TUBE = 'YOU_TUBE'
}

export type CareerProfile = {
   __typename?: 'CareerProfile',
  /** 취업 우대 사항 */
  advantageCondition?: Maybe<CareerAdvantageCondition>,
  /** 수상 */
  award?: Maybe<CareerAward>,
  /** 생성일 */
  createdAt: Scalars['Date'],
  /** 경력 */
  experience?: Maybe<CareerExperience>,
  /** 교외활동 */
  externship?: Maybe<CareerExternship>,
  id: Scalars['ID'],
  /** 소개 */
  introduction?: Maybe<CareerIntroduction>,
  /** 공유 가능 여부 */
  isSharing: Scalars['Boolean'],
  /** 희망 근무 조건 */
  jobPreference?: Maybe<CareerJobPreference>,
  /** 어학 */
  language?: Maybe<CareerLanguage>,
  /** 자격증 */
  license?: Maybe<CareerLicense>,
  /** 포트폴리오 */
  portfolio?: Maybe<CareerPortfolio>,
  /** 프로필 필드별 순서 */
  profileOrder: CareerProfileOrder,
  /** 학력 */
  school?: Maybe<CareerSchool>,
  /** 보유 기술 */
  skill?: Maybe<CareerSkill>,
  /** 프로필 템플릿 */
  template?: Maybe<CareerProfileTemplate>,
  /** 프로필 테마 */
  theme?: Maybe<CareerProfileTheme>,
  /** 수정일 */
  updatedAt?: Maybe<Scalars['Date']>,
  /** 유저 기본 정보 */
  userInformation?: Maybe<CareerUserInformation>,
};

/** 프로필 필드별 순서 */
export type CareerProfileOrder = {
   __typename?: 'CareerProfileOrder',
  /** 취업 우대사항 순서 */
  advantageConditionOrder: Scalars['Int'],
  /** 수상 순서 */
  awardOrder: Scalars['Int'],
  careerProfile: CareerProfile,
  /** 경력 순서 */
  experienceOrder: Scalars['Int'],
  /** 인턴/대외활동/프로젝트 순서 */
  externshipOrder: Scalars['Int'],
  /** 소개 순서 */
  introductionOrder: Scalars['Int'],
  /** 희망근무조건 순서 */
  jobPreferenceOrder: Scalars['Int'],
  /** 어학 순서 */
  languageOrder: Scalars['Int'],
  /** 자격증 순서 */
  licenseOrder: Scalars['Int'],
  /** 포트폴리오 순서 */
  portfolioOrder: Scalars['Int'],
  /** 학력 순서 */
  schoolOrder: Scalars['Int'],
  /** 보유 기술 순서 */
  skillOrder: Scalars['Int'],
  /** 유저 기본 정보 순서 */
  userInformationOrder: Scalars['Int'],
};

export enum CareerProfileTemplate {
  ORANGE = 'ORANGE',
  PURPLE = 'PURPLE',
  SKY = 'SKY'
}

export enum CareerProfileTheme {
  BLUE = 'BLUE',
  GREEN = 'GREEN',
  ORANGE = 'ORANGE',
  PINK = 'PINK',
  PURPLE = 'PURPLE',
  SKY = 'SKY'
}

export type CareerSchool = {
   __typename?: 'CareerSchool',
  /** 커리어 프로필 */
  careerProfile?: Maybe<CareerProfile>,
  /** 생성일 */
  createdAt: Scalars['Date'],
  id: Scalars['ID'],
  /** 공개 여부 */
  isPublic: Scalars['Boolean'],
  /** 작성 여부 */
  isWritten: Scalars['Boolean'],
  items: Array<CareerSchoolItem>,
  /** 수정일 */
  updatedAt?: Maybe<Scalars['Date']>,
};

export enum CareerSchoolGraduationStatus {
  COMPLETED = 'COMPLETED',
  DROPPED = 'DROPPED',
  ENROLLED = 'ENROLLED',
  EXPECTED_GRADUATION = 'EXPECTED_GRADUATION',
  GRADUATED = 'GRADUATED',
  ON_LEAVE = 'ON_LEAVE',
  SELF_DROPPED = 'SELF_DROPPED'
}

export type CareerSchoolItem = {
   __typename?: 'CareerSchoolItem',
  careerSchool: CareerSchool,
  /** 생성일 */
  createdAt: Scalars['Date'],
  /** 입학년월 */
  entranceYearMonth?: Maybe<Scalars['Int']>,
  /** 총점 */
  gradePerfectScore?: Maybe<Scalars['Float']>,
  /** 학점 */
  gradeScore?: Maybe<Scalars['Float']>,
  /** 졸업 여부 */
  graduationStatus?: Maybe<CareerSchoolGraduationStatus>,
  /** 졸업년월 */
  graduationYearMonth?: Maybe<Scalars['Int']>,
  id: Scalars['ID'],
  /** 편입 여부 */
  isTransfer: Scalars['Boolean'],
  majors: Array<CareerSchoolMajor>,
  school?: Maybe<School>,
  /** 순서 */
  seq: Scalars['Int'],
  /** 학교 구분 */
  type?: Maybe<CareerSchoolType>,
  /** 수정일 */
  updatedAt?: Maybe<Scalars['Date']>,
};

export type CareerSchoolMajor = {
   __typename?: 'CareerSchoolMajor',
  id: Scalars['ID'],
  name: Scalars['String'],
  type: CareerSchoolMajorType,
};

export enum CareerSchoolMajorType {
  DOUBLE_MAJOR = 'DOUBLE_MAJOR',
  MAJOR = 'MAJOR',
  MINOR = 'MINOR',
  PLURAL_MAJOR = 'PLURAL_MAJOR'
}

export enum CareerSchoolType {
  FOUR_YEAR_COLLEGE = 'FOUR_YEAR_COLLEGE',
  HIGH_SCHOOL = 'HIGH_SCHOOL',
  MASTER_COLLEGE = 'MASTER_COLLEGE',
  PH_D_COLLEGE = 'PH_D_COLLEGE',
  TWO_THREE_YEAR_COLLEGE = 'TWO_THREE_YEAR_COLLEGE'
}

export type CareerSkill = {
   __typename?: 'CareerSkill',
  /** 커리어 프로필 */
  careerProfile?: Maybe<CareerProfile>,
  /** 생성일 */
  createdAt: Scalars['Date'],
  id: Scalars['ID'],
  /** 공개 여부 */
  isPublic: Scalars['Boolean'],
  /** 작성 여부 */
  isWritten: Scalars['Boolean'],
  skillTags: Array<SkillTag>,
  /** 수정일 */
  updatedAt?: Maybe<Scalars['Date']>,
};

export type CareerUserInformation = {
   __typename?: 'CareerUserInformation',
  /** 주소 */
  address?: Maybe<Scalars['String']>,
  /** 상세 주소 */
  addressDetail?: Maybe<Scalars['String']>,
  /** 생년월일 (yyyy.MM.dd) */
  birthday?: Maybe<Scalars['Date']>,
  /** 커리어 프로필 */
  careerProfile?: Maybe<CareerProfile>,
  /** 생성일 */
  createdAt: Scalars['Date'],
  /** 이메일 */
  email?: Maybe<Scalars['String']>,
  /** 성별 */
  gender?: Maybe<Gender>,
  id: Scalars['ID'],
  /** 작성 여부 */
  isWritten: Scalars['Boolean'],
  /** 이름 */
  name?: Maybe<Scalars['String']>,
  /** 휴대폰 번호 */
  phoneNumber?: Maybe<Scalars['String']>,
  /** 프로필 이미지 */
  profileImage?: Maybe<Attachment>,
  /** 수정일 */
  updatedAt?: Maybe<Scalars['Date']>,
};

export type Category = {
   __typename?: 'Category',
  activityType?: Maybe<ActivityType>,
  children?: Maybe<Array<Category>>,
  id: Scalars['ID'],
  /** 카테고리 이름 */
  name: Scalars['String'],
  parent?: Maybe<Category>,
  /** 카테고리 순서 */
  seq?: Maybe<Scalars['Int']>,
  users?: Maybe<Array<Maybe<User>>>,
};

export enum CategoryActivityType {
  ACTIVITY = 'ACTIVITY',
  CLUB = 'CLUB',
  CONTEST = 'CONTEST',
  EDUCATION = 'EDUCATION',
  INTERN = 'INTERN',
  RECRUIT = 'RECRUIT'
}

export type CategoryFilter = {
  activityType?: Maybe<CategoryActivityType>,
  parentId?: Maybe<Scalars['ID']>,
};

export type Certification = {
   __typename?: 'Certification',
  count: Scalars['Int'],
  id: Scalars['ID'],
  isConfirmed: Scalars['Boolean'],
  name: Scalars['String'],
};

/** 채팅방 닉네임 변경 input */
export type ChangeChatNicknameInput = {
  /** 변경할 닉네임 */
  nickname: Scalars['String'],
};

/** 채팅 닉네임 변경 결과 */
export type ChangeChatNicknamePayload = {
   __typename?: 'ChangeChatNicknamePayload',
  /** 변경된 유저 */
  user: User,
};

/** 채팅방 프로필 이미지 변경 input */
export type ChangeChatProfileImageInput = {
  /** 변경할 이미지 ID */
  profileImageTemplateId: Scalars['ID'],
};

/** 채팅 닉네임 변경 결과 */
export type ChangeChatProfileImagePayload = {
   __typename?: 'ChangeChatProfileImagePayload',
  /** 변경된 유저 */
  user: User,
};

export type Channel = {
   __typename?: 'Channel',
  activities?: Maybe<ActivityConnection>,
  /**  채널이 작성한 소식 북마크 수  */
  bookmarkCount: Scalars['Int'],
  bookmarks?: Maybe<BookmarkedConnection>,
  briefDescription: Scalars['String'],
  /**  채널 완성 가이드 완료 시점  */
  completedAt?: Maybe<Scalars['Date']>,
  coverImage?: Maybe<Attachment>,
  createdAt?: Maybe<Scalars['Date']>,
  /** 
 * 하루치의 통계 데이터 조회
   * 인자가 null 혹은 undefined이면 가장 최근(어제) 통계 데이터를 반환함
 **/
  dailyStatistic?: Maybe<ChannelStatistic>,
  deactivatedAt?: Maybe<Scalars['Date']>,
  description?: Maybe<Scalars['String']>,
  /**  채널 팔로우 수  */
  followerCount: Scalars['Int'],
  /**  채널을 팔로우한 유저들  */
  followers: FollowerConnection,
  followings: FollowingConnection,
  hasNewNewsItem: Scalars['Boolean'],
  id: Scalars['ID'],
  /**  채널이 작성한 소식 좋아요 수  */
  likeCount: Scalars['Int'],
  /** 
 * 최근 30일 내의 통계 데이터 조회
   * 인자가 null 혹은 undefined이면 가장 최근(어제) 통계 데이터를 반환함
 **/
  monthlyStatistic?: Maybe<ChannelStatistic>,
  name: Scalars['String'],
  /**  채널이 작성한 소식에 달린 댓글 수  */
  newsCommentCount: Scalars['Int'],
  /**  채널이 작성한 댓글  */
  newsComments: NewsCommentConnection,
  /**  채널이 작성한 소식 수  */
  newsItemCount: Scalars['Int'],
  /**  채널이 작성한 소식 cursor 페이징  */
  newsItemFeed: NewsItemConnection,
  /**  채널이 작성한 소식 offset 페이징  */
  newsItems: NewsItemConnection,
  owner?: Maybe<Organization>,
  /**  채널이 작성한 소식 공유 수  */
  shareCount: Scalars['Int'],
  /** 
 * 기간별 채널 통계 리스트 조회
   * filterBy: 기본 전체 기간 조회
   * groupBy: 기본 일간 조회
 **/
  statistics?: Maybe<ChannelStatisticConnection>,
  tags?: Maybe<Array<Maybe<Tag>>>,
  url: Scalars['String'],
  /**  채널 방문 수  */
  viewCount: Scalars['Int'],
  viewerHasFollowed: Scalars['Boolean'],
  viewerIsOwner: Scalars['Boolean'],
};


export type ChannelActivitiesArgs = {
  filterBy?: Maybe<ChannelActivityFilter>,
  orderBy?: Maybe<ChannelActivityOrder>,
  pagination?: Maybe<Pagination>
};


export type ChannelBookmarksArgs = {
  pagination?: Maybe<Pagination>
};


export type ChannelDailyStatisticArgs = {
  statisticDate?: Maybe<Scalars['Date']>
};


export type ChannelFollowersArgs = {
  filterBy?: Maybe<FollowerFilter>,
  pagination?: Maybe<Pagination>
};


export type ChannelFollowingsArgs = {
  filterBy?: Maybe<FollowingFilter>,
  pagination?: Maybe<Pagination>
};


export type ChannelMonthlyStatisticArgs = {
  statisticDate?: Maybe<Scalars['Date']>
};


export type ChannelNewsCommentsArgs = {
  pagination?: Maybe<Pagination>
};


export type ChannelNewsItemFeedArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>
};


export type ChannelNewsItemsArgs = {
  filterBy?: Maybe<NewsItemFilter>,
  orderBy?: Maybe<NewsItemOrder>,
  pagination?: Maybe<Pagination>
};


export type ChannelStatisticsArgs = {
  filterBy?: Maybe<ChannelStatisticFilters>,
  groupBy?: Maybe<ChannelStatisticGroups>,
  pagination?: Maybe<Pagination>
};

export enum ChannelActivityDeadlineStatus {
  ALL = 'ALL',
  CLOSE = 'CLOSE',
  EXPECTED = 'EXPECTED',
  OPEN = 'OPEN'
}

export type ChannelActivityFilter = {
  deadlineStatus?: Maybe<ChannelActivityDeadlineStatus>,
};

export type ChannelActivityOrder = {
  direction?: Maybe<OrderDirection>,
  field?: Maybe<ChannelActivityOrderField>,
};

export enum ChannelActivityOrderField {
  CREATED_AT = 'CREATED_AT'
}

export type ChannelConnection = {
   __typename?: 'ChannelConnection',
  edges: Array<ChannelEdge>,
  nodes: Array<Channel>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type ChannelEdge = {
   __typename?: 'ChannelEdge',
  cursor: Scalars['String'],
  node: Channel,
};

export type ChannelFilter = {
  q?: Maybe<Scalars['String']>,
};

export type ChannelOrder = {
  direction: OrderDirection,
  field: ChannelOrderField,
};

export enum ChannelOrderField {
  BOOKMARK = 'BOOKMARK',
  FOLLOWER = 'FOLLOWER',
  LAST_NEWS_ITEM_CREATED_AT = 'LAST_NEWS_ITEM_CREATED_AT',
  LIKE = 'LIKE',
  NEWS_COMMENT = 'NEWS_COMMENT',
  NEWS_ITEM = 'NEWS_ITEM'
}

/**  채널별 통계  */
export type ChannelStatistic = {
   __typename?: 'ChannelStatistic',
  bookmarkCount: Scalars['Int'],
  /** 
 * compareDate: 기본 한달 전
   * 데이터가 없을 경우 null을 리턴하여 0과 구분함
 **/
  bookmarkCountPercentage?: Maybe<Scalars['Float']>,
  channel?: Maybe<Channel>,
  channelViewCount: Scalars['Int'],
  /** 
 * compareDate: 기본 한달 전
   * 데이터가 없을 경우 null을 리턴하여 0과 구분함
 **/
  channelViewCountPercentage?: Maybe<Scalars['Float']>,
  date: Scalars['String'],
  followerCount: Scalars['Int'],
  /** 
 * compareDate: 기본 한달 전
   * 데이터가 없을 경우 null을 리턴하여 0과 구분함
 **/
  followerCountPercentage?: Maybe<Scalars['Float']>,
  id: Scalars['ID'],
  likeCount: Scalars['Int'],
  /** 
 * compareDate: 기본 한달 전
   * 데이터가 없을 경우 null을 리턴하여 0과 구분함
 **/
  likeCountPercentage?: Maybe<Scalars['Float']>,
  newsCommentCount: Scalars['Int'],
  /** 
 * compareDate: 기본 한달 전
   * 데이터가 없을 경우 null을 리턴하여 0과 구분함
 **/
  newsCommentCountPercentage?: Maybe<Scalars['Float']>,
  newsItemCount: Scalars['Int'],
  /** 
 * compareDate: 기본 한달 전
   * 데이터가 없을 경우 null을 리턴하여 0과 구분함
 **/
  newsItemCountPercentage?: Maybe<Scalars['Float']>,
  newsItemViewCount: Scalars['Int'],
  /** 
 * compareDate: 기본 한달 전
   * 데이터가 없을 경우 null을 리턴하여 0과 구분함
 **/
  newsItemViewCountPercentage?: Maybe<Scalars['Float']>,
  shareCount: Scalars['Int'],
};


/**  채널별 통계  */
export type ChannelStatisticBookmarkCountPercentageArgs = {
  compareDate?: Maybe<Scalars['Date']>
};


/**  채널별 통계  */
export type ChannelStatisticChannelViewCountPercentageArgs = {
  compareDate?: Maybe<Scalars['Date']>
};


/**  채널별 통계  */
export type ChannelStatisticFollowerCountPercentageArgs = {
  compareDate?: Maybe<Scalars['Date']>
};


/**  채널별 통계  */
export type ChannelStatisticLikeCountPercentageArgs = {
  compareDate?: Maybe<Scalars['Date']>
};


/**  채널별 통계  */
export type ChannelStatisticNewsCommentCountPercentageArgs = {
  compareDate?: Maybe<Scalars['Date']>
};


/**  채널별 통계  */
export type ChannelStatisticNewsItemCountPercentageArgs = {
  compareDate?: Maybe<Scalars['Date']>
};


/**  채널별 통계  */
export type ChannelStatisticNewsItemViewCountPercentageArgs = {
  compareDate?: Maybe<Scalars['Date']>
};

export type ChannelStatisticConnection = {
   __typename?: 'ChannelStatisticConnection',
  edges: Array<ChannelStatisticEdge>,
  nodes: Array<ChannelStatistic>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type ChannelStatisticEdge = {
   __typename?: 'ChannelStatisticEdge',
  cursor: Scalars['String'],
  node: ChannelStatistic,
};

export type ChannelStatisticFilters = {
  channelName?: Maybe<Scalars['String']>,
  dateRange?: Maybe<DateRange>,
  organizationName?: Maybe<Scalars['String']>,
};

export type ChannelStatisticGroups = {
  term?: Maybe<DateTerm>,
};

export type ChannelStatisticOrder = {
  direction?: Maybe<OrderDirection>,
  field?: Maybe<ChannelStatisticOrderField>,
};

export enum ChannelStatisticOrderField {
  BOOKMARK = 'BOOKMARK',
  FOLLOWER = 'FOLLOWER',
  LIKE = 'LIKE',
  NEWS_COMMENT = 'NEWS_COMMENT',
  NEWS_ITEM = 'NEWS_ITEM'
}

export type ChannelsWithStatisticFilter = {
  channelName?: Maybe<Scalars['String']>,
  organizationName?: Maybe<Scalars['String']>,
  statisticDate?: Maybe<DateRange>,
};

export type ChannelsWithStatisticOrder = {
  direction: OrderDirection,
  field: ChannelOrderField,
};

export enum CharSize {
  CHAR = 'CHAR',
  THREE_BYTE = 'THREE_BYTE',
  TWO_BYTE = 'TWO_BYTE'
}

export type ChatAttachment = {
   __typename?: 'ChatAttachment',
  id: Scalars['ID'],
  name: Scalars['String'],
  size: Scalars['Int'],
  type: Scalars['String'],
  path?: Maybe<Scalars['String']>,
  url: Scalars['String'],
};

/** 채팅 메시지 */
export type ChatMessage = {
   __typename?: 'ChatMessage',
  /** 보낸 사람 닉네임 */
  senderNickname: Scalars['String'],
  /** 보낸 시각 */
  createdAt: Scalars['Date'],
  /** 좋아요 수 */
  likeCount: Scalars['Int'],
  /** 
 * 관리자만 접근 가능합니다.
   * 
   * 삭제 시점
 **/
  deletedAt: Scalars['Date'],
  /** 
 * 관리자만 접근 가능합니다.
   * 
   * 신고 수
 **/
  reportCount: Scalars['Int'],
  /** 채팅 메시지의 ID */
  id: Scalars['ID'],
  /** 답글 대상 */
  parent?: Maybe<ChatMessage>,
  /** 작성자 */
  sender?: Maybe<User>,
  /** 메시지가 속한 채팅방 */
  chatRoom?: Maybe<ChatRoom>,
  /** 메시지의 발신자가 현재 사용자인지 */
  viewerIsSender: Scalars['Boolean'],
  /** 현재 사용자가 메시지에 좋아요를 눌렀는지 */
  viewerHasLiked: Scalars['Boolean'],
  /** 삭제된 메시지인지 */
  isDeleted: Scalars['Boolean'],
  /** 메시지 내용. 차단/삭제 등의 메시지는 가려서 표현 */
  content: Scalars['String'],
  /** 
 * 관리자만 접근 가능합니다.
   * 
   * 메시지 내용. 차단/삭제 등의 메시지도 원문 표현
 **/
  adminContent: Scalars['String'],
  /** 
 * 관리자만 접근 가능합니다.
   * 
   * 특정 채팅 메시지에 대한 신고 로그들
 **/
  chatMessageReportLogs: ChatMessageReportLogOffsetConnection,
  /** 
 * 관리자만 접근 가능합니다.
   * 
   * 특정 채팅 메시지에 대한 마지막 신고 로그
 **/
  lastChatMessageReportLog?: Maybe<ChatMessageReportLog>,
  /** 
 * 관리자만 접근 가능합니다.
   * 
   * 특정 채팅 메시지에 대한 신고 사유들과 각 신고 사유별 신고 횟수들
 **/
  reportedReasonAndCounts: Array<ReportedReasonAndCount>,
  /** 현재 유저가 해당 메시지를 차단했는지 여부를 반환합니다. */
  hasViewerBlockedSender: Scalars['Boolean'],
  /** 
 * 관리자만 접근 가능합니다.
   * 
   * 어드민이 현재 메시지를 읽었는지 확인합니다
 **/
  hasAdminRead: Scalars['Boolean'],
};


/** 채팅 메시지 */
export type ChatMessageChatMessageReportLogsArgs = {
  pagination: Pagination
};

export type ChatMessageConnection = {
   __typename?: 'ChatMessageConnection',
  nodes: Array<ChatMessage>,
  edges: Array<ChatMessageEdge>,
  pageInfo: PageInfo,
};

export type ChatMessageEdge = {
   __typename?: 'ChatMessageEdge',
  node: ChatMessage,
  cursor: Scalars['String'],
};

export type ChatMessageFilter = {
  maxChatMessageID?: Maybe<Scalars['ID']>,
  minChatMessageID?: Maybe<Scalars['ID']>,
  /** minChatMessageID를 사용자가 읽지 않은 첫번째 채팅 id로 설정합니다. */
  minIsOldestUnreadLocation?: Maybe<Scalars['Boolean']>,
  /** maxChatMessageID를 사용자가 읽지 않은 첫번째 채팅 id로 설정합니다. */
  maxIsOldestUnreadLocation?: Maybe<Scalars['Boolean']>,
};

export type ChatMessageOffsetConnection = {
   __typename?: 'ChatMessageOffsetConnection',
  nodes: Array<ChatMessage>,
  totalCount: Scalars['Float'],
};

/**  채팅 답글 알림  */
export type ChatMessageReplyNotification = SimpleNotification & {
   __typename?: 'ChatMessageReplyNotification',
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  deepLinkUrl?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  linkUrl: Scalars['String'],
  title?: Maybe<Scalars['String']>,
  unread: Scalars['Boolean'],
  updatedAt?: Maybe<Scalars['Date']>,
};

/** 채팅 메시지 신고 로그 */
export type ChatMessageReportLog = {
   __typename?: 'ChatMessageReportLog',
  /** 신고자 */
  reportedBy?: Maybe<User>,
  /** 신고 시각 */
  reportedAt: Scalars['Date'],
  /** 신고 사유 */
  reportReason: Scalars['String'],
  /** 신고된 채팅 메시지 */
  reportedChatMessage?: Maybe<ChatMessage>,
};

export type ChatMessageReportLogOffsetConnection = {
   __typename?: 'ChatMessageReportLogOffsetConnection',
  nodes: Array<ChatMessageReportLog>,
  totalCount: Scalars['Int'],
};

export type ChatOrganization = {
   __typename?: 'ChatOrganization',
  name?: Maybe<Scalars['String']>,
};

/** 채팅방 */
export type ChatRoom = {
   __typename?: 'ChatRoom',
  /** 채팅방 ID */
  id: Scalars['ID'],
  /** 채팅방 인원 수 */
  memberCount: Scalars['Int'],
  /** 채팅방 인기 순위 */
  rank?: Maybe<Scalars['Int']>,
  /** 채팅방 생성 시각 */
  createdAt: Scalars['Date'],
  /** 채팅방 이름 */
  name: Scalars['String'],
  /** 채팅 메시지 수 */
  chatMessageCount: Scalars['Int'],
  /** 채팅방의 마지막 메시지 발신 시각 */
  lastChattedAt: Scalars['Date'],
  /** 채팅방의 총 메시지 발신자 수 */
  totalSenderCount: Scalars['Int'],
  /** 채팅방 이미지 url */
  imageUrl?: Maybe<Scalars['String']>,
  /** 채팅방 이미지 이름 */
  imageName?: Maybe<Scalars['String']>,
  /** 채팅방 활동 타입 */
  activityTypeId: Scalars['Int'],
  /** 채팅방 인기 순위(공고 타입별) */
  activityTypeRank?: Maybe<Scalars['Int']>,
  /** 채팅방의 멘토 채팅 메시지 마지막 발신 시각 */
  lastMentorChatMessageCreatedAt?: Maybe<Scalars['Date']>,
  /** 채팅방의 기업회원 채팅 메시지 마지막 발신 시각 */
  lastManagerChatMessageCreatedAt?: Maybe<Scalars['Date']>,
  /** 채팅방의 상단 고정 순서 */
  fixedOrder?: Maybe<Scalars['Int']>,
  /** 인기 채팅방 최근 멘토 메시지 발신 여부 */
  isMentorRecentChatMessageSentInPopularChatRoom: Scalars['Boolean'],
  /** 나의 채팅방 최근 멘토 메시지 발신 여부 */
  isMentorRecentChatMessageSentInMyChatRoom: Scalars['Boolean'],
  /** 인기 채팅방 최근 기업회원 메시지 발신 여부 */
  isManagerRecentChatMessageSentInPopularChatRoom: Scalars['Boolean'],
  /** 나의 채팅방 최근 기업회원 메시지 발신 여부 */
  isManagerRecentChatMessageSentInMyChatRoom: Scalars['Boolean'],
  /** 채팅방 별 알림 설정 */
  enableNotification: Scalars['Boolean'],
  /** 유저가 채팅방의 멤버인지 */
  viewerIsMember: Scalars['Boolean'],
  /** 
 * 읽지 않은 메시지 수.
   *   
   *   - 로그인 하지 않은 경우: null
   *   - 로그인 하였으며, 읽지 않은 메시지가 없는 경우: 0
   *   - 로그인 하였으며, 읽지 않은 메시지가 n개인 경우(n <= 100): n
   *   - 로그인 하였으며, 읽지 않은 메시지가 n개인 경우(n >= 101): 101
 **/
  unreadChatMessageCount?: Maybe<Scalars['Int']>,
  /** 채팅방의 마지막 메시지 */
  lastChatMessage?: Maybe<ChatMessage>,
  /** 채팅방 활성화 여부 */
  isActive: Scalars['Boolean'],
  /** 채팅방 고정 여부 */
  isFixed: Scalars['Boolean'],
};

export type ChatRoomConnection = {
   __typename?: 'ChatRoomConnection',
  nodes: Array<ChatRoom>,
  edges: Array<ChatRoomEdge>,
  pageInfo: PageInfo,
  totalCount?: Maybe<Scalars['Int']>,
};

export type ChatRoomEdge = {
   __typename?: 'ChatRoomEdge',
  node: ChatRoom,
  cursor: Scalars['String'],
};

export type ChatRoomOffsetConnection = {
   __typename?: 'ChatRoomOffsetConnection',
  nodes: Array<ChatRoom>,
  totalCount: Scalars['Int'],
};

/** 
 * 채팅방 정렬 필드
 * 
 * - CHAT_MESSAGE_COUNT: 채팅방의 채팅 메시지 수
 * - MEMBER_COUNT: 채팅방의 채팅 멤버 수
 * - RANK: 채팅방의 순위
 * - SENDER_COUNT: 채팅방의 채팅 메시지를 보낸 유저 수
 **/
export enum ChatRoomOrderByField {
  CHAT_MESSAGE_COUNT = 'CHAT_MESSAGE_COUNT',
  MEMBER_COUNT = 'MEMBER_COUNT',
  RANK = 'RANK',
  SENDER_COUNT = 'SENDER_COUNT'
}

export type ClubFilterCollection = {
   __typename?: 'ClubFilterCollection',
  categories: Array<Category>,
  interests: Array<Interest>,
  regions: Array<Region>,
  targets: Array<ActivityTarget>,
};

/**  커뮤니티 답글 알림  */
export type CommentToCommentNotification = SimpleNotification & {
   __typename?: 'CommentToCommentNotification',
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  deepLinkUrl?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  linkUrl: Scalars['String'],
  title?: Maybe<Scalars['String']>,
  unread: Scalars['Boolean'],
  updatedAt?: Maybe<Scalars['Date']>,
};

/**  커뮤니티 멘토 게시글 답글 알림  */
export type CommentToMentorPostNotification = SimpleNotification & {
   __typename?: 'CommentToMentorPostNotification',
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  deepLinkUrl?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  linkUrl: Scalars['String'],
  title?: Maybe<Scalars['String']>,
  unread: Scalars['Boolean'],
  updatedAt?: Maybe<Scalars['Date']>,
};

/**  커뮤니티 게시글 댓글 알림 */
export type CommentToPostNotification = SimpleNotification & {
   __typename?: 'CommentToPostNotification',
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  deepLinkUrl?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  linkUrl: Scalars['String'],
  title?: Maybe<Scalars['String']>,
  unread: Scalars['Boolean'],
  updatedAt?: Maybe<Scalars['Date']>,
};

export type Community_Menu = CommunityBookmarkable & {
   __typename?: 'Community_Menu',
  bookmarkable?: Maybe<Scalars['Boolean']>,
  hasNewPost?: Maybe<Scalars['Boolean']>,
  id: Scalars['ID'],
  isShortcut?: Maybe<Scalars['Boolean']>,
  order?: Maybe<Scalars['Int']>,
  slug?: Maybe<Scalars['String']>,
  subMenus: Array<Community_Menu>,
  title?: Maybe<Scalars['String']>,
  updatedAt?: Maybe<Scalars['Date']>,
  viewerHasBookmarked: Scalars['Boolean'],
};

export type CommunityBestPostListData = {
   __typename?: 'CommunityBestPostListData',
  data?: Maybe<Scalars['Object']>,
};

export type CommunityBestPostListInput = {
  /** Use type and pagination */
  endDate?: Maybe<Scalars['String']>,
  /** Use type and pagination */
  minimumCommentCount?: Maybe<Scalars['Int']>,
  /** Use type and pagination */
  minimumLikeCount?: Maybe<Scalars['Int']>,
  /** Use type and pagination */
  minimumViewCount?: Maybe<Scalars['Int']>,
  /** Use type and pagination */
  order?: Maybe<Scalars['String']>,
  pagination?: Maybe<Pagination>,
  /** Use type and pagination */
  startDate?: Maybe<Scalars['String']>,
  /** Use non-null assertion apoerator */
  type?: Maybe<CommunityBestPostListType>,
};

export enum CommunityBestPostListType {
  LAST_MONTH = 'LAST_MONTH',
  LAST_WEEK = 'LAST_WEEK',
  RECENT = 'RECENT',
  REPLY_COUNT = 'REPLY_COUNT',
  VOTE_COUNT = 'VOTE_COUNT'
}

export type CommunityBoardListData = {
   __typename?: 'communityBoardListData',
  menu?: Maybe<Community_Menu>,
};

export type CommunityBoardObject = {
   __typename?: 'CommunityBoardObject',
  id: Scalars['ID'],
  slug?: Maybe<Scalars['String']>,
};

export enum CommunityBoardSlug {
  HOTHOT = 'HOTHOT',
  INTERN_ACTIVITY = 'INTERN_ACTIVITY',
  JUNIOR_ACTIVITY = 'JUNIOR_ACTIVITY',
  STRATEGY = 'STRATEGY'
}

export type CommunityBoardUrl = {
   __typename?: 'CommunityBoardUrl',
  activityType?: Maybe<ActivityType>,
  id: Scalars['ID'],
  jobType?: Maybe<Scalars['String']>,
  slug?: Maybe<Array<Scalars['String']>>,
  title: Scalars['String'],
  url: Scalars['String'],
};

export type CommunityBoardUrlConnection = {
   __typename?: 'CommunityBoardUrlConnection',
  nodes: Array<Maybe<CommunityBoardUrl>>,
  totalCount: Scalars['Int'],
};

export type CommunityBookmarkable = {
  id: Scalars['ID'],
  viewerHasBookmarked: Scalars['Boolean'],
};

export type CommunityBookmarkableConnection = {
   __typename?: 'CommunityBookmarkableConnection',
  nodes: Array<CommunityBookmarkable>,
  totalCount: Scalars['Int'],
};

export type CommunityBookmarkOrder = {
  direction: OrderDirection,
  field: CommunityBookmarkOrderField,
};

export enum CommunityBookmarkOrderField {
  CREATED_AT = 'CREATED_AT',
  ID = 'ID'
}

export type CommunityNotificationsData = {
   __typename?: 'CommunityNotificationsData',
  data?: Maybe<Scalars['Object']>,
};

export type CommunityNotificationsInput = {
  authUserID: Scalars['ID'],
};

export type CommunityPostCollectionData = {
   __typename?: 'CommunityPostCollectionData',
  data?: Maybe<Scalars['Object']>,
};

export type CommunityPostCollectionInput = {
  collectionID: Scalars['Int'],
  pruneLength: Scalars['Int'],
};

export type CommunityPostObject = {
   __typename?: 'CommunityPostObject',
  board?: Maybe<CommunityBoardObject>,
  commentCount?: Maybe<Scalars['Int']>,
  content?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['Date']>,
  id: Scalars['ID'],
  imageSrc?: Maybe<Scalars['String']>,
  likes?: Maybe<Scalars['Int']>,
  nickname?: Maybe<Scalars['String']>,
  scrapCount?: Maybe<Scalars['Int']>,
  title?: Maybe<Scalars['String']>,
  url?: Maybe<Scalars['String']>,
  views?: Maybe<Scalars['Int']>,
  youtubeSrc?: Maybe<Scalars['String']>,
};

export type CommunityPostRelatedWithActivity = {
   __typename?: 'CommunityPostRelatedWithActivity',
  activity?: Maybe<Activity>,
  communityPost?: Maybe<CommunityPostObject>,
  id: Scalars['ID'],
  seq?: Maybe<Scalars['Int']>,
};

export type CommunityPostRelatedWithActivityConnection = {
   __typename?: 'CommunityPostRelatedWithActivityConnection',
  nodes?: Maybe<Array<CommunityPostObject>>,
  totalCount: Scalars['Int'],
};

export type CommunityPostRelatedWithActivityManualConnection = {
   __typename?: 'CommunityPostRelatedWithActivityManualConnection',
  nodes?: Maybe<Array<CommunityPostRelatedWithActivity>>,
  totalCount: Scalars['Int'],
};

export type CommunityPostRelatedWithActivitySearchData = {
   __typename?: 'CommunityPostRelatedWithActivitySearchData',
  data?: Maybe<Scalars['Object']>,
};

export type CommunityPostSearchData = {
   __typename?: 'CommunityPostSearchData',
  data?: Maybe<Scalars['Object']>,
};

export type CommunityPostSearchInput = {
  boardSlugs: Array<Maybe<Scalars['String']>>,
  dutyId?: Maybe<Scalars['ID']>,
  dutyName?: Maybe<Scalars['String']>,
  field?: Maybe<SearchPostFromBoardWordField>,
  isBestPost?: Maybe<Scalars['Boolean']>,
  minimumShouldMatch?: Maybe<Scalars['String']>,
  orderBy?: Maybe<CommunityPostSearchOrderInput>,
  organizationName?: Maybe<Scalars['String']>,
  pagination?: Maybe<Pagination>,
  pruneLength: Scalars['Int'],
  query?: Maybe<Scalars['String']>,
  schoolName?: Maybe<Scalars['String']>,
};

export enum CommunityPostSearchOrderField {
  CREATED_AT = 'CREATED_AT',
  RELEVANCE = 'RELEVANCE',
  VIEW_COUNT = 'VIEW_COUNT'
}

export type CommunityPostSearchOrderInput = {
  direction: OrderDirection,
  field: CommunityPostSearchOrderField,
};

export type CommunityPostsRelatedWithActivityInput = {
  activityId: Scalars['ID'],
};

export type CommunityPostsRelatedWithActivityManualInput = {
  activityId: Scalars['ID'],
};

export type CompleteQuestionSuggestionInput = {
  ids: Array<Scalars['ID']>,
};

export type CompleteQuestionSuggestionPayload = {
   __typename?: 'CompleteQuestionSuggestionPayload',
  questionSuggestion: Array<Maybe<QuestionSuggestion>>,
};

export type ContestFilterCollection = {
   __typename?: 'ContestFilterCollection',
  benefits: Array<Benefit>,
  categories: Array<Category>,
  organizationTypes: Array<OrganizationType>,
  rewards: Array<Reward>,
  targets: Array<ActivityTarget>,
};

/** 비용타입 필터 */
export type CostType = {
   __typename?: 'CostType',
  id: Scalars['ID'],
  name: Scalars['String'],
};

export type CoverLetter = {
   __typename?: 'CoverLetter',
  activity?: Maybe<Scalars['String']>,
  addViewCount: Scalars['Int'],
  career?: Maybe<Scalars['String']>,
  content: Scalars['String'],
  createdAt?: Maybe<Scalars['Date']>,
  deletedAt?: Maybe<Scalars['Date']>,
  grades?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  isScrapped?: Maybe<Scalars['Boolean']>,
  languageScore?: Maybe<Scalars['String']>,
  license?: Maybe<Scalars['String']>,
  major?: Maybe<Scalars['String']>,
  organizationName?: Maybe<Scalars['String']>,
  passedAt?: Maybe<Scalars['Date']>,
  role?: Maybe<Scalars['String']>,
  score?: Maybe<Scalars['Int']>,
  scrapCount?: Maybe<Scalars['Int']>,
  source?: Maybe<CoverLetterSource>,
  sourceKey?: Maybe<Scalars['String']>,
  status?: Maybe<CoverLetterStatus>,
  types?: Maybe<Array<Maybe<CoverLetterType>>>,
  university?: Maybe<Scalars['String']>,
  updatedAt?: Maybe<Scalars['Date']>,
  viewCount: Scalars['Int'],
  viewerParagraphScraps: Array<CoverLetterParagraphScrap>,
};


export type CoverLetterContentArgs = {
  length?: Maybe<Scalars['Int']>
};

export type CoverLetterBulkCreateInput = {
  activity?: Maybe<Scalars['String']>,
  career?: Maybe<Scalars['String']>,
  content: Scalars['String'],
  grades?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['ID']>,
  languageScore?: Maybe<Scalars['String']>,
  license?: Maybe<Scalars['String']>,
  major?: Maybe<Scalars['String']>,
  organizationName?: Maybe<Scalars['String']>,
  passedAt?: Maybe<Scalars['Date']>,
  role?: Maybe<Scalars['String']>,
  score?: Maybe<Scalars['Int']>,
  source?: Maybe<CoverLetterSource>,
  sourceKey?: Maybe<Scalars['String']>,
  types?: Maybe<Array<Maybe<CoverLetterType>>>,
  university?: Maybe<Scalars['String']>,
};

export type CoverLetterCreateInput = {
  activity?: Maybe<Scalars['String']>,
  career?: Maybe<Scalars['String']>,
  content: Scalars['String'],
  grades?: Maybe<Scalars['String']>,
  languageScore?: Maybe<Scalars['String']>,
  license?: Maybe<Scalars['String']>,
  major?: Maybe<Scalars['String']>,
  organizationName?: Maybe<Scalars['String']>,
  passedAt?: Maybe<Scalars['Date']>,
  role?: Maybe<Scalars['String']>,
  source?: Maybe<CoverLetterSource>,
  sourceKey?: Maybe<Scalars['String']>,
  status?: Maybe<CoverLetterStatus>,
  types?: Maybe<Array<Maybe<CoverLetterType>>>,
  university?: Maybe<Scalars['String']>,
};

export type CoverLetterCreatePayload = {
   __typename?: 'CoverLetterCreatePayload',
  coverLetter?: Maybe<CoverLetter>,
};

export type CoverLetterDeleteManyPayload = {
   __typename?: 'coverLetterDeleteManyPayload',
  coverLetters?: Maybe<Array<Maybe<CoverLetter>>>,
};

export type CoverLetterElasticConnection = {
   __typename?: 'CoverLetterElasticConnection',
  edges?: Maybe<Array<CoverLetterElasticEdge>>,
  totalCount: Scalars['Int'],
};

export type CoverLetterElasticEdge = {
   __typename?: 'CoverLetterElasticEdge',
  highlight?: Maybe<Array<Scalars['String']>>,
  node?: Maybe<CoverLetter>,
};

export enum CoverLetterField {
  ORGANIZATION_NAME = 'ORGANIZATION_NAME',
  ROLE = 'ROLE'
}

export type CoverLetterFilter = {
  fragmentSize?: Maybe<Scalars['Int']>,
  keyword?: Maybe<Scalars['String']>,
  numberOfFragments?: Maybe<Scalars['Int']>,
  organizationName?: Maybe<Scalars['String']>,
  role?: Maybe<Scalars['String']>,
  status?: Maybe<CoverLetterStatus>,
  types?: Maybe<Array<Maybe<CoverLetterType>>>,
};

export type CoverLetterManyAddTypeInput = {
  ids: Array<Scalars['ID']>,
  type: CoverLetterType,
};

export type CoverLetterManyAddTypePayload = {
   __typename?: 'CoverLetterManyAddTypePayload',
  updatedCoverLetters?: Maybe<Array<Maybe<CoverLetter>>>,
};

export type CoverLetterOrder = {
  direction: OrderDirection,
  field: CoverLetterOrderField,
};

export enum CoverLetterOrderField {
  CAREER = 'CAREER',
  GRADES = 'GRADES',
  ID = 'ID',
  LANGUAGE_SCORE = 'LANGUAGE_SCORE',
  MAJOR = 'MAJOR',
  ORGANIZATION_NAME = 'ORGANIZATION_NAME',
  PASSED_AT = 'PASSED_AT',
  RELEVANCE = 'RELEVANCE',
  ROLE = 'ROLE',
  SCORE = 'SCORE',
  SCRAP_COUNT = 'SCRAP_COUNT',
  STATUS = 'STATUS',
  UNIVERSITY = 'UNIVERSITY'
}

export type CoverLetterOrganizationListInput = {
  query?: Maybe<Scalars['String']>,
  status?: Maybe<CoverLetterStatus>,
  types?: Maybe<Array<Maybe<CoverLetterType>>>,
};

export type CoverLetterOrganizationListPayload = {
   __typename?: 'CoverLetterOrganizationListPayload',
  organizationName?: Maybe<Scalars['String']>,
};

export type CoverLetterParagraphScrap = {
   __typename?: 'CoverLetterParagraphScrap',
  coverLetter?: Maybe<CoverLetter>,
  createdAt: Scalars['Date'],
  endOffset: Scalars['Int'],
  id: Scalars['ID'],
  paragraph: Scalars['String'],
  startOffset: Scalars['Int'],
  user?: Maybe<User>,
};

export type CoverLetterParagraphScrapCreateInput = {
  coverLetterID: Scalars['ID'],
  endOffset: Scalars['Int'],
  paragraph: Scalars['String'],
  referer: Scalars['String'],
  startOffset: Scalars['Int'],
};

export type CoverLetterParagraphScrapCreatePayload = {
   __typename?: 'CoverLetterParagraphScrapCreatePayload',
  coverLetterParagraphScrap?: Maybe<CoverLetterParagraphScrap>,
  userScrap?: Maybe<UserScrap>,
};

export type CoverLetterParagraphScrapDeletePayload = {
   __typename?: 'CoverLetterParagraphScrapDeletePayload',
  coverLetterParagraphScrap?: Maybe<CoverLetterParagraphScrap>,
  userScrap?: Maybe<UserScrap>,
};

export type CoverLetterPlagiarism = {
   __typename?: 'CoverLetterPlagiarism',
  content: Scalars['String'],
  id: Scalars['ID'],
  score: Scalars['String'],
};

export type CoverLetterPlagiarismConnection = {
   __typename?: 'CoverLetterPlagiarismConnection',
  nodes: Array<CoverLetterPlagiarism>,
  totalCount: Scalars['Int'],
};

export type CoverLetterRelatedWithActivity = {
   __typename?: 'CoverLetterRelatedWithActivity',
  activity?: Maybe<Activity>,
  coverLetter?: Maybe<CoverLetter>,
  id: Scalars['ID'],
  seq?: Maybe<Scalars['Int']>,
};

export type CoverLetterRelatedWithActivityConnection = {
   __typename?: 'CoverLetterRelatedWithActivityConnection',
  nodes?: Maybe<Array<CoverLetter>>,
  totalCount: Scalars['Int'],
};

export type CoverLetterRelatedWithActivityManualConnection = {
   __typename?: 'CoverLetterRelatedWithActivityManualConnection',
  nodes?: Maybe<Array<CoverLetterRelatedWithActivity>>,
  totalCount: Scalars['Int'],
};

export type CoverLetterRoleListInput = {
  query?: Maybe<Scalars['String']>,
  status?: Maybe<CoverLetterStatus>,
  types?: Maybe<Array<Maybe<CoverLetterType>>>,
};

export type CoverLetterRoleListPayload = {
   __typename?: 'CoverLetterRoleListPayload',
  role?: Maybe<Scalars['String']>,
};

export type CoverLetterScrapUpdateInput = {
  coverLetterID: Scalars['ID'],
  referer: Scalars['String'],
};

export type CoverLetterScrapUpdatePayload = {
   __typename?: 'CoverLetterScrapUpdatePayload',
  coverLetter?: Maybe<CoverLetter>,
  scrapped?: Maybe<Scalars['Boolean']>,
  userScrap?: Maybe<UserScrap>,
};

export enum CoverLetterSource {
  MANUAL_BY_ADMIN = 'MANUAL_BY_ADMIN',
  NAVER_SPECUP = 'NAVER_SPECUP',
  OPEN_EVENT = 'OPEN_EVENT'
}

export type CoverLettersRelatedWithActivityInput = {
  activityId: Scalars['ID'],
};

export type CoverLettersRelatedWithActivityManualInput = {
  activityId: Scalars['ID'],
};

export enum CoverLetterStatus {
  PENDING = 'PENDING',
  PUBLISHED = 'PUBLISHED',
  REJECTED = 'REJECTED'
}

export enum CoverLetterType {
  ALL = 'ALL',
  BANKS = 'BANKS',
  ETC = 'ETC',
  INTERN = 'INTERN',
  MAJOR_COMPANY = 'MAJOR_COMPANY',
  MEDIUM_COMPANY = 'MEDIUM_COMPANY',
  NEWCOMER = 'NEWCOMER',
  PUBLIC_INSTITUTIONS = 'PUBLIC_INSTITUTIONS',
  SMALL_COMPANY = 'SMALL_COMPANY'
}

export type CoverLetterTypeConnection = {
   __typename?: 'CoverLetterTypeConnection',
  coverLetterID: Scalars['ID'],
  id: Scalars['ID'],
  name?: Maybe<CoverLetterType>,
};

export type CoverLetterUpdateInput = {
  activity?: Maybe<Scalars['String']>,
  career?: Maybe<Scalars['String']>,
  content?: Maybe<Scalars['String']>,
  grades?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  languageScore?: Maybe<Scalars['String']>,
  license?: Maybe<Scalars['String']>,
  major?: Maybe<Scalars['String']>,
  organizationName?: Maybe<Scalars['String']>,
  passedAt?: Maybe<Scalars['Date']>,
  role?: Maybe<Scalars['String']>,
  status?: Maybe<CoverLetterStatus>,
  types?: Maybe<Array<Maybe<CoverLetterType>>>,
  university?: Maybe<Scalars['String']>,
  viewCount?: Maybe<Scalars['Int']>,
};

export type CoverLetterUpdateManyInput = {
  activity?: Maybe<Scalars['String']>,
  career?: Maybe<Scalars['String']>,
  content?: Maybe<Scalars['String']>,
  grades?: Maybe<Scalars['String']>,
  languageScore?: Maybe<Scalars['String']>,
  license?: Maybe<Scalars['String']>,
  major?: Maybe<Scalars['String']>,
  organizationName?: Maybe<Scalars['String']>,
  passedAt?: Maybe<Scalars['Date']>,
  role?: Maybe<Scalars['String']>,
  status?: Maybe<CoverLetterStatus>,
  types?: Maybe<Array<Maybe<CoverLetterType>>>,
  university?: Maybe<Scalars['String']>,
};

export type CoverLetterUpdatePayload = {
   __typename?: 'CoverLetterUpdatePayload',
  coverLetter?: Maybe<CoverLetter>,
};

export type CoverLetterWithHighlight = {
   __typename?: 'CoverLetterWithHighlight',
  coverLetter?: Maybe<CoverLetter>,
  highlight?: Maybe<Array<Scalars['String']>>,
};

export type CreateAddOrganizationSuggestionInput = {
  /** 기기 정보 */
  deviceInfo?: Maybe<DeviceType>,
  /** 추가를 원하는 기업명 */
  organizationName: Scalars['String'],
};

export type CreateAddOrganizationSuggestionPayload = {
   __typename?: 'CreateAddOrganizationSuggestionPayload',
  suggestion: Suggestion,
};

export type CreateAdInput = {
  adPlacementId: Scalars['ID'],
  advertiser?: Maybe<Scalars['String']>,
  content?: Maybe<Scalars['String']>,
  image?: Maybe<Scalars['Upload']>,
  order: Scalars['Int'],
  relatedId?: Maybe<Scalars['ID']>,
  relationType?: Maybe<AdRelationType>,
  title?: Maybe<Scalars['String']>,
};

export type CreateAdPlacementInput = {
  code: Scalars['String'],
  defaultImage?: Maybe<Scalars['Upload']>,
  defaultLandingUrl?: Maybe<Scalars['String']>,
  height: Scalars['Int'],
  isActive: Scalars['Boolean'],
  name: Scalars['String'],
  width: Scalars['Int'],
};

export type CreateAdPlacementPayload = {
   __typename?: 'CreateAdPlacementPayload',
  adPlacement: AdPlacement,
};

export type CreateCampaignInput = {
  adInputs: Array<CreateAdInput>,
  content?: Maybe<Scalars['String']>,
  endAt: Scalars['Date'],
  isLandingDisabled?: Maybe<Scalars['Boolean']>,
  landingUrl: Scalars['String'],
  name: Scalars['String'],
  startAt: Scalars['Date'],
};

export type CreateCampaignPayload = {
   __typename?: 'CreateCampaignPayload',
  campaign: Campaign,
};

export type CreateChannelInput = {
  briefDescription: Scalars['String'],
  channelUrl: Scalars['String'],
  coverAttachmentId?: Maybe<Scalars['ID']>,
  description?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  ownerId?: Maybe<Scalars['ID']>,
};

export type CreateChannelPayload = {
   __typename?: 'CreateChannelPayload',
  channel?: Maybe<Channel>,
};

/** 채팅방 생성 정보 */
export type CreateChatRoomInput = {
  /** 채팅방 이름 */
  name: Scalars['String'],
  /** 
 * 채팅방의 activityTypeID.
   * 
   * - 채용: 5
   * - 대외활동: 1
   * - 공모전: 3
 **/
  activityTypeId?: Maybe<Scalars['Int']>,
  /** 이미지 url */
  imageUrl?: Maybe<Scalars['String']>,
  /** 이미지 이름 */
  imageName?: Maybe<Scalars['String']>,
};

/** 채팅방 생성 결과 */
export type CreateChatRoomPayload = {
   __typename?: 'CreateChatRoomPayload',
  /** 생성된 채팅방 */
  chatRoom: ChatRoom,
};

export type CreatedAtTerm = {
  from?: Maybe<Scalars['Date']>,
  to?: Maybe<Scalars['Date']>,
};

export type CreateEmbedInput = {
  description?: Maybe<Scalars['String']>,
  domain: Scalars['String'],
  imageUrl?: Maybe<Scalars['String']>,
  url: Scalars['String'],
};

export type CreateFolderInput = {
  name: Scalars['String'],
  parentId?: Maybe<Scalars['ID']>,
};

export type CreateFolderPayload = {
   __typename?: 'CreateFolderPayload',
  folder: Folder,
};

export type CreateLinkareerMagazineInput = {
  content?: Maybe<Scalars['String']>,
  pcImage?: Maybe<Scalars['String']>,
  pcImageId: Scalars['ID'],
  title: Scalars['String'],
  url: Scalars['String'],
};

export type CreateLinkareerMagazinePayload = {
   __typename?: 'CreateLinkareerMagazinePayload',
  linkareerMagazine?: Maybe<LinkareerMagazine>,
};

export type CreateNewsCommentInput = {
  content: Scalars['String'],
  newsItemId: Scalars['ID'],
  parentId?: Maybe<Scalars['ID']>,
};

export type CreateNewsCommentPayload = {
   __typename?: 'CreateNewsCommentPayload',
  newsComment?: Maybe<NewsComment>,
};

export type CreateNewsItemInput = {
  content: Scalars['String'],
  embedInput?: Maybe<CreateEmbedInput>,
  imageIds?: Maybe<Array<Scalars['ID']>>,
  tagNames?: Maybe<Array<Scalars['String']>>,
};

export type CreateNewsItemPayload = {
   __typename?: 'CreateNewsItemPayload',
  newsItem?: Maybe<NewsItem>,
};

export type CreateQnAInput = {
  answer?: Maybe<Scalars['String']>,
  blankType?: Maybe<BlankType>,
  charMaxSize?: Maybe<Scalars['Int']>,
  charSize?: Maybe<CharSize>,
  question?: Maybe<Scalars['String']>,
};

export type CreateQnAPayload = {
   __typename?: 'CreateQnAPayload',
  qna?: Maybe<QnA>,
};

export type CreateQuestionSuggestionInput = {
  activityId: Scalars['ID'],
  attachmentIDs?: Maybe<Array<Scalars['ID']>>,
  content: Scalars['String'],
  email: Scalars['String'],
};

export type CreateQuestionSuggestionPayload = {
   __typename?: 'CreateQuestionSuggestionPayload',
  questionSuggestion?: Maybe<QuestionSuggestion>,
};

export type CreateRecommendSearchKeywordInput = {
  searchField?: Maybe<SearchKeywordFieldType>,
  seq?: Maybe<Scalars['Int']>,
  title: Scalars['String'],
  type?: Maybe<RecommendSearchKeywordType>,
};

export type CreateRecommendSearchKeywordPayload = {
   __typename?: 'CreateRecommendSearchKeywordPayload',
  recommendSearchKeyword?: Maybe<RecommendSearchKeyword>,
};

export type CreateSelfIntroductionInput = {
  dutyId?: Maybe<Scalars['ID']>,
  qnas?: Maybe<Array<CreateQnAInput>>,
  title: Scalars['String'],
};

export type CreateSelfIntroductionPayload = {
   __typename?: 'CreateSelfIntroductionPayload',
  selfIntroduction?: Maybe<SelfIntroduction>,
};

export type CreateSuggestionInput = {
  attachmentIds?: Maybe<Array<Scalars['ID']>>,
  content: Scalars['String'],
  deviceInfo?: Maybe<DeviceType>,
  email: Scalars['String'],
};

export type CreateSuggestionPayload = {
   __typename?: 'createSuggestionPayload',
  suggestion: Suggestion,
};

export type CreateTagCollectionInput = {
  seq?: Maybe<Scalars['Int']>,
  title: Scalars['String'],
};

export type CreateTagCollectionPayload = {
   __typename?: 'CreateTagCollectionPayload',
  tagCollection?: Maybe<TagCollection>,
};

export type CreateTestUserInput = {
  birthday?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  password?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<Scalars['String']>,
  profileURL?: Maybe<Scalars['String']>,
  specupJoined?: Maybe<Scalars['Boolean']>,
  tsSignup?: Maybe<Scalars['Boolean']>,
  userType?: Maybe<UserType>,
  username?: Maybe<Scalars['String']>,
};

export type CreateTestUserPayload = {
   __typename?: 'CreateTestUserPayload',
  user?: Maybe<User>,
};

export type CreateUniqueNameOrganizationGroupInput = {
  id: Scalars['ID'],
  name: Scalars['String'],
};

export type CreateUniqueNameOrganizationInput = {
  createdAt: Scalars['Date'],
  group?: Maybe<CreateUniqueNameOrganizationGroupInput>,
  id: Scalars['ID'],
  isGroup: Scalars['Boolean'],
  name: Scalars['String'],
  postCount: Scalars['Int'],
};

export type CreateUniqueNameOrganizationsInput = {
  inputs: Array<CreateUniqueNameOrganizationInput>,
};

export type CreateUniqueNameOrganizationsPayload = {
   __typename?: 'CreateUniqueNameOrganizationsPayload',
  createdCount: Scalars['Int'],
};

export type Curation = {
   __typename?: 'Curation',
  createdAt: Scalars['Date'],
  id: Scalars['ID'],
  seq?: Maybe<Scalars['Int']>,
  title: Scalars['String'],
  updatedAt?: Maybe<Scalars['Date']>,
  url?: Maybe<Scalars['String']>,
};

export type CurationCreateInput = {
  seq?: Maybe<Scalars['Int']>,
  title: Scalars['String'],
  url?: Maybe<Scalars['String']>,
};

export type CurationCreatePayload = {
   __typename?: 'CurationCreatePayload',
  curation?: Maybe<Curation>,
};

export type CurationDeletePayload = {
   __typename?: 'CurationDeletePayload',
  curation?: Maybe<Curation>,
};

export type CurationListConnection = {
   __typename?: 'CurationListConnection',
  curations?: Maybe<Array<Maybe<Curation>>>,
  totalCount?: Maybe<Scalars['Int']>,
};

export type CurationUpdateInput = {
  seq?: Maybe<Scalars['Int']>,
  title?: Maybe<Scalars['String']>,
  url?: Maybe<Scalars['String']>,
};

export type CurationUpdatePayload = {
   __typename?: 'CurationUpdatePayload',
  curation?: Maybe<Curation>,
};

export type CurrentUserScrapCommunityConnection = {
   __typename?: 'CurrentUserScrapCommunityConnection',
  nodes: Array<UserScrapCommunity>,
  totalCount: Scalars['Int'],
};

export type CurrentUserScrapCommunityFilter = {
  isDeletedAt?: Maybe<Scalars['Boolean']>,
  postID?: Maybe<Scalars['ID']>,
  q?: Maybe<Scalars['String']>,
};

export type CurrentUserScrapDeleteCommunityPayload = {
   __typename?: 'currentUserScrapDeleteCommunityPayload',
  userScrapCommunity?: Maybe<UserScrapCommunity>,
};

export type CurrentUserScrapsDeleteAllCommunityPayload = {
   __typename?: 'currentUserScrapsDeleteAllCommunityPayload',
  userScrapsCommunity?: Maybe<Array<UserScrapCommunity>>,
};


/** 
 * 기간을 지정할 때 사용할 단위
 * 
 * - DAILY: 일 단위
 * - WEEKLY: 주 단위
 * - MONTHLY: 월 단위
 **/
export enum DatePeriod {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY'
}

export type DateRange = {
  from: Scalars['Date'],
  to: Scalars['Date'],
};

export enum DateRepresentationType {
  DAY = 'DAY',
  MONTH = 'MONTH'
}

export enum DateTerm {
  DAILY = 'DAILY',
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY'
}

export type DeactivateChannelPayload = {
   __typename?: 'DeactivateChannelPayload',
  channel?: Maybe<Channel>,
};

export type DeleteActivityManagerPresetPayload = {
   __typename?: 'DeleteActivityManagerPresetPayload',
  managerPreset?: Maybe<ActivityManagerPreset>,
};

export type DeleteActivityOrganizationPresetPayload = {
   __typename?: 'DeleteActivityOrganizationPresetPayload',
  organizationPreset?: Maybe<ActivityOrganizationPreset>,
};

export type DeleteAllNotificationPayload = {
   __typename?: 'DeleteAllNotificationPayload',
  count?: Maybe<Scalars['Int']>,
};

export type DeleteCampaignsPayload = {
   __typename?: 'DeleteCampaignsPayload',
  campaigns: Array<Campaign>,
};

export type DeleteChannelPayload = {
   __typename?: 'DeleteChannelPayload',
  channel: Channel,
};

/** 채팅 메시지를 삭제한 결과 */
export type DeleteChatMessagePayload = {
   __typename?: 'DeleteChatMessagePayload',
  /** 삭제된 채팅 메시지 */
  chatMessage: ChatMessage,
};

/** 채팅방 삭제 결과 */
export type DeleteChatRoomPayload = {
   __typename?: 'DeleteChatRoomPayload',
  /** 삭제된 채팅방 */
  chatRoom: ChatRoom,
};

export type DeleteCurrentUserInput = {
  reason: Scalars['String'],
  reasonType: UserDeleteReasonType,
  userID: Scalars['ID'],
  userType: Scalars['String'],
};

export type DeleteCurrentUserPayload = {
   __typename?: 'DeleteCurrentUserPayload',
  user?: Maybe<User>,
};

/** 
 * 삭제된 채팅 메시지 검색 필드
 * 
 * - CONTENT: 채팅 메시지 내용
 * - CHAT_NICKNAME: 채팅 메시지를 보낸 유저의 닉네임
 * - CHAT_ROOM_NAME: 채팅 메시지가 전송된 채팅방 이름
 **/
export enum DeletedChatMessageSearchField {
  CONTENT = 'CONTENT',
  CHAT_NICKNAME = 'CHAT_NICKNAME',
  CHAT_ROOM_NAME = 'CHAT_ROOM_NAME'
}

/** 삭제된 채팅 검색 조건 */
export type DeletedChatMessageSearchInput = {
  /** 검색 필드 */
  searchField: DeletedChatMessageSearchField,
  /** 검색 키워드 */
  searchKeyword: Scalars['String'],
};

export type DeletedNewsCommentFilter = {
  deletedAtRange?: Maybe<DateRange>,
  q?: Maybe<Scalars['String']>,
};

export type DeletedNewsItemFilter = {
  deletedAtRange?: Maybe<DateRange>,
  q?: Maybe<Scalars['String']>,
};

export type DeletedUserScrap = {
   __typename?: 'DeletedUserScrap',
  scrapped?: Maybe<Scalars['Boolean']>,
  userScrap?: Maybe<UserScrap>,
};

export type DeleteFolderInput = {
  id: Scalars['ID'],
};

export type DeleteFolderPayload = {
   __typename?: 'DeleteFolderPayload',
  folder: Folder,
};

export type DeleteManyUserAccountsPayload = {
   __typename?: 'DeleteManyUserAccountsPayload',
  users: Array<Maybe<User>>,
};

export type DeleteNewsCommentsPayload = {
   __typename?: 'DeleteNewsCommentsPayload',
  newsComments?: Maybe<Array<Maybe<NewsComment>>>,
};

export type DeleteNewsItemsPayload = {
   __typename?: 'DeleteNewsItemsPayload',
  newsItems?: Maybe<Array<Maybe<NewsItem>>>,
};

export type DeleteNotificationPayload = {
   __typename?: 'DeleteNotificationPayload',
  notification?: Maybe<SimpleNotification>,
};

export type DeleteQnAInput = {
  id: Scalars['ID'],
};

export type DeleteQnAPayload = {
   __typename?: 'DeleteQnAPayload',
  qna?: Maybe<QnA>,
};

export type DeleteQnASnapshotPayload = {
   __typename?: 'DeleteQnASnapshotPayload',
  snapshot?: Maybe<QnASnapshot>,
};

export type DeleteQuestionSuggestionInput = {
  ids: Array<Scalars['ID']>,
};

export type DeleteQuestionSuggestionPayload = {
   __typename?: 'DeleteQuestionSuggestionPayload',
  questionSuggestion: Array<Maybe<QuestionSuggestion>>,
};

export type DeleteRecommendSearchKeywordPayload = {
   __typename?: 'DeleteRecommendSearchKeywordPayload',
  recommendSearchKeyword?: Maybe<RecommendSearchKeyword>,
};

/** 자소서 삭제 입력 */
export type DeleteSelfIntroductionInput = {
  /** 삭제 대상 id */
  id: Scalars['ID'],
};

export type DeleteSelfIntroductionPayload = {
   __typename?: 'DeleteSelfIntroductionPayload',
  selfIntroduction?: Maybe<SelfIntroduction>,
};

export type DeleteTagCollectionPayload = {
   __typename?: 'DeleteTagCollectionPayload',
  tagCollection?: Maybe<TagCollection>,
};

export type DeleteUniqueNameOrganizationsPayload = {
   __typename?: 'DeleteUniqueNameOrganizationsPayload',
  deletedCount: Scalars['Int'],
};

export type DeleteUserAccountPayload = {
   __typename?: 'DeleteUserAccountPayload',
  user?: Maybe<User>,
};

export enum DeviceType {
  KAKAO_CHATBOT_ACTIVITY = 'KAKAO_CHATBOT_ACTIVITY',
  KAKAO_CHATBOT_CONTEST = 'KAKAO_CHATBOT_CONTEST',
  KAKAO_CHATBOT_RECRUIT = 'KAKAO_CHATBOT_RECRUIT',
  MOBILE = 'MOBILE',
  PC = 'PC'
}

export type Duty = {
   __typename?: 'Duty',
  activity?: Maybe<Activity>,
  categories: Array<Maybe<Category>>,
  id: Scalars['ID'],
  jobType: JobType,
  questionTemplates?: Maybe<Array<QuestionTemplate>>,
  selfIntroductionCount: Scalars['Int'],
  selfIntroductions: SelfIntroductionConnection,
  title: Scalars['String'],
  viewerSelfIntroductionFeed: SelfIntroductionConnection,
};


export type DutySelfIntroductionsArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>
};


export type DutyViewerSelfIntroductionFeedArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>
};

export type DutyConnection = {
   __typename?: 'DutyConnection',
  nodes: Array<Maybe<Duty>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type DutyFeedFilters = {
  hasQuestionTemplate?: Maybe<Scalars['Boolean']>,
};

export type DutyFilters = {
  categoryId?: Maybe<Scalars['ID']>,
  hasQuestionTemplate?: Maybe<Scalars['Boolean']>,
  ignoreEndedActivity?: Maybe<Scalars['Boolean']>,
  jobType?: Maybe<JobType>,
  query?: Maybe<Scalars['String']>,
};

export type DutyInput = {
  categoryIds: Array<Scalars['ID']>,
  jobType: JobType,
  questionTemplates?: Maybe<Array<QuestionTemplateInput>>,
  title: Scalars['String'],
};

export type DutyOrder = {
  direction: OrderDirection,
  field: DutyOrderField,
};

export enum DutyOrderField {
  CREATED_AT = 'CREATED_AT',
  END_AT = 'END_AT',
  JOB_TYPE_CREATED_AT = 'JOB_TYPE_CREATED_AT'
}

export enum EducationCost {
  FREE = 'FREE',
  FREE_SPONSORED = 'FREE_SPONSORED',
  PAID = 'PAID',
  PAID_SPONSORED = 'PAID_SPONSORED'
}

export type EducationFilterCollection = {
   __typename?: 'EducationFilterCollection',
  /** 카테고리 필터 */
  categories?: Maybe<Array<Category>>,
  /** 비용타입 필터 */
  costTypes?: Maybe<Array<CostType>>,
  /** 온오프라인 필터 */
  onofflines?: Maybe<Array<Onoffline>>,
  /** 기간 필터 */
  periods?: Maybe<Array<PeriodForEducationFilterCollection>>,
  /** 지역 필터 */
  regions?: Maybe<Array<Region>>,
};

export enum EducationType {
  COLLEAGE = 'COLLEAGE',
  EXAM = 'EXAM',
  GRADUATED = 'GRADUATED',
  HIGHSCHOOL = 'HIGHSCHOOL',
  UNIVERSITY = 'UNIVERSITY'
}

export type Embed = {
   __typename?: 'Embed',
  description?: Maybe<Scalars['String']>,
  domain: Scalars['String'],
  id: Scalars['ID'],
  imageUrl?: Maybe<Scalars['String']>,
  url: Scalars['String'],
};

export type Environment = {
   __typename?: 'Environment',
  domain: Scalars['String'],
};

export enum ErrorCodes {
  ALREADY_EXISTING_EMAIL = 'ALREADY_EXISTING_EMAIL',
  ALREADY_EXISTING_NICKNAME = 'ALREADY_EXISTING_NICKNAME',
  ALREADY_EXISTING_ORGANIZATION = 'ALREADY_EXISTING_ORGANIZATION',
  ALREADY_EXISTING_USERNAME = 'ALREADY_EXISTING_USERNAME',
  ALREADY_RATING_COMMENT = 'ALREADY_RATING_COMMENT',
  ALREADY_RATING_POST = 'ALREADY_RATING_POST',
  API_GRAPHQL_REQUEST_ERROR = 'API_GRAPHQL_REQUEST_ERROR',
  BEFORE_IDENTIFY = 'BEFORE_IDENTIFY',
  CANNOT_FOLLOW_OWN_CHANNEL = 'CANNOT_FOLLOW_OWN_CHANNEL',
  CANNOT_RATING_OWN_COMMENT = 'CANNOT_RATING_OWN_COMMENT',
  CANNOT_RATING_OWN_POST = 'CANNOT_RATING_OWN_POST',
  DEACTIVATED_CHANNEL = 'DEACTIVATED_CHANNEL',
  FORBIDDEN = 'FORBIDDEN',
  INVALID_REQUEST = 'INVALID_REQUEST',
  NOT_FOUND_ERROR = 'NOT_FOUND_ERROR',
  NOT_MATCHED_CERTIFICATION_INFO = 'NOT_MATCHED_CERTIFICATION_INFO',
  PERMANENTLY_MOVE = 'PERMANENTLY_MOVE',
  REQUEST_TIMEOUT = 'REQUEST_TIMEOUT',
  REQUIRED_LOGGED_IN = 'REQUIRED_LOGGED_IN',
  TEMPORARY_MOVE = 'TEMPORARY_MOVE',
  TIMESPREAD_STATUS_LUCKYBOX_ALREADY_OPEN = 'TIMESPREAD_STATUS_LUCKYBOX_ALREADY_OPEN',
  TIMESPREAD_STATUS_LUCKYBOX_LIMIT_EXCEED = 'TIMESPREAD_STATUS_LUCKYBOX_LIMIT_EXCEED',
  TIMESPREAD_STATUS_LUCKYBOX_NEED_CASH = 'TIMESPREAD_STATUS_LUCKYBOX_NEED_CASH',
  TIMESPREAD_STATUS_OBJECT_NOT_EXIST = 'TIMESPREAD_STATUS_OBJECT_NOT_EXIST',
  TIMESPREAD_STATUS_PHONE_ALREADY_AUTHORIZE = 'TIMESPREAD_STATUS_PHONE_ALREADY_AUTHORIZE',
  TIMESPREAD_STATUS_PHONE_CODE_DATA_NOT_FOUND = 'TIMESPREAD_STATUS_PHONE_CODE_DATA_NOT_FOUND',
  TIMESPREAD_STATUS_PHONE_CODE_REQUEST_EXCEED = 'TIMESPREAD_STATUS_PHONE_CODE_REQUEST_EXCEED',
  TIMESPREAD_STATUS_PHONE_FAIL_AUTHORIZE = 'TIMESPREAD_STATUS_PHONE_FAIL_AUTHORIZE',
  TIMESPREAD_STATUS_PHONE_NOT_ACCEPTED_IP = 'TIMESPREAD_STATUS_PHONE_NOT_ACCEPTED_IP',
  TIMESPREAD_STATUS_PHONE_REQUEST_EXCEED = 'TIMESPREAD_STATUS_PHONE_REQUEST_EXCEED',
  TIMESPREAD_STATUS_PHONE_USER_DELETE = 'TIMESPREAD_STATUS_PHONE_USER_DELETE',
  TIMESPREAD_STATUS_REQUIRE_PARAMETER = 'TIMESPREAD_STATUS_REQUIRE_PARAMETER',
  TIMESPREAD_STATUS_SCREEN_ALREADY_FINISH = 'TIMESPREAD_STATUS_SCREEN_ALREADY_FINISH',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR'
}

export type ErrorWord = {
   __typename?: 'ErrorWord',
  endIndex: Scalars['Int'],
  helpMessage?: Maybe<Scalars['String']>,
  originalWord: Scalars['String'],
  recommendedWords: Array<Scalars['String']>,
  startIndex: Scalars['Int'],
};

export type ExternalCareerExperienceInfo = {
   __typename?: 'ExternalCareerExperienceInfo',
  /** 퇴사년월 */
  endYearMonth?: Maybe<Scalars['Int']>,
  /** 회사명 */
  organizationName: Scalars['String'],
  /** 입사년월 */
  startYearMonth: Scalars['Int'],
};

export type FacebookAccount = {
   __typename?: 'FacebookAccount',
  id: Scalars['ID'],
};

export type FacebookDeleteUserInput = {
  facebookID: Scalars['ID'],
  sessionToken: Scalars['String'],
};

export type FacebookDeleteUserPayload = {
   __typename?: 'FacebookDeleteUserPayload',
  isFacebookUserDelete?: Maybe<Scalars['Boolean']>,
  isTimespreadUserDelete?: Maybe<Scalars['Boolean']>,
};

export type FacebookFakeLoginInput = {
  email?: Maybe<Scalars['String']>,
  nickname?: Maybe<Scalars['String']>,
};

export type FacebookFakeLoginPayload = {
   __typename?: 'FacebookFakeLoginPayload',
  signupSession?: Maybe<SignupSession>,
  user?: Maybe<User>,
};

export type FacebookFakeSignupPayload = {
   __typename?: 'FacebookFakeSignupPayload',
  email?: Maybe<Scalars['String']>,
  facebookID?: Maybe<Scalars['ID']>,
  name?: Maybe<Scalars['String']>,
  sessionToken?: Maybe<Scalars['String']>,
};

export type FacebookUpdateUserInput = {
  facebookID: Scalars['ID'],
  name?: Maybe<Scalars['String']>,
};

export type FamousActivityListInput = {
  activityID?: Maybe<Scalars['ID']>,
  /** Use activityTypeIDs field */
  activityTypeID?: Maybe<Scalars['Int']>,
  activityTypeIDs?: Maybe<Array<Scalars['Int']>>,
  categoryIDs: Array<Maybe<Scalars['Int']>>,
  interestIDs: Array<Maybe<Scalars['Int']>>,
  limit: Scalars['Int'],
  withInWeeks?: Maybe<Scalars['Int']>,
};

export type FamousActivityListPayload = {
   __typename?: 'FamousActivityListPayload',
  activities?: Maybe<Array<Activity>>,
};

/** 건강보험공단 자격득실확인 추가인증시 보내야 하는 객체 */
export type FetchNhis2WayIdentifyInput = {
  /** 생년월일 (YYMMDD 형태) */
  birthDate: Scalars['String'],
  /** 추가 인증 고유값 */
  id: Scalars['String'],
  /** 잡 인덱스 */
  jobIndex: Scalars['Int'],
  /** 건강보험공단 간편인증 로그인 구분 */
  loginTypeLevel: NhisLoginTypeLevel,
  /** 휴대폰 번호 */
  phoneNumber: Scalars['String'],
  /** 건강보험공단 통신사 구분 */
  telecom: NhisTelecom,
  /** 스레드 인덱스 */
  threadIndex: Scalars['Int'],
  /** 트렌젝션 아이디 */
  transactionId: Scalars['String'],
  /** 추가 인증 시간 */
  twoWayTimestamp: Scalars['String'],
  /** 사용자 이름 */
  userName: Scalars['String'],
};

export type FetchNhis2WayIdentifyPayload = {
   __typename?: 'FetchNhis2WayIdentifyPayload',
  /** 외부 경력사항 정보 리스트 */
  externalCareerExperienceInfos: Array<ExternalCareerExperienceInfo>,
  /** 불러온 날짜 */
  issueDate: Scalars['Date'],
  /** 총 경력월수 */
  totalCareerExperienceMonth: Scalars['Int'],
};

/** 건강보험공단 자격득실확인 조회시 보내야 하는 객체 */
export type FetchNhisIdentifyInput = {
  /** 생년월일 (YYMMDD 형태) */
  birthDate: Scalars['String'],
  /** 건강보험공단 간편인증 로그인 구분 */
  loginTypeLevel: NhisLoginTypeLevel,
  /** 휴대폰 번호 */
  phoneNumber: Scalars['String'],
  /** 건강보험공단 통신사 구분 */
  telecom: NhisTelecom,
  /** 사용자 이름 */
  userName: Scalars['String'],
};

export type FetchNhisIdentifyPayload = {
   __typename?: 'FetchNhisIdentifyPayload',
  /** 추가 인증 고유값 */
  id: Scalars['String'],
  /** 잡 인덱스 */
  jobIndex: Scalars['Int'],
  /** 스레드 인덱스 */
  threadIndex: Scalars['Int'],
  /** 트렌젝션 아이디 */
  transactionId: Scalars['String'],
  /** 추가 인증 시간 */
  twoWayTimestamp: Scalars['String'],
};

/** 기간과 범위, 카테고리를 지정하여 offset 기반 pagination으로 검색합니다. */
export type FindWithPeriodAndCategoryInput = {
  /** 페이지 번호 */
  page?: Maybe<Scalars['Int']>,
  /** 페이지 크기 */
  pageSize: Scalars['Int'],
  /** 검색 시작일 */
  from: Scalars['Date'],
  /** 검색 종료일 */
  to: Scalars['Date'],
  /** 지표의 기간 */
  datePeriod?: Maybe<DatePeriod>,
  /** 지표의 구분 */
  activityTypeId?: Maybe<ActivityTypeId>,
};

/** 기간과 범위를 지정하여 offset 기반 pagination으로 검색합니다. */
export type FindWithPeriodInput = {
  /** 페이지 번호 */
  page?: Maybe<Scalars['Int']>,
  /** 페이지 크기 */
  pageSize: Scalars['Int'],
  /** 검색 시작일 */
  from: Scalars['Date'],
  /** 검색 종료일 */
  to: Scalars['Date'],
  /** 지표의 기간 */
  datePeriod?: Maybe<DatePeriod>,
};

/** 범위를 지정하여 offset 기반 페이지네이션으로 검색합니다. */
export type FindWithRangeInput = {
  /** 페이지 번호 */
  page?: Maybe<Scalars['Int']>,
  /** 페이지 크기 */
  pageSize: Scalars['Int'],
  /** 검색 시작일 */
  from: Scalars['Date'],
  /** 검색 종료일 */
  to: Scalars['Date'],
};

/** 채팅방 상단 고정 여부 업데이트 입력 데이터 */
export type FixChatRoomOnTopInput = {
  /** 채팅방 번호 */
  chatRoomId: Scalars['ID'],
  /** 채팅방 상단 고정 여부 */
  fixOnTop: Scalars['Boolean'],
};

/** 채팅방 고정 결과 */
export type FixChatRoomPayload = {
   __typename?: 'FixChatRoomPayload',
  /** 고정된 채팅방 */
  chatRoom: ChatRoom,
};

export type Folder = {
   __typename?: 'Folder',
  createdAt?: Maybe<Scalars['Date']>,
  files: SelfIntroductionConnection,
  folders: FolderConnection,
  id: Scalars['ID'],
  name: Scalars['String'],
};


export type FolderFilesArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<SelfIntroductionOrder>
};


export type FolderFoldersArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<FolderOrder>
};

export type FolderConnection = {
   __typename?: 'FolderConnection',
  nodes: Array<Maybe<Folder>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type FolderOrder = {
  direction: OrderDirection,
  field: FolderOrderField,
};

export enum FolderOrderField {
  CREATED_AT = 'CREATED_AT'
}

export type FollowerConnection = {
   __typename?: 'FollowerConnection',
  edges: Array<FollowerEdge>,
  nodes: Array<Maybe<Actor>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type FollowerEdge = {
   __typename?: 'FollowerEdge',
  cursor: Scalars['String'],
  followedAt?: Maybe<Scalars['Date']>,
  node?: Maybe<Actor>,
};

export type FollowerFilter = {
  followedAt?: Maybe<DateRange>,
};

export type FollowingConnection = {
   __typename?: 'FollowingConnection',
  edges: Array<FollowingEdge>,
  nodes: Array<Maybe<Channel>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type FollowingEdge = {
   __typename?: 'FollowingEdge',
  cursor: Scalars['String'],
  followedAt?: Maybe<Scalars['Date']>,
  node?: Maybe<Channel>,
};

export type FollowingFilter = {
  followedAt?: Maybe<DateRange>,
};

export type FollowPayload = {
   __typename?: 'FollowPayload',
  channel?: Maybe<Channel>,
};

/**  채널 팔로우 알림  */
export type FollowToChannelNotification = SimpleNotification & {
   __typename?: 'FollowToChannelNotification',
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  deepLinkUrl?: Maybe<Scalars['String']>,
  follower?: Maybe<Actor>,
  id: Scalars['ID'],
  linkUrl: Scalars['String'],
  title?: Maybe<Scalars['String']>,
  unread: Scalars['Boolean'],
  updatedAt?: Maybe<Scalars['Date']>,
};

export enum Gender {
  FEMALE = 'FEMALE',
  MALE = 'MALE'
}

export type Highschool = {
   __typename?: 'Highschool',
  count: Scalars['Int'],
  district?: Maybe<RegionDistrict>,
  id: Scalars['ID'],
  isConfirmed: Scalars['Boolean'],
  name: Scalars['String'],
  region?: Maybe<Region>,
};

export enum HighschoolCategory {
  ART_AND_PHYSICS = 'ART_AND_PHYSICS',
  LIBERAL_ARTS = 'LIBERAL_ARTS',
  SCIENCE = 'SCIENCE',
  VOCATIONAL = 'VOCATIONAL'
}

export type HighschoolProfile = {
   __typename?: 'HighschoolProfile',
  category?: Maybe<HighschoolCategory>,
  graduatedAt: Scalars['Date'],
  highschool: Highschool,
  id: Scalars['ID'],
  startedAt?: Maybe<Scalars['Date']>,
  status: SchoolStatus,
};

export type IncrementShareCountPayload = {
   __typename?: 'IncrementShareCountPayload',
  newsItem?: Maybe<NewsItem>,
};

export type Industry = {
   __typename?: 'Industry',
  children: Array<Industry>,
  id: Scalars['ID'],
  name: Scalars['String'],
  parent?: Maybe<Industry>,
  seq: Scalars['Int'],
};

export type Interest = {
   __typename?: 'Interest',
  activityType?: Maybe<ActivityType>,
  id: Scalars['ID'],
  name: Scalars['String'],
  seq?: Maybe<Scalars['Int']>,
  users?: Maybe<Array<Maybe<User>>>,
};

export type InternFilterCollection = {
   __typename?: 'InternFilterCollection',
  /** 카테고리 필터 */
  categories: Array<Category>,
  /** 인턴 세분화 타입 */
  internTypes: Array<ActivityInternType>,
  /** 기업 타입 필터 */
  organizationTypes: Array<OrganizationType>,
  /** 지역 필터 */
  regions: Array<Region>,
};

export type IntroductionLetter = {
   __typename?: 'IntroductionLetter',
  content: Scalars['String'],
  id: Scalars['ID'],
  reference?: Maybe<IntroductionLetterReference>,
  type: IntroductionLetterType,
};

export type IntroductionLetterReference = {
   __typename?: 'IntroductionLetterReference',
  id: Scalars['ID'],
  introductionLetter?: Maybe<IntroductionLetter>,
  isFreeform: Scalars['Boolean'],
  resume: Resume,
};

export type IntroductionLetterType = {
   __typename?: 'IntroductionLetterType',
  id: Scalars['ID'],
  isFreeform: Scalars['Boolean'],
  type: Scalars['String'],
};

export type JobPosition = {
   __typename?: 'JobPosition',
  id: Scalars['ID'],
  name: Scalars['String'],
};

export type JobRank = {
   __typename?: 'JobRank',
  id: Scalars['ID'],
  name: Scalars['String'],
};

export enum JobType {
  CONTRACT = 'CONTRACT',
  EXPERIENCED = 'EXPERIENCED',
  INTERN = 'INTERN',
  NEW = 'NEW'
}

/** 채팅방 참여 결과 */
export type JoinChatRoomPayload = {
   __typename?: 'JoinChatRoomPayload',
  /** 참여한 채팅방 */
  chatRoom: ChatRoom,
  /** 참여한 유저 */
  user: User,
};

export type KakaoAccount = {
   __typename?: 'KakaoAccount',
  id: Scalars['ID'],
};

/** 채팅 KPI */
export type KpiChat = {
   __typename?: 'KpiChat',
  /** 채팅 KPI ID */
  id: Scalars['ID'],
  /** 지표 기준 날짜 */
  date: Scalars['String'],
  /** 채팅을 보낸 유저 수 */
  activeChatMessageSenderCount: Scalars['Int'],
  /** 채팅 메시지 수 */
  chatMessageCount: Scalars['Int'],
  /** 채팅이 있었던 채팅방 수 */
  activeChatRoomCount: Scalars['Int'],
};

export type KpiChatOffsetConnection = {
   __typename?: 'KpiChatOffsetConnection',
  nodes: Array<KpiChat>,
  totalCount: Scalars['Int'],
};

/** 채팅방 KPI */
export type KpiChatRoom = {
   __typename?: 'KpiChatRoom',
  /** 채팅방 KPI ID */
  id: Scalars['ID'],
  /** KPI 지표 기준 날짜 */
  date: Scalars['String'],
  /** 메시지 발송자 수 */
  senderCount: Scalars['Int'],
  /** 채팅방 멤버 수 */
  memberCount: Scalars['Int'],
  /** 채팅방에 들어온 멤버 수 */
  joinedMemberCount: Scalars['Int'],
  /** 채팅방에서 나간 멤버 수 */
  leftUserCount: Scalars['Int'],
  /** 공유 수 */
  shareCount: Scalars['Int'],
  /** 채팅 메시지 수 */
  newChatMessageCount: Scalars['Int'],
  /** 지표에 해당하는 채팅방 */
  chatRoom?: Maybe<ChatRoom>,
};

export type KpiChatRoomOffsetConnection = {
   __typename?: 'KpiChatRoomOffsetConnection',
  nodes: Array<KpiChatRoom>,
  totalCount: Scalars['Int'],
};

export type Language = {
   __typename?: 'Language',
  id: Scalars['ID'],
  language: Scalars['String'],
};

/** 채팅방 나가기 결과 */
export type LeaveChatRoomPayload = {
   __typename?: 'LeaveChatRoomPayload',
  /** 채팅방에서 나간 유저 */
  user: User,
};

/** 채팅방 메시지 좋아요 결과 */
export type LikeChatMessagePayload = {
   __typename?: 'LikeChatMessagePayload',
  /** 좋아요한 채팅방 메시지 */
  chatMessage: ChatMessage,
};

export type LikedConnection = {
   __typename?: 'LikedConnection',
  edges: Array<LikedEdge>,
  nodes: Array<Maybe<NewsItem>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type LikedEdge = {
   __typename?: 'LikedEdge',
  cursor: Scalars['String'],
  likedAt: Scalars['Date'],
  node?: Maybe<NewsItem>,
};

export type LikerConnection = {
   __typename?: 'LikerConnection',
  edges: Array<LikerEdge>,
  nodes: Array<Maybe<Actor>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type LikerEdge = {
   __typename?: 'LikerEdge',
  cursor: Scalars['String'],
  likedAt?: Maybe<Scalars['Date']>,
  node?: Maybe<Actor>,
};

/**  소식 좋아요 알림  */
export type LikeToNewsItemNotification = SimpleNotification & {
   __typename?: 'LikeToNewsItemNotification',
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  deepLinkUrl?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  /**  좋아요 개수 카운트  */
  likeCount?: Maybe<Scalars['Int']>,
  /**  마지막으로 좋아요한 유저  */
  liker?: Maybe<Actor>,
  linkUrl: Scalars['String'],
  newsItem?: Maybe<NewsItem>,
  title?: Maybe<Scalars['String']>,
  unread: Scalars['Boolean'],
  updatedAt?: Maybe<Scalars['Date']>,
};

export type LinkareerMagazine = {
   __typename?: 'LinkareerMagazine',
  content?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['Date']>,
  deletedAt?: Maybe<Scalars['Date']>,
  id: Scalars['ID'],
  pcImage?: Maybe<Scalars['String']>,
  pcImageFile?: Maybe<Attachment>,
  seq?: Maybe<Scalars['Int']>,
  title: Scalars['String'],
  updatedAt?: Maybe<Scalars['Date']>,
  url: Scalars['String'],
};

export type LinkareerMagazineListConnection = {
   __typename?: 'LinkareerMagazineListConnection',
  linkareerMagazines: Array<LinkareerMagazine>,
  totalCount: Scalars['Int'],
};

export type LinkareerMagazineUpdateInput = {
  content?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  pcImage?: Maybe<Scalars['String']>,
  pcImageId?: Maybe<Scalars['ID']>,
  seq?: Maybe<Scalars['Int']>,
  title?: Maybe<Scalars['String']>,
  url?: Maybe<Scalars['String']>,
};

export type LinkareerMagazineUpdatePayload = {
   __typename?: 'LinkareerMagazineUpdatePayload',
  linkareerMagazine?: Maybe<LinkareerMagazine>,
};

export type LinkareerTestUserDeletePayload = {
   __typename?: 'LinkareerTestUserDeletePayload',
  count?: Maybe<Scalars['Int']>,
};

export enum ListOrderByType {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum ListOrderType {
  DEAD_LINE = 'DEAD_LINE',
  RECENT = 'RECENT',
  REPLY_COUNT = 'REPLY_COUNT',
  SCRAP_COUNT = 'SCRAP_COUNT',
  VIEW_COUNT = 'VIEW_COUNT'
}

export type LoginPayload = {
   __typename?: 'LoginPayload',
  accessToken?: Maybe<Scalars['String']>,
  expiresAt?: Maybe<Scalars['Date']>,
  refreshToken?: Maybe<Scalars['String']>,
  /** 
 * signupToken이 있으면 회원가입 필요
   * sns 로그인의 경우 userSignupWithOAuth
   * email 로그인의 경우 userSignupWithEmail의 input에 signupToken 전달해야함
 **/
  signupToken?: Maybe<Scalars['String']>,
  user?: Maybe<User>,
};

export type Major = {
   __typename?: 'Major',
  id: Scalars['ID'],
  name: Scalars['String'],
};

export type MatchActivityToElasticPayload = {
   __typename?: 'MatchActivityToElasticPayload',
  activities?: Maybe<Array<Maybe<Activity>>>,
};

/**  멘토 정보 일치 댓글 작성 리워드 지급 알림  */
export type MatchedMentorCommentRewardNotification = SimpleNotification & {
   __typename?: 'MatchedMentorCommentRewardNotification',
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  deepLinkUrl?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  linkUrl: Scalars['String'],
  title?: Maybe<Scalars['String']>,
  unread: Scalars['Boolean'],
  updatedAt?: Maybe<Scalars['Date']>,
};

export type MentorApprovedNotification = SimpleNotification & {
   __typename?: 'MentorApprovedNotification',
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  deepLinkUrl?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  linkUrl: Scalars['String'],
  title?: Maybe<Scalars['String']>,
  unread: Scalars['Boolean'],
  updatedAt?: Maybe<Scalars['Date']>,
};

export enum MilitaryRank {
  BRIGADIER_GENERAL = 'BRIGADIER_GENERAL',
  CAPTAIN = 'CAPTAIN',
  CHIEF_WARRANT_OFFICER = 'CHIEF_WARRANT_OFFICER',
  COLONEL = 'COLONEL',
  CORPORAL = 'CORPORAL',
  ETC = 'ETC',
  FIRST_LIEUTENANT = 'FIRST_LIEUTENANT',
  GENERAL = 'GENERAL',
  LIEUTENANT_COLONEL = 'LIEUTENANT_COLONEL',
  LIEUTENANT_GENERAL = 'LIEUTENANT_GENERAL',
  MAJOR = 'MAJOR',
  MAJOR_GENERAL = 'MAJOR_GENERAL',
  MASTER_SERGEANT = 'MASTER_SERGEANT',
  PRIVATE_FIRST_CLASS = 'PRIVATE_FIRST_CLASS',
  PRIVATE_SECOND_CLASS = 'PRIVATE_SECOND_CLASS',
  SECOND_LIEUTENANT = 'SECOND_LIEUTENANT',
  SERGEANT = 'SERGEANT',
  SERGEANT_FIRST_CLASS = 'SERGEANT_FIRST_CLASS',
  SERGEANT_MAJOR = 'SERGEANT_MAJOR',
  STAFF_SERGEANT = 'STAFF_SERGEANT'
}

export enum MilitaryStatus {
  CALL_OFF = 'CALL_OFF',
  DISCHARGED_DISHONORABLY = 'DISCHARGED_DISHONORABLY',
  DISCHARGED_EXPIRATION = 'DISCHARGED_EXPIRATION',
  DISCHARGED_FOR_DAMAGE_REASON = 'DISCHARGED_FOR_DAMAGE_REASON',
  DISCHARGED_FOR_FAMILY_REASON = 'DISCHARGED_FOR_FAMILY_REASON',
  DISCHARGED_FOR_MEDICAL_REASON = 'DISCHARGED_FOR_MEDICAL_REASON',
  ETC = 'ETC'
}

export enum MilitaryTarget {
  COMPLETED = 'COMPLETED',
  EXEMPTED = 'EXEMPTED',
  NOT_YET = 'NOT_YET',
  SERVING = 'SERVING'
}

export enum MilitaryType {
  AIR_FORCE = 'AIR_FORCE',
  ARMY = 'ARMY',
  AUXILIARY_POLICE = 'AUXILIARY_POLICE',
  ETC = 'ETC',
  MARINE = 'MARINE',
  NAVY = 'NAVY',
  RIOT_POLICE = 'RIOT_POLICE',
  SOCIAL_SERVICE_PERSONNEL = 'SOCIAL_SERVICE_PERSONNEL'
}

export type Mutation = {
   __typename?: 'Mutation',
  /** 
 * 인증이 필요한 api입니다
   * 
   * 채팅 닉네임을 변경합니다.
   * 
   * - 에러 코드
   *   1. ALREADY_EXISTING_NICKNAME
   *     - 이미 사용 중인 닉네임일 경우
   *   2. CHAT_NICKNAME_MODIFY_COUNT_EXCEED
   *     - 닉네임 변경 횟수가 초과했을 경우
 **/
  changeChatNickname: ChangeChatNicknamePayload,
  /** 
 * 인증이 필요한 api입니다
   * 
   * 채팅에서 사용자를 차단합니다.
   * 
   * - 에러 코드
   *   1. CANNOT_BLOCK_YOURSELF
   *     - 자기 자신을 차단할 경우
   *   2. ALREADY_BLOCKED_USER
   *     - 이미 차단한 사용자일 경우
   *   3. NOT_FOUND_USER
   *     - 존재하지 않는 사용자일 경우
 **/
  blockUserInChat: BlockUserInChatPayload,
  /** 
 * 인증이 필요한 api입니다
   * 
   * 알람 설정을 변경합니다.
 **/
  updateChatNotificationConfig: UserSubscription,
  /** 
 * 인증이 필요한 api입니다
   * 
   * 채팅 프로필 이미지를 변경합니다.
 **/
  changeChatProfileImage: ChangeChatProfileImagePayload,
  /** 
 * 인증이 필요한 api입니다
   * 
   * 채팅방의 알람 설정을 변경합니다.
 **/
  updateChatRoomNotificationConfig: UpdateChatRoomNotificationPayload,
  /** 
 * 관리자만 접근 가능합니다.
   * 
   * 채팅방을 생성합니다.
   * 
   * - 에러 코드
   *   1. ALREADY_EXISTING_CHAT_ROOM_NAME
   *     - 중복된 채팅방 이름일 경우 
 **/
  createChatRoom: CreateChatRoomPayload,
  /** 
 * 인증이 필요한 api입니다
   * 
   * 채팅방에 참가합니다.
   * 
   * - 에러 코드
   *   1. NOT_FOUND_CHAT_ROOM
   *     - 채팅방이 존재하지 않을 경우
 **/
  joinChatRoom: JoinChatRoomPayload,
  /** 
 * 인증이 필요한 api입니다
   * 
   * 채팅방에서 나갑니다.
   * 
   * - 에러 코드
   *   1. NOT_CHAT_ROOM_MEMBER
   *     - 채팅방에 속해있지 않을 경우
 **/
  leaveChatRoom: LeaveChatRoomPayload,
  /** 
 * 채팅방을 공유합니다.
   * 
   * - 에러 코드
   *   1. NOT_FOUND_CHAT_ROOM
   *     - 채팅방이 존재하지 않을 경우
 **/
  shareChatRoom: ShareChatRoomPayload,
  /** 
 * 관리자만 접근 가능합니다.
   * 
   * 채팅방을 삭제합니다.
 **/
  deleteChatRoom: DeleteChatRoomPayload,
  /** 
 * 관리자만 접근 가능합니다.
   * 
   * 채팅방을 상단에 고정 여부를 업데이트 합니다.
 **/
  fixChatRoomOnTop: FixChatRoomPayload,
  /** 
 * 관리자만 접근 가능합니다.
   * 
   * 채팅방의 상단 고정 순서를 업데이트 합니다.
 **/
  reorderFixedChatRoom: ReorderChatRoomsOnTopPayload,
  /** 
 * 인증이 필요한 api입니다
   * 
   * 채팅 메시지에 좋아요를 누릅니다.
   * 좋아요가 이미 눌린 상태라면 취소됩니다.
   * 
   * - 에러 코드
   *   1. NOT_FOUND_MESSAGE
   *     - 메시지를 찾을 수 없는 경우
   *   2. NOT_FOUND_MESSAGE
   *     - 메시지를 읽을 수 없는 경우(채팅방에 속하지 않음/차단한 유저/삭제된 메시지 등)
 **/
  likeChatMessage: LikeChatMessagePayload,
  /** 
 * 인증이 필요한 api입니다
   * 
   * 채팅 메시지를 신고합니다.
   * 
   * - 에러 코드
   *   1. NOT_FOUND_MESSAGE
   *     - 메시지를 찾을 수 없는 경우
   *   2. NOT_FOUND_MESSAGE
   *     - 읽을 수 없는 메시지일 경우(채팅방에 속하지 않음/차단한 유저/삭제된 메시지 등)
   *   3. CANNOT_REPORT_OWN_CHAT_MESSAGE
   *     - 자신이 보낸 메시지일 경우
   *   4. ALREADY_REPORTED_CHAT_MESSAGE
   *     - 이미 신고한 메시지일 경우
 **/
  reportChatMessage: ReportChatMessagePayload,
  /** 
 * 관리자만 접근 가능합니다.
   * 
   * 메시지를 삭제합니다.
 **/
  deleteChatMessage: DeleteChatMessagePayload,
  /** 
 * 관리자만 접근 가능합니다.
   * 
   * 유저를 채팅 서비스로부터 활동 정지 처리합니다.
   * 
   * - 에러 코드
   *   1. NOT_FOUND_USER
   *     - 유저를 찾을 수 없는 경우
   *   2. ALREADY_BANNED_USER
   *     - 이미 차단된 회원일 경우
 **/
  banUserInChat: BanUserInChatPayload,
  /** 
 * 관리자만 접근 가능합니다.
   * 
   * 신고당한 메시지를 읽음 처리 합니다.
   * 
   * - 에러 코드
   *   1. NOT_FOUND_MESSAGE
   *     - 해당 메시지를 찾을 수 없는 경우
 **/
  readReportedChatMessage: ReadReportedChatMessagePayload,
  /** 
 * 관리자만 접근 가능합니다.
   * 
   * user ban id들을 받아서, 해당하는 활동 정지 조치를 해제해줍니다.
 **/
  unbanUsersInChat: UnbanUsersInChatPayload,
  activateChannel: ActivateChannelPayload,
  activitiesDelete?: Maybe<ActivitiesDeletePayload>,
  activitiesStatusUpdate?: Maybe<ActivitiesStatusUpdatePayload>,
  activityDelete?: Maybe<ActivityDeletePayload>,
  activityFileCreate?: Maybe<ActivityFile>,
  activityFileDelete?: Maybe<Scalars['Boolean']>,
  activityReferenceUpdate?: Maybe<ActivityReferenceUpdatePayload>,
  activityScrapUpdate?: Maybe<ActivityScrapUpdatePayload>,
  activityUpdate?: Maybe<ActivityUpdatePayload>,
  activityViewCountUpdate?: Maybe<ActivityViewCountUpdatePayload>,
  adPlacementCreate?: Maybe<AdPlacementCreatePayload>,
  adPlacementUpdate?: Maybe<AdPlacementUpdatePayload>,
  addBookmark: AddBookmarkPayload,
  addCommunityBookmark?: Maybe<AddCommunityBookmarkPayLoad>,
  addCommunityMenuBookmark?: Maybe<AddCommunityMenuBookmarkPayLoad>,
  addLike: AddLikePayload,
  addTypeByOrgNameRegex?: Maybe<AddTypeByOrgNameRegexPayload>,
  advertisementBulkDelete?: Maybe<AdvertisementBulkDeletePayload>,
  advertisementCreate?: Maybe<AdvertisementCreatePayload>,
  advertisementDelete?: Maybe<AdvertisementDeletePayload>,
  advertisementUpdate?: Maybe<AdvertisementUpdatePayload>,
  attachmentCompleteUpload?: Maybe<AttachmentCompleteUploadPayload>,
  attachmentDelete?: Maybe<AttachmentDeletePayload>,
  attachmentPrepareUpload?: Maybe<AttachmentPrepareUploadPayload>,
  authenticateEmail?: Maybe<AuthenticateEmailPayload>,
  authenticateEmailCallback?: Maybe<AuthenticateEmailCallbackPayload>,
  bizCheckOrganizationAliasIsDuplicated: Scalars['Boolean'],
  bizCreateActivity?: Maybe<BizCreateActivityPayload>,
  bizDeleteActivity?: Maybe<ActivityDeletePayload>,
  bizFindLogin: Array<BizFindLoginPayload>,
  bizHideActivity?: Maybe<Activity>,
  bizLogin?: Maybe<LoginPayload>,
  bizResetPassword: BizResetPasswordPayload,
  bizShowActivity?: Maybe<Activity>,
  bizSignup?: Maybe<UserSignupPayload>,
  bizUpdateActivity?: Maybe<BizUpdateActivityPayload>,
  bizUpdateRecruit?: Maybe<BizUpdateRecruitPayload>,
  bizUpdateRecruitCloseAt?: Maybe<BizUpdateRecruitCloseAtPayload>,
  bizUpdateUser: BizUpdateUserPayload,
  bizVerifyLogin: BizVerifyLoginPayload,
  campaignCreate?: Maybe<CampaignCreatePayload>,
  campaignDelete?: Maybe<CampaignDeletePayload>,
  campaignUpdate?: Maybe<CampaignUpdatePayload>,
  completeChannel?: Maybe<Channel>,
  completeQuestionSuggestion: CompleteQuestionSuggestionPayload,
  completeViewerChannel?: Maybe<Channel>,
  coverLetterBulkCreate?: Maybe<Array<Maybe<CoverLetter>>>,
  coverLetterCreate?: Maybe<CoverLetterCreatePayload>,
  coverLetterDeleteMany?: Maybe<CoverLetterDeleteManyPayload>,
  coverLetterManyAddType?: Maybe<CoverLetterManyAddTypePayload>,
  coverLetterParagraphScrapCreate?: Maybe<CoverLetterParagraphScrapCreatePayload>,
  coverLetterParagraphScrapDelete?: Maybe<CoverLetterParagraphScrapDeletePayload>,
  coverLetterScrapUpdate?: Maybe<CoverLetterScrapUpdatePayload>,
  coverLetterUpdate?: Maybe<CoverLetterUpdatePayload>,
  /** 관리자만 이용 가능한 서비스 입니다. */
  createAdPlacement: CreateAdPlacementPayload,
  /** 기업 추가 요청 cs */
  createAddOrganizationSuggestion: CreateAddOrganizationSuggestionPayload,
  /** 관리자만 이용 가능한 서비스 입니다. */
  createCampaign: CreateCampaignPayload,
  createChannel: CreateChannelPayload,
  createChannelTagCollection?: Maybe<CreateTagCollectionPayload>,
  createFolder: CreateFolderPayload,
  createLinkareerMagazine?: Maybe<CreateLinkareerMagazinePayload>,
  createNewsComment: CreateNewsCommentPayload,
  createNewsItem: CreateNewsItemPayload,
  createNewsItemTagCollection?: Maybe<CreateTagCollectionPayload>,
  createQnA: CreateQnAPayload,
  createQuestionSuggestion: CreateQuestionSuggestionPayload,
  createRecommendSearchKeyword?: Maybe<CreateRecommendSearchKeywordPayload>,
  createSelfIntroduction: CreateSelfIntroductionPayload,
  createSuggestion: CreateSuggestionPayload,
  createTestUser?: Maybe<CreateTestUserPayload>,
  createUniqueNameOrganizations: CreateUniqueNameOrganizationsPayload,
  curationCreate?: Maybe<CurationCreatePayload>,
  curationDelete?: Maybe<CurationDeletePayload>,
  curationUpdate?: Maybe<CurationUpdatePayload>,
  currentUserScrapDeleteCommunity?: Maybe<CurrentUserScrapDeleteCommunityPayload>,
  currentUserScrapsDeleteAllCommunity: CurrentUserScrapsDeleteAllCommunityPayload,
  deactivateChannel: DeactivateChannelPayload,
  /** 관리자 프리셋 삭제 */
  deleteActivityManagerPreset: DeleteActivityManagerPresetPayload,
  /** 조직 프리셋 삭제 */
  deleteActivityOrganizationPreset: DeleteActivityOrganizationPresetPayload,
  /**  스코프에 포함되는 알림 전체 삭제  */
  deleteAllNotification: DeleteAllNotificationPayload,
  /** 관리자만 이용 가능한 서비스 입니다. */
  deleteCampaigns: DeleteCampaignsPayload,
  deleteChannel: DeleteChannelPayload,
  deleteCurrentUser: DeleteCurrentUserPayload,
  deleteFolder: DeleteFolderPayload,
  deleteManyUserAccounts: DeleteManyUserAccountsPayload,
  deleteNewsComment: NewsComment,
  deleteNewsComments: DeleteNewsCommentsPayload,
  deleteNewsItem: NewsItem,
  deleteNewsItems: DeleteNewsItemsPayload,
  /**  알림 개별 삭제  */
  deleteNotification: DeleteNotificationPayload,
  deleteQnA: DeleteQnAPayload,
  deleteQuestionSuggestion: DeleteQuestionSuggestionPayload,
  deleteRecommendSearchKeyword?: Maybe<DeleteRecommendSearchKeywordPayload>,
  deleteSelfIntroduction: DeleteSelfIntroductionPayload,
  deleteSnapshot: DeleteQnASnapshotPayload,
  deleteTagCollection?: Maybe<DeleteTagCollectionPayload>,
  deleteUniqueNameOrganizations: DeleteUniqueNameOrganizationsPayload,
  deleteUserAccount: DeleteUserAccountPayload,
  facebookDeleteUser: FacebookDeleteUserPayload,
  facebookFakeLogin: FacebookFakeLoginPayload,
  facebookFakeSignup: FacebookFakeSignupPayload,
  facebookUpdateUser: Scalars['Boolean'],
  /** 
 * 에러 코드
   * 
   * BEFORE_IDENTIFY: 본인인증 전에 2way 인증을 요청한 경우
   * REQUEST_TIMEOUT: 인증시간이 만료된 경우
   * API_REQUEST_ERROR: API 요청 중 에러가 발생한 경우
 **/
  fetchNhis2WayIdentify: FetchNhis2WayIdentifyPayload,
  /** 
 * 에러 코드
   * 
   * NOT_MATCHED_CERTIFICATION_INFO: 유저가 입력한 정보가 일치하지 않는 경우
   * REQUEST_TIMEOUT: 요청시간이 초과된 경우
   * API_REQUEST_ERROR: API 요청 중 에러가 발생한 경우
 **/
  fetchNhisIdentify: FetchNhisIdentifyPayload,
  follow: FollowPayload,
  generateUserStatistic?: Maybe<Scalars['Boolean']>,
  incrementShareCount: IncrementShareCountPayload,
  invalidateActivityCache?: Maybe<Activity>,
  issueSignedURL?: Maybe<Scalars['String']>,
  linkareerMagazineUpdate?: Maybe<LinkareerMagazineUpdatePayload>,
  linkareerTestUserDelete: LinkareerTestUserDeletePayload,
  login?: Maybe<LoginPayload>,
  loginByAdmin?: Maybe<LoginPayload>,
  loginWithApple?: Maybe<LoginPayload>,
  loginWithFaceBook?: Maybe<LoginPayload>,
  loginWithKakao?: Maybe<LoginPayload>,
  loginWithNaver?: Maybe<LoginPayload>,
  loginWithTimespread?: Maybe<LoginPayload>,
  logout?: Maybe<Scalars['Boolean']>,
  matchActivityToElastic?: Maybe<MatchActivityToElasticPayload>,
  muteUser?: Maybe<MuteUserPayload>,
  notificationDelete?: Maybe<NotificationDeletePayload>,
  notificationDeleteAll?: Maybe<NotificationDeleteAllPayload>,
  notificationUpdate?: Maybe<NotificationUpdatePayload>,
  organizationCreate?: Maybe<OrganizationCreatePayload>,
  organizationDelete: OrganizationDeletePayload,
  organizationUpdate: OrganizationUpdatePayload,
  organizationsDelete: OrganizationsDeletePayload,
  readNewsItem: UpdateNewsItemViewCountPayload,
  /**  알림 읽음 처리  */
  readNotification: ReadNotificationPayload,
  recommendSearchKeywordCreate?: Maybe<RecommendSearchKeywordCreatePayload>,
  recommendSearchKeywordDelete?: Maybe<RecommendSearchKeywordDeletePayload>,
  recommendSearchKeywordUpdate?: Maybe<RecommendSearchKeywordUpdatePayload>,
  recoverNewsComments: RecoverNewsCommentsPayload,
  recoverNewsItems: RecoverNewsItemsPayload,
  refreshAccessToken?: Maybe<RefreshAccessTokenPayload>,
  removeAllBookmark: RemoveAllBookmarkPayload,
  removeBookmark: RemoveBookmarkPayload,
  removeBookmarks: RemoveBookmarksPayload,
  removeCommunityBookmark?: Maybe<RemoveCommunityBookmarkPayLoad>,
  removeCommunityMenuBookmark?: Maybe<RemoveCommunityMenuBookmarkPayLoad>,
  removeLike: RemoveLikePayload,
  replyCreate?: Maybe<ReplyCreatePayload>,
  replyDelete?: Maybe<ReplyDeletePayload>,
  replyDeleteByAdministrator?: Maybe<ReplyDeletePayload>,
  replyLike?: Maybe<ReplyLikePayload>,
  reportNewsComment: ReportPayload,
  reportNewsItem: ReportPayload,
  rollbackSnapshot: RollbackQnASnapshotPayload,
  /** 만약 로그인하지 않았다면 NULL을 반환함 */
  saveFcmToken?: Maybe<SaveFcmTokenPayload>,
  sendJobPostingDataToNaver?: Maybe<SendJobPostingDataToNaverPayload>,
  sendMailSelfIntroduction: SendMailSelfIntroductionPayload,
  setNewsItemAsNotice: SetNewsItemAsNoticePayload,
  setNoticeAsNewsItem: SetNoticeAsNewsItemPayload,
  skipSignupSession: SkipSignupSessionPayload,
  spellCheck: SpellCheckPayload,
  spellCheckWithAutoSplit: SpellCheckWithAutoSplitPayload,
  syncCoverLetterWithElastic: Scalars['Int'],
  unFollow: UnFollowPayload,
  unmuteUser?: Maybe<UnmuteUserPayload>,
  /** 관리자만 이용 가능한 서비스 입니다. */
  updateAdPlacement: UpdateAdPlacementPayload,
  /** 관리자만 이용 가능한 서비스 입니다. */
  updateCampaign: UpdateCampaignPayload,
  /** 로그인 후 이용 가능한 서비스 입니다. */
  updateCareerAdvantageCondition: UpdateCareerAdvantageConditionPayload,
  /** 로그인 후 이용 가능한 서비스 입니다. */
  updateCareerAward: UpdateCareerAwardPayload,
  /** 로그인 후 이용 가능한 서비스 입니다. */
  updateCareerExperience: UpdateCareerExperiencePayload,
  /** 로그인 후 이용 가능한 서비스 입니다. */
  updateCareerExternship: UpdateCareerExternshipPayload,
  /** 로그인 후 이용 가능한 서비스 입니다. */
  updateCareerIntroduction: UpdateCareerIntroductionPayload,
  /** 로그인 후 이용 가능한 서비스 입니다. */
  updateCareerJobPreference: UpdateCareerJobPreferencePayload,
  /** 로그인 후 이용 가능한 서비스 입니다. */
  updateCareerLanguage: UpdateCareerLanguagePayload,
  updateCareerLicense: UpdateCareerLicensePayload,
  /** 로그인 후 이용 가능한 서비스 입니다. */
  updateCareerPortfolio: UpdateCareerPortfolioPayload,
  /** 로그인 후 이용 가능한 서비스 입니다. */
  updateCareerProfile: UpdateCareerProfilePayload,
  updateCareerProfileOrder: UpdateCareerProfileOrderPayload,
  /** 로그인 후 이용 가능한 서비스 입니다. */
  updateCareerSchool: UpdateCareerSchoolPayload,
  /** 로그인 후 이용 가능한 서비스 입니다. */
  updateCareerSkill: UpdateCareerSkillPayload,
  /** 로그인 후 이용 가능한 서비스 입니다. */
  updateCareerUserInformation: UpdateCareerUserInformationPayload,
  updateChannelAndOrganization: UpdateChannelAndOrganizationPayload,
  updateChannelTagCollections?: Maybe<UpdateTagCollectionsPayload>,
  updateCommunityMentorPost: Scalars['Boolean'],
  updateCoverLetterFieldByQuery?: Maybe<UpdateCoverLetterFieldByQueryPayload>,
  updateCurrentUser: UpdateCurrentUserPayload,
  updateDetailedActivity: UpdateDetailedActivityPayload,
  updateDetailedRecruit: UpdateDetailedRecruitPayload,
  updateFolder: UpdateFolderPayload,
  updateManyCommunityBoardUrls: UpdateManyCommunityBoardUrlPayload,
  updateManyCommunityPostRelatedWithActivity: UpdateManyCommunityPostRelatedWithActivityPayload,
  updateManyCoverLetterRelatedWithActivity: UpdateManyCoverLetterRelatedWithActivityPayload,
  updateNewsComment: UpdateNewsCommentPayload,
  updateNewsItem: UpdateNewsItemPayload,
  updateNewsItemTagCollections?: Maybe<UpdateTagCollectionsPayload>,
  updateQnA: UpdateQnAPayload,
  updateRecommendSearchKeyword?: Maybe<UpdateRecommendSearchKeywordPayload>,
  updateRecommendSearchKeywords?: Maybe<UpdateRecommendSearchKeywordsPayload>,
  updateRecruit?: Maybe<UpdateRecruitPayload>,
  updateSelfIntroduction: UpdateSelfIntroductionPayload,
  updateSubscription?: Maybe<UpdateSubscriptionPayload>,
  updateTagCollection?: Maybe<UpdateTagCollectionPayload>,
  updateTestUser?: Maybe<UpdateTestUserPayload>,
  updateUserPhoneNumberByAuthUserId?: Maybe<UpdateUserPhoneNumberByAuthUserIdPayload>,
  upsertManyLinkareerMagazine: UpsertManyLinkareerMagazinePayload,
  userAuthorizePhoneNumber?: Maybe<UserAuthorizePhoneNumberPayload>,
  userCheckPhoneNumberAuthorization?: Maybe<UserCheckPhoneNumberAuthorizationPayload>,
  userDeactivate: UserDeactivatePayload,
  userDelete: UserDeletePayload,
  userDeleteMany: UserDeleteManyPayload,
  userIsDuplicateLogin?: Maybe<Scalars['Boolean']>,
  userIsDuplicateNickname?: Maybe<Scalars['Boolean']>,
  userIsDuplicateNicknameWithoutToken?: Maybe<Scalars['Boolean']>,
  userProfileImageDelete?: Maybe<ApplyFile>,
  userScrapDeleteAll?: Maybe<UserScrapDeleteAllPayload>,
  userSignupWithEmail: UserSignupPayload,
  userSignupWithOAuth: UserSignupPayload,
  userUnlinkTimespread: UserUnlinkTimespreadPayload,
  userUpdate: UserUpdatePayload,
  userUpdateBirth?: Maybe<Scalars['Boolean']>,
  userUpdatePhoneNumberAuthorization?: Maybe<TimeSpreadApiResponseType>,
  verifyChannelName: Scalars['Boolean'],
  verifyChannelUrl: Scalars['Boolean'],
  viewerCheckedParagraphScrapGuide: ViewerCheckedParagraphScrapGuidePayload,
  visitChannel: UpdateChannelViewCountPayload,
};


export type MutationChangeChatNicknameArgs = {
  input: ChangeChatNicknameInput
};


export type MutationBlockUserInChatArgs = {
  userId: Scalars['ID']
};


export type MutationUpdateChatNotificationConfigArgs = {
  input: UpdateChatSubscribeInput
};


export type MutationChangeChatProfileImageArgs = {
  input: ChangeChatProfileImageInput
};


export type MutationUpdateChatRoomNotificationConfigArgs = {
  input: UpdateChatRoomNotificationInput
};


export type MutationCreateChatRoomArgs = {
  input: CreateChatRoomInput
};


export type MutationJoinChatRoomArgs = {
  chatRoomId: Scalars['ID']
};


export type MutationLeaveChatRoomArgs = {
  chatRoomId: Scalars['ID']
};


export type MutationShareChatRoomArgs = {
  chatRoomId: Scalars['ID'],
  input: ShareChatRoomInput
};


export type MutationDeleteChatRoomArgs = {
  chatRoomId: Scalars['ID']
};


export type MutationFixChatRoomOnTopArgs = {
  fixChatRoomInput: FixChatRoomOnTopInput
};


export type MutationReorderFixedChatRoomArgs = {
  fixChatRoomInput: ReorderChatRoomsOnTopInput
};


export type MutationLikeChatMessageArgs = {
  chatMessageId: Scalars['ID']
};


export type MutationReportChatMessageArgs = {
  chatMessageId: Scalars['ID'],
  input: ReportChatMessageInput
};


export type MutationDeleteChatMessageArgs = {
  chatMessageId: Scalars['ID']
};


export type MutationBanUserInChatArgs = {
  userId: Scalars['ID'],
  input: BanUserInChatInput
};


export type MutationReadReportedChatMessageArgs = {
  chatMessageId: Scalars['ID']
};


export type MutationUnbanUsersInChatArgs = {
  banIds: Array<Scalars['ID']>
};


export type MutationActivateChannelArgs = {
  channelId: Scalars['ID']
};


export type MutationActivitiesDeleteArgs = {
  ids: Array<Scalars['ID']>
};


export type MutationActivitiesStatusUpdateArgs = {
  input: ActivitiesStatusUpdateInput
};


export type MutationActivityDeleteArgs = {
  id: Scalars['ID']
};


export type MutationActivityFileCreateArgs = {
  input: ActivityFileCreateInput
};


export type MutationActivityFileDeleteArgs = {
  id: Scalars['ID']
};


export type MutationActivityReferenceUpdateArgs = {
  input: ActivityReferenceUpdateInput
};


export type MutationActivityScrapUpdateArgs = {
  input: ActivityScrapUpdateInput
};


export type MutationActivityUpdateArgs = {
  input: ActivityUpdateInput
};


export type MutationActivityViewCountUpdateArgs = {
  id: Scalars['ID']
};


export type MutationAdPlacementCreateArgs = {
  input: AdPlacementCreateInput
};


export type MutationAdPlacementUpdateArgs = {
  id: Scalars['ID'],
  input: AdPlacementUpdateInput
};


export type MutationAddBookmarkArgs = {
  newsItemId: Scalars['ID']
};


export type MutationAddCommunityBookmarkArgs = {
  id: Scalars['ID']
};


export type MutationAddCommunityMenuBookmarkArgs = {
  id: Scalars['ID']
};


export type MutationAddLikeArgs = {
  newsItemId: Scalars['ID']
};


export type MutationAddTypeByOrgNameRegexArgs = {
  input: AddTypeByOrgNameRegexInput
};


export type MutationAdvertisementBulkDeleteArgs = {
  ids: Array<Scalars['ID']>
};


export type MutationAdvertisementCreateArgs = {
  input: AdvertisementCreateInput
};


export type MutationAdvertisementDeleteArgs = {
  id: Scalars['ID']
};


export type MutationAdvertisementUpdateArgs = {
  input: AdvertisementUpdateInput
};


export type MutationAttachmentCompleteUploadArgs = {
  attachmentID: Scalars['ID']
};


export type MutationAttachmentDeleteArgs = {
  attachmentID: Scalars['ID']
};


export type MutationAttachmentPrepareUploadArgs = {
  name: Scalars['String'],
  path?: Maybe<Scalars['String']>,
  size: Scalars['Int'],
  type: Scalars['String'],
  useFileName?: Maybe<Scalars['Boolean']>
};


export type MutationAuthenticateEmailArgs = {
  input: AuthenticateEmailInput
};


export type MutationAuthenticateEmailCallbackArgs = {
  input: AuthenticateEmailCallbackInput
};


export type MutationBizCheckOrganizationAliasIsDuplicatedArgs = {
  alias: Scalars['String']
};


export type MutationBizCreateActivityArgs = {
  input: BizCreateActivityInput
};


export type MutationBizDeleteActivityArgs = {
  id: Scalars['ID']
};


export type MutationBizFindLoginArgs = {
  input: BizFindLoginInput
};


export type MutationBizHideActivityArgs = {
  id: Scalars['ID']
};


export type MutationBizLoginArgs = {
  email: Scalars['String'],
  password: Scalars['String']
};


export type MutationBizResetPasswordArgs = {
  input: BizResetPasswordInput
};


export type MutationBizShowActivityArgs = {
  id: Scalars['ID']
};


export type MutationBizSignupArgs = {
  input: BizSignupInput
};


export type MutationBizUpdateActivityArgs = {
  input: BizUpdateActivityInput
};


export type MutationBizUpdateRecruitArgs = {
  input: BizUpdateRecruitInput
};


export type MutationBizUpdateRecruitCloseAtArgs = {
  input: BizUpdateRecruitCloseAtInput
};


export type MutationBizUpdateUserArgs = {
  input: BizUpdateUserInput
};


export type MutationBizVerifyLoginArgs = {
  input: BizVerifyLoginInput
};


export type MutationCampaignCreateArgs = {
  input: CampaignCreateInput
};


export type MutationCampaignDeleteArgs = {
  id: Scalars['ID']
};


export type MutationCampaignUpdateArgs = {
  id: Scalars['ID'],
  input: CampaignUpdateInput
};


export type MutationCompleteQuestionSuggestionArgs = {
  input: CompleteQuestionSuggestionInput
};


export type MutationCoverLetterBulkCreateArgs = {
  input: Array<CoverLetterBulkCreateInput>
};


export type MutationCoverLetterCreateArgs = {
  input: CoverLetterCreateInput
};


export type MutationCoverLetterDeleteManyArgs = {
  ids: Array<Scalars['ID']>
};


export type MutationCoverLetterManyAddTypeArgs = {
  input: CoverLetterManyAddTypeInput
};


export type MutationCoverLetterParagraphScrapCreateArgs = {
  input: CoverLetterParagraphScrapCreateInput
};


export type MutationCoverLetterParagraphScrapDeleteArgs = {
  id: Scalars['ID']
};


export type MutationCoverLetterScrapUpdateArgs = {
  input: CoverLetterScrapUpdateInput
};


export type MutationCoverLetterUpdateArgs = {
  input: CoverLetterUpdateInput
};


export type MutationCreateAdPlacementArgs = {
  input: CreateAdPlacementInput
};


export type MutationCreateAddOrganizationSuggestionArgs = {
  input: CreateAddOrganizationSuggestionInput
};


export type MutationCreateCampaignArgs = {
  input: CreateCampaignInput
};


export type MutationCreateChannelArgs = {
  input: CreateChannelInput
};


export type MutationCreateChannelTagCollectionArgs = {
  input: CreateTagCollectionInput
};


export type MutationCreateFolderArgs = {
  input: CreateFolderInput
};


export type MutationCreateLinkareerMagazineArgs = {
  input: CreateLinkareerMagazineInput
};


export type MutationCreateNewsCommentArgs = {
  input: CreateNewsCommentInput
};


export type MutationCreateNewsItemArgs = {
  input: CreateNewsItemInput
};


export type MutationCreateNewsItemTagCollectionArgs = {
  input: CreateTagCollectionInput
};


export type MutationCreateQnAArgs = {
  input: CreateQnAInput,
  selfIntroductionId: Scalars['ID']
};


export type MutationCreateQuestionSuggestionArgs = {
  input: CreateQuestionSuggestionInput
};


export type MutationCreateRecommendSearchKeywordArgs = {
  input?: Maybe<CreateRecommendSearchKeywordInput>
};


export type MutationCreateSelfIntroductionArgs = {
  input: CreateSelfIntroductionInput
};


export type MutationCreateSuggestionArgs = {
  input: CreateSuggestionInput
};


export type MutationCreateTestUserArgs = {
  input?: Maybe<CreateTestUserInput>
};


export type MutationCreateUniqueNameOrganizationsArgs = {
  input: CreateUniqueNameOrganizationsInput
};


export type MutationCurationCreateArgs = {
  input: CurationCreateInput
};


export type MutationCurationDeleteArgs = {
  id: Scalars['ID']
};


export type MutationCurationUpdateArgs = {
  id: Scalars['ID'],
  input: CurationUpdateInput
};


export type MutationCurrentUserScrapDeleteCommunityArgs = {
  postID: Scalars['ID']
};


export type MutationDeactivateChannelArgs = {
  channelId: Scalars['ID']
};


export type MutationDeleteActivityManagerPresetArgs = {
  presetId: Scalars['Float']
};


export type MutationDeleteActivityOrganizationPresetArgs = {
  presetId: Scalars['Float']
};


export type MutationDeleteAllNotificationArgs = {
  scope: NotificationScope
};


export type MutationDeleteCampaignsArgs = {
  ids: Array<Scalars['ID']>
};


export type MutationDeleteChannelArgs = {
  id: Scalars['ID']
};


export type MutationDeleteCurrentUserArgs = {
  input: DeleteCurrentUserInput
};


export type MutationDeleteFolderArgs = {
  input: DeleteFolderInput
};


export type MutationDeleteManyUserAccountsArgs = {
  ids: Array<Scalars['ID']>
};


export type MutationDeleteNewsCommentArgs = {
  newsCommentId: Scalars['ID']
};


export type MutationDeleteNewsCommentsArgs = {
  newsCommentIds: Array<Scalars['ID']>
};


export type MutationDeleteNewsItemArgs = {
  id: Scalars['ID']
};


export type MutationDeleteNewsItemsArgs = {
  ids: Array<Scalars['ID']>
};


export type MutationDeleteNotificationArgs = {
  id: Scalars['ID']
};


export type MutationDeleteQnAArgs = {
  input: DeleteQnAInput
};


export type MutationDeleteQuestionSuggestionArgs = {
  input: DeleteQuestionSuggestionInput
};


export type MutationDeleteRecommendSearchKeywordArgs = {
  id: Scalars['ID']
};


export type MutationDeleteSelfIntroductionArgs = {
  input: DeleteSelfIntroductionInput
};


export type MutationDeleteSnapshotArgs = {
  snapshotId: Scalars['ID']
};


export type MutationDeleteTagCollectionArgs = {
  id: Scalars['ID']
};


export type MutationDeleteUniqueNameOrganizationsArgs = {
  ids: Array<Scalars['ID']>
};


export type MutationDeleteUserAccountArgs = {
  id: Scalars['ID']
};


export type MutationFacebookDeleteUserArgs = {
  input: FacebookDeleteUserInput
};


export type MutationFacebookFakeLoginArgs = {
  input?: Maybe<FacebookFakeLoginInput>
};


export type MutationFacebookUpdateUserArgs = {
  input: FacebookUpdateUserInput
};


export type MutationFetchNhis2WayIdentifyArgs = {
  input: FetchNhis2WayIdentifyInput
};


export type MutationFetchNhisIdentifyArgs = {
  input: FetchNhisIdentifyInput
};


export type MutationFollowArgs = {
  channelId: Scalars['ID']
};


export type MutationGenerateUserStatisticArgs = {
  date: Scalars['Date']
};


export type MutationIncrementShareCountArgs = {
  newsItemId: Scalars['ID'],
  platform?: Maybe<SharePlatFormType>
};


export type MutationInvalidateActivityCacheArgs = {
  id: Scalars['ID']
};


export type MutationIssueSignedUrlArgs = {
  contentType?: Maybe<Scalars['String']>
};


export type MutationLinkareerMagazineUpdateArgs = {
  input: LinkareerMagazineUpdateInput
};


export type MutationLinkareerTestUserDeleteArgs = {
  id: Scalars['ID']
};


export type MutationLoginArgs = {
  email: Scalars['String'],
  password: Scalars['String']
};


export type MutationLoginByAdminArgs = {
  id: Scalars['ID']
};


export type MutationLoginWithAppleArgs = {
  accessToken: Scalars['String'],
  appleIdToken: Scalars['String'],
  displayName: Scalars['String'],
  email: Scalars['String'],
  profileURL: Scalars['String'],
  snsID: Scalars['String']
};


export type MutationLoginWithFaceBookArgs = {
  accessToken: Scalars['String'],
  displayName: Scalars['String'],
  email: Scalars['String'],
  profileURL: Scalars['String'],
  snsID: Scalars['String']
};


export type MutationLoginWithKakaoArgs = {
  accessToken: Scalars['String'],
  displayName: Scalars['String'],
  email: Scalars['String'],
  profileURL: Scalars['String'],
  snsID: Scalars['String']
};


export type MutationLoginWithNaverArgs = {
  accessToken: Scalars['String'],
  displayName: Scalars['String'],
  email: Scalars['String'],
  profileURL: Scalars['String'],
  snsID: Scalars['String']
};


export type MutationLoginWithTimespreadArgs = {
  email: Scalars['String'],
  password: Scalars['String']
};


export type MutationMuteUserArgs = {
  input?: Maybe<MuteUserInput>
};


export type MutationNotificationDeleteArgs = {
  input: NotificationDeleteInput
};


export type MutationNotificationUpdateArgs = {
  input: NotificationUpdateInput
};


export type MutationOrganizationCreateArgs = {
  input: OrganizationCreateInput
};


export type MutationOrganizationDeleteArgs = {
  id: Scalars['ID']
};


export type MutationOrganizationUpdateArgs = {
  input: UpdateOrganizationInput
};


export type MutationOrganizationsDeleteArgs = {
  ids: Array<Scalars['ID']>
};


export type MutationReadNewsItemArgs = {
  newsItemId: Scalars['ID']
};


export type MutationReadNotificationArgs = {
  id: Scalars['ID']
};


export type MutationRecommendSearchKeywordCreateArgs = {
  input: RecommendSearchKeywordCreateInput
};


export type MutationRecommendSearchKeywordDeleteArgs = {
  id: Scalars['ID']
};


export type MutationRecommendSearchKeywordUpdateArgs = {
  id: Scalars['ID'],
  input: RecommendSearchKeywordUpdateInput
};


export type MutationRecoverNewsCommentsArgs = {
  newsCommentIds: Array<Scalars['ID']>
};


export type MutationRecoverNewsItemsArgs = {
  newsItemIds: Array<Scalars['ID']>
};


export type MutationRefreshAccessTokenArgs = {
  refreshToken: Scalars['String']
};


export type MutationRemoveBookmarkArgs = {
  newsItemId: Scalars['ID']
};


export type MutationRemoveBookmarksArgs = {
  newsItemIds: Array<Scalars['ID']>
};


export type MutationRemoveCommunityBookmarkArgs = {
  id: Scalars['ID']
};


export type MutationRemoveCommunityMenuBookmarkArgs = {
  id: Scalars['ID']
};


export type MutationRemoveLikeArgs = {
  newsItemId: Scalars['ID']
};


export type MutationReplyCreateArgs = {
  input: ReplyCreateInput
};


export type MutationReplyDeleteArgs = {
  input: ReplyDeleteInput
};


export type MutationReplyDeleteByAdministratorArgs = {
  id: Scalars['ID']
};


export type MutationReplyLikeArgs = {
  input: ReplyLikeInput
};


export type MutationReportNewsCommentArgs = {
  input: ReportInput
};


export type MutationReportNewsItemArgs = {
  input: ReportInput
};


export type MutationRollbackSnapshotArgs = {
  snapshotId: Scalars['ID']
};


export type MutationSaveFcmTokenArgs = {
  input: SaveFcmTokenInput
};


export type MutationSendMailSelfIntroductionArgs = {
  input: SendMailSelfIntroductionInput
};


export type MutationSetNewsItemAsNoticeArgs = {
  newsItemId: Scalars['ID']
};


export type MutationSetNoticeAsNewsItemArgs = {
  newsItemId: Scalars['ID']
};


export type MutationSkipSignupSessionArgs = {
  input: SkipSignupSessionInput
};


export type MutationSpellCheckArgs = {
  input: SpellCheckInput
};


export type MutationSpellCheckWithAutoSplitArgs = {
  input: SpellCheckWithAutoSplitInput
};


export type MutationUnFollowArgs = {
  channelId: Scalars['ID']
};


export type MutationUnmuteUserArgs = {
  input?: Maybe<UnmuteUserInput>
};


export type MutationUpdateAdPlacementArgs = {
  input: UpdateAdPlacementInput
};


export type MutationUpdateCampaignArgs = {
  input: UpdateCampaignInput
};


export type MutationUpdateCareerAdvantageConditionArgs = {
  input: UpdateCareerAdvantageConditionInput
};


export type MutationUpdateCareerAwardArgs = {
  input: UpdateCareerAwardInput
};


export type MutationUpdateCareerExperienceArgs = {
  input: UpdateCareerExperienceInput
};


export type MutationUpdateCareerExternshipArgs = {
  input: UpdateCareerExternshipInput
};


export type MutationUpdateCareerIntroductionArgs = {
  input: UpdateCareerIntroductionInput
};


export type MutationUpdateCareerJobPreferenceArgs = {
  input: UpdateCareerJobPreferenceInput
};


export type MutationUpdateCareerLanguageArgs = {
  input: UpdateCareerLanguageInput
};


export type MutationUpdateCareerLicenseArgs = {
  input: UpdateCareerLicenseInput
};


export type MutationUpdateCareerPortfolioArgs = {
  input: UpdateCareerPortfolioInput
};


export type MutationUpdateCareerProfileArgs = {
  input: UpdateCareerProfileInput
};


export type MutationUpdateCareerProfileOrderArgs = {
  input: UpdateCareerProfileOrderInput
};


export type MutationUpdateCareerSchoolArgs = {
  input: UpdateCareerSchoolInput
};


export type MutationUpdateCareerSkillArgs = {
  input: UpdateCareerSkillInput
};


export type MutationUpdateCareerUserInformationArgs = {
  input: UpdateCareerUserInformationInput
};


export type MutationUpdateChannelAndOrganizationArgs = {
  channelInput: UpdateChannelInput,
  organizationInput: UpdateOrganizationInput
};


export type MutationUpdateChannelTagCollectionsArgs = {
  input: Array<UpdateTagCollectionsInput>
};


export type MutationUpdateCommunityMentorPostArgs = {
  input: UpdateCommunityMentorPostInput
};


export type MutationUpdateCoverLetterFieldByQueryArgs = {
  input: UpdateCoverLetterFieldByQueryInput
};


export type MutationUpdateCurrentUserArgs = {
  input: UpdateCurrentUserInput
};


export type MutationUpdateDetailedActivityArgs = {
  input: UpdateDetailedActivityInput
};


export type MutationUpdateDetailedRecruitArgs = {
  input: UpdateDetailedRecruitInput
};


export type MutationUpdateFolderArgs = {
  input: UpdateFolderInput
};


export type MutationUpdateManyCommunityBoardUrlsArgs = {
  input: UpdateManyCommunityBoardUrlsInput
};


export type MutationUpdateManyCommunityPostRelatedWithActivityArgs = {
  input: UpdateManyCommunityPostRelatedWithActivityInput
};


export type MutationUpdateManyCoverLetterRelatedWithActivityArgs = {
  input: UpdateManyCoverLetterRelatedWithActivityInput
};


export type MutationUpdateNewsCommentArgs = {
  input: UpdateNewsCommentInput
};


export type MutationUpdateNewsItemArgs = {
  input: UpdateNewsItemInput
};


export type MutationUpdateNewsItemTagCollectionsArgs = {
  input: Array<UpdateTagCollectionsInput>
};


export type MutationUpdateQnAArgs = {
  input: UpdateQnAInput
};


export type MutationUpdateRecommendSearchKeywordArgs = {
  input: UpdateRecommendSearchKeywordInput
};


export type MutationUpdateRecommendSearchKeywordsArgs = {
  input: UpdateRecommendSearchKeywordsInput
};


export type MutationUpdateRecruitArgs = {
  input: UpdateRecruitInput
};


export type MutationUpdateSelfIntroductionArgs = {
  input: UpdateSelfIntroductionInput
};


export type MutationUpdateSubscriptionArgs = {
  input: UpdateSubscriptionInput
};


export type MutationUpdateTagCollectionArgs = {
  input: UpdateTagCollectionInput
};


export type MutationUpdateTestUserArgs = {
  id: Scalars['ID'],
  input: UpdateTestUserInput
};


export type MutationUpdateUserPhoneNumberByAuthUserIdArgs = {
  input: UpdateUserPhoneNumberByAuthUserIdInput
};


export type MutationUpsertManyLinkareerMagazineArgs = {
  input: UpsertManyLinkareerMagazineInput
};


export type MutationUserAuthorizePhoneNumberArgs = {
  input: UserAuthorizePhoneNumberInput
};


export type MutationUserCheckPhoneNumberAuthorizationArgs = {
  phoneNumber: Scalars['String'],
  signupToken?: Maybe<Scalars['String']>
};


export type MutationUserDeactivateArgs = {
  userID: Scalars['ID']
};


export type MutationUserDeleteArgs = {
  id: Scalars['ID']
};


export type MutationUserDeleteManyArgs = {
  ids: Array<Scalars['ID']>
};


export type MutationUserIsDuplicateLoginArgs = {
  input: UserIsDuplicateLoginInput
};


export type MutationUserIsDuplicateNicknameArgs = {
  input: UserIsDuplicateNicknameInput
};


export type MutationUserIsDuplicateNicknameWithoutTokenArgs = {
  input: UserIsDuplicateNicknameWithoutTokenInput
};


export type MutationUserProfileImageDeleteArgs = {
  id: Scalars['ID']
};


export type MutationUserScrapDeleteAllArgs = {
  userScrapType?: Maybe<UserScrapType>
};


export type MutationUserSignupWithEmailArgs = {
  input: UserSignupWithEmailInput
};


export type MutationUserSignupWithOAuthArgs = {
  input: UserSignupWithOAuthInput
};


export type MutationUserUnlinkTimespreadArgs = {
  userID: Scalars['ID']
};


export type MutationUserUpdateArgs = {
  id: Scalars['ID'],
  input: UserInput
};


export type MutationUserUpdateBirthArgs = {
  input: UserUpdateBirthInput
};


export type MutationUserUpdatePhoneNumberAuthorizationArgs = {
  input: UserUpdatePhoneNumberAuthorizationInput
};


export type MutationVerifyChannelNameArgs = {
  name: Scalars['String']
};


export type MutationVerifyChannelUrlArgs = {
  url: Scalars['String']
};


export type MutationVisitChannelArgs = {
  channelId: Scalars['ID']
};

export type MuteUserInput = {
  mutedUserId: Scalars['ID'],
  newsCommentId?: Maybe<Scalars['ID']>,
};

export type MuteUserPayload = {
   __typename?: 'MuteUserPayload',
  newsComment?: Maybe<NewsComment>,
  user?: Maybe<User>,
};

export type MyActivitiesCalendarFilter = {
  q?: Maybe<Scalars['String']>,
};

export type NaverAccount = {
   __typename?: 'NaverAccount',
  id: Scalars['ID'],
};

/**  팔로우한 채널 새로운 공고 알림  */
export type NewActivityNotification = SimpleNotification & {
   __typename?: 'NewActivityNotification',
  activity?: Maybe<Activity>,
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  deepLinkUrl?: Maybe<Scalars['String']>,
  /**  팔로우한 채널 정보  */
  following?: Maybe<Channel>,
  id: Scalars['ID'],
  linkUrl: Scalars['String'],
  title?: Maybe<Scalars['String']>,
  unread: Scalars['Boolean'],
  updatedAt?: Maybe<Scalars['Date']>,
};

/**  팔로우한 채널 새로운 소식 알림  */
export type NewNewsItemNotification = SimpleNotification & {
   __typename?: 'NewNewsItemNotification',
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  deepLinkUrl?: Maybe<Scalars['String']>,
  /**  팔로우한 채널  */
  following?: Maybe<Channel>,
  id: Scalars['ID'],
  linkUrl: Scalars['String'],
  newsItem?: Maybe<NewsItem>,
  title?: Maybe<Scalars['String']>,
  unread: Scalars['Boolean'],
  updatedAt?: Maybe<Scalars['Date']>,
};

export type NewsComment = Reportable & {
   __typename?: 'NewsComment',
  author?: Maybe<Actor>,
  authorName: Scalars['String'],
  channelName?: Maybe<Scalars['String']>,
  children: NewsCommentConnection,
  content: Scalars['String'],
  createdAt: Scalars['Date'],
  deletedAt?: Maybe<Scalars['Date']>,
  id: Scalars['ID'],
  lastReportReason?: Maybe<Scalars['String']>,
  lastReportedAt?: Maybe<Scalars['Date']>,
  newsItem?: Maybe<NewsItem>,
  originContent?: Maybe<Scalars['String']>,
  parent?: Maybe<NewsComment>,
  reportCount: Scalars['Int'],
  updatedAt?: Maybe<Scalars['Date']>,
  viewerCanDelete: Scalars['Boolean'],
  viewerCanReply: Scalars['Boolean'],
  viewerHasMuteAuthor: Scalars['Boolean'],
  viewerHasReported: Scalars['Boolean'],
  viewerIsAuthor: Scalars['Boolean'],
};


export type NewsCommentChildrenArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>
};

export type NewsCommentConnection = {
   __typename?: 'NewsCommentConnection',
  edges: Array<NewsCommentEdge>,
  nodes: Array<NewsComment>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type NewsCommentEdge = {
   __typename?: 'NewsCommentEdge',
  cursor: Scalars['String'],
  node: NewsComment,
};

export type NewsCommentOrder = {
  direction?: Maybe<OrderDirection>,
  field?: Maybe<NewsCommentOrderField>,
};

export enum NewsCommentOrderField {
  DELETED_AT = 'DELETED_AT',
  REPORTED_AT = 'REPORTED_AT',
  REPORT_COUNT = 'REPORT_COUNT'
}

/**  소식 답글 알림  */
export type NewsCommentToNewsCommentNotification = SimpleNotification & {
   __typename?: 'NewsCommentToNewsCommentNotification',
  /**  댓글 작성자  */
  author?: Maybe<Actor>,
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  deepLinkUrl?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  linkUrl: Scalars['String'],
  newsComment?: Maybe<NewsComment>,
  title?: Maybe<Scalars['String']>,
  unread: Scalars['Boolean'],
  updatedAt?: Maybe<Scalars['Date']>,
};

/**  소식 댓글 알림  */
export type NewsCommentToNewsItemNotification = SimpleNotification & {
   __typename?: 'NewsCommentToNewsItemNotification',
  /**  댓글 작성자  */
  author?: Maybe<Actor>,
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  deepLinkUrl?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  linkUrl: Scalars['String'],
  newsComment?: Maybe<NewsComment>,
  title?: Maybe<Scalars['String']>,
  unread: Scalars['Boolean'],
  updatedAt?: Maybe<Scalars['Date']>,
};

export type NewsItem = Reportable & {
   __typename?: 'NewsItem',
  author?: Maybe<User>,
  bookmarkCount: Scalars['Int'],
  bookmarkers: BookmarkerConnection,
  channel?: Maybe<Channel>,
  comments: NewsCommentConnection,
  content: Scalars['String'],
  createdAt: Scalars['Date'],
  deletedAt?: Maybe<Scalars['Date']>,
  embed?: Maybe<Embed>,
  id: Scalars['ID'],
  images: AttachmentConnection,
  isNotice: Scalars['Boolean'],
  lastReportReason?: Maybe<Scalars['String']>,
  lastReportedAt?: Maybe<Scalars['Date']>,
  likeCount: Scalars['Int'],
  likers: LikerConnection,
  newsCommentCount: Scalars['Int'],
  originContent?: Maybe<Scalars['String']>,
  plainText?: Maybe<Scalars['String']>,
  reportCount: Scalars['Int'],
  shareCount: Scalars['Int'],
  tags?: Maybe<Array<Maybe<Tag>>>,
  updatedAt?: Maybe<Scalars['Date']>,
  viewCount: Scalars['Int'],
  viewerCanDelete: Scalars['Boolean'],
  viewerCanReply: Scalars['Boolean'],
  viewerHasBookmarked: Scalars['Boolean'],
  viewerHasLiked: Scalars['Boolean'],
  viewerHasReported: Scalars['Boolean'],
  viewerIsAuthor: Scalars['Boolean'],
};


export type NewsItemBookmarkersArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>
};


export type NewsItemCommentsArgs = {
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type NewsItemImagesArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>
};


export type NewsItemLikersArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>
};

export type NewsItemConnection = {
   __typename?: 'NewsItemConnection',
  edges: Array<NewsItemEdge>,
  nodes: Array<Maybe<NewsItem>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type NewsItemEdge = {
   __typename?: 'NewsItemEdge',
  cursor: Scalars['String'],
  node?: Maybe<NewsItem>,
};

export type NewsItemFeedFilter = {
  q?: Maybe<Scalars['String']>,
};

export type NewsItemFilter = {
  createdAtRange?: Maybe<DateRange>,
  q?: Maybe<Scalars['String']>,
};

export type NewsItemOrder = {
  direction: OrderDirection,
  field: NewsItemOrderField,
};

export enum NewsItemOrderField {
  CREATED_AT = 'CREATED_AT',
  DELETED_AT = 'DELETED_AT',
  LIKE_COUNT = 'LIKE_COUNT',
  NEWS_COMMENT_COUNT = 'NEWS_COMMENT_COUNT',
  POPULAR = 'POPULAR',
  REPORTED_AT = 'REPORTED_AT',
  REPORT_COUNT = 'REPORT_COUNT',
  SCRAP_COUNT = 'SCRAP_COUNT',
  SHARE_COUNT = 'SHARE_COUNT',
  VIEW_COUNT = 'VIEW_COUNT'
}

/** 건강보험공단 간편인증 로그인 구분 */
export enum NhisLoginTypeLevel {
  KAKAO_TALK = 'KAKAO_TALK',
  KB_MOBILE = 'KB_MOBILE',
  NAVER = 'NAVER',
  PAYCO = 'PAYCO',
  SAMSUNG_PASS = 'SAMSUNG_PASS',
  SHINHAN_CERTIFICATE = 'SHINHAN_CERTIFICATE',
  TELECOM_PASS = 'TELECOM_PASS',
  TOSS = 'TOSS'
}

/** 건강보험공단 통신사 구분 */
export enum NhisTelecom {
  KT = 'KT',
  LG_U_PLUS = 'LG_U_PLUS',
  SKT = 'SKT'
}

export type Notification = {
   __typename?: 'Notification',
  activity?: Maybe<Activity>,
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  deletedAt?: Maybe<Scalars['Date']>,
  hasRead: Scalars['Boolean'],
  id: Scalars['ID'],
  linkURL: Scalars['String'],
  senderNickname?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
};

export type NotificationConnection = {
   __typename?: 'NotificationConnection',
  edges: Array<NotificationEdge>,
  nodes: Array<Maybe<SimpleNotification>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type NotificationDeleteAllPayload = {
   __typename?: 'NotificationDeleteAllPayload',
  count?: Maybe<Scalars['Int']>,
};

export type NotificationDeleteInput = {
  id: Scalars['ID'],
  type: NotificationType,
};

export type NotificationDeletePayload = {
   __typename?: 'NotificationDeletePayload',
  notification?: Maybe<Notification>,
};

export type NotificationEdge = {
   __typename?: 'NotificationEdge',
  cursor: Scalars['String'],
  node: SimpleNotification,
};

/**  스코프 각각에 해당 하는 데이터 타입이 정의되어 있음  */
export enum NotificationScope {
  /**  링커리어 공고 알림  */
  ACTIVITY_NOTIFICATION = 'ACTIVITY_NOTIFICATION',
  /**  biz 공고 알림  */
  BIZ_ACTIVITY_NOTIFICATION = 'BIZ_ACTIVITY_NOTIFICATION',
  /**  biz 채널 스크랩 알림  */
  BIZ_BOOKMARK_NOTIFICATION = 'BIZ_BOOKMARK_NOTIFICATION',
  /**  biz 채널 알림  */
  BIZ_CHANNEL_NOTIFICATION = 'BIZ_CHANNEL_NOTIFICATION',
  /**  biz 채널 팔로워 알림  */
  BIZ_FOLLOWER_NOTIFICATION = 'BIZ_FOLLOWER_NOTIFICATION',
  /**  biz 채널 좋아요 알림  */
  BIZ_LIKE_NOTIFICATION = 'BIZ_LIKE_NOTIFICATION',
  /**  biz 채널 댓글 알림  */
  BIZ_NEWS_COMMENT_NOTIFICATION = 'BIZ_NEWS_COMMENT_NOTIFICATION',
  /**  biz 전체 알림  */
  BIZ_NOTIFICATION = 'BIZ_NOTIFICATION',
  /**  링커리어 채널 알림  */
  CHANNEL_NOTIFICATION = 'CHANNEL_NOTIFICATION',
  /**  채팅 알림  */
  CHAT_NOTIFICATION = 'CHAT_NOTIFICATION',
  /**  링커리어 커뮤니티 알림  */
  COMMUNITY_NOTIFICATION = 'COMMUNITY_NOTIFICATION',
  /**  링커리어 전체 알림  */
  NOTIFICATION = 'NOTIFICATION'
}

export type NotificationsFilter = {
  scope?: Maybe<NotificationScope>,
};

export type NotificationsPayload = {
   __typename?: 'NotificationsPayload',
  notifications?: Maybe<Array<Maybe<Notification>>>,
};

export enum NotificationType {
  COMMENT_TO_COMMENT = 'COMMENT_TO_COMMENT',
  COMMENT_TO_POST = 'COMMENT_TO_POST',
  MESSAGE = 'MESSAGE',
  SCRAP_ACTIVITY_CLOSE_SOON = 'SCRAP_ACTIVITY_CLOSE_SOON',
  SCRAP_ACTIVITY_CLOSE_SOONER = 'SCRAP_ACTIVITY_CLOSE_SOONER'
}

export type NotificationUpdateInput = {
  hasRead: Scalars['Boolean'],
  id: Scalars['ID'],
  type: NotificationType,
};

export type NotificationUpdatePayload = {
   __typename?: 'NotificationUpdatePayload',
  notification?: Maybe<Notification>,
};


/** 온오프라인 필터 */
export type Onoffline = {
   __typename?: 'Onoffline',
  id?: Maybe<Scalars['ID']>,
  name?: Maybe<Scalars['String']>,
};

export enum OrderDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type Organization = {
   __typename?: 'Organization',
  address?: Maybe<Scalars['String']>,
  alias?: Maybe<Scalars['String']>,
  channel?: Maybe<Channel>,
  confirmationRequestedAt?: Maybe<Scalars['Date']>,
  email?: Maybe<Scalars['String']>,
  establishedAt?: Maybe<Scalars['Date']>,
  id: Scalars['ID'],
  isConfirmed?: Maybe<Scalars['Boolean']>,
  jobCategory?: Maybe<Category>,
  licenseImages: Array<Maybe<Attachment>>,
  logoImage?: Maybe<Attachment>,
  name: Scalars['String'],
  organizationType?: Maybe<OrganizationType>,
  organizationTypeID?: Maybe<Scalars['ID']>,
  phoneNumber?: Maybe<Scalars['String']>,
  registrationNumber?: Maybe<Scalars['String']>,
  representative?: Maybe<Scalars['String']>,
  scale?: Maybe<OrganizationScale>,
  websiteURL?: Maybe<Scalars['String']>,
};

/**  기업 정보 승인 알림  */
export type OrganizationApprovedNotification = SimpleNotification & {
   __typename?: 'OrganizationApprovedNotification',
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  deepLinkUrl?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  linkUrl: Scalars['String'],
  title?: Maybe<Scalars['String']>,
  unread: Scalars['Boolean'],
  updatedAt?: Maybe<Scalars['Date']>,
};

export type OrganizationConnection = {
   __typename?: 'OrganizationConnection',
  nodes?: Maybe<Array<Maybe<Organization>>>,
  totalCount: Scalars['Int'],
};

export type OrganizationCreateInput = {
  address?: Maybe<Scalars['String']>,
  isConfirmed?: Maybe<Scalars['Boolean']>,
  name: Scalars['String'],
  organizationTypeID?: Maybe<Scalars['ID']>,
  registrationNumber?: Maybe<Scalars['String']>,
  representative?: Maybe<Scalars['String']>,
  websiteURL?: Maybe<Scalars['String']>,
};

export type OrganizationCreateOrUpdatePayload = {
   __typename?: 'OrganizationCreateOrUpdatePayload',
  organization?: Maybe<Organization>,
};

export type OrganizationCreatePayload = {
   __typename?: 'OrganizationCreatePayload',
  organization?: Maybe<Organization>,
};

export type OrganizationDeletePayload = {
   __typename?: 'OrganizationDeletePayload',
  organizationID?: Maybe<Scalars['ID']>,
};

export type OrganizationFilterInput = {
  name?: Maybe<Scalars['String']>,
};

export type OrganizationFromDart = {
   __typename?: 'OrganizationFromDart',
  organizationName: Scalars['String'],
  registrationNumber: Scalars['String'],
  representative: Scalars['String'],
};

export type OrganizationListFromDart = {
   __typename?: 'OrganizationListFromDart',
  corpCode?: Maybe<Scalars['String']>,
  organizationName?: Maybe<Scalars['String']>,
  updatedAt?: Maybe<Scalars['Date']>,
};

export type OrganizationListFromDartConnection = {
   __typename?: 'OrganizationListFromDartConnection',
  nodes?: Maybe<Array<Maybe<OrganizationListFromDart>>>,
  totalCount: Scalars['Int'],
};

export type OrganizationListFromDartFilterInput = {
  organizationName: Scalars['String'],
};

export enum OrganizationOrderField {
  ID = 'ID',
  NAME = 'NAME'
}

export type OrganizationOrderInput = {
  direction: OrderDirection,
  field: OrganizationOrderField,
};

/**  기업 정보 승인 대기중 알림  */
export type OrganizationPendingNotification = SimpleNotification & {
   __typename?: 'OrganizationPendingNotification',
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  deepLinkUrl?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  linkUrl: Scalars['String'],
  title?: Maybe<Scalars['String']>,
  unread: Scalars['Boolean'],
  updatedAt?: Maybe<Scalars['Date']>,
};

export enum OrganizationScale {
  FIFTY_TO_TWOHUNDRED = 'FIFTY_TO_TWOHUNDRED',
  FIVEHUNDRED_TO_THOUSAND = 'FIVEHUNDRED_TO_THOUSAND',
  FIVETHOUSAND_TO_TENTHOUSAND = 'FIVETHOUSAND_TO_TENTHOUSAND',
  ONE_TO_TEN = 'ONE_TO_TEN',
  OVER_TENTHOUSAND = 'OVER_TENTHOUSAND',
  TEN_TO_FIFTY = 'TEN_TO_FIFTY',
  THOUSAND_TO_FIVETHOUSAND = 'THOUSAND_TO_FIVETHOUSAND',
  TWOHUNDRED_TO_FIVEHUNDRED = 'TWOHUNDRED_TO_FIVEHUNDRED',
  ZERO_TO_ONE = 'ZERO_TO_ONE'
}

export type OrganizationsDeletePayload = {
   __typename?: 'OrganizationsDeletePayload',
  organizationIDs?: Maybe<Array<Maybe<Scalars['ID']>>>,
};

export type OrganizationType = {
   __typename?: 'OrganizationType',
  id: Scalars['ID'],
  /** 기업 구분 명 */
  name: Scalars['String'],
  seq: Scalars['Int'],
};

export type OrganizationUpdatePayload = {
   __typename?: 'OrganizationUpdatePayload',
  organization?: Maybe<Organization>,
};

/** 커서 기반 페이지네이션에 대한 정보 */
export type PageInfo = {
   __typename?: 'PageInfo',
  /** 마지막 커서 */
  endCursor?: Maybe<Scalars['String']>,
  /** 
 * 다음 페이지가 있는지 여부.
   * 
   * `true`이면 `endCursor`를 사용하여 다음 페이지를 가져올 수 있습니다.
   * last&before를 사용하여 페이지를 가져올 때는 항상 `false`입니다.
 **/
  hasNextPage: Scalars['Boolean'],
  /** 
 * 이전 페이지가 있는지 여부.
   * 
   * `true`이면 `startCursor`를 사용하여 이전 페이지를 가져올 수 있습니다.
   * first&after를 사용하여 페이지를 가져올 때는 항상 `false`입니다.
 **/
  hasPreviousPage: Scalars['Boolean'],
  /** 첫번째 커서 */
  startCursor?: Maybe<Scalars['String']>,
};

/** 오프셋 기반 페이지네이션에 사용되는 input */
export type Pagination = {
  /** 페이지 번호 */
  page: Scalars['Int'],
  /** 페이지 크기 */
  pageSize: Scalars['Int'],
};

export type Period = {
   __typename?: 'Period',
  from?: Maybe<Scalars['Int']>,
  id: Scalars['ID'],
  name: Scalars['String'],
  to?: Maybe<Scalars['Int']>,
};

/** 기간 필터 */
export type PeriodForEducationFilterCollection = {
   __typename?: 'PeriodForEducationFilterCollection',
  from?: Maybe<Scalars['Int']>,
  id: Scalars['Int'],
  name: Scalars['String'],
  to?: Maybe<Scalars['Int']>,
};

export type Persona = {
   __typename?: 'Persona',
  id: Scalars['ID'],
  name: Scalars['String'],
};

export enum Platform {
  ANDROID = 'ANDROID',
  IOS = 'IOS'
}

export type PopularChatRoomsFilterBy = {
  /** 
 * 채팅방의 activityTypeID.
   * 
   * - 채용: 5
   * - 대외활동: 1
   * - 공모전: 3
 **/
  activityTypeID?: Maybe<Scalars['Int']>,
};

export type Post = {
   __typename?: 'Post',
  board?: Maybe<Board>,
  commentCount?: Maybe<Scalars['Int']>,
  createdAt?: Maybe<Scalars['Date']>,
  deletedAt?: Maybe<Scalars['Date']>,
  id: Scalars['ID'],
  nickname?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  updatedAt?: Maybe<Scalars['Date']>,
  username?: Maybe<Scalars['String']>,
  views?: Maybe<Scalars['Int']>,
};

export type PostDuty = {
  dutyId?: Maybe<Scalars['String']>,
  parentDutyId?: Maybe<Scalars['String']>,
  rootDutyId?: Maybe<Scalars['String']>,
};

export type PostDutyV2 = {
  id?: Maybe<Scalars['String']>,
  parentId?: Maybe<Scalars['String']>,
  rootId?: Maybe<Scalars['String']>,
};

export type PostOrganization = {
  organizationGroupName?: Maybe<Scalars['String']>,
  organizationName?: Maybe<Scalars['String']>,
};

export type PostOrganizationV2 = {
  groupName?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
};

export type Profile = {
   __typename?: 'Profile',
  address?: Maybe<Scalars['String']>,
  birthday?: Maybe<Scalars['Date']>,
  certifications?: Maybe<Array<Maybe<Certification>>>,
  education: EducationType,
  email?: Maybe<Scalars['String']>,
  examedAt?: Maybe<Scalars['Date']>,
  gender?: Maybe<Gender>,
  highschools?: Maybe<Array<Maybe<HighschoolProfile>>>,
  id: Scalars['ID'],
  identificationImage?: Maybe<ApplyFile>,
  isFirst: Scalars['Boolean'],
  languages?: Maybe<Array<Maybe<Language>>>,
  name?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<Scalars['String']>,
  speciality?: Maybe<Scalars['String']>,
  techniques?: Maybe<Array<Maybe<Technique>>>,
  updatedAt: Scalars['Date'],
  user: User,
};

/** 채팅 프로필 색상 */
export type ProfileBackgroundColor = {
   __typename?: 'ProfileBackgroundColor',
  id: Scalars['ID'],
  name: Scalars['String'],
  code: Scalars['String'],
};

/** 채팅 프로필 이미지 */
export type ProfileImageTemplate = {
   __typename?: 'ProfileImageTemplate',
  id: Scalars['ID'],
  /** 프로필 일러스트 */
  image: ChatAttachment,
  /** 배경 색상 */
  bgColor: ProfileBackgroundColor,
};

export type QnA = {
   __typename?: 'QnA',
  answer?: Maybe<Scalars['String']>,
  author?: Maybe<User>,
  blankType: BlankType,
  charMaxSize: Scalars['Int'],
  charSize: CharSize,
  createdAt: Scalars['Date'],
  id: Scalars['ID'],
  question?: Maybe<Scalars['String']>,
  selfIntroduction?: Maybe<SelfIntroduction>,
  seq: Scalars['Int'],
  snapshots: QnASnapshotConnection,
};


export type QnASnapshotsArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>
};

export type QnASnapshot = {
   __typename?: 'QnASnapshot',
  answer?: Maybe<Scalars['String']>,
  blankType: BlankType,
  charMaxSize: Scalars['Int'],
  charSize: CharSize,
  createdAt: Scalars['Date'],
  id: Scalars['ID'],
  qnaId: Scalars['Int'],
  question?: Maybe<Scalars['String']>,
  seq: Scalars['Int'],
};

export type QnASnapshotConnection = {
   __typename?: 'QnASnapshotConnection',
  nodes: Array<Maybe<QnASnapshot>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type Query = {
   __typename?: 'Query',
  /** 
 * 인증이 필요한 api입니다
   * 
   * 채팅방 알림이 켜져있는 채팅방이 있는지 여부
 **/
  viewerSubscribeAnyChatRoomNotification: Scalars['Boolean'],
  /** 랜덤 프로필 이미지를 하나 가져옵니다. */
  getRandomProfileImageTemplate: ProfileImageTemplate,
  /** 채팅방을 가져옵니다. */
  chatRoom?: Maybe<ChatRoom>,
  /** 채팅방 리스트를 순위대로 불러옵니다. */
  popularChatRooms: Array<ChatRoom>,
  /** 채팅방의 이름으로 채팅방을 검색합니다. */
  searchChatRoomsByName: ChatRoomConnection,
  /** 
 * 관리자만 접근 가능합니다.
   * 
   * offset 기반 페이지네이션으로 채팅방을 검색합니다.
 **/
  searchChatRoomsByNameWithOffset: ChatRoomOffsetConnection,
  /** 
 * 인증이 필요한 api입니다
   * 
   * 채팅방에서 키워드로 채팅 메시지를 검색합니다.
   * 검색 키워드는 2글자 이상이어야 합니다.
   * 
   * - 에러 코드
   *   1. BAD_USER_INPUT
   *     - 2글자 미만의 키워드
   *   2. NOT_FOUND_CHAT_ROOM
   *     - 채팅방이 존재하지 않음
 **/
  searchChatMessagesFromChatRoom: ChatMessageConnection,
  /** 
 * 채팅방의 메시지들을 조회합니다.
   * first&after 혹은 last&before 중 하나만 사용할 수 있습니다.
   * 이를 통해서 불러오는 방향과 개수를 조절할 수 있습니다.
   * 추가적으로, 읽지 않은 메시지 수를 0으로 만듭니다.
   * 
   * - 에러 코드
   *   1. BAD_USER_INPUT
   *     - 2글자 미만의 키워드
   *   2. NOT_FOUND_CHAT_ROOM
   *     - 채팅방이 존재하지 않음
 **/
  chatMessagesByChatRoomId: ChatMessageConnection,
  /** 
 * 관리자만 접근 가능합니다.
   * 
   * 특정 채팅 메시지를 조회합니다.
   * 
   * - 에러 코드
   *   1. NOT_FOUND_MESSAGE
   *     - 해당 메시지를 찾을 수 없는 경우
 **/
  chatMessage: ChatMessage,
  /** 
 * 관리자만 접근 가능합니다.
   * 
   * 신고당한 메시지들을 조회합니다.
 **/
  reportedChatMessages: ChatMessageOffsetConnection,
  /** 
 * 관리자만 접근 가능합니다.
   * 
   * 삭제된 메시지들을 조회합니다.
 **/
  deletedChatMessages: ChatMessageOffsetConnection,
  /** 
 * 읽지 않은 메시지 수.
   *   
   *   - 로그인 하지 않은 경우: null
   *   - 로그인 하였으며, 읽지 않은 메시지가 없는 경우: 0
   *   - 로그인 하였으며, 읽지 않은 메시지가 n개인 경우(n <= 99): n
   *   - 로그인 하였으며, 읽지 않은 메시지가 n개인 경우(n >= 100): 100
 **/
  chatMessageUnreadCount?: Maybe<Scalars['Int']>,
  /** 
 * 관리자만 접근 가능합니다.
   * 
   * 활동 정지 로그들을 가져옵니다.
 **/
  userBans: UserBanOffsetConnection,
  /** 
 * 관리자만 접근 가능합니다.
   * 
   * 채팅방별 kpi 지표를 불러옵니다.
 **/
  kpiChatRooms: KpiChatRoomOffsetConnection,
  /** 
 * 관리자만 접근 가능합니다.
   * 
   * 채팅 서비스의 kpi 지표를 가져옵니다.
 **/
  kpiChats: KpiChatOffsetConnection,
  activities?: Maybe<ActivityConnection>,
  activitiesByID: Array<Activity>,
  activitiesCalendar: ActivityCalendarConnection,
  activitiesCount?: Maybe<Scalars['Int']>,
  activitiesForRelatedContent?: Maybe<ActivityConnection>,
  activity?: Maybe<Activity>,
  activityCalendarEntries: ActivityCalendarEntryConnection,
  activityCalendarEntry?: Maybe<ActivityCalendarEntry>,
  activityCardNewsList?: Maybe<ActivityCardNewsListPayload>,
  activityCounts?: Maybe<ActivityCountsPayload>,
  activityFile?: Maybe<ActivityFile>,
  activityFileType?: Maybe<ActivityFileType>,
  activityFilterCollection: ActivityFilterCollection,
  activityFilterList?: Maybe<Array<ActivityFilterListItem>>,
  activityInteger?: Maybe<ActivityInteger>,
  activityIntegerType?: Maybe<ActivityIntegerType>,
  activityOutLink?: Maybe<ActivityOutLink>,
  activityReference?: Maybe<ActivityReference>,
  activityScrapUserList: ActivityScrapUserListConnection,
  activitySearch?: Maybe<ActivitySearchConnection>,
  activitySelfIntroductionStatistics: ActivitySelfIntroductionStatisticConnection,
  activityTarget?: Maybe<ActivityTarget>,
  activityTargets?: Maybe<Array<ActivityTarget>>,
  activityText?: Maybe<ActivityText>,
  activityTextType?: Maybe<ActivityTextType>,
  activityType?: Maybe<ActivityType>,
  adPlacement?: Maybe<AdPlacement>,
  adPlacementByCode?: Maybe<AdPlacement>,
  adPlacementClickLogsByCampaignID: Array<AdPlacementClickLog>,
  adPlacementImpressionLogsByCampaignID: Array<AdPlacementImpressionLog>,
  adPlacementLandingURLWithCampaignID?: Maybe<AdPlacementLandingUrl>,
  adPlacements: AdPlacementConnection,
  adPlacementsByCampaignID: AdPlacementsByCampaignIdPayload,
  /** 관리자만 이용 가능한 서비스 입니다. */
  adminCampaigns: AdminCampaignConnection,
  adsByPlacementCode: AdConnection,
  advertisement?: Maybe<Advertisement>,
  advertisementList?: Maybe<AdvertisementListPayload>,
  applyFile?: Maybe<ApplyFile>,
  applyType?: Maybe<ApplyType>,
  benefit?: Maybe<Benefit>,
  benefitsByTypeID?: Maybe<Array<Benefit>>,
  bestNewsItems?: Maybe<NewsItemConnection>,
  bizActivity?: Maybe<Activity>,
  campaign?: Maybe<Campaign>,
  campaignStatistics?: Maybe<CampaignStatisticConnection>,
  campaigns: CampaignConnection,
  careerEmploymentSupportTargets: Array<CareerEmploymentSupportTarget>,
  categories?: Maybe<Array<Category>>,
  categoriesByTypeID?: Maybe<Array<Category>>,
  category?: Maybe<Category>,
  certification?: Maybe<Certification>,
  channel?: Maybe<Channel>,
  channelByUrl?: Maybe<Channel>,
  channelFeed?: Maybe<ChannelConnection>,
  channels?: Maybe<ChannelConnection>,
  channelsWithStatistic?: Maybe<ChannelConnection>,
  clubFilterCollection: ClubFilterCollection,
  comments: NewsCommentConnection,
  communityBestPostList?: Maybe<CommunityBestPostListData>,
  communityBoardList?: Maybe<CommunityBoardListData>,
  communityBoardUrl?: Maybe<CommunityBoardUrl>,
  communityBoardUrlByActivity?: Maybe<CommunityBoardUrl>,
  communityBoardUrls: CommunityBoardUrlConnection,
  communityBookmarks: CommunityBookmarkableConnection,
  communityNotifications?: Maybe<CommunityNotificationsData>,
  communityPost?: Maybe<CommunityPostObject>,
  communityPostCollection?: Maybe<CommunityPostCollectionData>,
  communityPostSearch?: Maybe<CommunityPostSearchData>,
  communityPostsRelatedWithActivity: CommunityPostRelatedWithActivityConnection,
  communityPostsRelatedWithActivityManual: CommunityPostRelatedWithActivityManualConnection,
  contestFilterCollection: ContestFilterCollection,
  costTypes: Array<CostType>,
  coverLetter?: Maybe<CoverLetter>,
  coverLetterOrganizationList?: Maybe<Array<Maybe<CoverLetterOrganizationListPayload>>>,
  coverLetterPlagiarismList?: Maybe<CoverLetterPlagiarismConnection>,
  coverLetterRoleList?: Maybe<Array<Maybe<CoverLetterRoleListPayload>>>,
  coverLetterTotalCount?: Maybe<Scalars['Int']>,
  coverLetterWithHighlight?: Maybe<CoverLetterWithHighlight>,
  coverLetters?: Maybe<CoverLetterElasticConnection>,
  coverLettersRelatedWithActivity: CoverLetterRelatedWithActivityConnection,
  coverLettersRelatedWithActivityManual: CoverLetterRelatedWithActivityManualConnection,
  curation?: Maybe<Curation>,
  curationList?: Maybe<CurationListConnection>,
  currentAdByPlacementCodeAndCurrentTime?: Maybe<Ad>,
  currentUser?: Maybe<User>,
  currentUserScrapsCommunity: CurrentUserScrapCommunityConnection,
  currentUserScrapsCountCommunity: Scalars['Int'],
  deletedNewsComments: NewsCommentConnection,
  deletedNewsItems: NewsItemConnection,
  duties: DutyConnection,
  educationFilterCollection: EducationFilterCollection,
  environment?: Maybe<Environment>,
  famousActivityList?: Maybe<FamousActivityListPayload>,
  highschool?: Maybe<Highschool>,
  highschoolProfile?: Maybe<HighschoolProfile>,
  interest?: Maybe<Interest>,
  interests?: Maybe<Array<Interest>>,
  internFilterCollection: InternFilterCollection,
  introductionLetter?: Maybe<IntroductionLetter>,
  introductionLetterReference?: Maybe<IntroductionLetterReference>,
  introductionLetterType?: Maybe<IntroductionLetterType>,
  /** 직책 전체 목록을 가져오는 쿼리 */
  jobPositions: Array<JobPosition>,
  /** 직급 전체 목록을 가져오는 쿼리 */
  jobRanks: Array<JobRank>,
  language?: Maybe<Language>,
  linkareerMagazine?: Maybe<LinkareerMagazine>,
  linkareerMagazineList: LinkareerMagazineListConnection,
  majorByName: Major,
  myActivitiesCalendar: ActivityCalendarConnection,
  newsComment?: Maybe<NewsComment>,
  newsItem?: Maybe<NewsItem>,
  /**  홈 소식  */
  newsItemFeed: NewsItemConnection,
  /**  특정 태그가 포함된 소식  */
  newsItemFeedByTagIDs: NewsItemConnection,
  newsItems: NewsItemConnection,
  noticeNewsItem?: Maybe<NewsItem>,
  notification?: Maybe<Notification>,
  notifications?: Maybe<NotificationsPayload>,
  notificationsUnreadCount?: Maybe<Scalars['Int']>,
  notificationsUnreadCountByAuthUserID?: Maybe<Scalars['Int']>,
  onofflines: Array<Onoffline>,
  organization?: Maybe<Organization>,
  organizationFromDart?: Maybe<OrganizationFromDart>,
  organizationListFromDart?: Maybe<OrganizationListFromDartConnection>,
  organizationType?: Maybe<OrganizationType>,
  organizationTypes?: Maybe<Array<Maybe<OrganizationType>>>,
  organizations?: Maybe<OrganizationConnection>,
  organizationsByID?: Maybe<Array<Maybe<Organization>>>,
  periods: Array<PeriodForEducationFilterCollection>,
  persona?: Maybe<Persona>,
  profile?: Maybe<Profile>,
  profileImageByAuthUserID?: Maybe<Scalars['String']>,
  qnaSnapshotsByQnaId: QnASnapshotConnection,
  questionSuggestion: QuestionSuggestion,
  questionSuggestions: QuestionSuggestionConnection,
  recommendChannelFeed: ChannelConnection,
  /** 
 * 조회시 seq가 낮은 순으로 정렬
   * 만약 seq가 같다면 createdAt 기준 최신순으로 정렬
 **/
  recommendChannelTagCollection?: Maybe<Array<TagCollection>>,
  recommendChannels?: Maybe<ChannelConnection>,
  /** 
 * 조회시 seq가 낮은 순으로 정렬
   * 만약 seq가 같다면 createdAt 기준 최신순으로 정렬
 **/
  recommendNewsItemTagCollection?: Maybe<Array<TagCollection>>,
  recommendSearchKeyword?: Maybe<RecommendSearchKeyword>,
  recommendSearchKeywordList?: Maybe<RecommendSearchKeywordListConnection>,
  recruitFilterCollection: RecruitFilterCollection,
  region?: Maybe<Region>,
  regionDistrict?: Maybe<RegionDistrict>,
  regions: Array<Region>,
  replies: ReplyConnection,
  reply?: Maybe<Reply>,
  replyList?: Maybe<ReplyListPayload>,
  replyQna?: Maybe<Array<Reply>>,
  reportedNewsComments: NewsCommentConnection,
  reportedNewsItems?: Maybe<NewsItemConnection>,
  resume?: Maybe<Resume>,
  /** 산업 1depth 전체 리스트 쿼리 */
  rootIndustries: Array<Industry>,
  /** 복리후생 1depth 리스트 */
  rootWelfareBenefits: Array<WelfareBenefit>,
  schoolByName: School,
  searchExactCoverLetter?: Maybe<Array<Maybe<SearchExactCoverLetterPayload>>>,
  searchMajors: Array<Major>,
  searchSchools: Array<School>,
  searchTags?: Maybe<TagConnection>,
  searchUniqueNameOrganizations: UniqueNameOrganizationConnection,
  searchViewerSelfIntroductionFeed: SelfIntroductionConnection,
  selfIntroductionStatistics: SelfIntroductionStatisticConnection,
  sharedCareerProfile?: Maybe<CareerProfile>,
  signupSession?: Maybe<SignupSession>,
  signupSessions: SignupSessionConnection,
  signupSessionsByToken: Array<Maybe<SignupSession>>,
  skill?: Maybe<Skill>,
  skillTagByName: SkillTag,
  skills?: Maybe<Array<Skill>>,
  technique?: Maybe<Technique>,
  tendency?: Maybe<Tendency>,
  /** 자만검 실시간인기채용에 사용됩니다. */
  top4Recruit: Array<Activity>,
  topFollowingChannels: TopFollowingChannelConnection,
  university?: Maybe<University>,
  user?: Maybe<User>,
  userAuth?: Maybe<Scalars['Object']>,
  userScrap?: Maybe<UserScrap>,
  userScrapCounts?: Maybe<UserScrapCountsPayload>,
  userScraps?: Maybe<UserScrapsConnection>,
  userStatistics: UserStatisticConnection,
  users: UserConnection,
  usersByEmail: Array<User>,
  usersByID: Array<Maybe<User>>,
  /** viewer의 활동 관리자 프리셋 목록 */
  viewerActivityManagerPresets: ActivityManagerPresetOffsetConnection,
  /** viewer의 활동 기업 프리셋 목록 */
  viewerActivityOrganizationPresets: ActivityOrganizationPresetOffsetConnection,
  viewerBookmarks?: Maybe<BookmarkedConnection>,
  /** 로그인 후 이용 가능한 서비스 입니다. */
  viewerCareerProfile: CareerProfile,
  viewerComments: NewsCommentConnection,
  viewerFiles: SelfIntroductionConnection,
  viewerFolder: Folder,
  viewerFolders: FolderConnection,
  viewerFollowers: FollowerConnection,
  viewerFollowingFeed?: Maybe<FollowingConnection>,
  viewerFollowings: FollowingConnection,
  /**  내 관심소식  */
  viewerNewsItemFeed: NewsItemConnection,
  /**  스코프를 통하여 원하는 데이터 영역 선택해서 조회가능  */
  viewerNotifications?: Maybe<NotificationConnection>,
  viewerSelfIntroduction: SelfIntroduction,
  viewerSelfIntroductionFeedByDutyId: SelfIntroductionConnection,
  viewerSelfIntroductionFeedByQuery: SelfIntroductionConnection,
  viewerSelfIntroductions: SelfIntroductionConnection,
  workfield?: Maybe<Workfield>,
};


export type QueryChatRoomArgs = {
  id: Scalars['ID']
};


export type QueryPopularChatRoomsArgs = {
  limit?: Maybe<Scalars['Int']>,
  filterBy?: Maybe<PopularChatRoomsFilterBy>
};


export type QuerySearchChatRoomsByNameArgs = {
  name: Scalars['String'],
  first: Scalars['Int'],
  after?: Maybe<Scalars['String']>
};


export type QuerySearchChatRoomsByNameWithOffsetArgs = {
  pagination: Pagination,
  orderBy?: Maybe<ChatRoomOrderByField>,
  roomName?: Maybe<Scalars['String']>,
  filterBy?: Maybe<SearchChatRoomsByNameWithOffsetFilterBy>
};


export type QuerySearchChatMessagesFromChatRoomArgs = {
  keyword: Scalars['String'],
  chatRoomId: Scalars['ID'],
  first: Scalars['Int'],
  after?: Maybe<Scalars['String']>
};


export type QueryChatMessagesByChatRoomIdArgs = {
  chatRoomId: Scalars['ID'],
  isUpdateLastReadChat?: Maybe<Scalars['Boolean']>,
  first?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  filterBy?: Maybe<ChatMessageFilter>
};


export type QueryChatMessageArgs = {
  chatMessageId: Scalars['ID']
};


export type QueryReportedChatMessagesArgs = {
  findWithRangeInput: FindWithRangeInput,
  hasAdminRead?: Maybe<Scalars['Boolean']>,
  reportedChatMessageSearchInput?: Maybe<ReportedChatMessageSearchInput>
};


export type QueryDeletedChatMessagesArgs = {
  findWithRangeInput: FindWithRangeInput,
  deletedChatMessageSearchInput?: Maybe<DeletedChatMessageSearchInput>
};


export type QueryUserBansArgs = {
  pagination: Pagination,
  bannedUserSearchInput?: Maybe<BannedUserSearchInput>
};


export type QueryKpiChatRoomsArgs = {
  chatRoomId: Scalars['ID'],
  findWithPeriodInput: FindWithPeriodInput
};


export type QueryKpiChatsArgs = {
  findWithPeriodAndCategoryInput: FindWithPeriodAndCategoryInput
};


export type QueryActivitiesArgs = {
  filterBy?: Maybe<ActivityFilter>,
  orderBy?: Maybe<ActivityOrder>,
  pagination?: Maybe<Pagination>,
  rangeBy?: Maybe<ActivityRange>
};


export type QueryActivitiesByIdArgs = {
  ids: Array<Scalars['ID']>
};


export type QueryActivitiesCalendarArgs = {
  field: ActivityCalendarGroupByField,
  filterBy?: Maybe<ActivityFilter>,
  from?: Maybe<Scalars['Date']>,
  orderBy?: Maybe<ActivityOrder>,
  pagination?: Maybe<Pagination>,
  to?: Maybe<Scalars['Date']>
};


export type QueryActivitiesCountArgs = {
  filterBy?: Maybe<ActivityFilter>
};


export type QueryActivitiesForRelatedContentArgs = {
  filterBy?: Maybe<ActivityForRelatedContentFilter>,
  pagination?: Maybe<Pagination>
};


export type QueryActivityArgs = {
  id: Scalars['ID']
};


export type QueryActivityCalendarEntriesArgs = {
  from: Scalars['Date'],
  pagination?: Maybe<Pagination>,
  to: Scalars['Date']
};


export type QueryActivityCalendarEntryArgs = {
  date: Scalars['Date']
};


export type QueryActivityCardNewsListArgs = {
  input: ActivityCardNewsListInput,
  pagination?: Maybe<Pagination>
};


export type QueryActivityFileArgs = {
  id: Scalars['ID']
};


export type QueryActivityFileTypeArgs = {
  id: Scalars['ID']
};


export type QueryActivityFilterListArgs = {
  input: ActivityFilterListInput
};


export type QueryActivityIntegerArgs = {
  id: Scalars['ID']
};


export type QueryActivityIntegerTypeArgs = {
  id: Scalars['ID']
};


export type QueryActivityOutLinkArgs = {
  id: Scalars['ID']
};


export type QueryActivityReferenceArgs = {
  id: Scalars['ID']
};


export type QueryActivityScrapUserListArgs = {
  input: ActivityScrapUserListInput,
  pagination?: Maybe<Pagination>
};


export type QueryActivitySearchArgs = {
  filterBy: ActivitySearchFilter,
  orderBy?: Maybe<ActivitySearchOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryActivitySelfIntroductionStatisticsArgs = {
  filterBy?: Maybe<ActivitySelfIntroductionStatisticFilters>,
  orderBy?: Maybe<ActivitySelfIntroductionStatisticOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryActivityTargetArgs = {
  id: Scalars['ID']
};


export type QueryActivityTextArgs = {
  id: Scalars['ID']
};


export type QueryActivityTextTypeArgs = {
  id: Scalars['ID']
};


export type QueryActivityTypeArgs = {
  id: Scalars['ID']
};


export type QueryAdPlacementArgs = {
  id: Scalars['ID']
};


export type QueryAdPlacementByCodeArgs = {
  code: Scalars['String']
};


export type QueryAdPlacementClickLogsByCampaignIdArgs = {
  campaignID: Scalars['ID']
};


export type QueryAdPlacementImpressionLogsByCampaignIdArgs = {
  campaignID: Scalars['ID']
};


export type QueryAdPlacementLandingUrlWithCampaignIdArgs = {
  campaignID?: Maybe<Scalars['ID']>,
  id: Scalars['ID']
};


export type QueryAdPlacementsArgs = {
  filterBy?: Maybe<AdPlacementFilters>,
  orderBy?: Maybe<AdPlacementOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryAdPlacementsByCampaignIdArgs = {
  campaignID: Scalars['ID']
};


export type QueryAdminCampaignsArgs = {
  filterBy: AdminCampaignFilters,
  orderBy?: Maybe<AdminCampaignOrders>,
  pagination?: Maybe<Pagination>
};


export type QueryAdsByPlacementCodeArgs = {
  code: Scalars['String']
};


export type QueryAdvertisementArgs = {
  id?: Maybe<Scalars['ID']>
};


export type QueryAdvertisementListArgs = {
  filterBy: AdvertisementFilter,
  orderBy?: Maybe<AdvertisementOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryApplyFileArgs = {
  id: Scalars['ID']
};


export type QueryApplyTypeArgs = {
  id: Scalars['ID']
};


export type QueryBenefitArgs = {
  id: Scalars['ID']
};


export type QueryBenefitsByTypeIdArgs = {
  activityTypeID: Scalars['ID']
};


export type QueryBestNewsItemsArgs = {
  pagination?: Maybe<Pagination>,
  type?: Maybe<BestNewsItemType>
};


export type QueryBizActivityArgs = {
  id: Scalars['ID']
};


export type QueryCampaignArgs = {
  id: Scalars['ID']
};


export type QueryCampaignStatisticsArgs = {
  filterBy?: Maybe<CampaignStatisticFilters>
};


export type QueryCampaignsArgs = {
  filterBy?: Maybe<CampaignFilters>,
  orderBy?: Maybe<CampaignOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryCategoriesArgs = {
  filterBy?: Maybe<CategoryFilter>
};


export type QueryCategoriesByTypeIdArgs = {
  activityTypeID: Scalars['ID']
};


export type QueryCategoryArgs = {
  id: Scalars['ID']
};


export type QueryCertificationArgs = {
  id: Scalars['ID']
};


export type QueryChannelArgs = {
  id: Scalars['ID']
};


export type QueryChannelByUrlArgs = {
  url: Scalars['String']
};


export type QueryChannelFeedArgs = {
  after?: Maybe<Scalars['String']>,
  filterBy?: Maybe<ChannelFilter>,
  first?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<ChannelOrder>
};


export type QueryChannelsArgs = {
  filterBy?: Maybe<ChannelFilter>,
  orderBy?: Maybe<ChannelOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryChannelsWithStatisticArgs = {
  filterBy?: Maybe<ChannelsWithStatisticFilter>,
  orderBy?: Maybe<ChannelsWithStatisticOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryCommentsArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  newsItemId: Scalars['ID'],
  parentId?: Maybe<Scalars['ID']>
};


export type QueryCommunityBestPostListArgs = {
  input: CommunityBestPostListInput
};


export type QueryCommunityBoardUrlArgs = {
  id: Scalars['ID']
};


export type QueryCommunityBoardUrlByActivityArgs = {
  activityId: Scalars['ID']
};


export type QueryCommunityBookmarksArgs = {
  orderBy?: Maybe<CommunityBookmarkOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryCommunityNotificationsArgs = {
  input: CommunityNotificationsInput
};


export type QueryCommunityPostArgs = {
  id: Scalars['ID']
};


export type QueryCommunityPostCollectionArgs = {
  input: CommunityPostCollectionInput
};


export type QueryCommunityPostSearchArgs = {
  input: CommunityPostSearchInput
};


export type QueryCommunityPostsRelatedWithActivityArgs = {
  input: CommunityPostsRelatedWithActivityInput
};


export type QueryCommunityPostsRelatedWithActivityManualArgs = {
  input: CommunityPostsRelatedWithActivityManualInput
};


export type QueryCoverLetterArgs = {
  id: Scalars['ID']
};


export type QueryCoverLetterOrganizationListArgs = {
  input: CoverLetterOrganizationListInput
};


export type QueryCoverLetterPlagiarismListArgs = {
  id: Scalars['ID']
};


export type QueryCoverLetterRoleListArgs = {
  input: CoverLetterRoleListInput
};


export type QueryCoverLetterWithHighlightArgs = {
  id: Scalars['ID'],
  keyword?: Maybe<Scalars['String']>
};


export type QueryCoverLettersArgs = {
  filterBy: CoverLetterFilter,
  orderBy?: Maybe<CoverLetterOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryCoverLettersRelatedWithActivityArgs = {
  input: CoverLettersRelatedWithActivityInput
};


export type QueryCoverLettersRelatedWithActivityManualArgs = {
  input: CoverLettersRelatedWithActivityManualInput
};


export type QueryCurationArgs = {
  id: Scalars['ID']
};


export type QueryCurrentAdByPlacementCodeAndCurrentTimeArgs = {
  code: Scalars['String'],
  currentTime: Scalars['String']
};


export type QueryCurrentUserScrapsCommunityArgs = {
  filterBy: CurrentUserScrapCommunityFilter,
  orderBy: UserScrapCommunityOrder,
  pagination?: Maybe<Pagination>
};


export type QueryDeletedNewsCommentsArgs = {
  filterBy?: Maybe<DeletedNewsCommentFilter>,
  orderBy?: Maybe<NewsCommentOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryDeletedNewsItemsArgs = {
  filterBy?: Maybe<DeletedNewsItemFilter>,
  orderBy?: Maybe<NewsItemOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryDutiesArgs = {
  filterBy?: Maybe<DutyFilters>,
  orderBy?: Maybe<DutyOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryFamousActivityListArgs = {
  input: FamousActivityListInput
};


export type QueryHighschoolArgs = {
  id: Scalars['ID']
};


export type QueryHighschoolProfileArgs = {
  id: Scalars['ID']
};


export type QueryInterestArgs = {
  id: Scalars['ID']
};


export type QueryIntroductionLetterArgs = {
  id: Scalars['ID']
};


export type QueryIntroductionLetterReferenceArgs = {
  id: Scalars['ID']
};


export type QueryIntroductionLetterTypeArgs = {
  id: Scalars['ID']
};


export type QueryLanguageArgs = {
  id: Scalars['ID']
};


export type QueryLinkareerMagazineArgs = {
  id: Scalars['ID']
};


export type QueryMajorByNameArgs = {
  name: Scalars['String']
};


export type QueryMyActivitiesCalendarArgs = {
  field: ActivityCalendarGroupByField,
  filterBy?: Maybe<MyActivitiesCalendarFilter>,
  from?: Maybe<Scalars['Date']>,
  pagination?: Maybe<Pagination>,
  to?: Maybe<Scalars['Date']>
};


export type QueryNewsCommentArgs = {
  id: Scalars['ID']
};


export type QueryNewsItemArgs = {
  id: Scalars['ID']
};


export type QueryNewsItemFeedArgs = {
  after?: Maybe<Scalars['String']>,
  filterBy?: Maybe<NewsItemFeedFilter>,
  first?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<NewsItemOrder>
};


export type QueryNewsItemFeedByTagIDsArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  tagIds: Array<Scalars['ID']>
};


export type QueryNewsItemsArgs = {
  filterBy?: Maybe<NewsItemFilter>,
  orderBy?: Maybe<NewsItemOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryNoticeNewsItemArgs = {
  channelId: Scalars['ID']
};


export type QueryNotificationArgs = {
  id: Scalars['ID']
};


export type QueryNotificationsUnreadCountByAuthUserIdArgs = {
  authUserID: Scalars['ID']
};


export type QueryOrganizationArgs = {
  id: Scalars['ID']
};


export type QueryOrganizationFromDartArgs = {
  corpCode: Scalars['String']
};


export type QueryOrganizationListFromDartArgs = {
  filterBy: OrganizationListFromDartFilterInput,
  pagination?: Maybe<Pagination>
};


export type QueryOrganizationTypeArgs = {
  id: Scalars['ID']
};


export type QueryOrganizationsArgs = {
  filterBy?: Maybe<OrganizationFilterInput>,
  orderBy?: Maybe<OrganizationOrderInput>,
  pagination?: Maybe<Pagination>
};


export type QueryOrganizationsByIdArgs = {
  ids: Array<Scalars['ID']>
};


export type QueryPersonaArgs = {
  id: Scalars['ID']
};


export type QueryProfileArgs = {
  id: Scalars['ID']
};


export type QueryProfileImageByAuthUserIdArgs = {
  id: Scalars['ID']
};


export type QueryQnaSnapshotsByQnaIdArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  qnaId: Scalars['ID']
};


export type QueryQuestionSuggestionArgs = {
  id: Scalars['ID']
};


export type QueryQuestionSuggestionsArgs = {
  filterBy?: Maybe<QuestionSuggestionFilters>,
  pagination?: Maybe<Pagination>
};


export type QueryRecommendChannelFeedArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>
};


export type QueryRecommendChannelsArgs = {
  pagination?: Maybe<Pagination>
};


export type QueryRecommendSearchKeywordArgs = {
  id: Scalars['ID']
};


export type QueryRecommendSearchKeywordListArgs = {
  type?: Maybe<RecommendSearchKeywordType>
};


export type QueryRegionArgs = {
  id: Scalars['ID']
};


export type QueryRegionDistrictArgs = {
  id: Scalars['ID']
};


export type QueryRegionsArgs = {
  filterBy?: Maybe<RegionsFilters>
};


export type QueryRepliesArgs = {
  filterBy?: Maybe<ReplyFilters>,
  orderBy?: Maybe<ReplyOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryReplyArgs = {
  id: Scalars['ID']
};


export type QueryReplyListArgs = {
  input: ReplyListInput
};


export type QueryReplyQnaArgs = {
  input: ReplyQnaInput
};


export type QueryReportedNewsCommentsArgs = {
  filterBy?: Maybe<ReportedNewsCommentFilter>,
  orderBy?: Maybe<NewsCommentOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryReportedNewsItemsArgs = {
  filterBy?: Maybe<ReportedNewsItemFilter>,
  orderBy?: Maybe<NewsItemOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryResumeArgs = {
  id: Scalars['ID']
};


export type QuerySchoolByNameArgs = {
  name: Scalars['String'],
  type: SchoolType
};


export type QuerySearchExactCoverLetterArgs = {
  field: CoverLetterField,
  query: Scalars['String']
};


export type QuerySearchMajorsArgs = {
  filterBy: SearchMajorFilters
};


export type QuerySearchSchoolsArgs = {
  filterBy: SearchSchoolFilters
};


export type QuerySearchTagsArgs = {
  tagName: Scalars['String']
};


export type QuerySearchUniqueNameOrganizationsArgs = {
  filterBy: SearchUniqueNameOrganizationFilter,
  orderBy?: Maybe<SearchUniqueNameOrganizationOrder>,
  pagination?: Maybe<Pagination>
};


export type QuerySearchViewerSelfIntroductionFeedArgs = {
  after?: Maybe<Scalars['String']>,
  filterBy?: Maybe<SearchSelfIntroductionFilters>,
  first?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<SelfIntroductionOrder>
};


export type QuerySelfIntroductionStatisticsArgs = {
  filterBy?: Maybe<SelfIntroductionStatisticFilters>,
  pagination?: Maybe<Pagination>
};


export type QuerySharedCareerProfileArgs = {
  id: Scalars['ID']
};


export type QuerySignupSessionArgs = {
  token: Scalars['ID']
};


export type QuerySignupSessionsArgs = {
  filterBy?: Maybe<SignupSessionFilters>,
  orderBy?: Maybe<SignupSessionOrder>,
  pagination?: Maybe<Pagination>
};


export type QuerySignupSessionsByTokenArgs = {
  tokens: Array<Scalars['ID']>
};


export type QuerySkillArgs = {
  id: Scalars['ID']
};


export type QuerySkillTagByNameArgs = {
  name: Scalars['String']
};


export type QueryTechniqueArgs = {
  id: Scalars['ID']
};


export type QueryTendencyArgs = {
  id: Scalars['ID']
};


export type QueryTopFollowingChannelsArgs = {
  pagination?: Maybe<Pagination>
};


export type QueryUniversityArgs = {
  id: Scalars['ID']
};


export type QueryUserArgs = {
  id: Scalars['ID']
};


export type QueryUserAuthArgs = {
  id: Scalars['ID']
};


export type QueryUserScrapArgs = {
  id: Scalars['ID']
};


export type QueryUserScrapsArgs = {
  filterBy: UserScrapsFilter,
  orderBy?: Maybe<UserScrapsOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryUserStatisticsArgs = {
  pagination?: Maybe<Pagination>
};


export type QueryUsersArgs = {
  filterBy?: Maybe<UserFilters>,
  orderBy?: Maybe<UserOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryUsersByEmailArgs = {
  email: Scalars['String']
};


export type QueryUsersByIdArgs = {
  ids: Array<Scalars['ID']>
};


export type QueryViewerActivityManagerPresetsArgs = {
  pagination?: Maybe<Pagination>
};


export type QueryViewerActivityOrganizationPresetsArgs = {
  pagination?: Maybe<Pagination>
};


export type QueryViewerBookmarksArgs = {
  pagination?: Maybe<Pagination>
};


export type QueryViewerCommentsArgs = {
  pagination?: Maybe<Pagination>
};


export type QueryViewerFilesArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  folderId?: Maybe<Scalars['ID']>,
  orderBy?: Maybe<SelfIntroductionOrder>
};


export type QueryViewerFolderArgs = {
  id: Scalars['ID']
};


export type QueryViewerFoldersArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<FolderOrder>
};


export type QueryViewerFollowersArgs = {
  filterBy?: Maybe<FollowerFilter>,
  pagination?: Maybe<Pagination>
};


export type QueryViewerFollowingFeedArgs = {
  after?: Maybe<Scalars['String']>,
  filterBy?: Maybe<FollowingFilter>,
  first?: Maybe<Scalars['Int']>
};


export type QueryViewerFollowingsArgs = {
  filterBy?: Maybe<FollowingFilter>,
  pagination?: Maybe<Pagination>
};


export type QueryViewerNewsItemFeedArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<ViewerNewsItemFeedOrder>
};


export type QueryViewerNotificationsArgs = {
  filterBy?: Maybe<NotificationsFilter>,
  pagination?: Maybe<Pagination>
};


export type QueryViewerSelfIntroductionArgs = {
  id: Scalars['ID']
};


export type QueryViewerSelfIntroductionFeedByDutyIdArgs = {
  after?: Maybe<Scalars['String']>,
  dutyId: Scalars['ID'],
  first?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<SelfIntroductionOrder>
};


export type QueryViewerSelfIntroductionFeedByQueryArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  query?: Maybe<Scalars['String']>
};


export type QueryViewerSelfIntroductionsArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<SelfIntroductionOrder>
};


export type QueryWorkfieldArgs = {
  id: Scalars['ID']
};

export type QuestionSuggestion = {
   __typename?: 'QuestionSuggestion',
  activity?: Maybe<Activity>,
  completedAt?: Maybe<Scalars['Date']>,
  content: Scalars['String'],
  createdAt: Scalars['Date'],
  email: Scalars['String'],
  id: Scalars['ID'],
  images: AttachmentConnection,
};


export type QuestionSuggestionImagesArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>
};

export type QuestionSuggestionConnection = {
   __typename?: 'QuestionSuggestionConnection',
  nodes: Array<Maybe<QuestionSuggestion>>,
  totalCount: Scalars['Int'],
};

export type QuestionSuggestionFilters = {
  activityTitle?: Maybe<Scalars['String']>,
  contentQuery?: Maybe<Scalars['String']>,
  dateRange?: Maybe<DateRange>,
  isCompleted?: Maybe<Scalars['Boolean']>,
};

export type QuestionTemplate = {
   __typename?: 'QuestionTemplate',
  blankType: BlankType,
  charMaxSize?: Maybe<Scalars['Int']>,
  charSize: CharSize,
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  duty: Duty,
  id: Scalars['ID'],
};

export type QuestionTemplateInput = {
  blankType?: Maybe<BlankType>,
  charMaxSize?: Maybe<Scalars['Int']>,
  charSize?: Maybe<CharSize>,
  content?: Maybe<Scalars['String']>,
};

export enum QuestionType {
  FREE = 'FREE',
  STRICT = 'STRICT'
}

export type ReadNotificationPayload = {
   __typename?: 'ReadNotificationPayload',
  notification?: Maybe<SimpleNotification>,
};

/** 신고된 채팅 메시지를 읽은 결과 */
export type ReadReportedChatMessagePayload = {
   __typename?: 'ReadReportedChatMessagePayload',
  /** 읽은 채팅 메시지 */
  chatMessage: ChatMessage,
};

/**  답변 대기중인 게시글 알림  */
export type RecommendedMentorPostNotification = SimpleNotification & {
   __typename?: 'RecommendedMentorPostNotification',
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  deepLinkUrl?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  linkUrl: Scalars['String'],
  title?: Maybe<Scalars['String']>,
  unread: Scalars['Boolean'],
  updatedAt?: Maybe<Scalars['Date']>,
};

export type RecommendSearchKeyword = {
   __typename?: 'RecommendSearchKeyword',
  createdAt: Scalars['Date'],
  id: Scalars['ID'],
  searchField?: Maybe<SearchKeywordFieldType>,
  seq?: Maybe<Scalars['Int']>,
  title: Scalars['String'],
  type?: Maybe<RecommendSearchKeywordType>,
  updatedAt?: Maybe<Scalars['Date']>,
};

export type RecommendSearchKeywordCreateInput = {
  searchField?: Maybe<SearchKeywordFieldType>,
  seq?: Maybe<Scalars['Int']>,
  title: Scalars['String'],
  type?: Maybe<RecommendSearchKeywordType>,
};

export type RecommendSearchKeywordCreatePayload = {
   __typename?: 'RecommendSearchKeywordCreatePayload',
  recommendSearchKeyword?: Maybe<RecommendSearchKeyword>,
};

export type RecommendSearchKeywordDeletePayload = {
   __typename?: 'RecommendSearchKeywordDeletePayload',
  recommendSearchKeyword?: Maybe<RecommendSearchKeyword>,
};

export type RecommendSearchKeywordListConnection = {
   __typename?: 'RecommendSearchKeywordListConnection',
  recommendSearchKeywords?: Maybe<Array<Maybe<RecommendSearchKeyword>>>,
  totalCount?: Maybe<Scalars['Int']>,
};

export enum RecommendSearchKeywordType {
  ACTIVITY = 'ACTIVITY',
  COVER_LETTER = 'COVER_LETTER',
  POPULAR_CHANNEL = 'POPULAR_CHANNEL',
  RECOMMEND_CHANNEL = 'RECOMMEND_CHANNEL'
}

export type RecommendSearchKeywordUpdateInput = {
  searchField?: Maybe<SearchKeywordFieldType>,
  seq?: Maybe<Scalars['Int']>,
  title?: Maybe<Scalars['String']>,
  type?: Maybe<RecommendSearchKeywordType>,
};

export type RecommendSearchKeywordUpdatePayload = {
   __typename?: 'RecommendSearchKeywordUpdatePayload',
  recommendSearchKeyword?: Maybe<RecommendSearchKeyword>,
};

export type RecoverNewsCommentsPayload = {
   __typename?: 'RecoverNewsCommentsPayload',
  newsComments?: Maybe<Array<Maybe<NewsComment>>>,
};

export type RecoverNewsItemsPayload = {
   __typename?: 'RecoverNewsItemsPayload',
  newsItems?: Maybe<Array<Maybe<NewsItem>>>,
};

export type RecruitFilterCollection = {
   __typename?: 'RecruitFilterCollection',
  categories: Array<Category>,
  jobTypes: Array<RecuritJogType>,
  organizationTypes: Array<OrganizationType>,
  regions: Array<Region>,
};

export type RecruitInformation = {
   __typename?: 'RecruitInformation',
  /** 우대 조건 */
  beneficialCondition?: Maybe<Scalars['String']>,
  /** 계약 기간 (월 단위) */
  contractDuration?: Maybe<Scalars['Int']>,
  createdAt: Scalars['Date'],
  /** 직무 */
  duty: Duty,
  id: Scalars['ID'],
  /** 인턴 세분화 타입(배열) */
  internTypes: Array<ActivityInternType>,
  /** 직급 */
  jobPositions?: Maybe<Array<JobPosition>>,
  /** 직책 */
  jobRanks?: Maybe<Array<JobRank>>,
  /** 담당 업무 */
  jobResponsibility?: Maybe<Scalars['String']>,
  jobType?: Maybe<JobType>,
  /** 채용 인원 */
  recruitScale: Scalars['String'],
};

export type RecruitInformationInput = {
  /** 우대 사항 */
  beneficialCondition?: Maybe<Scalars['String']>,
  /** 계약 기간 (월 단위) */
  contractDuration?: Maybe<Scalars['Int']>,
  duty: DutyInput,
  /** 인턴 타입 아이디 배열 */
  internTypes?: Maybe<Array<Scalars['ID']>>,
  /** 직무 ID */
  jobPositionIds?: Maybe<Array<Scalars['ID']>>,
  /** 직책 ID */
  jobRankIds?: Maybe<Array<Scalars['ID']>>,
  /** 담당 업무 */
  jobResponsibility?: Maybe<Scalars['String']>,
  /** 모집 인원 */
  recruitScale: Scalars['String'],
};

export type RecruitManagerInput = {
  /** 담당자 부서 */
  department: Scalars['String'],
  /** 담당자 이메일 */
  email: Scalars['String'],
  /** 담당자 이메일 공개 여부 */
  isManagerEmailVisible: Scalars['Boolean'],
  /** 담당자 전화번호 공개 여부 */
  isManagerPhoneNumberVisible: Scalars['Boolean'],
  /** 담당자 이름 */
  name: Scalars['String'],
  /** 담당자 전화번호 */
  phoneNumber: Scalars['String'],
  /** 현재 담당자 정보 저장 여부 */
  saveManagerInformation: Scalars['Boolean'],
};

export type RecruitOrganizationInput = {
  /** 홈페이지 주소 */
  homepageUrl?: Maybe<Scalars['String']>,
  /** 업종 */
  industries: Array<Scalars['ID']>,
  /** 로고 */
  logo: ActivityOrganizationLogoInput,
  /** 기업명 */
  name: Scalars['String'],
  /** 기업구분 */
  organizationTypeID: Scalars['ID'],
  /** 현재 기업 정보 저장 여부 */
  saveOrganizationInformation: Scalars['Boolean'],
  /** 복리 후생 */
  welfareBenefits?: Maybe<Array<Scalars['ID']>>,
};

export enum RecruitOrganizationType {
  BANK = 'BANK',
  CLUB = 'CLUB',
  ETC = 'ETC',
  FOREIGN_COMPANIES = 'FOREIGN_COMPANIES',
  HOSPITAL = 'HOSPITAL',
  MAJOR_COMPANY = 'MAJOR_COMPANY',
  MIDSIZE_BUSINESS = 'MIDSIZE_BUSINESS',
  NON_PROFIT_ORGANIZATION = 'NON_PROFIT_ORGANIZATION',
  PUBLIC_INSTITUTIONS = 'PUBLIC_INSTITUTIONS',
  SMALL_BUSINESS = 'SMALL_BUSINESS',
  START_UP = 'START_UP'
}

export type RecruitProcedureInput = {
  /** 지원양식 및 추가안내 자료 */
  applicationFileAttachmentIds?: Maybe<Array<Scalars['ID']>>,
  /** 지원 절차 */
  applicationProcedures?: Maybe<Array<ApplicationProcedureInput>>,
  /** 접수 방식 상세 */
  applyDetail?: Maybe<Scalars['String']>,
  /** 접수 방식 */
  applyTypeId: Scalars['ID'],
  /** 지원 절차가 보여지는지 여부 */
  isApplicationProcedureVisible?: Maybe<Scalars['Boolean']>,
  /** 채용 마감일 */
  recruitCloseAt?: Maybe<Scalars['Date']>,
  /** 채용 시작일 */
  recruitStartAt?: Maybe<Scalars['Date']>,
  /** 채용 기간의 유형 */
  recruitType: ActivityRecruitType,
};

export enum RecruitScaleType {
  HUNDRED_DIGIT = 'HUNDRED_DIGIT',
  INPUT_NUMBER = 'INPUT_NUMBER',
  ONE_DIGIT = 'ONE_DIGIT',
  TEN_DIGIT = 'TEN_DIGIT'
}

export type RecuritJogType = {
   __typename?: 'RecuritJogType',
  id: Scalars['ID'],
  name: Scalars['String'],
};

export type RefreshAccessTokenPayload = {
   __typename?: 'RefreshAccessTokenPayload',
  accessToken?: Maybe<Scalars['String']>,
  expiresAt?: Maybe<Scalars['Date']>,
  refreshToken?: Maybe<Scalars['String']>,
};

export type Region = {
   __typename?: 'Region',
  districts?: Maybe<Array<Maybe<RegionDistrict>>>,
  highschools?: Maybe<Array<Maybe<Highschool>>>,
  id: Scalars['ID'],
  level?: Maybe<Scalars['Int']>,
  /** 지역 이름 */
  name: Scalars['String'],
  universities?: Maybe<Array<Maybe<University>>>,
  users?: Maybe<Array<Maybe<User>>>,
};

export type RegionDistrict = {
   __typename?: 'RegionDistrict',
  highschools?: Maybe<Array<Maybe<Highschool>>>,
  id: Scalars['ID'],
  /** 지역구 이름 */
  name: Scalars['String'],
  region: Region,
};

export type RegionsFilters = {
  excludeUnrestricted?: Maybe<Scalars['Boolean']>,
};

export type RemoveAllBookmarkPayload = {
   __typename?: 'RemoveAllBookmarkPayload',
  count: Scalars['Int'],
};

export type RemoveBookmarkPayload = {
   __typename?: 'RemoveBookmarkPayload',
  newsItem?: Maybe<NewsItem>,
};

export type RemoveBookmarksPayload = {
   __typename?: 'RemoveBookmarksPayload',
  newsItems: Array<Maybe<NewsItem>>,
};

export type RemoveCommunityBookmarkPayLoad = {
   __typename?: 'removeCommunityBookmarkPayLoad',
  bookmarkable?: Maybe<CommunityBookmarkable>,
};

export type RemoveCommunityMenuBookmarkPayLoad = {
   __typename?: 'removeCommunityMenuBookmarkPayLoad',
  bookmarkable?: Maybe<CommunityBookmarkable>,
};

export type RemoveLikePayload = {
   __typename?: 'RemoveLikePayload',
  newsItem?: Maybe<NewsItem>,
};

/** 고정할 채팅방 순서 입력 데이터 */
export type ReorderChatRoomsOnTopInput = {
  /** 고정할 순서에 따른 채팅방 id 리스트 */
  chatRoomIds: Array<Scalars['ID']>,
};

/** 고정된 채팅방 순서 업데이트 결과 */
export type ReorderChatRoomsOnTopPayload = {
   __typename?: 'ReorderChatRoomsOnTopPayload',
  /** 고정된 순서에 따른 채팅방 리스트 */
  chatRooms: Array<ChatRoom>,
};

export type Reply = {
   __typename?: 'Reply',
  activity?: Maybe<Activity>,
  avatarUrl?: Maybe<Scalars['String']>,
  content: Scalars['String'],
  createdAt: Scalars['Date'],
  hasLiked: Scalars['Boolean'],
  id: Scalars['ID'],
  isDeleted: Scalars['Boolean'],
  isManagerReply: Scalars['Boolean'],
  isOwnerReply: Scalars['Boolean'],
  name?: Maybe<Scalars['String']>,
  parent?: Maybe<Reply>,
  replies?: Maybe<Array<Maybe<Reply>>>,
  user?: Maybe<User>,
};


export type ReplyRepliesArgs = {
  pagination?: Maybe<Pagination>
};

export type ReplyConnection = {
   __typename?: 'ReplyConnection',
  nodes: Array<Reply>,
  totalCount: Scalars['Int'],
};

export type ReplyCreateInput = {
  content: Scalars['String'],
  pageID: Scalars['Int'],
  pageType: Scalars['Int'],
  replyID?: Maybe<Scalars['Int']>,
};

export type ReplyCreatePayload = {
   __typename?: 'ReplyCreatePayload',
  reply?: Maybe<Reply>,
};

export type ReplyDeleteInput = {
  replyID: Scalars['ID'],
};

export type ReplyDeletePayload = {
   __typename?: 'ReplyDeletePayload',
  reply?: Maybe<Reply>,
};

export type ReplyFilters = {
  activityID?: Maybe<Scalars['ID']>,
  /** no need to use */
  id?: Maybe<Scalars['ID']>,
  /** use activityID */
  pageID?: Maybe<Scalars['ID']>,
  /** no need to use */
  pageType?: Maybe<Scalars['Int']>,
  parentID?: Maybe<Scalars['ID']>,
  q?: Maybe<Scalars['String']>,
};

export type ReplyLike = {
   __typename?: 'ReplyLike',
  createdAt: Scalars['Date'],
  id: Scalars['ID'],
  reply?: Maybe<Reply>,
  user?: Maybe<User>,
};

export type ReplyLikeInput = {
  liked: Scalars['Boolean'],
  replyID: Scalars['ID'],
};

export type ReplyLikePayload = {
   __typename?: 'ReplyLikePayload',
  replyLike?: Maybe<ReplyLike>,
};

export type ReplyListInput = {
  limit: Scalars['Int'],
  offset: Scalars['Int'],
  pageID: Scalars['ID'],
  pageType: Scalars['Int'],
};

export type ReplyListPayload = {
   __typename?: 'ReplyListPayload',
  count: Scalars['Int'],
  replies?: Maybe<Array<Reply>>,
};

export type ReplyOrder = {
  direction: OrderDirection,
  field: ReplyOrderField,
};

export enum ReplyOrderField {
  CREATED_AT = 'CREATED_AT',
  ID = 'ID'
}

export type ReplyQnaInput = {
  activityStatus?: Maybe<ActivityStatus>,
  replyCount?: Maybe<Scalars['Int']>,
  withClosed?: Maybe<Scalars['Boolean']>,
};

/**  공고 댓글 알림  */
export type ReplyToActivityNotification = SimpleNotification & {
   __typename?: 'ReplyToActivityNotification',
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  deepLinkUrl?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  linkUrl: Scalars['String'],
  reply?: Maybe<Reply>,
  title?: Maybe<Scalars['String']>,
  unread: Scalars['Boolean'],
  updatedAt?: Maybe<Scalars['Date']>,
};

/**  공고 답글 알림 */
export type ReplyToReplyNotification = SimpleNotification & {
   __typename?: 'ReplyToReplyNotification',
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  deepLinkUrl?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  linkUrl: Scalars['String'],
  reply?: Maybe<Reply>,
  title?: Maybe<Scalars['String']>,
  unread: Scalars['Boolean'],
  updatedAt?: Maybe<Scalars['Date']>,
};

export type Reportable = {
  lastReportReason?: Maybe<Scalars['String']>,
  lastReportedAt?: Maybe<Scalars['Date']>,
  reportCount: Scalars['Int'],
  viewerHasReported: Scalars['Boolean'],
};

/** 채팅방 메시지 신고 입력 데이터 */
export type ReportChatMessageInput = {
  /** 신고 사유 */
  reason: Scalars['String'],
};

/** 채팅방 메시지 신고 결과 */
export type ReportChatMessagePayload = {
   __typename?: 'ReportChatMessagePayload',
  /** 신고한 채팅방 메시지 */
  chatMessage: ChatMessage,
};

/** 
 * 신고된 채팅 메시지 검색 필드
 * 
 * - CONTENT: 채팅 메시지 내용
 * - CHAT_NICKNAME: 채팅 메시지를 보낸 유저의 닉네임
 * - CHAT_ROOM_NAME: 채팅 메시지가 전송된 채팅방 이름
 **/
export enum ReportedChatMessageSearchField {
  CONTENT = 'CONTENT',
  CHAT_NICKNAME = 'CHAT_NICKNAME',
  CHAT_ROOM_NAME = 'CHAT_ROOM_NAME'
}

/** 신고된 채팅 검색 조건 */
export type ReportedChatMessageSearchInput = {
  /** 검색 필드 */
  searchField: ReportedChatMessageSearchField,
  /** 검색 키워드 */
  searchKeyword: Scalars['String'],
};

export type ReportedNewsCommentFilter = {
  lastReportedAtRange?: Maybe<DateRange>,
  q?: Maybe<Scalars['String']>,
};

export type ReportedNewsItemFilter = {
  lastReportedAtRange?: Maybe<DateRange>,
  q?: Maybe<Scalars['String']>,
};

/** 신고된 이유와 그 이유로 신고된 횟수 */
export type ReportedReasonAndCount = {
   __typename?: 'ReportedReasonAndCount',
  /** 신고된 이유 */
  reason: Scalars['String'],
  /** 신고된 횟수 */
  count: Scalars['Int'],
};

export type ReportInput = {
  reason?: Maybe<Scalars['String']>,
  /**  newsItem 또는 newsComment Id  */
  reportedId: Scalars['ID'],
};

export type ReportPayload = {
   __typename?: 'ReportPayload',
  result?: Maybe<Reportable>,
};

export type Resume = {
   __typename?: 'Resume',
  address?: Maybe<Scalars['String']>,
  birthday?: Maybe<Scalars['Date']>,
  certifications?: Maybe<Array<Maybe<Certification>>>,
  createdAt: Scalars['Date'],
  education: EducationType,
  email?: Maybe<Scalars['String']>,
  examedAt?: Maybe<Scalars['Date']>,
  gender?: Maybe<Gender>,
  highschools?: Maybe<Array<Maybe<HighschoolProfile>>>,
  id: Scalars['ID'],
  isFirst: Scalars['Boolean'],
  isMain: Scalars['Boolean'],
  isPC: Scalars['Boolean'],
  languages?: Maybe<Array<Maybe<Language>>>,
  name?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<Scalars['String']>,
  speciality?: Maybe<Scalars['String']>,
  status: Scalars['Boolean'],
  techniques?: Maybe<Array<Maybe<Technique>>>,
  tendencies?: Maybe<Array<Maybe<Tendency>>>,
  title: Scalars['String'],
  updatedAt: Scalars['Date'],
  user: User,
};

export type Reward = {
   __typename?: 'Reward',
  from?: Maybe<Scalars['Int']>,
  id: Scalars['ID'],
  name: Scalars['String'],
  to?: Maybe<Scalars['Int']>,
};

export type RollbackQnASnapshotPayload = {
   __typename?: 'RollbackQnASnapshotPayload',
  snapshot?: Maybe<QnASnapshot>,
};

export type SaveFcmTokenInput = {
  appVersion?: Maybe<Scalars['String']>,
  platform: Platform,
  token: Scalars['String'],
};

export type SaveFcmTokenPayload = {
   __typename?: 'SaveFcmTokenPayload',
  /** 토큰 생성일 */
  createdAt?: Maybe<Scalars['Date']>,
  /** 저장된 fcm Token. SaveFcmTokenInput의 token과 같음 */
  token?: Maybe<Scalars['String']>,
  /** 
 * 토큰 수정일
   * 이미 저장된 토큰은 updatedAt만 변경됨
 **/
  updatedAt?: Maybe<Scalars['Date']>,
};

export type School = {
   __typename?: 'School',
  id: Scalars['ID'],
  name: Scalars['String'],
};

export enum SchoolStatus {
  BEING_SCHOOL = 'BEING_SCHOOL',
  DROP_SCHOOL = 'DROP_SCHOOL',
  GRADUATE = 'GRADUATE',
  REST_SCHOOL = 'REST_SCHOOL',
  SOON = 'SOON'
}

export enum SchoolType {
  HIGH_SCHOOL = 'HIGH_SCHOOL',
  UNIVERSITY = 'UNIVERSITY'
}

/**  스크랩한 공고 마감 1일전 알림  */
export type ScrapActivityCloseSoonerNotification = SimpleNotification & {
   __typename?: 'ScrapActivityCloseSoonerNotification',
  activity?: Maybe<Activity>,
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  deepLinkUrl?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  linkUrl: Scalars['String'],
  title?: Maybe<Scalars['String']>,
  unread: Scalars['Boolean'],
  updatedAt?: Maybe<Scalars['Date']>,
};

/**  스크랩한 공고 마갑 3일전 알림  */
export type ScrapActivityCloseSoonNotification = SimpleNotification & {
   __typename?: 'ScrapActivityCloseSoonNotification',
  activity?: Maybe<Activity>,
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  deepLinkUrl?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  linkUrl: Scalars['String'],
  title?: Maybe<Scalars['String']>,
  unread: Scalars['Boolean'],
  updatedAt?: Maybe<Scalars['Date']>,
};

export type ScrapActivityFilter = {
  /** 공고명/기업명 */
  q?: Maybe<Scalars['String']>,
};

export type SearchChatRoomsByNameWithOffsetFilterBy = {
  /** 
 * 채팅방의 activityTypeID.
   * 
   * - 채용: 5
   * - 대외활동: 1
   * - 공모전: 3
 **/
  activityTypeID?: Maybe<Scalars['Int']>,
  /** 상단 고정된 채팅방만 불러오기 */
  fixedChatRoom?: Maybe<Scalars['Boolean']>,
};

export type SearchExactCoverLetterPayload = {
   __typename?: 'SearchExactCoverLetterPayload',
  count: Scalars['Int'],
  query?: Maybe<Scalars['String']>,
};

export enum SearchKeywordFieldType {
  KEYWORD = 'KEYWORD',
  ORGANIZATION_NAME = 'ORGANIZATION_NAME',
  ROLE = 'ROLE'
}

export type SearchMajorFilters = {
  query: Scalars['String'],
};

export enum SearchPostFromBoardWordField {
  CONTENT = 'CONTENT',
  TITLE = 'TITLE',
  TITLE_WITH_CONTENT = 'TITLE_WITH_CONTENT'
}

export type SearchSchoolFilters = {
  query: Scalars['String'],
  type?: Maybe<SchoolType>,
};

export type SearchSelfIntroductionFilters = {
  searchText?: Maybe<Scalars['String']>,
};

export type SearchUniqueNameOrganizationFilter = {
  includeGroup?: Maybe<Scalars['Boolean']>,
  query: Scalars['String'],
};

export type SearchUniqueNameOrganizationOrder = {
  direction: OrderDirection,
  field: SearchUniqueNameOrganizationOrderField,
};

export enum SearchUniqueNameOrganizationOrderField {
  RELEVANCE = 'RELEVANCE',
  SCORE = 'SCORE'
}

export type SelfIntroduction = {
   __typename?: 'SelfIntroduction',
  activity?: Maybe<Activity>,
  author?: Maybe<User>,
  createdAt: Scalars['Date'],
  deletedAt?: Maybe<Scalars['Date']>,
  duty?: Maybe<Duty>,
  folder?: Maybe<Folder>,
  id: Scalars['ID'],
  qna?: Maybe<Array<QnA>>,
  title?: Maybe<Scalars['String']>,
  updatedAt?: Maybe<Scalars['Date']>,
};

export type SelfIntroductionConnection = {
   __typename?: 'SelfIntroductionConnection',
  edges: Array<SelfIntroductionEdge>,
  nodes: Array<Maybe<SelfIntroduction>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type SelfIntroductionEdge = {
   __typename?: 'SelfIntroductionEdge',
  cursor: Scalars['String'],
  node: SelfIntroduction,
};

export type SelfIntroductionFilters = {
  dutyId?: Maybe<Scalars['ID']>,
  folderId?: Maybe<Scalars['ID']>,
};

export type SelfIntroductionOrder = {
  direction: OrderDirection,
  field: SelfIntroductionOrderField,
};

export enum SelfIntroductionOrderField {
  CREATED_AT = 'CREATED_AT',
  EXPIRED_AT = 'EXPIRED_AT',
  UPDATED_AT = 'UPDATED_AT'
}

export type SelfIntroductionStatistic = {
   __typename?: 'SelfIntroductionStatistic',
  activityCount: Scalars['Int'],
  activityWithQuestionCount: Scalars['Int'],
  createdAt: Scalars['Date'],
  date: Scalars['String'],
  id: Scalars['ID'],
  selfIntroductionCount: Scalars['Int'],
  selfIntroductionUserCount: Scalars['Int'],
};

export type SelfIntroductionStatisticConnection = {
   __typename?: 'SelfIntroductionStatisticConnection',
  nodes: Array<SelfIntroductionStatistic>,
  totalCount: Scalars['Int'],
};

export type SelfIntroductionStatisticFilters = {
  dateRange?: Maybe<DateRange>,
  dateTerm?: Maybe<DateTerm>,
};

export type SelfIntroductionStatus = {
   __typename?: 'SelfIntroductionStatus',
  totalCount: Scalars['Int'],
};

export type SendJobPostingDataToNaverPayload = {
   __typename?: 'SendJobPostingDataToNaverPayload',
  data?: Maybe<Scalars['String']>,
  totalCount?: Maybe<Scalars['Int']>,
};

export type SendMailSelfIntroductionInput = {
  receiverAddress: Scalars['String'],
  selfIntroductionId: Scalars['ID'],
  senderAddress: Scalars['String'],
};

export type SendMailSelfIntroductionPayload = {
   __typename?: 'SendMailSelfIntroductionPayload',
  selfIntroduction?: Maybe<SelfIntroduction>,
};

export type SetNewsItemAsNoticePayload = {
   __typename?: 'SetNewsItemAsNoticePayload',
  newsItem?: Maybe<NewsItem>,
};

export type SetNoticeAsNewsItemPayload = {
   __typename?: 'SetNoticeAsNewsItemPayload',
  newsItem?: Maybe<NewsItem>,
};

/** 채팅방 공유 입력 데이터 */
export type ShareChatRoomInput = {
  /** 공유할 플랫폼 */
  platform: SharePlatform,
};

/** 채팅방 공유 결과 */
export type ShareChatRoomPayload = {
   __typename?: 'ShareChatRoomPayload',
  /** 공유한 채팅방 */
  chatRoom: ChatRoom,
};

/** 
 * 공유 가능 플랫폼 이름
 * 
 * - FACEBOOK: 페이스북
 * - TWITTER: 트위터
 * - NAVER: 네이버
 * - NAVERBAND: 네이버 밴드
 * - KAKAOSTORY: 카카오스토리
 * - KAKAO: 카카오
 * - URL: URL
 **/
export enum SharePlatform {
  FACEBOOK = 'FACEBOOK',
  TWITTER = 'TWITTER',
  NAVER = 'NAVER',
  NAVERBAND = 'NAVERBAND',
  KAKAOSTORY = 'KAKAOSTORY',
  KAKAO = 'KAKAO',
  URL = 'URL'
}

export enum SharePlatFormType {
  FACEBOOK = 'FACEBOOK',
  KAKAO = 'KAKAO',
  KAKAOSTORY = 'KAKAOSTORY',
  NAVER = 'NAVER',
  NAVERBAND = 'NAVERBAND',
  TWITTER = 'TWITTER',
  URL = 'URL'
}

export type SignupSession = {
   __typename?: 'SignupSession',
  createdAt?: Maybe<Scalars['Date']>,
  defaultEmail?: Maybe<Scalars['String']>,
  defaultNickname?: Maybe<Scalars['String']>,
  displayName?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  ipAddress?: Maybe<Scalars['String']>,
  profileURL?: Maybe<Scalars['String']>,
  signup: Scalars['Boolean'],
  signupAt?: Maybe<Scalars['Date']>,
  signupElapsedTimeInSeconds?: Maybe<Scalars['Int']>,
  signupSession?: Maybe<Scalars['String']>,
  signupUser?: Maybe<User>,
  snsID?: Maybe<Scalars['String']>,
  snsType?: Maybe<Scalars['String']>,
  timespread?: Maybe<TimespreadAccount>,
  token?: Maybe<Scalars['String']>,
  user?: Maybe<User>,
};

export type SignupSessionConnection = {
   __typename?: 'SignupSessionConnection',
  nodes?: Maybe<Array<Maybe<SignupSession>>>,
  totalCount: Scalars['Int'],
};

export type SignupSessionFilters = {
  q?: Maybe<Scalars['String']>,
};

export type SignupSessionOrder = {
  direction: OrderDirection,
  field: SignupSessionOrderField,
};

export enum SignupSessionOrderField {
  CREATED_AT = 'CREATED_AT'
}

export type SimpleNotification = {
  content?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  deepLinkUrl?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  linkUrl: Scalars['String'],
  title?: Maybe<Scalars['String']>,
  unread: Scalars['Boolean'],
  updatedAt?: Maybe<Scalars['Date']>,
};

export type Skill = {
   __typename?: 'Skill',
  id: Scalars['ID'],
  name: Scalars['String'],
  seq?: Maybe<Scalars['Int']>,
  users?: Maybe<Array<Maybe<User>>>,
};

export type SkillTag = {
   __typename?: 'SkillTag',
  id: Scalars['ID'],
  /** 태그명 */
  name: Scalars['String'],
};

export type SkipSignupSessionInput = {
  signupToken: Scalars['String'],
};

export type SkipSignupSessionPayload = {
   __typename?: 'SkipSignupSessionPayload',
  user?: Maybe<User>,
};

export enum SnsType {
  FACEBOOK = 'FACEBOOK',
  KAKAO = 'KAKAO',
  NAVER = 'NAVER'
}

export type SpellCheckInput = {
  text: Scalars['String'],
};

export type SpellCheckPayload = {
   __typename?: 'SpellCheckPayload',
  result: SpellCheckResult,
};

export type SpellCheckResult = {
   __typename?: 'SpellCheckResult',
  errorWords: Array<ErrorWord>,
  originalSentence: Scalars['String'],
};

export type SpellCheckWithAutoSplitInput = {
  text: Scalars['String'],
};

export type SpellCheckWithAutoSplitPayload = {
   __typename?: 'SpellCheckWithAutoSplitPayload',
  result: Array<SpellCheckResult>,
};

export type Suggestion = {
   __typename?: 'Suggestion',
  attachments: AttachmentConnection,
  content: Scalars['String'],
  createdAt: Scalars['Date'],
  deviceInfo: DeviceType,
  email: Scalars['String'],
};

export type Tag = {
   __typename?: 'Tag',
  id: Scalars['ID'],
  mentionCount: Scalars['Int'],
  name: Scalars['String'],
};

export type TagCollection = {
   __typename?: 'TagCollection',
  createdAt: Scalars['Date'],
  id: Scalars['ID'],
  /** 정렬기준 */
  seq?: Maybe<Scalars['Int']>,
  tags: TagConnection,
  title: Scalars['String'],
  updatedAt?: Maybe<Scalars['Date']>,
};

export type TagConnection = {
   __typename?: 'TagConnection',
  edges: Array<TagEdge>,
  nodes: Array<Tag>,
  totalCount: Scalars['Int'],
};

export type TagEdge = {
   __typename?: 'TagEdge',
  cursor: Scalars['String'],
  node?: Maybe<Tag>,
};

export type Technique = {
   __typename?: 'Technique',
  count: Scalars['Int'],
  id: Scalars['ID'],
  isConfirmed: Scalars['Boolean'],
  tech: Scalars['String'],
  workfield?: Maybe<Workfield>,
};

export type Tendency = {
   __typename?: 'Tendency',
  content: Scalars['String'],
  count: Scalars['Int'],
  id: Scalars['ID'],
};

export type TimespreadAccount = {
   __typename?: 'TimespreadAccount',
  birthday?: Maybe<Scalars['Date']>,
  cash?: Maybe<Scalars['Int']>,
  email?: Maybe<Scalars['String']>,
  facebookID?: Maybe<Scalars['ID']>,
  id: Scalars['ID'],
  kakaoID?: Maybe<Scalars['ID']>,
  name?: Maybe<Scalars['String']>,
  naverID?: Maybe<Scalars['ID']>,
  phoneNumber?: Maybe<Scalars['String']>,
  profileURL?: Maybe<Scalars['String']>,
};

export enum TimeSpreadApiResponseType {
  LUCKYBOX_ALREADY_OPEN = 'LUCKYBOX_ALREADY_OPEN',
  LUCKYBOX_LIMIT_EXCEED = 'LUCKYBOX_LIMIT_EXCEED',
  LUCKYBOX_NEED_CASH = 'LUCKYBOX_NEED_CASH',
  OBJECT_NOT_EXIST = 'OBJECT_NOT_EXIST',
  OK = 'OK',
  PHONE_ALREADY_AUTHORIZE = 'PHONE_ALREADY_AUTHORIZE',
  PHONE_CODE_DATA_NOT_FOUND = 'PHONE_CODE_DATA_NOT_FOUND',
  PHONE_CODE_REQUEST_EXCEED = 'PHONE_CODE_REQUEST_EXCEED',
  PHONE_FAIL_AUTHORIZE = 'PHONE_FAIL_AUTHORIZE',
  PHONE_NOT_ACCEPTED_IP = 'PHONE_NOT_ACCEPTED_IP',
  PHONE_REQUEST_EXCEED = 'PHONE_REQUEST_EXCEED',
  PHONE_USER_DELETE = 'PHONE_USER_DELETE',
  REQUIRE_PARAMETER = 'REQUIRE_PARAMETER',
  SCREEN_ALREADY_FINISH = 'SCREEN_ALREADY_FINISH'
}

export type TokenSet = {
   __typename?: 'TokenSet',
  accessToken?: Maybe<Scalars['String']>,
  expiresAt?: Maybe<Scalars['Date']>,
  refreshToken?: Maybe<Scalars['String']>,
  signupToken?: Maybe<Scalars['String']>,
};

export type TopFollowingChannelConnection = {
   __typename?: 'TopFollowingChannelConnection',
  edges: Array<TopFollowingChannelEdge>,
  nodes: Array<Maybe<Channel>>,
  pageInfo: PageInfo,
  totalCount: Scalars['Int'],
};

export type TopFollowingChannelEdge = {
   __typename?: 'TopFollowingChannelEdge',
  cursor: Scalars['String'],
  dailyFollowerCount: Scalars['Int'],
  daliyFollowerCount: Scalars['Int'],
  node: Channel,
};

/** 활동 정지를 해제한 결과 */
export type UnbanUsersInChatPayload = {
   __typename?: 'UnbanUsersInChatPayload',
  /** 정지 해제된 활동 정지 로그 */
  userBans: Array<UserBanInChat>,
};

export type UnFollowPayload = {
   __typename?: 'UnFollowPayload',
  channel?: Maybe<Channel>,
};

export type UniqueNameOrganization = {
   __typename?: 'UniqueNameOrganization',
  createdAt: Scalars['Date'],
  group?: Maybe<UniqueNameOrganizationGroup>,
  id: Scalars['ID'],
  isGroup?: Maybe<Scalars['Boolean']>,
  name: Scalars['String'],
  updatedAt?: Maybe<Scalars['Date']>,
};

export type UniqueNameOrganizationConnection = {
   __typename?: 'UniqueNameOrganizationConnection',
  nodes: Array<UniqueNameOrganization>,
  totalCount: Scalars['Int'],
};

export type UniqueNameOrganizationGroup = {
   __typename?: 'UniqueNameOrganizationGroup',
  id: Scalars['ID'],
  name: Scalars['String'],
};

export type University = {
   __typename?: 'University',
  count: Scalars['Int'],
  id: Scalars['ID'],
  isConfirmed: Scalars['Boolean'],
  name: Scalars['String'],
  region?: Maybe<Region>,
};

export type UnmuteUserInput = {
  mutedUserId: Scalars['ID'],
  newsCommentId?: Maybe<Scalars['ID']>,
};

export type UnmuteUserPayload = {
   __typename?: 'UnmuteUserPayload',
  newsComment?: Maybe<NewsComment>,
  user?: Maybe<User>,
};

export type UpdateAdInput = {
  adPlacementId?: Maybe<Scalars['ID']>,
  advertiser?: Maybe<Scalars['String']>,
  content?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  image?: Maybe<Scalars['Upload']>,
  order?: Maybe<Scalars['Int']>,
  relatedId?: Maybe<Scalars['ID']>,
  relationType?: Maybe<AdRelationType>,
  title?: Maybe<Scalars['String']>,
};

export type UpdateAdPlacementInput = {
  code?: Maybe<Scalars['String']>,
  defaultImage?: Maybe<Scalars['Upload']>,
  defaultLandingUrl?: Maybe<Scalars['String']>,
  height?: Maybe<Scalars['Int']>,
  id: Scalars['ID'],
  isActive?: Maybe<Scalars['Boolean']>,
  name?: Maybe<Scalars['String']>,
  width?: Maybe<Scalars['Int']>,
};

export type UpdateAdPlacementPayload = {
   __typename?: 'UpdateAdPlacementPayload',
  adPlacement: AdPlacement,
};

export type UpdateCampaignInput = {
  adInputs?: Maybe<Array<UpdateAdInput>>,
  content?: Maybe<Scalars['String']>,
  endAt?: Maybe<Scalars['Date']>,
  id: Scalars['ID'],
  isLandingDisabled?: Maybe<Scalars['Boolean']>,
  landingUrl?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  startAt?: Maybe<Scalars['Date']>,
};

export type UpdateCampaignPayload = {
   __typename?: 'UpdateCampaignPayload',
  campaign: Campaign,
};

/** 커리어 취업 우대사항 업데이트할 때 보내야하는 객체 */
export type UpdateCareerAdvantageConditionInput = {
  /** 커리어 프로필 아이디 */
  careerProfileId?: Maybe<Scalars['ID']>,
  /** 고용 지원금 대상 아이디 리스트 */
  employmentSupportTargetIds?: Maybe<Array<Scalars['ID']>>,
  /** 장애 등급 */
  handicapLevel?: Maybe<Scalars['Int']>,
  /** 고용 지원금 대상 여부 */
  isEmploymentSupportTarget?: Maybe<Scalars['Boolean']>,
  /** 장애 여부 */
  isHandicap?: Maybe<Scalars['Boolean']>,
  /** 병역 대상 여부 */
  isMilitary?: Maybe<Scalars['Boolean']>,
  /** 공개 여부 */
  isPublic?: Maybe<Scalars['Boolean']>,
  /** 보훈 대상 여부 */
  isVeteran?: Maybe<Scalars['Boolean']>,
  /** 작성 여부 */
  isWritten?: Maybe<Scalars['Boolean']>,
  /** 전역 년월 */
  militaryEndYearMonth?: Maybe<Scalars['Int']>,
  /** 군 계급 */
  militaryRank?: Maybe<MilitaryRank>,
  /** 입대 년월 */
  militaryStartYearMonth?: Maybe<Scalars['Int']>,
  /** 전역 사유 */
  militaryStatus?: Maybe<MilitaryStatus>,
  /** 병역 대상 */
  militaryTarget?: Maybe<MilitaryTarget>,
  /** 군별 */
  militaryType?: Maybe<MilitaryType>,
  /** 보훈 대상 사유 */
  veteranReason?: Maybe<Scalars['String']>,
};

/** 커리어 취업 우대사항 업데이트할 때 반환하는 객체 */
export type UpdateCareerAdvantageConditionPayload = {
   __typename?: 'UpdateCareerAdvantageConditionPayload',
  careerAdvantageCondition: CareerAdvantageCondition,
};

/** 커리어 수상 업데이트할 때 보내야하는 객체 */
export type UpdateCareerAwardInput = {
  /** 커리어 프로필 아이디 */
  careerProfileId?: Maybe<Scalars['ID']>,
  /** 공개 여부 */
  isPublic?: Maybe<Scalars['Boolean']>,
  /** 작성 여부 */
  isWritten?: Maybe<Scalars['Boolean']>,
  /** 프로필 항목 리스트 */
  itemInputs: Array<UpdateCareerAwardItemInput>,
};

/** 커리어 수상 항목 업데이트할 때 보내야하는 객체 */
export type UpdateCareerAwardItemInput = {
  /** 수상일 */
  acquiredDate?: Maybe<Scalars['Date']>,
  /** 내용 */
  content?: Maybe<Scalars['String']>,
  /** 커리어 수상 항목 아이디 */
  id?: Maybe<Scalars['ID']>,
  /** 수여기관 이름 */
  institutionName?: Maybe<Scalars['String']>,
  /** 수상명 */
  name?: Maybe<Scalars['String']>,
  /** 순서 */
  seq: Scalars['Int'],
};

/** 커리어 수상 업데이트할 때 반환하는 객체 */
export type UpdateCareerAwardPayload = {
   __typename?: 'UpdateCareerAwardPayload',
  careerAward: CareerAward,
};

/** 커리어 경력 업데이트할 때 보내야 하는 객체 */
export type UpdateCareerExperienceInput = {
  /** 커리어 프로필 아이디 */
  careerProfileId?: Maybe<Scalars['ID']>,
  /** 공개 여부 */
  isPublic?: Maybe<Scalars['Boolean']>,
  /** 작성 여부 */
  isWritten?: Maybe<Scalars['Boolean']>,
  /** 커리어 경력 항목 */
  itemInputs: Array<UpdateCareerExperienceItemInput>,
};

/** 커리어 경력 항목 업데이트할 때 보내야하는 객체 */
export type UpdateCareerExperienceItemInput = {
  /** 담당 직무 아이디 */
  dutyIds: Array<Scalars['ID']>,
  /** 퇴사년월 */
  endYearMonth?: Maybe<Scalars['Int']>,
  /** 커리어 경력 항목의 아이디 */
  id?: Maybe<Scalars['ID']>,
  /** 재직 여부 */
  inOffice?: Maybe<Scalars['Boolean']>,
  /** 외부 데이터 여부 */
  isExternal: Scalars['Boolean'],
  /** 회사명 비공개 여부 */
  isOrganizationNamePrivate?: Maybe<Scalars['Boolean']>,
  /** 직책 아이디 */
  jobPositionId?: Maybe<Scalars['ID']>,
  /** 직급 아이디 */
  jobRankId?: Maybe<Scalars['ID']>,
  /** 커리어 회사 이름 */
  organizationName?: Maybe<Scalars['String']>,
  /** 연봉 */
  pay?: Maybe<Scalars['Int']>,
  /** 담당업무 */
  performancePart?: Maybe<Scalars['String']>,
  /** 순서 */
  seq: Scalars['Int'],
  /** 입사년월 */
  startYearMonth?: Maybe<Scalars['Int']>,
};

/** 커리어 경력 업데이트할 때 반환되는 객체 */
export type UpdateCareerExperiencePayload = {
   __typename?: 'UpdateCareerExperiencePayload',
  careerExperience: CareerExperience,
};

/** 커리어 외부활동 업데이트할 때 보내야하는 객체 */
export type UpdateCareerExternshipInput = {
  /** 커리어 프로필 아이디 */
  careerProfileId?: Maybe<Scalars['ID']>,
  /** 공개 여부 */
  isPublic?: Maybe<Scalars['Boolean']>,
  /** 작성 여부 */
  isWritten?: Maybe<Scalars['Boolean']>,
  /** 커리어 외부활동 항목 리스트 */
  itemInputs: Array<UpdateCareerExternshipItemInput>,
};

/** 커리어 외부활동 항목 업데이트할 때 보내야하는 객체 */
export type UpdateCareerExternshipItemInput = {
  /** 활동 내용 */
  content?: Maybe<Scalars['String']>,
  /** 종료년월 */
  endYearMonth?: Maybe<Scalars['Int']>,
  /** 커리어 외부활동 항목 아이디 */
  id?: Maybe<Scalars['ID']>,
  /** 진행중 여부 */
  inProgress?: Maybe<Scalars['Boolean']>,
  /** 기관/장소 이름 */
  institutionName?: Maybe<Scalars['String']>,
  /** 순서 */
  seq: Scalars['Int'],
  /** 시작년월 */
  startYearMonth?: Maybe<Scalars['Int']>,
  /** 활동 구분 타입 */
  type?: Maybe<CareerExternshipType>,
  /** URL */
  url?: Maybe<Scalars['String']>,
};

/** 커리어 외부활동 업데이트할 때 반환하는 객체 */
export type UpdateCareerExternshipPayload = {
   __typename?: 'UpdateCareerExternshipPayload',
  careerExternship: CareerExternship,
};

/** 커리어 소개 업데이트할 때 보내야 하는 객체 */
export type UpdateCareerIntroductionInput = {
  /** 한 줄 소개 */
  briefContent?: Maybe<Scalars['String']>,
  /** 커리어 프로필 아이디 */
  careerProfileId?: Maybe<Scalars['ID']>,
  /** 자기소개 */
  content?: Maybe<Scalars['String']>,
  /** 공개 여부 */
  isPublic?: Maybe<Scalars['Boolean']>,
  /** 작성 여부 */
  isWritten?: Maybe<Scalars['Boolean']>,
};

/** 커리어 소개 업데이트할 때 반환하는 객체 */
export type UpdateCareerIntroductionPayload = {
   __typename?: 'UpdateCareerIntroductionPayload',
  careerIntroduction: CareerIntroduction,
};

/** 커리어 희망근무조건 업데이트할 때 보내야 하는 객체 */
export type UpdateCareerJobPreferenceInput = {
  /** 커리어 프로필 아이디 */
  careerProfileId?: Maybe<Scalars['ID']>,
  /** 면접 후 결정 여부 */
  decidedAfterInterview?: Maybe<Scalars['Boolean']>,
  /** 회사 내규에 따름 여부 */
  decidedByOrganizationPolicy?: Maybe<Scalars['Boolean']>,
  /** 희망 직무 아이디 리스트 */
  dutyIds: Array<Scalars['ID']>,
  /** 희망 업좀 아이디 리스트 */
  industryIds: Array<Scalars['ID']>,
  /** 공개 여부 */
  isPublic?: Maybe<Scalars['Boolean']>,
  /** 작성 여부 */
  isWritten?: Maybe<Scalars['Boolean']>,
  /** 연봉 (단위 만원) */
  pay?: Maybe<Scalars['Int']>,
  /** 희망 근무지 아이디 리스트 */
  regionDistrictIds: Array<Scalars['ID']>,
};

/** 커리어 희망근무조건 업데이트할 때 반환하는 객체 */
export type UpdateCareerJobPreferencePayload = {
   __typename?: 'UpdateCareerJobPreferencePayload',
  careerJobPreference: CareerJobPreference,
};

/** 커리어 어학 업데이트할 때 보내주는 객체 */
export type UpdateCareerLanguageInput = {
  /** 커리어 프로필 아이디 */
  careerProfileId?: Maybe<Scalars['ID']>,
  /** 공개 여부 */
  isPublic?: Maybe<Scalars['Boolean']>,
  /** 작성 여부 */
  isWritten?: Maybe<Scalars['Boolean']>,
  /** 어학 항목 리스트 */
  itemInputs: Array<UpdateCareerLanguageItemInput>,
};

export type UpdateCareerLanguageItemInput = {
  /** 취득일 (yyyy.MM.dd) */
  acquiredDate?: Maybe<Scalars['Date']>,
  /** 커리어 어학 항목 아이디 */
  id?: Maybe<Scalars['ID']>,
  /** 급수 */
  level?: Maybe<Scalars['Int']>,
  /** 언어/시험종류 */
  name?: Maybe<Scalars['String']>,
  /** 점수 */
  score?: Maybe<Scalars['Int']>,
  /** 순서 */
  seq: Scalars['Int'],
  /** 회화 능력 */
  status?: Maybe<CareerLanguageConversationStatus>,
  /** 어학 구분 타입 */
  type: CareerLanguageType,
};

/** 커리어 어학 업데이트할 때 반환하는 객체 */
export type UpdateCareerLanguagePayload = {
   __typename?: 'UpdateCareerLanguagePayload',
  careerLanguage: CareerLanguage,
};

/** 커리어 자격증 업데이트할 때 보내야하는 객체 */
export type UpdateCareerLicenseInput = {
  /** 커리어 프로필 아이디 */
  careerProfileId?: Maybe<Scalars['ID']>,
  /** 공개 여부 */
  isPublic?: Maybe<Scalars['Boolean']>,
  /** 작성 여부 */
  isWritten?: Maybe<Scalars['Boolean']>,
  /** 커리어 자격증 항목 리스트 */
  itemInputs: Array<UpdateCareerLicenseItemInput>,
};

/** 커리어 자격증 항목 업데이트할 때 보내야하는 객체 */
export type UpdateCareerLicenseItemInput = {
  /** 취득일 */
  acquiredDate?: Maybe<Scalars['Date']>,
  /** 커리어 자격증 항목 아이디 */
  id?: Maybe<Scalars['ID']>,
  /** 발급기관 */
  institutionName?: Maybe<Scalars['String']>,
  /** 외부 데이터 여부 */
  isExternal: Scalars['Boolean'],
  /** 자격증명 */
  name?: Maybe<Scalars['String']>,
  /** 순서 */
  seq: Scalars['Int'],
};

/** 커리어 자격증 업데이트할 때 반환하는 객체 */
export type UpdateCareerLicensePayload = {
   __typename?: 'UpdateCareerLicensePayload',
  careerLicense: CareerLicense,
};

/** 커리어 포트폴리오 파일 업데이트할 때 보내야하는 객체 */
export type UpdateCareerPortfolioFileInput = {
  /** 삭제할 커리어 포트폴리오 파일 아이디 리스트 */
  deleteCareerPortfolioFileIds?: Maybe<Array<Scalars['ID']>>,
  /** 파일 */
  files?: Maybe<Array<Scalars['Upload']>>,
};

/** 커리어 포트폴리오 이미지 업데이트할 때 보내야하는 객체 */
export type UpdateCareerPortfolioImageInput = {
  /** 커리어 포트폴리오 이미지의 아이디 */
  id?: Maybe<Scalars['ID']>,
  /** 이미지 */
  image?: Maybe<Scalars['Upload']>,
  /** 순서 */
  seq: Scalars['Int'],
};

/** 커리어 포트폴리오 업데이트할 때 보내야하는 객체 */
export type UpdateCareerPortfolioInput = {
  /** 커리어 프로필 아이디 */
  careerProfileId?: Maybe<Scalars['ID']>,
  /** 파일 리스트 */
  fileInput: UpdateCareerPortfolioFileInput,
  /** 이미지 리스트 */
  imageInputs: Array<UpdateCareerPortfolioImageInput>,
  /** 공개 여부 */
  isPublic?: Maybe<Scalars['Boolean']>,
  /** 작성 여부 */
  isWritten?: Maybe<Scalars['Boolean']>,
  /** url 리스트 */
  urlInputs: Array<UpdateCareerPortfolioUrlInput>,
};

/** 커리어 포트폴리오 업데이트할 때 반환하는 객체 */
export type UpdateCareerPortfolioPayload = {
   __typename?: 'UpdateCareerPortfolioPayload',
  careerPortfolio: CareerPortfolio,
};

/** 커리어 포트폴리오 URL 업데이트할 때 보내야하는 객체 */
export type UpdateCareerPortfolioUrlInput = {
  id?: Maybe<Scalars['ID']>,
  /** url 타입 */
  type: CareerPortfolioUrlType,
  /** url */
  url: Scalars['String'],
};

export type UpdateCareerProfileInput = {
  /** 커리어 프로필 아이디 */
  id: Scalars['ID'],
  /** 공유 가능 여부 */
  isSharing?: Maybe<Scalars['Boolean']>,
  /** 프로필 템플릿 */
  template?: Maybe<CareerProfileTemplate>,
  /** 프로필 테마 */
  theme?: Maybe<CareerProfileTheme>,
};

/** 커리어 프로필 필드별 순서 변경할 때 반환하는 객체 */
export type UpdateCareerProfileOrderInput = {
  /** 취업 우대사항 순서 */
  advantageConditionOrder: Scalars['Int'],
  /** 수상 순서 */
  awardOrder: Scalars['Int'],
  /** 커리어 프로필 아이디 */
  careerProfileId: Scalars['ID'],
  /** 경력 순서 */
  experienceOrder: Scalars['Int'],
  /** 인턴/대외활동/프로젝트 순서 */
  externshipOrder: Scalars['Int'],
  /** 희망근무조건 순서 */
  jobPreferenceOrder: Scalars['Int'],
  /** 어학 순서 */
  languageOrder: Scalars['Int'],
  /** 자격증 순서 */
  licenseOrder: Scalars['Int'],
  /** 포트폴리오 순서 */
  portfolioOrder: Scalars['Int'],
  /** 학력 순서 */
  schoolOrder: Scalars['Int'],
  /** 보유 기술 순서 */
  skillOrder: Scalars['Int'],
};

/** 커리어 프로필 필드별 순서 변경할 때 반환하는 객체 */
export type UpdateCareerProfileOrderPayload = {
   __typename?: 'UpdateCareerProfileOrderPayload',
  careerProfileOrder: CareerProfileOrder,
};

export type UpdateCareerProfilePayload = {
   __typename?: 'UpdateCareerProfilePayload',
  careerProfile: CareerProfile,
};

/** 커리어 학교 업데이트할 때 보내야 하는 객체 */
export type UpdateCareerSchoolInput = {
  /** 커리어 프로필 아이디 */
  careerProfileId?: Maybe<Scalars['ID']>,
  /** 공개 여부 */
  isPublic?: Maybe<Scalars['Boolean']>,
  /** 작성 여부 */
  isWritten?: Maybe<Scalars['Boolean']>,
  /** 커리어 학교 항목 */
  itemInputs: Array<UpdateCareerSchoolItemInput>,
};

/** 커리어 학교 항목 업데이트할 때 보내야 하는 객체 */
export type UpdateCareerSchoolItemInput = {
  /** 입학년월 */
  entranceYearMonth?: Maybe<Scalars['Int']>,
  /** 총점 */
  gradePerfectScore?: Maybe<Scalars['Float']>,
  /** 학점 */
  gradeScore?: Maybe<Scalars['Float']>,
  /** 졸업 여부 */
  graduationStatus?: Maybe<CareerSchoolGraduationStatus>,
  /** 졸업년월 */
  graduationYearMonth?: Maybe<Scalars['Int']>,
  /** 커리어 학교 항목 아이디 */
  id?: Maybe<Scalars['ID']>,
  /** 편입 여부 */
  isTransfer?: Maybe<Scalars['Boolean']>,
  /** 전공 */
  majorInputs: Array<UpdateCareerSchoolMajorInput>,
  /** 학교 아이디 */
  schoolId?: Maybe<Scalars['ID']>,
  /** 순서 */
  seq: Scalars['Int'],
  /** 학교 구분 */
  type?: Maybe<CareerSchoolType>,
};

/** 커리어 학교 전공 업데이트할 때 보내야 하는 객체 */
export type UpdateCareerSchoolMajorInput = {
  /** 전공 아이디 */
  majorId: Scalars['ID'],
  /** 전공 타입 */
  type: CareerSchoolMajorType,
};

/** 커리어 학교 업데이트할 때 반환하는 객체 */
export type UpdateCareerSchoolPayload = {
   __typename?: 'UpdateCareerSchoolPayload',
  careerSchool: CareerSchool,
};

/** 커리어 보유 기술 업데이트할 때 보내야하는 객체 */
export type UpdateCareerSkillInput = {
  /** 커리어 프로필 아아디 */
  careerProfileId?: Maybe<Scalars['ID']>,
  /** 공개 여부 */
  isPublic?: Maybe<Scalars['Boolean']>,
  /** 작성 여부 */
  isWritten?: Maybe<Scalars['Boolean']>,
  /** 스킬 태그 아이디 리스트 */
  skillTagIds: Array<Scalars['ID']>,
};

/** 커리어 보유 기술 업데이트할 때 반환하는 객체 */
export type UpdateCareerSkillPayload = {
   __typename?: 'UpdateCareerSkillPayload',
  careerSkill: CareerSkill,
};

/** 커리어 유저 프로필 업데이트할 때 보내야 하는 객체 */
export type UpdateCareerUserInformationInput = {
  /** 기본 주소 */
  address?: Maybe<Scalars['String']>,
  /** 상세 주소 */
  addressDetail?: Maybe<Scalars['String']>,
  /** 법정동/법정리 코드 */
  bcode?: Maybe<Scalars['String']>,
  /** 생년월일 */
  birthday?: Maybe<Scalars['Date']>,
  /** 법정동/법정리 이름 */
  bname?: Maybe<Scalars['String']>,
  /** 커리어 프로필 아이디 */
  careerProfileId?: Maybe<Scalars['ID']>,
  /** 이메일 */
  email?: Maybe<Scalars['String']>,
  /** 성별 */
  gender?: Maybe<Gender>,
  /** 작성 여부 */
  isWritten?: Maybe<Scalars['Boolean']>,
  /** 이름 */
  name?: Maybe<Scalars['String']>,
  /** 휴대폰 번호 */
  phoneNumber?: Maybe<Scalars['String']>,
  /** 프로필 이미지 아이디 */
  profileImage?: Maybe<Scalars['Upload']>,
  /** 도/시 이름 */
  sido?: Maybe<Scalars['String']>,
  /** 시/군/구 이름 */
  sigungu?: Maybe<Scalars['String']>,
  /** 시/군/구 코드 */
  sigunguCode?: Maybe<Scalars['String']>,
  /** 우편번호 */
  zoneCode?: Maybe<Scalars['String']>,
};

/** 커리어 유저 프로필 업데이트할 때 반환하는 객체 */
export type UpdateCareerUserInformationPayload = {
   __typename?: 'UpdateCareerUserInformationPayload',
  careerUserInformation: CareerUserInformation,
};

export type UpdateChannelAndOrganizationPayload = {
   __typename?: 'UpdateChannelAndOrganizationPayload',
  channel?: Maybe<Channel>,
  organization?: Maybe<Organization>,
};

export type UpdateChannelInput = {
  briefDescription?: Maybe<Scalars['String']>,
  coverAttachmentId?: Maybe<Scalars['ID']>,
  description?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  name?: Maybe<Scalars['String']>,
  tagNames?: Maybe<Array<Scalars['String']>>,
};

export type UpdateChannelPayload = {
   __typename?: 'UpdateChannelPayload',
  channel?: Maybe<Channel>,
};

export type UpdateChannelViewCountPayload = {
   __typename?: 'UpdateChannelViewCountPayload',
  channel?: Maybe<Channel>,
};

/** 채팅방 알림 설정 input */
export type UpdateChatRoomNotificationInput = {
  chatRoomId: Scalars['ID'],
  enableNotification: Scalars['Boolean'],
};

/** 채팅방 알림 설정 payload */
export type UpdateChatRoomNotificationPayload = {
   __typename?: 'UpdateChatRoomNotificationPayload',
  id: Scalars['ID'],
  memberId: Scalars['Int'],
  chatRoomId: Scalars['Int'],
  enableNotification: Scalars['Boolean'],
};

/** 채팅방 알림 설정 input */
export type UpdateChatSubscribeInput = {
  subscribeChatNotification: Scalars['Boolean'],
};

export type UpdateCommunityBoardUrlInput = {
  communityBoardUrlId: Scalars['ID'],
  slug?: Maybe<Array<Maybe<Scalars['String']>>>,
  title?: Maybe<Scalars['String']>,
  url?: Maybe<Scalars['String']>,
};

export type UpdateCommunityMentorPostInput = {
  duties?: Maybe<Array<Maybe<PostDutyV2>>>,
  dutyId?: Maybe<Scalars['ID']>,
  id: Scalars['ID'],
  organizationGroupName?: Maybe<Scalars['String']>,
  organizationName?: Maybe<Scalars['String']>,
  organizations?: Maybe<Array<Maybe<PostOrganizationV2>>>,
  parentDutyId?: Maybe<Scalars['ID']>,
  rootDutyId?: Maybe<Scalars['ID']>,
  schoolName?: Maybe<Scalars['String']>,
  schoolNames?: Maybe<Array<Maybe<Scalars['String']>>>,
};

export type UpdateCommunityPostRelatedWithActivityInput = {
  communityPostId?: Maybe<Scalars['ID']>,
  communityPostRelatedWithActivityId?: Maybe<Scalars['ID']>,
  seq?: Maybe<Scalars['Int']>,
};

export type UpdateCoverLetterFieldByQueryInput = {
  field: CoverLetterField,
  newName?: Maybe<Scalars['String']>,
  oldName: Scalars['String'],
};

export type UpdateCoverLetterFieldByQueryPayload = {
   __typename?: 'UpdateCoverLetterFieldByQueryPayload',
  updatedCoverLetters?: Maybe<Array<Maybe<CoverLetter>>>,
};

export type UpdateCoverLetterRelatedWithActivityInput = {
  coverLetterId?: Maybe<Scalars['ID']>,
  coverLetterRelatedWithActivityId?: Maybe<Scalars['ID']>,
  seq?: Maybe<Scalars['Int']>,
};

export type UpdateCurrentUserInput = {
  acceptedMentorCommentReward?: Maybe<Scalars['Boolean']>,
  birthday?: Maybe<Scalars['Date']>,
  email: Scalars['String'],
  matchedMentorCommentReward?: Maybe<Scalars['Boolean']>,
  mentorPost?: Maybe<Scalars['Boolean']>,
  nickname: Scalars['String'],
  phoneNumber?: Maybe<Scalars['String']>,
  profileURL?: Maybe<Scalars['String']>,
  recommendedMentorPost?: Maybe<Scalars['Boolean']>,
  subscribeComment?: Maybe<Scalars['Boolean']>,
  subscribeEmail?: Maybe<Scalars['Boolean']>,
  subscribeInfo?: Maybe<Scalars['Boolean']>,
  subscribePost?: Maybe<Scalars['Boolean']>,
  subscribeScrap?: Maybe<Scalars['Boolean']>,
};

export type UpdateCurrentUserPayload = {
   __typename?: 'UpdateCurrentUserPayload',
  user?: Maybe<User>,
};

export type UpdateDetailedActivityInput = {
  activityEndAt?: Maybe<Scalars['Date']>,
  activityID: Scalars['ID'],
  activityStartAt?: Maybe<Scalars['Date']>,
  activityTypeID: Scalars['ID'],
  additionalBenefit?: Maybe<Scalars['String']>,
  applicationFileAttachmentIDs?: Maybe<Array<Scalars['ID']>>,
  applyDetail?: Maybe<Scalars['String']>,
  applyTypeIDs?: Maybe<Array<Scalars['String']>>,
  awards?: Maybe<Scalars['String']>,
  benefits?: Maybe<Array<Scalars['ID']>>,
  categories?: Maybe<Array<Scalars['ID']>>,
  cost?: Maybe<Scalars['Int']>,
  country?: Maybe<Scalars['String']>,
  detailText?: Maybe<Scalars['String']>,
  detailTextID?: Maybe<Scalars['String']>,
  emailConfirmToken?: Maybe<Scalars['String']>,
  facetimeDay?: Maybe<Scalars['String']>,
  facetimePeriod?: Maybe<Scalars['String']>,
  facetimeTime?: Maybe<Scalars['String']>,
  facetimeWeekday?: Maybe<Scalars['String']>,
  interests?: Maybe<Array<Scalars['ID']>>,
  isManagerEmailVisible?: Maybe<Scalars['Boolean']>,
  isManagerPhoneNumberVisible?: Maybe<Scalars['Boolean']>,
  isOffline?: Maybe<Scalars['Boolean']>,
  isOnline?: Maybe<Scalars['Boolean']>,
  isSponsored?: Maybe<Scalars['Boolean']>,
  isSubmit?: Maybe<Scalars['Boolean']>,
  /** 기관의 로고 이미지입니다. */
  logoImage?: Maybe<Scalars['ID']>,
  managerEmail?: Maybe<Scalars['String']>,
  managerName?: Maybe<Scalars['String']>,
  managerPhoneNumber?: Maybe<Scalars['String']>,
  organizationName: Scalars['String'],
  organizationTypeID?: Maybe<Scalars['String']>,
  outlink?: Maybe<Scalars['String']>,
  percent?: Maybe<Scalars['String']>,
  posterAttachmentID?: Maybe<Scalars['String']>,
  recruitCloseAt?: Maybe<Scalars['Date']>,
  recruitScale?: Maybe<Scalars['String']>,
  recruitStartAt?: Maybe<Scalars['Date']>,
  recruitType?: Maybe<ActivityRecruitType>,
  regionDistricts?: Maybe<Array<Scalars['ID']>>,
  regions?: Maybe<Array<Scalars['ID']>>,
  skills?: Maybe<Array<Scalars['ID']>>,
  targets?: Maybe<Array<Scalars['ID']>>,
  thumbnailAttachmentID?: Maybe<Scalars['ID']>,
  title: Scalars['String'],
  youtubeURL?: Maybe<Scalars['String']>,
};

export type UpdateDetailedActivityPayload = {
   __typename?: 'UpdateDetailedActivityPayload',
  activity: Activity,
};

export type UpdateDetailedRecruitInput = {
  activityId: Scalars['ID'],
  detailText?: Maybe<Scalars['String']>,
  detailTextId?: Maybe<Scalars['ID']>,
  emailConfirmToken?: Maybe<Scalars['String']>,
  isAllSameQuestion?: Maybe<Scalars['Boolean']>,
  isSubmit: Scalars['Boolean'],
  /** 담당자 */
  manager: RecruitManagerInput,
  /** 조직 */
  organization: RecruitOrganizationInput,
  /** 자격 조건 */
  qualification: ActivityQualificationInput,
  questionType?: Maybe<QuestionType>,
  /** 모집 정보 */
  recruitInformations: Array<RecruitInformationInput>,
  /** 채용 절차 */
  recruitProcedure: RecruitProcedureInput,
  title: Scalars['String'],
  /** 근무 조건 */
  workingCondition: WorkingConditionInput,
};

export type UpdateDetailedRecruitPayload = {
   __typename?: 'UpdateDetailedRecruitPayload',
  activity: Activity,
};

export type UpdateFolderInput = {
  id: Scalars['ID'],
  name?: Maybe<Scalars['String']>,
  parentId?: Maybe<Scalars['ID']>,
};

export type UpdateFolderPayload = {
   __typename?: 'UpdateFolderPayload',
  folder: Folder,
};

export type UpdateManyCommunityBoardUrlPayload = {
   __typename?: 'UpdateManyCommunityBoardUrlPayload',
  communityBoardUrls?: Maybe<Array<Maybe<CommunityBoardUrl>>>,
};

export type UpdateManyCommunityBoardUrlsInput = {
  updateManyCommunityBoardUrls: Array<UpdateCommunityBoardUrlInput>,
};

export type UpdateManyCommunityPostRelatedWithActivityInput = {
  activityId: Scalars['ID'],
  updateCommunityPostRelatedWithActivityInputs: Array<Maybe<UpdateCommunityPostRelatedWithActivityInput>>,
};

export type UpdateManyCommunityPostRelatedWithActivityPayload = {
   __typename?: 'UpdateManyCommunityPostRelatedWithActivityPayload',
  communityPostsRelatedWithActivity?: Maybe<Array<Maybe<CommunityPostRelatedWithActivity>>>,
};

export type UpdateManyCoverLetterRelatedWithActivityInput = {
  activityId: Scalars['ID'],
  updateCoverLetterRelatedWithActivityInputs: Array<Maybe<UpdateCoverLetterRelatedWithActivityInput>>,
};

export type UpdateManyCoverLetterRelatedWithActivityPayload = {
   __typename?: 'UpdateManyCoverLetterRelatedWithActivityPayload',
  coverLettersRelatedWithActivity?: Maybe<Array<Maybe<CoverLetterRelatedWithActivity>>>,
};

export type UpdateManyRecommendSearchKeywordInput = {
  id?: Maybe<Scalars['ID']>,
  searchField?: Maybe<SearchKeywordFieldType>,
  seq?: Maybe<Scalars['Int']>,
  title?: Maybe<Scalars['String']>,
  type: RecommendSearchKeywordType,
};

export type UpdateNewsCommentInput = {
  content: Scalars['String'],
  id: Scalars['ID'],
};

export type UpdateNewsCommentPayload = {
   __typename?: 'UpdateNewsCommentPayload',
  newsComment?: Maybe<NewsComment>,
};

export type UpdateNewsItemInput = {
  content?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  imageIds?: Maybe<Array<Scalars['ID']>>,
  tagNames?: Maybe<Array<Scalars['String']>>,
};

export type UpdateNewsItemPayload = {
   __typename?: 'UpdateNewsItemPayload',
  newsItem?: Maybe<NewsItem>,
};

export type UpdateNewsItemViewCountPayload = {
   __typename?: 'UpdateNewsItemViewCountPayload',
  newsItem?: Maybe<NewsItem>,
};

export type UpdateOrganizationInput = {
  address?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  establishedAt?: Maybe<Scalars['Date']>,
  id: Scalars['ID'],
  jobCategoryId?: Maybe<Scalars['ID']>,
  logoAttachmentId?: Maybe<Scalars['ID']>,
  phoneNumber?: Maybe<Scalars['String']>,
  scale?: Maybe<OrganizationScale>,
  websiteUrl?: Maybe<Scalars['String']>,
};

export type UpdateQnAInput = {
  answer?: Maybe<Scalars['String']>,
  blankType?: Maybe<BlankType>,
  charMaxSize?: Maybe<Scalars['Int']>,
  charSize?: Maybe<CharSize>,
  id: Scalars['ID'],
  question?: Maybe<Scalars['String']>,
};

export type UpdateQnAPayload = {
   __typename?: 'UpdateQnAPayload',
  qna?: Maybe<QnA>,
};

export type UpdateRecommendSearchKeywordInput = {
  id: Scalars['ID'],
  searchField?: Maybe<SearchKeywordFieldType>,
  seq?: Maybe<Scalars['Int']>,
  title?: Maybe<Scalars['String']>,
  type?: Maybe<RecommendSearchKeywordType>,
};

export type UpdateRecommendSearchKeywordPayload = {
   __typename?: 'UpdateRecommendSearchKeywordPayload',
  recommendSearchKeyword?: Maybe<RecommendSearchKeyword>,
};

export type UpdateRecommendSearchKeywordsInput = {
  recommendSearchKeywords: Array<UpdateManyRecommendSearchKeywordInput>,
};

export type UpdateRecommendSearchKeywordsPayload = {
   __typename?: 'UpdateRecommendSearchKeywordsPayload',
  recommendSearchKeywords?: Maybe<Array<Maybe<RecommendSearchKeyword>>>,
};

export type UpdateRecruitInput = {
  activityID: Scalars['ID'],
  applicationFileAttachmentIDs?: Maybe<Array<Scalars['String']>>,
  applyDetail?: Maybe<Scalars['String']>,
  applyTypeID?: Maybe<Scalars['ID']>,
  categories?: Maybe<Array<Scalars['ID']>>,
  detailText?: Maybe<Scalars['String']>,
  detailTextID?: Maybe<Scalars['ID']>,
  duties?: Maybe<Array<DutyInput>>,
  emailConfirmToken?: Maybe<Scalars['String']>,
  isAllSameQuestion?: Maybe<Scalars['Boolean']>,
  isManagerEmailVisible?: Maybe<Scalars['Boolean']>,
  isManagerPhoneNumberVisible?: Maybe<Scalars['Boolean']>,
  isSubmit?: Maybe<Scalars['Boolean']>,
  jobTypes?: Maybe<Array<JobType>>,
  logo?: Maybe<ActivityFileAttachmentInput>,
  managerEmail?: Maybe<Scalars['String']>,
  managerName?: Maybe<Scalars['String']>,
  managerPhoneNumber?: Maybe<Scalars['String']>,
  organizationName?: Maybe<Scalars['String']>,
  organizationTypeID?: Maybe<Scalars['ID']>,
  outlink?: Maybe<Scalars['String']>,
  questionType?: Maybe<QuestionType>,
  recruitCloseAt?: Maybe<Scalars['Date']>,
  recruitScale?: Maybe<Scalars['String']>,
  recruitStartAt?: Maybe<Scalars['Date']>,
  recruitType?: Maybe<ActivityRecruitType>,
  regionDistricts?: Maybe<Array<Scalars['ID']>>,
  regions?: Maybe<Array<Scalars['ID']>>,
  title: Scalars['String'],
};

export type UpdateRecruitPayload = {
   __typename?: 'UpdateRecruitPayload',
  activity?: Maybe<Activity>,
};

export type UpdateSelfIntroductionInput = {
  folderId?: Maybe<Scalars['ID']>,
  id: Scalars['ID'],
  qnas?: Maybe<Array<UpdateQnAInput>>,
  title?: Maybe<Scalars['String']>,
};

export type UpdateSelfIntroductionPayload = {
   __typename?: 'UpdateSelfIntroductionPayload',
  selfIntroduction?: Maybe<SelfIntroduction>,
};

export type UpdateSubscriptionInput = {
  acceptedMentorCommentReward?: Maybe<Scalars['Boolean']>,
  activity?: Maybe<Scalars['Boolean']>,
  activityReply?: Maybe<Scalars['Boolean']>,
  chatMessageReply?: Maybe<Scalars['Boolean']>,
  comment?: Maybe<Scalars['Boolean']>,
  info?: Maybe<Scalars['Boolean']>,
  matchedMentorCommentReward?: Maybe<Scalars['Boolean']>,
  mentorPost?: Maybe<Scalars['Boolean']>,
  newsComment?: Maybe<Scalars['Boolean']>,
  newsItem?: Maybe<Scalars['Boolean']>,
  post?: Maybe<Scalars['Boolean']>,
  recommendedMentorPost?: Maybe<Scalars['Boolean']>,
  scrap?: Maybe<Scalars['Boolean']>,
};

export type UpdateSubscriptionPayload = {
   __typename?: 'UpdateSubscriptionPayload',
  user?: Maybe<User>,
};

export type UpdateTagCollectionInput = {
  id: Scalars['ID'],
  seq?: Maybe<Scalars['Int']>,
  tags?: Maybe<Array<UpsertTagInput>>,
  title?: Maybe<Scalars['String']>,
};

export type UpdateTagCollectionPayload = {
   __typename?: 'UpdateTagCollectionPayload',
  tagCollection?: Maybe<TagCollection>,
};

export type UpdateTagCollectionsInput = {
  id?: Maybe<Scalars['ID']>,
  seq?: Maybe<Scalars['Int']>,
  title: Scalars['String'],
};

export type UpdateTagCollectionsPayload = {
   __typename?: 'UpdateTagCollectionsPayload',
  tagCollections?: Maybe<Array<Maybe<TagCollection>>>,
};

export type UpdateTestUserInput = {
  email?: Maybe<Scalars['String']>,
  specupJoined?: Maybe<Scalars['Boolean']>,
  username?: Maybe<Scalars['String']>,
};

export type UpdateTestUserPayload = {
   __typename?: 'UpdateTestUserPayload',
  user?: Maybe<User>,
};

export type UpdateUserPhoneNumberByAuthUserIdInput = {
  phoneNumber: Scalars['String'],
  userID: Scalars['Int'],
};

export type UpdateUserPhoneNumberByAuthUserIdPayload = {
   __typename?: 'UpdateUserPhoneNumberByAuthUserIdPayload',
  user?: Maybe<User>,
};


export type UpsertLinkareerMagazineInput = {
  content?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['ID']>,
  pcImage?: Maybe<Scalars['String']>,
  pcImageId: Scalars['ID'],
  seq: Scalars['Int'],
  title: Scalars['String'],
  url: Scalars['String'],
};

export type UpsertManyLinkareerMagazineInput = {
  linkareerMagazines: Array<UpsertLinkareerMagazineInput>,
};

export type UpsertManyLinkareerMagazinePayload = {
   __typename?: 'UpsertManyLinkareerMagazinePayload',
  linkareerMagazines: Array<Maybe<LinkareerMagazine>>,
};

export type UpsertTagInput = {
  id?: Maybe<Scalars['ID']>,
  name: Scalars['String'],
  seq?: Maybe<Scalars['Int']>,
};

/** 유저 */
export type User = {
   __typename?: 'User',
  /** 채팅메시지 내 조회시에만 사용됩니다. */
  chatMentorAuthenticationStatus?: Maybe<Scalars['String']>,
  /** 채팅메시지 내 조회시에만 사용됩니다. */
  chatMentorOrganizationName?: Maybe<Scalars['String']>,
  /** 채팅메시지 내 조회시에만 사용됩니다. */
  chatMentorDutyName?: Maybe<Scalars['String']>,
  /** 채팅메시지 내 조회시에만 사용됩니다. */
  chatUserType?: Maybe<UserType>,
  /** 채팅메시지 내 조회시에만 사용됩니다. */
  chatOrganization?: Maybe<ChatOrganization>,
  /** 유저의 아이디 */
  id: Scalars['ID'],
  /** 유저의 채팅 닉네임 */
  chatNickname: Scalars['String'],
  /** 유저의 채팅 닉네임 변경 가능 횟수 */
  chatNicknameModifiableCount: Scalars['Int'],
  /** 유저 프로필 이미지 */
  profileImage: ProfileImageTemplate,
  /** 유저의 채팅 이용 정지 해제 시간 */
  chatBannedUntil?: Maybe<Scalars['Date']>,
  /** 유저 채팅 알림 설정 */
  chatSubscription?: Maybe<UserSubscription>,
  /** 유저가 채팅 이용 정지 상태인지 여부 */
  chatIsBanned: Scalars['Boolean'],
  /** 유저가 속한 채팅방 목록 */
  chatRooms: ChatRoomConnection,
  /** 유저가 차단한 사용자 목록 */
  chatBlockedUsers?: Maybe<Array<User>>,
  /** 유저의 마지막 채팅 이용 정지 기록 */
  chatLastUserBan?: Maybe<UserBanInChat>,
  activitiesCountByUser?: Maybe<Scalars['Int']>,
  avatarUrl?: Maybe<Scalars['String']>,
  benefits?: Maybe<Array<Maybe<Benefit>>>,
  birthday?: Maybe<Scalars['Date']>,
  bizActivities?: Maybe<ActivityConnection>,
  bizActivityCountByStatus: Array<BizActivityCountByStatusPayload>,
  bookmarks?: Maybe<BookmarkedConnection>,
  categories?: Maybe<Array<Maybe<Category>>>,
  channel?: Maybe<Channel>,
  communityUserId?: Maybe<Scalars['ID']>,
  createdAt?: Maybe<Scalars['Date']>,
  customizedActivities?: Maybe<ActivityConnection>,
  deactivated: Scalars['Boolean'],
  deletedAt?: Maybe<Scalars['Date']>,
  email?: Maybe<Scalars['String']>,
  facebook?: Maybe<FacebookAccount>,
  favoriteCategory?: Maybe<Category>,
  followings?: Maybe<FollowingConnection>,
  gender?: Maybe<Gender>,
  interests?: Maybe<Array<Maybe<Interest>>>,
  isCheckedParagraphScrapGuide?: Maybe<Scalars['Boolean']>,
  isFirst: Scalars['Boolean'],
  isStaff: Scalars['Boolean'],
  isSuperuser: Scalars['Boolean'],
  kakao?: Maybe<KakaoAccount>,
  lastLoginAt?: Maybe<Scalars['Date']>,
  lastLoginSession?: Maybe<Scalars['ID']>,
  likes: LikedConnection,
  login?: Maybe<Scalars['String']>,
  managerName?: Maybe<Scalars['String']>,
  mentorAuthenticationStatus?: Maybe<Scalars['String']>,
  mentorDutyName?: Maybe<Scalars['String']>,
  mentorOrganizationName?: Maybe<Scalars['String']>,
  naver?: Maybe<NaverAccount>,
  newsComments?: Maybe<NewsCommentConnection>,
  newsItemFeed?: Maybe<NewsItemConnection>,
  notificationUnreadCount?: Maybe<Scalars['Int']>,
  notifications?: Maybe<NotificationConnection>,
  organization?: Maybe<Organization>,
  persona?: Maybe<Persona>,
  phoneNumber?: Maybe<Scalars['String']>,
  preferInterests?: Maybe<Array<Maybe<Interest>>>,
  preferTypes?: Maybe<Array<Maybe<ActivityType>>>,
  profile?: Maybe<Profile>,
  receiveEmailAt?: Maybe<Scalars['Date']>,
  receiveSMSAt?: Maybe<Scalars['Date']>,
  regions?: Maybe<Array<Maybe<Region>>>,
  resumes?: Maybe<Array<Maybe<Resume>>>,
  scrapCount?: Maybe<Scalars['Int']>,
  scraps?: Maybe<Array<Maybe<UserScrap>>>,
  signupSession?: Maybe<SignupSession>,
  skills?: Maybe<Array<Maybe<Skill>>>,
  specupJoined: Scalars['Boolean'],
  specupJoinedAt?: Maybe<Scalars['Date']>,
  specupJoinedSession?: Maybe<Scalars['ID']>,
  subscribeAcceptedMentorCommentReward: Scalars['Boolean'],
  subscribeActivity: Scalars['Boolean'],
  subscribeActivityReply: Scalars['Boolean'],
  subscribeComment: Scalars['Boolean'],
  subscribeEmail: Scalars['Boolean'],
  subscribeInfo: Scalars['Boolean'],
  subscribeMatchedMentorCommentReward: Scalars['Boolean'],
  subscribeMentorPost: Scalars['Boolean'],
  subscribeNewsComment: Scalars['Boolean'],
  subscribeNewsItem: Scalars['Boolean'],
  subscribePost: Scalars['Boolean'],
  subscribeRecommendedMentorPost: Scalars['Boolean'],
  subscribeSMS: Scalars['Boolean'],
  subscribeScrap: Scalars['Boolean'],
  timespread?: Maybe<TimespreadAccount>,
  timespreadAuthKey?: Maybe<Scalars['String']>,
  timespreadID?: Maybe<Scalars['ID']>,
  tokenSet?: Maybe<TokenSet>,
  type: UserType,
  username?: Maybe<Scalars['String']>,
};


/** 유저 */
export type UserChatRoomsArgs = {
  first: Scalars['Int'],
  after?: Maybe<Scalars['String']>,
  filterBy?: Maybe<UserChatRoomsFilterBy>
};


/** 유저 */
export type UserBizActivitiesArgs = {
  filterBy: BizActivityFilter,
  orderBy?: Maybe<BizActivityOrder>,
  pagination?: Maybe<Pagination>
};


/** 유저 */
export type UserBookmarksArgs = {
  pagination?: Maybe<Pagination>
};


/** 유저 */
export type UserFollowingsArgs = {
  filterBy?: Maybe<FollowingFilter>,
  pagination?: Maybe<Pagination>
};


/** 유저 */
export type UserLikesArgs = {
  pagination?: Maybe<Pagination>
};


/** 유저 */
export type UserNewsCommentsArgs = {
  pagination?: Maybe<Pagination>
};


/** 유저 */
export type UserNewsItemFeedArgs = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<NewsItemOrder>
};


/** 유저 */
export type UserNotificationUnreadCountArgs = {
  notificationType?: Maybe<NotificationScope>
};


/** 유저 */
export type UserNotificationsArgs = {
  filterBy?: Maybe<NotificationsFilter>,
  pagination?: Maybe<Pagination>
};

export type UserAuthorizePhoneNumberInput = {
  authID: Scalars['Int'],
  code: Scalars['String'],
  phoneNumber: Scalars['String'],
  signupToken?: Maybe<Scalars['String']>,
};

export type UserAuthorizePhoneNumberPayload = {
   __typename?: 'UserAuthorizePhoneNumberPayload',
  authorizeID?: Maybe<Scalars['Int']>,
};

/** 활동 정지 로그 */
export type UserBanInChat = {
   __typename?: 'UserBanInChat',
  /** 활동 정지 로그 ID */
  id: Scalars['ID'],
  /** 활동 정지 시작 시각 */
  bannedAt: Scalars['Date'],
  /** 활동 정지 종료 시각 */
  bannedUntil: Scalars['Date'],
  /** 활동 정지 해제 시각 */
  unbannedAt?: Maybe<Scalars['Date']>,
  /** 정지된 유저 */
  bannedUser: User,
  /** 정지 사유 */
  bannedReason: Scalars['String'],
};

export type UserBanOffsetConnection = {
   __typename?: 'UserBanOffsetConnection',
  nodes: Array<UserBanInChat>,
  totalCount: Scalars['Int'],
};

export type UserChatRoomsFilterBy = {
  /** 
 * 채팅방의 activityTypeID.
   * 
   * - 채용: 5
   * - 대외활동: 1
   * - 공모전: 3
 **/
  activityTypeID?: Maybe<Scalars['Int']>,
};

export type UserCheckPhoneNumberAuthorizationPayload = {
   __typename?: 'UserCheckPhoneNumberAuthorizationPayload',
  authID?: Maybe<Scalars['Int']>,
};

export type UserConnection = {
   __typename?: 'UserConnection',
  nodes?: Maybe<Array<Maybe<User>>>,
  totalCount: Scalars['Int'],
};

export type UserDeactivatePayload = {
   __typename?: 'UserDeactivatePayload',
  user?: Maybe<User>,
};

export type UserDeleteManyPayload = {
   __typename?: 'UserDeleteManyPayload',
  users: Array<Maybe<User>>,
};

export type UserDeletePayload = {
   __typename?: 'UserDeletePayload',
  user?: Maybe<User>,
};

export enum UserDeleteReasonType {
  ETC = 'ETC',
  LACK_OF_CONTENT_QUALITY = 'LACK_OF_CONTENT_QUALITY',
  LOW_FREQUENCY_USES = 'LOW_FREQUENCY_USES',
  OUT_OF_JOB_MARKET = 'OUT_OF_JOB_MARKET',
  POOR_SECURITY = 'POOR_SECURITY',
  POOR_UX_UI = 'POOR_UX_UI'
}

export type UserFilters = {
  isConfirmed?: Maybe<Scalars['Boolean']>,
  isOrganization?: Maybe<Scalars['Boolean']>,
  q?: Maybe<Scalars['String']>,
  signupStep?: Maybe<UserSignupStepFilter>,
};

export type UserInput = {
  alias?: Maybe<Scalars['String']>,
  /** 삭제하려는 이미지 아이디 배열 */
  deleteLicenseImageIds?: Maybe<Array<Scalars['ID']>>,
  email?: Maybe<Scalars['String']>,
  isConfirmed?: Maybe<Scalars['Boolean']>,
  licenseImages?: Maybe<Array<Scalars['Upload']>>,
  managerEmail?: Maybe<Scalars['String']>,
  managerName?: Maybe<Scalars['String']>,
  managerPhoneNumber?: Maybe<Scalars['String']>,
  organizationName?: Maybe<Scalars['String']>,
  organizationTypeID?: Maybe<Scalars['ID']>,
  password?: Maybe<Scalars['String']>,
  registrationNumber?: Maybe<Scalars['String']>,
  representative?: Maybe<Scalars['String']>,
  specupJoined?: Maybe<Scalars['Boolean']>,
  type?: Maybe<UserType>,
  username?: Maybe<Scalars['String']>,
};

export type UserIsDuplicateLoginInput = {
  login: Scalars['String'],
};

export type UserIsDuplicateNicknameInput = {
  nickname: Scalars['String'],
  signupToken: Scalars['String'],
};

export type UserIsDuplicateNicknameWithoutTokenInput = {
  nickname: Scalars['String'],
};

export type UserOrder = {
  direction: OrderDirection,
  field: UserOrderField,
};

export enum UserOrderField {
  CONFIRMATION_REQUESTED_AT = 'CONFIRMATION_REQUESTED_AT',
  CREATED_AT = 'CREATED_AT',
  ID = 'ID',
  LAST_LOGIN_AT = 'LAST_LOGIN_AT',
  SPECUP_JOINED = 'SPECUP_JOINED'
}

export type UserScrap = {
   __typename?: 'UserScrap',
  activity?: Maybe<Activity>,
  coverLetter?: Maybe<CoverLetter>,
  coverLetterParagraphScrap?: Maybe<CoverLetterParagraphScrap>,
  createdAt: Scalars['Date'],
  id: Scalars['ID'],
  scrapType: UserScrapType,
  scrapedURL: Scalars['String'],
  user?: Maybe<User>,
  userOS: Scalars['String'],
};

export type UserScrapCommunity = {
   __typename?: 'UserScrapCommunity',
  createdAt?: Maybe<Scalars['Date']>,
  externalID?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  listOrder?: Maybe<Scalars['Int']>,
  post?: Maybe<Post>,
  postID: Scalars['ID'],
  userID: Scalars['ID'],
};

export type UserScrapCommunityOrder = {
  direction: OrderDirection,
  field: UserScrapCommunityOrderField,
};

export enum UserScrapCommunityOrderField {
  CREATED_AT = 'CREATED_AT',
  LIST_ORDER = 'LIST_ORDER'
}

export type UserScrapCountsPayload = {
   __typename?: 'UserScrapCountsPayload',
  activityCounts: Scalars['Int'],
  communityCounts: Scalars['Int'],
  coverLetterAndParagraphCounts: Scalars['Int'],
  coverLetterCounts: Scalars['Int'],
  coverLetterParagraphCounts: Scalars['Int'],
  newsItemCounts: Scalars['Int'],
};

export type UserScrapDeleteAllPayload = {
   __typename?: 'UserScrapDeleteAllPayload',
  count: Scalars['Int'],
  coverLetterParagraphScraps: Array<CoverLetterParagraphScrap>,
  deletedUserScraps: Array<DeletedUserScrap>,
};

export enum UserScrapOrderField {
  CREATED_AT = 'CREATED_AT'
}

export type UserScrapResult = NewsItem | UserScrap | UserScrapCommunity;

export type UserScrapsConnection = {
   __typename?: 'UserScrapsConnection',
  nodes?: Maybe<Array<Maybe<UserScrapResult>>>,
  totalCount: Scalars['Int'],
};

export type UserScrapsFilter = {
  userScrapType: UserScrapType,
};

export type UserScrapsOrder = {
  direction: OrderDirection,
  field: UserScrapOrderField,
};

export enum UserScrapType {
  ACTIVITY = 'ACTIVITY',
  COMMUNITY = 'COMMUNITY',
  COVER_LETTER = 'COVER_LETTER',
  COVER_LETTER_AND_PARAGRAPH = 'COVER_LETTER_AND_PARAGRAPH',
  COVER_LETTER_PARAGRAPH = 'COVER_LETTER_PARAGRAPH',
  NEWS_ITEM = 'NEWS_ITEM'
}

export type UserSignupPayload = {
   __typename?: 'UserSignupPayload',
  accessToken?: Maybe<Scalars['String']>,
  expiresAt?: Maybe<Scalars['Date']>,
  refreshToken?: Maybe<Scalars['String']>,
  user?: Maybe<User>,
};

export enum UserSignupStepFilter {
  ALL = 'ALL',
  ONLY_LINKAREER = 'ONLY_LINKAREER',
  ONLY_TIMESPREAD = 'ONLY_TIMESPREAD',
  SIGNUP_SKIP = 'SIGNUP_SKIP',
  SOMETHING_WRONG = 'SOMETHING_WRONG',
  SPECUP_JOINED = 'SPECUP_JOINED'
}

export type UserSignupWithEmailInput = {
  birthday?: Maybe<Scalars['Date']>,
  email?: Maybe<Scalars['String']>,
  nickname?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<Scalars['String']>,
  phoneNumberAuthID?: Maybe<Scalars['Int']>,
  profileURL?: Maybe<Scalars['String']>,
  signupToken: Scalars['String'],
  subscribeEmail?: Maybe<Scalars['Boolean']>,
  subscribeSMS?: Maybe<Scalars['Boolean']>,
};

export type UserSignupWithOAuthInput = {
  birthday?: Maybe<Scalars['Date']>,
  email?: Maybe<Scalars['String']>,
  nickname?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<Scalars['String']>,
  phoneNumberAuthID?: Maybe<Scalars['Int']>,
  profileURL?: Maybe<Scalars['String']>,
  signupToken: Scalars['String'],
  subscribeEmail?: Maybe<Scalars['Boolean']>,
  subscribeSMS?: Maybe<Scalars['Boolean']>,
};

export type UserStatistic = {
   __typename?: 'UserStatistic',
  createdAt: Scalars['Date'],
  date: Scalars['Date'],
  id: Scalars['ID'],
  normalSignupCount?: Maybe<Scalars['Int']>,
  orgSignupCount?: Maybe<Scalars['Int']>,
  totalSignupCount?: Maybe<Scalars['Int']>,
  updatedAt: Scalars['Date'],
};

export type UserStatisticConnection = {
   __typename?: 'UserStatisticConnection',
  nodes?: Maybe<Array<UserStatistic>>,
  totalCount: Scalars['Int'],
};

/** 유저의 채팅 알림 설정 */
export type UserSubscription = {
   __typename?: 'UserSubscription',
  subscribeChatNotification: Scalars['Boolean'],
};

export enum UserType {
  NORMAL = 'NORMAL',
  MANAGER = 'MANAGER',
  WRITER = 'WRITER',
  MANAGER_VERIFIED = 'MANAGER_VERIFIED',
  MANAGER_UNVERIFIED = 'MANAGER_UNVERIFIED',
  MANAGER_REJECTED = 'MANAGER_REJECTED'
}

export type UserUnlinkTimespreadPayload = {
   __typename?: 'UserUnlinkTimespreadPayload',
  user?: Maybe<User>,
};

export type UserUpdateBirthInput = {
  birthday: Scalars['Date'],
};

export type UserUpdatePayload = {
   __typename?: 'UserUpdatePayload',
  user?: Maybe<User>,
};

export type UserUpdatePhoneNumberAuthorizationInput = {
  authorizeID: Scalars['Int'],
  phoneNumber: Scalars['String'],
};

export type ViewerCheckedParagraphScrapGuidePayload = {
   __typename?: 'ViewerCheckedParagraphScrapGuidePayload',
  user?: Maybe<User>,
};

export type ViewerNewsItemFeedOrder = {
  direction: OrderDirection,
  field: ViewerNewsItemFeedOrderField,
};

export enum ViewerNewsItemFeedOrderField {
  CREATED_AT = 'CREATED_AT'
}

/** 복리 후생 */
export type WelfareBenefit = {
   __typename?: 'WelfareBenefit',
  /** 하위 depth 복리 후생 */
  children: Array<WelfareBenefit>,
  id: Scalars['ID'],
  /** 복리 후생 이름 */
  name: Scalars['String'],
  /** 상위 depth 복리 후생 */
  parent?: Maybe<WelfareBenefit>,
};

export type Workfield = {
   __typename?: 'Workfield',
  field: Scalars['String'],
  id: Scalars['ID'],
};

/** 근무 조건 입력 */
export type WorkingConditionInput = {
  /** 급여 */
  salary: WorkingConditionSalaryInput,
  /** 근무 시간 */
  workHour?: Maybe<WorkingConditionWorkHourInput>,
  /** 근무 지역 */
  workingRegions: Array<WorkingConditionRegionInput>,
};

/** 근무 조건 - 급여 입력 */
export type WorkingConditionRegionInput = {
  /** 주소 */
  address: Scalars['String'],
  /** 법정동/법정리 코드 */
  bcode: Scalars['String'],
  /** 법정동/법정리 */
  bname: Scalars['String'],
  /** 상세 주소 */
  detailAddress?: Maybe<Scalars['String']>,
  /** 해외 여부 */
  isAbroad: Scalars['Boolean'],
  /** 재택근무 가능 여부 */
  isPossibleWorkingFromHome: Scalars['Boolean'],
  /** 도/시 */
  sido: Scalars['String'],
  /** 시/군/구 */
  sigungu: Scalars['String'],
  /** 시/군/구 코드 */
  sigunguCode: Scalars['String'],
  /** 우편번호 */
  zoneCode: Scalars['String'],
};

/** 근무 조건 - 급여 입력 */
export type WorkingConditionSalaryInput = {
  /** 면접 후 급여 결정 여부 */
  doesSalaryDecidedAfterInterview: Scalars['Boolean'],
  /** 회사 내규에 따른 급여 여부 */
  doesSalaryDecidedByCompanyPolicy: Scalars['Boolean'],
  /** 최대 급여 */
  maxSalary?: Maybe<Scalars['Int']>,
  /** 최소 급여 */
  minSalary?: Maybe<Scalars['Int']>,
  /** 급여 유형 */
  salaryType?: Maybe<ActivityWorkingConditionSalarySalaryType>,
};

export type WorkingConditionWorkHourInput = {
  /** 면접 후에 근무 시간이 정해지는지 여부 */
  doesWorkHourDecidedAfterInterview?: Maybe<Scalars['Boolean']>,
  /** 근무 종료 시간 */
  workEndTime?: Maybe<Scalars['String']>,
  /** 근무 시간 유형 */
  workHourType?: Maybe<ActivityWorkingConditionWorkHourType>,
  /** 근무 시작 시간 */
  workStartTime?: Maybe<Scalars['String']>,
  /** 근무 시간 */
  workingHours?: Maybe<Scalars['String']>,
};

export type AuthLoginMutationVariables = {
  email: Scalars['String'],
  password: Scalars['String']
};


export type AuthLoginMutation = (
  { __typename?: 'Mutation' }
  & { login: Maybe<(
    { __typename?: 'LoginPayload' }
    & { user: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )> }
  )> }
);

export type AuthLogoutMutationVariables = {};


export type AuthLogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type AuthCheckQueryVariables = {};


export type AuthCheckQuery = (
  { __typename?: 'Query' }
  & { currentUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'isSuperuser'>
  )> }
);

export type AuthGetPermissionsQueryVariables = {};


export type AuthGetPermissionsQuery = (
  { __typename?: 'Query' }
  & { currentUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )> }
);

export type AttachmentDeleteMutationVariables = {
  attachmentID: Scalars['ID']
};


export type AttachmentDeleteMutation = (
  { __typename?: 'Mutation' }
  & { attachmentDelete: Maybe<(
    { __typename?: 'AttachmentDeletePayload' }
    & { attachment: Maybe<(
      { __typename?: 'Attachment' }
      & UseAttachmentFileDelete_AttachmentFragment
    )> }
  )> }
);

export type UseAttachmentFileDelete_AttachmentFragment = (
  { __typename?: 'Attachment' }
  & Pick<Attachment, 'id' | 'url' | 'name' | 'type' | 'size'>
);

export type AttachmentPrepareUploadMutationVariables = {
  name: Scalars['String'],
  size: Scalars['Int'],
  type: Scalars['String'],
  path?: Maybe<Scalars['String']>,
  useFileName?: Maybe<Scalars['Boolean']>
};


export type AttachmentPrepareUploadMutation = (
  { __typename?: 'Mutation' }
  & { attachmentPrepareUpload: Maybe<(
    { __typename?: 'AttachmentPrepareUploadPayload' }
    & Pick<AttachmentPrepareUploadPayload, 'signedURL'>
    & { attachment: (
      { __typename?: 'Attachment' }
      & Pick<Attachment, 'id' | 'url'>
    ) }
  )> }
);

export type AttachmentCompleteUploadMutationVariables = {
  attachmentID: Scalars['ID']
};


export type AttachmentCompleteUploadMutation = (
  { __typename?: 'Mutation' }
  & { attachmentCompleteUpload: Maybe<(
    { __typename?: 'AttachmentCompleteUploadPayload' }
    & { attachment: Maybe<(
      { __typename?: 'Attachment' }
      & Pick<Attachment, 'id'>
      & UseAttachmentFileUpload_AttachmentFragment
    )> }
  )> }
);

export type UseAttachmentFileUpload_AttachmentFragment = (
  { __typename?: 'Attachment' }
  & Pick<Attachment, 'id' | 'url' | 'name' | 'type' | 'size'>
);

export type UserStatisticsPageQueryVariables = {
  pagination: Pagination
};


export type UserStatisticsPageQuery = (
  { __typename?: 'Query' }
  & { userStatistics: (
    { __typename?: 'UserStatisticConnection' }
    & Pick<UserStatisticConnection, 'totalCount'>
    & { nodes: Maybe<Array<(
      { __typename?: 'UserStatistic' }
      & Pick<UserStatistic, 'id' | 'date' | 'createdAt' | 'orgSignupCount' | 'totalSignupCount' | 'normalSignupCount'>
    )>> }
  ) }
);

export type ActivityFragmentFragment = (
  { __typename?: 'Activity' }
  & Pick<Activity, 'id' | 'activityTypeID' | 'title' | 'recruitCloseAt' | 'createdAt' | 'status' | 'refusalReason' | 'organizationName' | 'statusUpdatedAt'>
  & { manager: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'username' | 'email' | 'phoneNumber'>
  )> }
);

export type ActivityGetOneQueryVariables = {
  id: Scalars['ID']
};


export type ActivityGetOneQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'Activity' }
    & ActivityFragmentFragment
  )> }
);

export type ActivityGetListQueryVariables = {
  filterBy?: Maybe<ActivityFilter>,
  orderBy?: Maybe<ActivityOrder>,
  pagination?: Maybe<Pagination>
};


export type ActivityGetListQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'ActivityConnection' }
    & { total: ActivityConnection['totalCount'] }
    & { data: Maybe<Array<(
      { __typename?: 'Activity' }
      & ActivityFragmentFragment
    )>> }
  )> }
);

export type ActivityGetManyQueryVariables = {
  ids: Array<Scalars['ID']>
};


export type ActivityGetManyQuery = (
  { __typename?: 'Query' }
  & { data: Array<(
    { __typename?: 'Activity' }
    & ActivityFragmentFragment
  )> }
);

export type ActivityUpdateMutationVariables = {
  input: ActivityUpdateInput
};


export type ActivityUpdateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'ActivityUpdatePayload' }
    & { data: Maybe<(
      { __typename?: 'Activity' }
      & ActivityFragmentFragment
    )> }
  )> }
);

export type ActivitiesStatusUpdateMutationVariables = {
  input: ActivitiesStatusUpdateInput
};


export type ActivitiesStatusUpdateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'ActivitiesStatusUpdatePayload' }
    & { data: ActivitiesStatusUpdatePayload['count'] }
  )> }
);

export type ActivityDeleteMutationVariables = {
  id: Scalars['ID']
};


export type ActivityDeleteMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'ActivityDeletePayload' }
    & { data: Maybe<(
      { __typename?: 'Activity' }
      & ActivityFragmentFragment
    )> }
  )> }
);

export type ActivitiesDeleteMutationVariables = {
  ids: Array<Scalars['ID']>
};


export type ActivitiesDeleteMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'ActivitiesDeletePayload' }
    & { data: Maybe<Array<Maybe<(
      { __typename?: 'Activity' }
      & Pick<Activity, 'id'>
    )>>> }
  )> }
);

export type AdvertisementFragment = (
  { __typename?: 'Advertisement' }
  & Pick<Advertisement, 'id' | 'adType' | 'activityID' | 'outLink' | 'title' | 'content' | 'pcImage' | 'mobileImage' | 'startAt' | 'endAt' | 'status' | 'seq'>
  & { activity: Maybe<(
    { __typename?: 'Activity' }
    & Pick<Activity, 'id' | 'title'>
  )> }
);

export type AdvertisementGetQueryVariables = {
  id: Scalars['ID']
};


export type AdvertisementGetQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'Advertisement' }
    & AdvertisementFragment
  )> }
);

export type AdvertisementGetListQueryVariables = {
  filterBy: AdvertisementFilter,
  orderBy?: Maybe<AdvertisementOrder>,
  pagination?: Maybe<Pagination>
};


export type AdvertisementGetListQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'AdvertisementListPayload' }
    & { total: AdvertisementListPayload['totalCount'] }
    & { data: Maybe<Array<(
      { __typename?: 'Advertisement' }
      & AdvertisementFragment
    )>> }
  )> }
);

export type AdvertisementDeleteMutationVariables = {
  id: Scalars['ID']
};


export type AdvertisementDeleteMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'AdvertisementDeletePayload' }
    & { data: Maybe<(
      { __typename?: 'Advertisement' }
      & AdvertisementFragment
    )> }
  )> }
);

export type AdvertisementDeleteManyMutationVariables = {
  ids: Array<Scalars['ID']>
};


export type AdvertisementDeleteManyMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'AdvertisementBulkDeletePayload' }
    & { data: Maybe<Array<(
      { __typename?: 'Advertisement' }
      & AdvertisementFragment
    )>> }
  )> }
);

export type SearchExactCoverLetterQueryVariables = {
  query: Scalars['String'],
  field: CoverLetterField
};


export type SearchExactCoverLetterQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<Array<Maybe<(
    { __typename?: 'SearchExactCoverLetterPayload' }
    & Pick<SearchExactCoverLetterPayload, 'count' | 'query'>
  )>>> }
);

export type UpdateCoverLetterFieldByQueryMutationVariables = {
  input: UpdateCoverLetterFieldByQueryInput
};


export type UpdateCoverLetterFieldByQueryMutation = (
  { __typename?: 'Mutation' }
  & { updateCoverLetterFieldByQuery: Maybe<(
    { __typename?: 'UpdateCoverLetterFieldByQueryPayload' }
    & { updatedCoverLetters: Maybe<Array<Maybe<(
      { __typename?: 'CoverLetter' }
      & Pick<CoverLetter, 'organizationName' | 'role'>
    )>>> }
  )> }
);

export type CoverLetterFragmentFragment = (
  { __typename?: 'CoverLetter' }
  & Pick<CoverLetter, 'id' | 'passedAt' | 'organizationName' | 'role' | 'university' | 'major' | 'grades' | 'languageScore' | 'career' | 'content' | 'types' | 'createdAt' | 'updatedAt' | 'deletedAt' | 'source' | 'status' | 'score' | 'sourceKey' | 'scrapCount' | 'activity' | 'license'>
);

export type CoverLetterGetQueryVariables = {
  id: Scalars['ID']
};


export type CoverLetterGetQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'CoverLetter' }
    & CoverLetterFragmentFragment
  )> }
);

export type CoverLetterGetListQueryVariables = {
  filterBy: CoverLetterFilter,
  orderBy?: Maybe<CoverLetterOrder>,
  pagination?: Maybe<Pagination>
};


export type CoverLetterGetListQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'CoverLetterElasticConnection' }
    & { total: CoverLetterElasticConnection['totalCount'] }
    & { data: Maybe<Array<(
      { __typename?: 'CoverLetterElasticEdge' }
      & Pick<CoverLetterElasticEdge, 'highlight'>
      & { node: Maybe<(
        { __typename?: 'CoverLetter' }
        & Pick<CoverLetter, 'id'>
        & CoverLetterFragmentFragment
      )> }
    )>> }
  )> }
);

export type CoverLetterCreateMutationVariables = {
  input: CoverLetterCreateInput
};


export type CoverLetterCreateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'CoverLetterCreatePayload' }
    & { data: Maybe<(
      { __typename?: 'CoverLetter' }
      & CoverLetterFragmentFragment
    )> }
  )> }
);

export type CoverLetterUpdateMutationVariables = {
  input: CoverLetterUpdateInput
};


export type CoverLetterUpdateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'CoverLetterUpdatePayload' }
    & { data: Maybe<(
      { __typename?: 'CoverLetter' }
      & CoverLetterFragmentFragment
    )> }
  )> }
);

export type CoverLetterDeleteManyMutationVariables = {
  ids: Array<Scalars['ID']>
};


export type CoverLetterDeleteManyMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'coverLetterDeleteManyPayload' }
    & { data: Maybe<Array<Maybe<(
      { __typename?: 'CoverLetter' }
      & CoverLetterFragmentFragment
    )>>> }
  )> }
);

export type CoverLetterSyncWithElasticMutationVariables = {};


export type CoverLetterSyncWithElasticMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'syncCoverLetterWithElastic'>
);

export type CurationFragmentFragment = (
  { __typename?: 'Curation' }
  & Pick<Curation, 'id' | 'title' | 'url' | 'seq'>
);

export type CurationGetListQueryVariables = {};


export type CurationGetListQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'CurationListConnection' }
    & { total: CurationListConnection['totalCount'] }
    & { data: Maybe<Array<Maybe<(
      { __typename?: 'Curation' }
      & CurationFragmentFragment
    )>>> }
  )> }
);

export type CurationGetQueryVariables = {
  id: Scalars['ID']
};


export type CurationGetQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'Curation' }
    & CurationFragmentFragment
  )> }
);

export type CurationCreateMutationVariables = {
  input: CurationCreateInput
};


export type CurationCreateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'CurationCreatePayload' }
    & { data: Maybe<(
      { __typename?: 'Curation' }
      & CurationFragmentFragment
    )> }
  )> }
);

export type CurationUpdateMutationVariables = {
  id: Scalars['ID'],
  input: CurationUpdateInput
};


export type CurationUpdateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'CurationUpdatePayload' }
    & { data: Maybe<(
      { __typename?: 'Curation' }
      & CurationFragmentFragment
    )> }
  )> }
);

export type UsersByEmailFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'email' | 'username'>
  & { profile: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id'>
    & { identificationImage: Maybe<(
      { __typename?: 'ApplyFile' }
      & Pick<ApplyFile, 'id' | 'url'>
    )> }
  )> }
);

export type UsersByEmailQueryVariables = {
  email: Scalars['String']
};


export type UsersByEmailQuery = (
  { __typename?: 'Query' }
  & { usersByEmail: Array<(
    { __typename?: 'User' }
    & UsersByEmailFragment
  )> }
);

export type UserProfileImageDeleteMutationVariables = {
  id: Scalars['ID']
};


export type UserProfileImageDeleteMutation = (
  { __typename?: 'Mutation' }
  & { userProfileImageDelete: Maybe<(
    { __typename?: 'ApplyFile' }
    & Pick<ApplyFile, 'id' | 'url'>
  )> }
);

export type SendJobPostingDataToNaverMutationVariables = {};


export type SendJobPostingDataToNaverMutation = (
  { __typename?: 'Mutation' }
  & { sendJobPostingDataToNaver: Maybe<(
    { __typename?: 'SendJobPostingDataToNaverPayload' }
    & Pick<SendJobPostingDataToNaverPayload, 'data' | 'totalCount'>
  )> }
);

export type RecommendKeywordFragmentFragment = (
  { __typename?: 'RecommendSearchKeyword' }
  & Pick<RecommendSearchKeyword, 'id' | 'title' | 'seq' | 'searchField'>
);

export type RecommendKeywordGetListQueryVariables = {
  type?: Maybe<RecommendSearchKeywordType>
};


export type RecommendKeywordGetListQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'RecommendSearchKeywordListConnection' }
    & { total: RecommendSearchKeywordListConnection['totalCount'] }
    & { data: Maybe<Array<Maybe<(
      { __typename?: 'RecommendSearchKeyword' }
      & RecommendKeywordFragmentFragment
    )>>> }
  )> }
);

export type RecommendKeywordGetQueryVariables = {
  id: Scalars['ID']
};


export type RecommendKeywordGetQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'RecommendSearchKeyword' }
    & RecommendKeywordFragmentFragment
  )> }
);

export type RecommendKeywordCreateMutationVariables = {
  input: RecommendSearchKeywordCreateInput
};


export type RecommendKeywordCreateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'RecommendSearchKeywordCreatePayload' }
    & { data: Maybe<(
      { __typename?: 'RecommendSearchKeyword' }
      & RecommendKeywordFragmentFragment
    )> }
  )> }
);

export type RecommendKeywordUpdateMutationVariables = {
  id: Scalars['ID'],
  input: RecommendSearchKeywordUpdateInput
};


export type RecommendKeywordUpdateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'RecommendSearchKeywordUpdatePayload' }
    & { data: Maybe<(
      { __typename?: 'RecommendSearchKeyword' }
      & RecommendKeywordFragmentFragment
    )> }
  )> }
);

export type ReplyFragmentFragment = (
  { __typename?: 'Reply' }
  & Pick<Reply, 'id' | 'content' | 'createdAt'>
  & { activity: Maybe<(
    { __typename?: 'Activity' }
    & Pick<Activity, 'id'>
  )>, user: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )>, replies: Maybe<Array<Maybe<(
    { __typename?: 'Reply' }
    & Pick<Reply, 'id' | 'content' | 'createdAt'>
    & { activity: Maybe<(
      { __typename?: 'Activity' }
      & Pick<Activity, 'id'>
    )>, user: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )> }
  )>>> }
);

export type ReplyGetOneQueryVariables = {
  id: Scalars['ID']
};


export type ReplyGetOneQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'Reply' }
    & ReplyFragmentFragment
  )> }
);

export type ReplyGetListQueryVariables = {
  filterBy?: Maybe<ReplyFilters>,
  orderBy?: Maybe<ReplyOrder>,
  pagination?: Maybe<Pagination>
};


export type ReplyGetListQuery = (
  { __typename?: 'Query' }
  & { data: (
    { __typename?: 'ReplyConnection' }
    & { total: ReplyConnection['totalCount'] }
    & { data: Array<(
      { __typename?: 'Reply' }
      & ReplyFragmentFragment
    )> }
  ) }
);

export type ReplyDeleteMutationVariables = {
  id: Scalars['ID']
};


export type ReplyDeleteMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'ReplyDeletePayload' }
    & { data: Maybe<(
      { __typename?: 'Reply' }
      & ReplyFragmentFragment
    )> }
  )> }
);

export type SignupSessionFragmentFragment = (
  { __typename?: 'SignupSession' }
  & Pick<SignupSession, 'snsID' | 'snsType' | 'displayName' | 'email' | 'ipAddress' | 'signup' | 'signupSession' | 'signupAt' | 'signupElapsedTimeInSeconds' | 'createdAt'>
  & { id: SignupSession['token'] }
  & { user: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )>, timespread: Maybe<(
    { __typename?: 'TimespreadAccount' }
    & Pick<TimespreadAccount, 'id' | 'name' | 'email' | 'birthday' | 'profileURL' | 'phoneNumber' | 'cash' | 'facebookID' | 'kakaoID' | 'naverID'>
  )>, signupUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )> }
);

export type SignupSessionDetailFragmentFragment = (
  { __typename?: 'SignupSession' }
  & SignupSessionFragmentFragment
);

export type SignupSessionGetListQueryVariables = {
  filterBy?: Maybe<SignupSessionFilters>,
  orderBy?: Maybe<SignupSessionOrder>,
  pagination?: Maybe<Pagination>
};


export type SignupSessionGetListQuery = (
  { __typename?: 'Query' }
  & { data: (
    { __typename?: 'SignupSessionConnection' }
    & { total: SignupSessionConnection['totalCount'] }
    & { data: Maybe<Array<Maybe<(
      { __typename?: 'SignupSession' }
      & SignupSessionFragmentFragment
    )>>> }
  ) }
);

export type SignupSessionGetQueryVariables = {
  id: Scalars['ID']
};


export type SignupSessionGetQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'SignupSession' }
    & SignupSessionDetailFragmentFragment
  )> }
);

export type SignupSessionGetManyQueryVariables = {
  ids: Array<Scalars['ID']>
};


export type SignupSessionGetManyQuery = (
  { __typename?: 'Query' }
  & { data: Array<Maybe<(
    { __typename?: 'SignupSession' }
    & SignupSessionFragmentFragment
  )>> }
);

export type SignupUserFragmentFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'type' | 'username' | 'email' | 'birthday' | 'phoneNumber' | 'createdAt' | 'lastLoginAt' | 'specupJoined' | 'specupJoinedAt' | 'specupJoinedSession' | 'deactivated'>
  & { timespread: Maybe<(
    { __typename?: 'TimespreadAccount' }
    & Pick<TimespreadAccount, 'id' | 'name' | 'email' | 'birthday' | 'profileURL' | 'phoneNumber' | 'cash' | 'facebookID' | 'kakaoID' | 'naverID'>
  )>, facebook: Maybe<(
    { __typename?: 'FacebookAccount' }
    & Pick<FacebookAccount, 'id'>
  )>, kakao: Maybe<(
    { __typename?: 'KakaoAccount' }
    & Pick<KakaoAccount, 'id'>
  )>, naver: Maybe<(
    { __typename?: 'NaverAccount' }
    & Pick<NaverAccount, 'id'>
  )> }
);

export type SignupUserDetailFragmentFragment = (
  { __typename?: 'User' }
  & Pick<User, 'lastLoginSession' | 'timespreadID' | 'timespreadAuthKey'>
  & SignupUserFragmentFragment
);

export type SignupUserGetListQueryVariables = {
  filterBy?: Maybe<UserFilters>,
  orderBy?: Maybe<UserOrder>,
  pagination?: Maybe<Pagination>
};


export type SignupUserGetListQuery = (
  { __typename?: 'Query' }
  & { data: (
    { __typename?: 'UserConnection' }
    & { total: UserConnection['totalCount'] }
    & { data: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & SignupUserFragmentFragment
    )>>> }
  ) }
);

export type SignupUserGetQueryVariables = {
  id: Scalars['ID']
};


export type SignupUserGetQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'User' }
    & SignupUserDetailFragmentFragment
  )> }
);

export type SignupUserGetManyQueryVariables = {
  ids: Array<Scalars['ID']>
};


export type SignupUserGetManyQuery = (
  { __typename?: 'Query' }
  & { data: Array<Maybe<(
    { __typename?: 'User' }
    & SignupUserFragmentFragment
  )>> }
);

export type SignupUserUpdateMutationVariables = {
  id: Scalars['ID'],
  input: UserInput
};


export type SignupUserUpdateMutation = (
  { __typename?: 'Mutation' }
  & { data: (
    { __typename?: 'UserUpdatePayload' }
    & { data: Maybe<(
      { __typename?: 'User' }
      & SignupUserDetailFragmentFragment
    )> }
  ) }
);

export type SignupUserDeleteMutationVariables = {
  id: Scalars['ID']
};


export type SignupUserDeleteMutation = (
  { __typename?: 'Mutation' }
  & { data: (
    { __typename?: 'UserDeletePayload' }
    & { data: Maybe<(
      { __typename?: 'User' }
      & SignupUserFragmentFragment
    )> }
  ) }
);

export type SignupUserDeleteManyMutationVariables = {
  ids: Array<Scalars['ID']>
};


export type SignupUserDeleteManyMutation = (
  { __typename?: 'Mutation' }
  & { data: (
    { __typename?: 'UserDeleteManyPayload' }
    & { data: Array<Maybe<(
      { __typename?: 'User' }
      & SignupUserFragmentFragment
    )>> }
  ) }
);

export type UserFragmentFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'type' | 'username' | 'email' | 'birthday' | 'phoneNumber' | 'createdAt' | 'lastLoginAt' | 'specupJoined' | 'specupJoinedAt' | 'specupJoinedSession' | 'deactivated' | 'deletedAt'>
  & { signupSession: Maybe<(
    { __typename?: 'SignupSession' }
    & Pick<SignupSession, 'ipAddress'>
  )>, timespread: Maybe<(
    { __typename?: 'TimespreadAccount' }
    & Pick<TimespreadAccount, 'id' | 'name' | 'email' | 'birthday' | 'profileURL' | 'phoneNumber' | 'cash' | 'facebookID' | 'kakaoID' | 'naverID'>
  )>, facebook: Maybe<(
    { __typename?: 'FacebookAccount' }
    & Pick<FacebookAccount, 'id'>
  )>, kakao: Maybe<(
    { __typename?: 'KakaoAccount' }
    & Pick<KakaoAccount, 'id'>
  )>, naver: Maybe<(
    { __typename?: 'NaverAccount' }
    & Pick<NaverAccount, 'id'>
  )> }
);

export type UserDetailFragmentFragment = (
  { __typename?: 'User' }
  & Pick<User, 'lastLoginSession'>
  & UserFragmentFragment
);

export type UserGetListQueryVariables = {
  filterBy?: Maybe<UserFilters>,
  orderBy?: Maybe<UserOrder>,
  pagination?: Maybe<Pagination>
};


export type UserGetListQuery = (
  { __typename?: 'Query' }
  & { data: (
    { __typename?: 'UserConnection' }
    & { total: UserConnection['totalCount'] }
    & { data: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & UserFragmentFragment
    )>>> }
  ) }
);

export type UserGetQueryVariables = {
  id: Scalars['ID']
};


export type UserGetQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'User' }
    & UserDetailFragmentFragment
  )> }
);

export type UserGetManyQueryVariables = {
  ids: Array<Scalars['ID']>
};


export type UserGetManyQuery = (
  { __typename?: 'Query' }
  & { data: Array<Maybe<(
    { __typename?: 'User' }
    & UserFragmentFragment
  )>> }
);

export type UserUpdateMutationVariables = {
  id: Scalars['ID'],
  input: UserInput
};


export type UserUpdateMutation = (
  { __typename?: 'Mutation' }
  & { data: (
    { __typename?: 'UserUpdatePayload' }
    & { data: Maybe<(
      { __typename?: 'User' }
      & UserDetailFragmentFragment
    )> }
  ) }
);

export type UserDeleteMutationVariables = {
  id: Scalars['ID']
};


export type UserDeleteMutation = (
  { __typename?: 'Mutation' }
  & { data: (
    { __typename?: 'DeleteUserAccountPayload' }
    & { data: Maybe<(
      { __typename?: 'User' }
      & UserFragmentFragment
    )> }
  ) }
);

export type UserDeleteManyMutationVariables = {
  ids: Array<Scalars['ID']>
};


export type UserDeleteManyMutation = (
  { __typename?: 'Mutation' }
  & { data: (
    { __typename?: 'DeleteManyUserAccountsPayload' }
    & { data: Array<Maybe<(
      { __typename?: 'User' }
      & UserFragmentFragment
    )>> }
  ) }
);

export type ActivityJudgeMutationVariables = {
  input: ActivitiesStatusUpdateInput
};


export type ActivityJudgeMutation = (
  { __typename?: 'Mutation' }
  & { activitiesStatusUpdate: Maybe<(
    { __typename?: 'ActivitiesStatusUpdatePayload' }
    & Pick<ActivitiesStatusUpdatePayload, 'count'>
    & { activities: Maybe<Array<Maybe<(
      { __typename?: 'Activity' }
      & ActivityFragmentFragment
    )>>> }
  )> }
);

export type InvalidateActivityCacheMutationVariables = {
  id: Scalars['ID']
};


export type InvalidateActivityCacheMutation = (
  { __typename?: 'Mutation' }
  & { invalidateActivityCache: Maybe<(
    { __typename?: 'Activity' }
    & Pick<Activity, 'id'>
  )> }
);

export type AdvertisementForm_ActivityIdField_ActivityQueryVariables = {
  id: Scalars['ID']
};


export type AdvertisementForm_ActivityIdField_ActivityQuery = (
  { __typename?: 'Query' }
  & { activity: Maybe<(
    { __typename?: 'Activity' }
    & Pick<Activity, 'status'>
  )> }
);

export type AdvertisementForm_AdvertisementFragment = (
  { __typename?: 'Advertisement' }
  & Pick<Advertisement, 'id' | 'activityID' | 'title' | 'pcImage' | 'mobileImage' | 'outLink' | 'startAt' | 'endAt' | 'seq'>
);

export type AdvertisementForm_AdvertisementQueryVariables = {
  id: Scalars['ID']
};


export type AdvertisementForm_AdvertisementQuery = (
  { __typename?: 'Query' }
  & { advertisement: Maybe<(
    { __typename?: 'Advertisement' }
    & AdvertisementForm_AdvertisementFragment
  )> }
);

export type AdvertisementForm_UpdateMutationVariables = {
  input: AdvertisementUpdateInput
};


export type AdvertisementForm_UpdateMutation = (
  { __typename?: 'Mutation' }
  & { advertisementUpdate: Maybe<(
    { __typename?: 'AdvertisementUpdatePayload' }
    & { advertisement: Maybe<(
      { __typename?: 'Advertisement' }
      & AdvertisementForm_AdvertisementFragment
    )> }
  )> }
);

export type AdvertisementForm_CreateMutationVariables = {
  input: AdvertisementCreateInput
};


export type AdvertisementForm_CreateMutation = (
  { __typename?: 'Mutation' }
  & { advertisementCreate: Maybe<(
    { __typename?: 'AdvertisementCreatePayload' }
    & { advertisement: Maybe<(
      { __typename?: 'Advertisement' }
      & AdvertisementForm_AdvertisementFragment
    )> }
  )> }
);

export type AdvertisementForm_DeleteMutationVariables = {
  id: Scalars['ID']
};


export type AdvertisementForm_DeleteMutation = (
  { __typename?: 'Mutation' }
  & { advertisementDelete: Maybe<(
    { __typename?: 'AdvertisementDeletePayload' }
    & { advertisement: Maybe<(
      { __typename?: 'Advertisement' }
      & AdvertisementForm_AdvertisementFragment
    )> }
  )> }
);

export type AdvertisementList_AdvertisementsQueryVariables = {
  filterBy: AdvertisementFilter
};


export type AdvertisementList_AdvertisementsQuery = (
  { __typename?: 'Query' }
  & { advertisementList: Maybe<(
    { __typename?: 'AdvertisementListPayload' }
    & { advertisements: Maybe<Array<(
      { __typename?: 'Advertisement' }
      & Pick<Advertisement, 'status'>
    )>> }
  )> }
);

export type AdvertisementInput_AdPlacementFragment = (
  { __typename?: 'AdPlacement' }
  & Pick<AdPlacement, 'name' | 'isActive' | 'width' | 'height'>
  & { adPlacementID: AdPlacement['id'] }
);

export type AdvertisementInput_PlacementListQueryVariables = {};


export type AdvertisementInput_PlacementListQuery = (
  { __typename?: 'Query' }
  & { adPlacements: (
    { __typename?: 'AdPlacementConnection' }
    & { nodes: Array<(
      { __typename?: 'AdPlacement' }
      & AdvertisementInput_AdPlacementFragment
    )> }
  ) }
);

export type CampaignCreate_CampaignCreateMutationVariables = {
  input: CampaignCreateInput
};


export type CampaignCreate_CampaignCreateMutation = (
  { __typename?: 'Mutation' }
  & { campaignCreate: Maybe<(
    { __typename?: 'CampaignCreatePayload' }
    & { campaign: Maybe<(
      { __typename?: 'Campaign' }
      & Pick<Campaign, 'id'>
    )> }
  )> }
);

export type CampaignEdit_CampaignUpdateMutationVariables = {
  id: Scalars['ID'],
  input: CampaignUpdateInput
};


export type CampaignEdit_CampaignUpdateMutation = (
  { __typename?: 'Mutation' }
  & { campaignUpdate: Maybe<(
    { __typename?: 'CampaignUpdatePayload' }
    & { campaign: Maybe<(
      { __typename?: 'Campaign' }
      & Pick<Campaign, 'id'>
    )> }
  )> }
);

export type CampaignEdit_CampaignDeleteMutationVariables = {
  id: Scalars['ID']
};


export type CampaignEdit_CampaignDeleteMutation = (
  { __typename?: 'Mutation' }
  & { campaignDelete: Maybe<(
    { __typename?: 'CampaignDeletePayload' }
    & { campaign: Maybe<(
      { __typename?: 'Campaign' }
      & Pick<Campaign, 'id'>
    )> }
  )> }
);

export type CampaignList_CampaignFragment = (
  { __typename?: 'Campaign' }
  & Pick<Campaign, 'id' | 'name' | 'isActive' | 'startAt' | 'endAt' | 'status'>
  & { ads: Array<(
    { __typename?: 'Ad' }
    & Pick<Ad, 'id'>
    & { adPlacement: Maybe<(
      { __typename?: 'AdPlacement' }
      & Pick<AdPlacement, 'id' | 'name'>
    )> }
  )> }
);

export type CampaignListQueryVariables = {
  filterBy?: Maybe<CampaignFilters>,
  orderBy?: Maybe<CampaignOrder>,
  pagination?: Maybe<Pagination>
};


export type CampaignListQuery = (
  { __typename?: 'Query' }
  & { campaigns: (
    { __typename?: 'CampaignConnection' }
    & Pick<CampaignConnection, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'Campaign' }
      & CampaignList_CampaignFragment
    )> }
  ) }
);

export type CampaignReport_CampaignStatisticQueryVariables = {
  filterBy?: Maybe<CampaignStatisticFilters>
};


export type CampaignReport_CampaignStatisticQuery = (
  { __typename?: 'Query' }
  & { campaignStatistics: Maybe<(
    { __typename?: 'CampaignStatisticConnection' }
    & Pick<CampaignStatisticConnection, 'totalImpressionCount' | 'totalClickCount'>
    & { nodes: Array<(
      { __typename?: 'CampaignStatistic' }
      & Pick<CampaignStatistic, 'id' | 'date' | 'impressionCount' | 'clickCount' | 'ctr'>
      & { adPlacement: Maybe<(
        { __typename?: 'AdPlacement' }
        & Pick<AdPlacement, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type CampaignReportDialog_CampaignQueryVariables = {
  id: Scalars['ID']
};


export type CampaignReportDialog_CampaignQuery = (
  { __typename?: 'Query' }
  & { campaign: Maybe<(
    { __typename?: 'Campaign' }
    & Pick<Campaign, 'id' | 'name'>
    & { ads: Array<(
      { __typename?: 'Ad' }
      & { adPlacement: Maybe<(
        { __typename?: 'AdPlacement' }
        & Pick<AdPlacement, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type Campaign_CampaignFragment = (
  { __typename?: 'Campaign' }
  & Pick<Campaign, 'id' | 'name' | 'content' | 'isActive' | 'landingURL' | 'startAt' | 'endAt' | 'status' | 'createdAt' | 'updatedAt'>
  & { user: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'username'>
  )>, ads: Array<(
    { __typename?: 'Ad' }
    & Pick<Ad, 'id'>
    & { adPlacement: Maybe<(
      { __typename?: 'AdPlacement' }
      & Pick<AdPlacement, 'id' | 'name'>
    )>, image: Maybe<(
      { __typename?: 'Attachment' }
      & Pick<Attachment, 'id'>
    )> }
  )>, schedules: Array<(
    { __typename?: 'CampaignSchedule' }
    & Pick<CampaignSchedule, 'dayOfWeek' | 'startTime' | 'endTime'>
  )> }
);

export type CampaignGetOneQueryVariables = {
  id: Scalars['ID']
};


export type CampaignGetOneQuery = (
  { __typename?: 'Query' }
  & { campaign: Maybe<(
    { __typename?: 'Campaign' }
    & Campaign_CampaignFragment
  )> }
);

export type PlacementCreate_AdPlacementCreateMutationVariables = {
  input: AdPlacementCreateInput
};


export type PlacementCreate_AdPlacementCreateMutation = (
  { __typename?: 'Mutation' }
  & { adPlacementCreate: Maybe<(
    { __typename?: 'AdPlacementCreatePayload' }
    & { adPlacement: Maybe<(
      { __typename?: 'AdPlacement' }
      & Pick<AdPlacement, 'id'>
    )> }
  )> }
);

export type PlacementEdit_AdPlacementUpdateMutationVariables = {
  id: Scalars['ID'],
  input: AdPlacementUpdateInput
};


export type PlacementEdit_AdPlacementUpdateMutation = (
  { __typename?: 'Mutation' }
  & { adPlacementUpdate: Maybe<(
    { __typename?: 'AdPlacementUpdatePayload' }
    & { adPlacement: Maybe<(
      { __typename?: 'AdPlacement' }
      & Pick<AdPlacement, 'id'>
    )> }
  )> }
);

export type PlacementList_AdPlacementFragment = (
  { __typename?: 'AdPlacement' }
  & Pick<AdPlacement, 'id' | 'name' | 'isActive' | 'width' | 'height' | 'defaultLandingURL'>
  & { defaultImage: Maybe<(
    { __typename?: 'Attachment' }
    & Pick<Attachment, 'id'>
  )> }
);

export type PlacementListQueryVariables = {
  filterBy?: Maybe<AdPlacementFilters>,
  orderBy?: Maybe<AdPlacementOrder>,
  pagination?: Maybe<Pagination>
};


export type PlacementListQuery = (
  { __typename?: 'Query' }
  & { adPlacements: (
    { __typename?: 'AdPlacementConnection' }
    & Pick<AdPlacementConnection, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'AdPlacement' }
      & PlacementList_AdPlacementFragment
    )> }
  ) }
);

export type Placement_AdPlacementFragment = (
  { __typename?: 'AdPlacement' }
  & Pick<AdPlacement, 'id' | 'code' | 'name' | 'width' | 'height' | 'isActive' | 'defaultLandingURL' | 'createdAt' | 'updatedAt'>
  & { defaultImage: Maybe<(
    { __typename?: 'Attachment' }
    & Pick<Attachment, 'id'>
  )> }
);

export type PlacementGetOneQueryVariables = {
  id: Scalars['ID']
};


export type PlacementGetOneQuery = (
  { __typename?: 'Query' }
  & { adPlacement: Maybe<(
    { __typename?: 'AdPlacement' }
    & Placement_AdPlacementFragment
  )> }
);

export type BulkCoverLetterTypeAddMutationVariables = {
  input: CoverLetterManyAddTypeInput
};


export type BulkCoverLetterTypeAddMutation = (
  { __typename?: 'Mutation' }
  & { coverLetterManyAddType: Maybe<(
    { __typename?: 'CoverLetterManyAddTypePayload' }
    & { updatedCoverLetters: Maybe<Array<Maybe<(
      { __typename?: 'CoverLetter' }
      & CoverLetterFragmentFragment
    )>>> }
  )> }
);

export type CoverLettersPlagiarismQueryVariables = {
  id: Scalars['ID']
};


export type CoverLettersPlagiarismQuery = (
  { __typename?: 'Query' }
  & { coverLetterPlagiarismList: Maybe<(
    { __typename?: 'CoverLetterPlagiarismConnection' }
    & Pick<CoverLetterPlagiarismConnection, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'CoverLetterPlagiarism' }
      & Pick<CoverLetterPlagiarism, 'id' | 'content' | 'score'>
    )> }
  )> }
);

export type OrganizationDetail_AttachmentFragment = (
  { __typename?: 'Attachment' }
  & Pick<Attachment, 'id' | 'url' | 'name'>
);

export type OrganizationDetail_OrganizationTypeFragment = (
  { __typename?: 'OrganizationType' }
  & Pick<OrganizationType, 'id' | 'name'>
);

export type OrganizationDetail_OrganizationFragment = (
  { __typename?: 'Organization' }
  & Pick<Organization, 'id' | 'name' | 'registrationNumber' | 'isConfirmed' | 'representative' | 'alias'>
  & { organizationType: Maybe<(
    { __typename?: 'OrganizationType' }
    & OrganizationDetail_OrganizationTypeFragment
  )>, licenseImages: Array<Maybe<(
    { __typename?: 'Attachment' }
    & OrganizationDetail_AttachmentFragment
  )>> }
);

export type OrganizationDetail_UserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'login' | 'type' | 'managerName' | 'phoneNumber' | 'email' | 'deactivated' | 'deletedAt'>
  & { organization: Maybe<(
    { __typename?: 'Organization' }
    & OrganizationDetail_OrganizationFragment
  )> }
);

export type OrganizationDetailUserQueryVariables = {
  id: Scalars['ID']
};


export type OrganizationDetailUserQuery = (
  { __typename?: 'Query' }
  & { user: Maybe<(
    { __typename?: 'User' }
    & OrganizationDetail_UserFragment
  )> }
);

export type OrganizationDetailOrganizationTypesQueryVariables = {};


export type OrganizationDetailOrganizationTypesQuery = (
  { __typename?: 'Query' }
  & { organizationTypes: Maybe<Array<Maybe<(
    { __typename?: 'OrganizationType' }
    & OrganizationDetail_OrganizationTypeFragment
  )>>> }
);

export type OrganizationDetail_UserUpdateMutationVariables = {
  id: Scalars['ID'],
  input: UserInput
};


export type OrganizationDetail_UserUpdateMutation = (
  { __typename?: 'Mutation' }
  & { userUpdate: (
    { __typename?: 'UserUpdatePayload' }
    & { user: Maybe<(
      { __typename?: 'User' }
      & OrganizationDetail_UserFragment
    )> }
  ) }
);

export type CurationDeleteMutationVariables = {
  id: Scalars['ID']
};


export type CurationDeleteMutation = (
  { __typename?: 'Mutation' }
  & { curationDelete: Maybe<(
    { __typename?: 'CurationDeletePayload' }
    & { curation: Maybe<(
      { __typename?: 'Curation' }
      & Pick<Curation, 'id'>
    )> }
  )> }
);

export type RecommendKeywordDeleteMutationVariables = {
  id: Scalars['ID']
};


export type RecommendKeywordDeleteMutation = (
  { __typename?: 'Mutation' }
  & { recommendSearchKeywordDelete: Maybe<(
    { __typename?: 'RecommendSearchKeywordDeletePayload' }
    & { recommendSearchKeyword: Maybe<(
      { __typename?: 'RecommendSearchKeyword' }
      & Pick<RecommendSearchKeyword, 'id'>
    )> }
  )> }
);

export type UserUnlinkTimespreadMutationVariables = {
  userID: Scalars['ID']
};


export type UserUnlinkTimespreadMutation = (
  { __typename?: 'Mutation' }
  & { userUnlinkTimespread: (
    { __typename?: 'UserUnlinkTimespreadPayload' }
    & { user: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )> }
  ) }
);

export type UserDeactivateMutationVariables = {
  userID: Scalars['ID']
};


export type UserDeactivateMutation = (
  { __typename?: 'Mutation' }
  & { deleteUserAccount: (
    { __typename?: 'DeleteUserAccountPayload' }
    & { user: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )> }
  ) }
);

export type LoginByAdminMutationVariables = {
  id: Scalars['ID']
};


export type LoginByAdminMutation = (
  { __typename?: 'Mutation' }
  & { loginByAdmin: Maybe<(
    { __typename?: 'LoginPayload' }
    & { user: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )> }
  )> }
);

export type OrganizationTable_OrganizationTypeFragment = (
  { __typename?: 'OrganizationType' }
  & Pick<OrganizationType, 'id' | 'name'>
);

export type OrganizationTable_OrganizationFragment = (
  { __typename?: 'Organization' }
  & Pick<Organization, 'id' | 'confirmationRequestedAt' | 'name' | 'registrationNumber' | 'isConfirmed'>
  & { organizationType: Maybe<(
    { __typename?: 'OrganizationType' }
    & OrganizationTable_OrganizationTypeFragment
  )> }
);

export type OrganizationTable_UserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'managerName' | 'phoneNumber' | 'email' | 'createdAt' | 'deactivated' | 'deletedAt'>
  & { organization: Maybe<(
    { __typename?: 'Organization' }
    & OrganizationTable_OrganizationFragment
  )> }
);

export type OrganizationTable_UserConnectionFragment = (
  { __typename?: 'UserConnection' }
  & Pick<UserConnection, 'totalCount'>
  & { nodes: Maybe<Array<Maybe<(
    { __typename?: 'User' }
    & OrganizationTable_UserFragment
  )>>> }
);

export type OrganizationTableUserQueryVariables = {
  filterBy?: Maybe<UserFilters>,
  orderBy?: Maybe<UserOrder>,
  pagination?: Maybe<Pagination>
};


export type OrganizationTableUserQuery = (
  { __typename?: 'Query' }
  & { users: (
    { __typename?: 'UserConnection' }
    & OrganizationTable_UserConnectionFragment
  ) }
);

export type UserTable_UserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'type' | 'createdAt' | 'username' | 'email' | 'birthday' | 'phoneNumber' | 'lastLoginAt' | 'deactivated' | 'deletedAt'>
  & { profile: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id'>
    & { identificationImage: Maybe<(
      { __typename?: 'ApplyFile' }
      & Pick<ApplyFile, 'id' | 'url'>
    )> }
  )>, timespread: Maybe<(
    { __typename?: 'TimespreadAccount' }
    & Pick<TimespreadAccount, 'id' | 'name' | 'phoneNumber' | 'email' | 'profileURL'>
  )> }
);

export type UserTable_UserConnectionFragment = (
  { __typename?: 'UserConnection' }
  & Pick<UserConnection, 'totalCount'>
  & { nodes: Maybe<Array<Maybe<(
    { __typename?: 'User' }
    & UserTable_UserFragment
  )>>> }
);

export type UserTableQueryVariables = {
  filterBy?: Maybe<UserFilters>,
  orderBy?: Maybe<UserOrder>,
  pagination?: Maybe<Pagination>
};


export type UserTableQuery = (
  { __typename?: 'Query' }
  & { users: (
    { __typename?: 'UserConnection' }
    & UserTable_UserConnectionFragment
  ) }
);

export const UseAttachmentFileDelete_AttachmentFragmentDoc = gql`
    fragment useAttachmentFileDelete_attachment on Attachment {
  id
  url
  name
  type
  size
}
    `;
export const UseAttachmentFileUpload_AttachmentFragmentDoc = gql`
    fragment useAttachmentFileUpload_attachment on Attachment {
  id
  url
  name
  type
  size
}
    `;
export const ActivityFragmentFragmentDoc = gql`
    fragment ActivityFragment on Activity {
  id
  activityTypeID
  title
  recruitCloseAt
  createdAt
  status
  refusalReason
  organizationName
  manager {
    username
    email
    phoneNumber
  }
  statusUpdatedAt
}
    `;
export const AdvertisementFragmentDoc = gql`
    fragment Advertisement on Advertisement {
  id
  adType
  activityID
  outLink
  title
  content
  pcImage
  mobileImage
  startAt
  endAt
  status
  seq
  activity {
    id
    title
  }
}
    `;
export const CoverLetterFragmentFragmentDoc = gql`
    fragment CoverLetterFragment on CoverLetter {
  id
  passedAt
  organizationName
  role
  university
  major
  grades
  languageScore
  career
  content
  types
  createdAt
  updatedAt
  deletedAt
  source
  status
  score
  sourceKey
  scrapCount
  activity
  license
}
    `;
export const CurationFragmentFragmentDoc = gql`
    fragment CurationFragment on Curation {
  id
  title
  url
  seq
}
    `;
export const UsersByEmailFragmentDoc = gql`
    fragment UsersByEmail on User {
  id
  email
  username
  profile {
    id
    identificationImage {
      id
      url
    }
  }
}
    `;
export const RecommendKeywordFragmentFragmentDoc = gql`
    fragment RecommendKeywordFragment on RecommendSearchKeyword {
  id
  title
  seq
  searchField
}
    `;
export const ReplyFragmentFragmentDoc = gql`
    fragment ReplyFragment on Reply {
  id
  content
  activity {
    id
  }
  user {
    id
  }
  createdAt
  replies {
    id
    content
    activity {
      id
    }
    user {
      id
    }
    createdAt
  }
}
    `;
export const SignupSessionFragmentFragmentDoc = gql`
    fragment SignupSessionFragment on SignupSession {
  id: token
  snsID
  snsType
  displayName
  email
  ipAddress
  user {
    id
  }
  timespread {
    id
    name
    email
    birthday
    profileURL
    phoneNumber
    cash
    facebookID
    kakaoID
    naverID
  }
  signup
  signupUser {
    id
  }
  signupSession
  signupAt
  signupElapsedTimeInSeconds
  createdAt
}
    `;
export const SignupSessionDetailFragmentFragmentDoc = gql`
    fragment SignupSessionDetailFragment on SignupSession {
  ...SignupSessionFragment
}
    ${SignupSessionFragmentFragmentDoc}`;
export const SignupUserFragmentFragmentDoc = gql`
    fragment SignupUserFragment on User {
  id
  type
  username
  email
  birthday
  phoneNumber
  timespread {
    id
    name
    email
    birthday
    profileURL
    phoneNumber
    cash
    facebookID
    kakaoID
    naverID
  }
  facebook {
    id
  }
  kakao {
    id
  }
  naver {
    id
  }
  createdAt
  lastLoginAt
  specupJoined
  specupJoinedAt
  specupJoinedSession
  deactivated
}
    `;
export const SignupUserDetailFragmentFragmentDoc = gql`
    fragment SignupUserDetailFragment on User {
  ...SignupUserFragment
  lastLoginSession
  timespreadID
  timespreadAuthKey
}
    ${SignupUserFragmentFragmentDoc}`;
export const UserFragmentFragmentDoc = gql`
    fragment UserFragment on User {
  id
  type
  username
  email
  birthday
  phoneNumber
  signupSession {
    ipAddress
  }
  timespread {
    id
    name
    email
    birthday
    profileURL
    phoneNumber
    cash
    facebookID
    kakaoID
    naverID
  }
  facebook {
    id
  }
  kakao {
    id
  }
  naver {
    id
  }
  createdAt
  lastLoginAt
  specupJoined
  specupJoinedAt
  specupJoinedSession
  deactivated
  deletedAt
}
    `;
export const UserDetailFragmentFragmentDoc = gql`
    fragment UserDetailFragment on User {
  ...UserFragment
  lastLoginSession
}
    ${UserFragmentFragmentDoc}`;
export const AdvertisementForm_AdvertisementFragmentDoc = gql`
    fragment AdvertisementForm_advertisement on Advertisement {
  id
  activityID
  title
  pcImage
  mobileImage
  outLink
  startAt
  endAt
  seq
}
    `;
export const AdvertisementInput_AdPlacementFragmentDoc = gql`
    fragment AdvertisementInput_adPlacement on AdPlacement {
  adPlacementID: id
  name
  isActive
  width
  height
}
    `;
export const CampaignList_CampaignFragmentDoc = gql`
    fragment CampaignList_campaign on Campaign {
  id
  name
  isActive
  startAt
  endAt
  status
  ads {
    id
    adPlacement {
      id
      name
    }
  }
}
    `;
export const Campaign_CampaignFragmentDoc = gql`
    fragment Campaign_campaign on Campaign {
  id
  name
  content
  user {
    id
    username
  }
  isActive
  landingURL
  startAt
  endAt
  status
  ads {
    id
    adPlacement {
      id
      name
    }
    image {
      id
    }
  }
  schedules {
    dayOfWeek
    startTime
    endTime
  }
  createdAt
  updatedAt
}
    `;
export const PlacementList_AdPlacementFragmentDoc = gql`
    fragment PlacementList_adPlacement on AdPlacement {
  id
  name
  isActive
  width
  height
  defaultImage {
    id
  }
  defaultLandingURL
}
    `;
export const Placement_AdPlacementFragmentDoc = gql`
    fragment Placement_adPlacement on AdPlacement {
  id
  code
  name
  width
  height
  isActive
  defaultImage {
    id
  }
  defaultLandingURL
  createdAt
  updatedAt
}
    `;
export const OrganizationDetail_OrganizationTypeFragmentDoc = gql`
    fragment OrganizationDetail_organizationType on OrganizationType {
  id
  name
}
    `;
export const OrganizationDetail_AttachmentFragmentDoc = gql`
    fragment OrganizationDetail_attachment on Attachment {
  id
  url
  name
}
    `;
export const OrganizationDetail_OrganizationFragmentDoc = gql`
    fragment OrganizationDetail_organization on Organization {
  id
  organizationType {
    ...OrganizationDetail_organizationType
  }
  name
  registrationNumber
  isConfirmed
  representative
  alias
  licenseImages {
    ...OrganizationDetail_attachment
  }
}
    ${OrganizationDetail_OrganizationTypeFragmentDoc}
${OrganizationDetail_AttachmentFragmentDoc}`;
export const OrganizationDetail_UserFragmentDoc = gql`
    fragment OrganizationDetail_user on User {
  id
  login
  type
  managerName
  phoneNumber
  email
  deactivated
  deletedAt
  organization {
    ...OrganizationDetail_organization
  }
}
    ${OrganizationDetail_OrganizationFragmentDoc}`;
export const OrganizationTable_OrganizationTypeFragmentDoc = gql`
    fragment OrganizationTable_organizationType on OrganizationType {
  id
  name
}
    `;
export const OrganizationTable_OrganizationFragmentDoc = gql`
    fragment OrganizationTable_organization on Organization {
  id
  confirmationRequestedAt
  organizationType {
    ...OrganizationTable_organizationType
  }
  name
  registrationNumber
  isConfirmed
}
    ${OrganizationTable_OrganizationTypeFragmentDoc}`;
export const OrganizationTable_UserFragmentDoc = gql`
    fragment OrganizationTable_user on User {
  id
  managerName
  phoneNumber
  email
  createdAt
  deactivated
  organization {
    ...OrganizationTable_organization
  }
  deletedAt
}
    ${OrganizationTable_OrganizationFragmentDoc}`;
export const OrganizationTable_UserConnectionFragmentDoc = gql`
    fragment OrganizationTable_userConnection on UserConnection {
  nodes {
    ...OrganizationTable_user
  }
  totalCount
}
    ${OrganizationTable_UserFragmentDoc}`;
export const UserTable_UserFragmentDoc = gql`
    fragment UserTable_user on User {
  id
  type
  createdAt
  username
  email
  birthday
  phoneNumber
  username
  phoneNumber
  email
  lastLoginAt
  deactivated
  profile {
    id
    identificationImage {
      id
      url
    }
  }
  timespread {
    id
    name
    phoneNumber
    email
    profileURL
  }
  deletedAt
}
    `;
export const UserTable_UserConnectionFragmentDoc = gql`
    fragment UserTable_userConnection on UserConnection {
  nodes {
    ...UserTable_user
  }
  totalCount
}
    ${UserTable_UserFragmentDoc}`;
export const AuthLoginDocument = gql`
    mutation AuthLogin($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    user {
      id
    }
  }
}
    `;
export type AuthLoginMutationFn = ApolloReactCommon.MutationFunction<AuthLoginMutation, AuthLoginMutationVariables>;
export type AuthLoginComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AuthLoginMutation, AuthLoginMutationVariables>, 'mutation'>;

    export const AuthLoginComponent = (props: AuthLoginComponentProps) => (
      <ApolloReactComponents.Mutation<AuthLoginMutation, AuthLoginMutationVariables> mutation={AuthLoginDocument} {...props} />
    );
    

/**
 * __useAuthLoginMutation__
 *
 * To run a mutation, you first call `useAuthLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authLoginMutation, { data, loading, error }] = useAuthLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useAuthLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AuthLoginMutation, AuthLoginMutationVariables>) {
        return ApolloReactHooks.useMutation<AuthLoginMutation, AuthLoginMutationVariables>(AuthLoginDocument, baseOptions);
      }
export type AuthLoginMutationHookResult = ReturnType<typeof useAuthLoginMutation>;
export type AuthLoginMutationResult = ApolloReactCommon.MutationResult<AuthLoginMutation>;
export type AuthLoginMutationOptions = ApolloReactCommon.BaseMutationOptions<AuthLoginMutation, AuthLoginMutationVariables>;
export const AuthLogoutDocument = gql`
    mutation AuthLogout {
  logout
}
    `;
export type AuthLogoutMutationFn = ApolloReactCommon.MutationFunction<AuthLogoutMutation, AuthLogoutMutationVariables>;
export type AuthLogoutComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AuthLogoutMutation, AuthLogoutMutationVariables>, 'mutation'>;

    export const AuthLogoutComponent = (props: AuthLogoutComponentProps) => (
      <ApolloReactComponents.Mutation<AuthLogoutMutation, AuthLogoutMutationVariables> mutation={AuthLogoutDocument} {...props} />
    );
    

/**
 * __useAuthLogoutMutation__
 *
 * To run a mutation, you first call `useAuthLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authLogoutMutation, { data, loading, error }] = useAuthLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useAuthLogoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AuthLogoutMutation, AuthLogoutMutationVariables>) {
        return ApolloReactHooks.useMutation<AuthLogoutMutation, AuthLogoutMutationVariables>(AuthLogoutDocument, baseOptions);
      }
export type AuthLogoutMutationHookResult = ReturnType<typeof useAuthLogoutMutation>;
export type AuthLogoutMutationResult = ApolloReactCommon.MutationResult<AuthLogoutMutation>;
export type AuthLogoutMutationOptions = ApolloReactCommon.BaseMutationOptions<AuthLogoutMutation, AuthLogoutMutationVariables>;
export const AuthCheckDocument = gql`
    query AuthCheck {
  currentUser {
    id
    isSuperuser
  }
}
    `;
export type AuthCheckComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AuthCheckQuery, AuthCheckQueryVariables>, 'query'>;

    export const AuthCheckComponent = (props: AuthCheckComponentProps) => (
      <ApolloReactComponents.Query<AuthCheckQuery, AuthCheckQueryVariables> query={AuthCheckDocument} {...props} />
    );
    

/**
 * __useAuthCheckQuery__
 *
 * To run a query within a React component, call `useAuthCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthCheckQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthCheckQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AuthCheckQuery, AuthCheckQueryVariables>) {
        return ApolloReactHooks.useQuery<AuthCheckQuery, AuthCheckQueryVariables>(AuthCheckDocument, baseOptions);
      }
export function useAuthCheckLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AuthCheckQuery, AuthCheckQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AuthCheckQuery, AuthCheckQueryVariables>(AuthCheckDocument, baseOptions);
        }
export type AuthCheckQueryHookResult = ReturnType<typeof useAuthCheckQuery>;
export type AuthCheckLazyQueryHookResult = ReturnType<typeof useAuthCheckLazyQuery>;
export type AuthCheckQueryResult = ApolloReactCommon.QueryResult<AuthCheckQuery, AuthCheckQueryVariables>;
export const AuthGetPermissionsDocument = gql`
    query AuthGetPermissions {
  currentUser {
    id
  }
}
    `;
export type AuthGetPermissionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AuthGetPermissionsQuery, AuthGetPermissionsQueryVariables>, 'query'>;

    export const AuthGetPermissionsComponent = (props: AuthGetPermissionsComponentProps) => (
      <ApolloReactComponents.Query<AuthGetPermissionsQuery, AuthGetPermissionsQueryVariables> query={AuthGetPermissionsDocument} {...props} />
    );
    

/**
 * __useAuthGetPermissionsQuery__
 *
 * To run a query within a React component, call `useAuthGetPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthGetPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthGetPermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthGetPermissionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AuthGetPermissionsQuery, AuthGetPermissionsQueryVariables>) {
        return ApolloReactHooks.useQuery<AuthGetPermissionsQuery, AuthGetPermissionsQueryVariables>(AuthGetPermissionsDocument, baseOptions);
      }
export function useAuthGetPermissionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AuthGetPermissionsQuery, AuthGetPermissionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AuthGetPermissionsQuery, AuthGetPermissionsQueryVariables>(AuthGetPermissionsDocument, baseOptions);
        }
export type AuthGetPermissionsQueryHookResult = ReturnType<typeof useAuthGetPermissionsQuery>;
export type AuthGetPermissionsLazyQueryHookResult = ReturnType<typeof useAuthGetPermissionsLazyQuery>;
export type AuthGetPermissionsQueryResult = ApolloReactCommon.QueryResult<AuthGetPermissionsQuery, AuthGetPermissionsQueryVariables>;
export const AttachmentDeleteDocument = gql`
    mutation AttachmentDelete($attachmentID: ID!) {
  attachmentDelete(attachmentID: $attachmentID) {
    attachment {
      ...useAttachmentFileDelete_attachment
    }
  }
}
    ${UseAttachmentFileDelete_AttachmentFragmentDoc}`;
export type AttachmentDeleteMutationFn = ApolloReactCommon.MutationFunction<AttachmentDeleteMutation, AttachmentDeleteMutationVariables>;
export type AttachmentDeleteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AttachmentDeleteMutation, AttachmentDeleteMutationVariables>, 'mutation'>;

    export const AttachmentDeleteComponent = (props: AttachmentDeleteComponentProps) => (
      <ApolloReactComponents.Mutation<AttachmentDeleteMutation, AttachmentDeleteMutationVariables> mutation={AttachmentDeleteDocument} {...props} />
    );
    

/**
 * __useAttachmentDeleteMutation__
 *
 * To run a mutation, you first call `useAttachmentDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttachmentDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attachmentDeleteMutation, { data, loading, error }] = useAttachmentDeleteMutation({
 *   variables: {
 *      attachmentID: // value for 'attachmentID'
 *   },
 * });
 */
export function useAttachmentDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AttachmentDeleteMutation, AttachmentDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<AttachmentDeleteMutation, AttachmentDeleteMutationVariables>(AttachmentDeleteDocument, baseOptions);
      }
export type AttachmentDeleteMutationHookResult = ReturnType<typeof useAttachmentDeleteMutation>;
export type AttachmentDeleteMutationResult = ApolloReactCommon.MutationResult<AttachmentDeleteMutation>;
export type AttachmentDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<AttachmentDeleteMutation, AttachmentDeleteMutationVariables>;
export const AttachmentPrepareUploadDocument = gql`
    mutation AttachmentPrepareUpload($name: String!, $size: Int!, $type: String!, $path: String, $useFileName: Boolean) {
  attachmentPrepareUpload(name: $name, size: $size, type: $type, path: $path, useFileName: $useFileName) {
    attachment {
      id
      url
    }
    signedURL
  }
}
    `;
export type AttachmentPrepareUploadMutationFn = ApolloReactCommon.MutationFunction<AttachmentPrepareUploadMutation, AttachmentPrepareUploadMutationVariables>;
export type AttachmentPrepareUploadComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AttachmentPrepareUploadMutation, AttachmentPrepareUploadMutationVariables>, 'mutation'>;

    export const AttachmentPrepareUploadComponent = (props: AttachmentPrepareUploadComponentProps) => (
      <ApolloReactComponents.Mutation<AttachmentPrepareUploadMutation, AttachmentPrepareUploadMutationVariables> mutation={AttachmentPrepareUploadDocument} {...props} />
    );
    

/**
 * __useAttachmentPrepareUploadMutation__
 *
 * To run a mutation, you first call `useAttachmentPrepareUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttachmentPrepareUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attachmentPrepareUploadMutation, { data, loading, error }] = useAttachmentPrepareUploadMutation({
 *   variables: {
 *      name: // value for 'name'
 *      size: // value for 'size'
 *      type: // value for 'type'
 *      path: // value for 'path'
 *      useFileName: // value for 'useFileName'
 *   },
 * });
 */
export function useAttachmentPrepareUploadMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AttachmentPrepareUploadMutation, AttachmentPrepareUploadMutationVariables>) {
        return ApolloReactHooks.useMutation<AttachmentPrepareUploadMutation, AttachmentPrepareUploadMutationVariables>(AttachmentPrepareUploadDocument, baseOptions);
      }
export type AttachmentPrepareUploadMutationHookResult = ReturnType<typeof useAttachmentPrepareUploadMutation>;
export type AttachmentPrepareUploadMutationResult = ApolloReactCommon.MutationResult<AttachmentPrepareUploadMutation>;
export type AttachmentPrepareUploadMutationOptions = ApolloReactCommon.BaseMutationOptions<AttachmentPrepareUploadMutation, AttachmentPrepareUploadMutationVariables>;
export const AttachmentCompleteUploadDocument = gql`
    mutation AttachmentCompleteUpload($attachmentID: ID!) {
  attachmentCompleteUpload(attachmentID: $attachmentID) {
    attachment {
      id
      ...useAttachmentFileUpload_attachment
    }
  }
}
    ${UseAttachmentFileUpload_AttachmentFragmentDoc}`;
export type AttachmentCompleteUploadMutationFn = ApolloReactCommon.MutationFunction<AttachmentCompleteUploadMutation, AttachmentCompleteUploadMutationVariables>;
export type AttachmentCompleteUploadComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AttachmentCompleteUploadMutation, AttachmentCompleteUploadMutationVariables>, 'mutation'>;

    export const AttachmentCompleteUploadComponent = (props: AttachmentCompleteUploadComponentProps) => (
      <ApolloReactComponents.Mutation<AttachmentCompleteUploadMutation, AttachmentCompleteUploadMutationVariables> mutation={AttachmentCompleteUploadDocument} {...props} />
    );
    

/**
 * __useAttachmentCompleteUploadMutation__
 *
 * To run a mutation, you first call `useAttachmentCompleteUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttachmentCompleteUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attachmentCompleteUploadMutation, { data, loading, error }] = useAttachmentCompleteUploadMutation({
 *   variables: {
 *      attachmentID: // value for 'attachmentID'
 *   },
 * });
 */
export function useAttachmentCompleteUploadMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AttachmentCompleteUploadMutation, AttachmentCompleteUploadMutationVariables>) {
        return ApolloReactHooks.useMutation<AttachmentCompleteUploadMutation, AttachmentCompleteUploadMutationVariables>(AttachmentCompleteUploadDocument, baseOptions);
      }
export type AttachmentCompleteUploadMutationHookResult = ReturnType<typeof useAttachmentCompleteUploadMutation>;
export type AttachmentCompleteUploadMutationResult = ApolloReactCommon.MutationResult<AttachmentCompleteUploadMutation>;
export type AttachmentCompleteUploadMutationOptions = ApolloReactCommon.BaseMutationOptions<AttachmentCompleteUploadMutation, AttachmentCompleteUploadMutationVariables>;
export const UserStatisticsPageDocument = gql`
    query UserStatisticsPage($pagination: Pagination!) {
  userStatistics(pagination: $pagination) {
    totalCount
    nodes {
      id
      date
      createdAt
      orgSignupCount
      totalSignupCount
      normalSignupCount
    }
  }
}
    `;
export type UserStatisticsPageComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserStatisticsPageQuery, UserStatisticsPageQueryVariables>, 'query'> & ({ variables: UserStatisticsPageQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const UserStatisticsPageComponent = (props: UserStatisticsPageComponentProps) => (
      <ApolloReactComponents.Query<UserStatisticsPageQuery, UserStatisticsPageQueryVariables> query={UserStatisticsPageDocument} {...props} />
    );
    

/**
 * __useUserStatisticsPageQuery__
 *
 * To run a query within a React component, call `useUserStatisticsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserStatisticsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserStatisticsPageQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useUserStatisticsPageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserStatisticsPageQuery, UserStatisticsPageQueryVariables>) {
        return ApolloReactHooks.useQuery<UserStatisticsPageQuery, UserStatisticsPageQueryVariables>(UserStatisticsPageDocument, baseOptions);
      }
export function useUserStatisticsPageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserStatisticsPageQuery, UserStatisticsPageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserStatisticsPageQuery, UserStatisticsPageQueryVariables>(UserStatisticsPageDocument, baseOptions);
        }
export type UserStatisticsPageQueryHookResult = ReturnType<typeof useUserStatisticsPageQuery>;
export type UserStatisticsPageLazyQueryHookResult = ReturnType<typeof useUserStatisticsPageLazyQuery>;
export type UserStatisticsPageQueryResult = ApolloReactCommon.QueryResult<UserStatisticsPageQuery, UserStatisticsPageQueryVariables>;
export const ActivityGetOneDocument = gql`
    query ActivityGetOne($id: ID!) {
  data: activity(id: $id) {
    ...ActivityFragment
  }
}
    ${ActivityFragmentFragmentDoc}`;
export type ActivityGetOneComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ActivityGetOneQuery, ActivityGetOneQueryVariables>, 'query'> & ({ variables: ActivityGetOneQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ActivityGetOneComponent = (props: ActivityGetOneComponentProps) => (
      <ApolloReactComponents.Query<ActivityGetOneQuery, ActivityGetOneQueryVariables> query={ActivityGetOneDocument} {...props} />
    );
    

/**
 * __useActivityGetOneQuery__
 *
 * To run a query within a React component, call `useActivityGetOneQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityGetOneQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityGetOneQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActivityGetOneQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ActivityGetOneQuery, ActivityGetOneQueryVariables>) {
        return ApolloReactHooks.useQuery<ActivityGetOneQuery, ActivityGetOneQueryVariables>(ActivityGetOneDocument, baseOptions);
      }
export function useActivityGetOneLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ActivityGetOneQuery, ActivityGetOneQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ActivityGetOneQuery, ActivityGetOneQueryVariables>(ActivityGetOneDocument, baseOptions);
        }
export type ActivityGetOneQueryHookResult = ReturnType<typeof useActivityGetOneQuery>;
export type ActivityGetOneLazyQueryHookResult = ReturnType<typeof useActivityGetOneLazyQuery>;
export type ActivityGetOneQueryResult = ApolloReactCommon.QueryResult<ActivityGetOneQuery, ActivityGetOneQueryVariables>;
export const ActivityGetListDocument = gql`
    query ActivityGetList($filterBy: ActivityFilter, $orderBy: ActivityOrder, $pagination: Pagination) {
  data: activities(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    data: nodes {
      ...ActivityFragment
    }
    total: totalCount
  }
}
    ${ActivityFragmentFragmentDoc}`;
export type ActivityGetListComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ActivityGetListQuery, ActivityGetListQueryVariables>, 'query'>;

    export const ActivityGetListComponent = (props: ActivityGetListComponentProps) => (
      <ApolloReactComponents.Query<ActivityGetListQuery, ActivityGetListQueryVariables> query={ActivityGetListDocument} {...props} />
    );
    

/**
 * __useActivityGetListQuery__
 *
 * To run a query within a React component, call `useActivityGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityGetListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useActivityGetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ActivityGetListQuery, ActivityGetListQueryVariables>) {
        return ApolloReactHooks.useQuery<ActivityGetListQuery, ActivityGetListQueryVariables>(ActivityGetListDocument, baseOptions);
      }
export function useActivityGetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ActivityGetListQuery, ActivityGetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ActivityGetListQuery, ActivityGetListQueryVariables>(ActivityGetListDocument, baseOptions);
        }
export type ActivityGetListQueryHookResult = ReturnType<typeof useActivityGetListQuery>;
export type ActivityGetListLazyQueryHookResult = ReturnType<typeof useActivityGetListLazyQuery>;
export type ActivityGetListQueryResult = ApolloReactCommon.QueryResult<ActivityGetListQuery, ActivityGetListQueryVariables>;
export const ActivityGetManyDocument = gql`
    query ActivityGetMany($ids: [ID!]!) {
  data: activitiesByID(ids: $ids) {
    ...ActivityFragment
  }
}
    ${ActivityFragmentFragmentDoc}`;
export type ActivityGetManyComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ActivityGetManyQuery, ActivityGetManyQueryVariables>, 'query'> & ({ variables: ActivityGetManyQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ActivityGetManyComponent = (props: ActivityGetManyComponentProps) => (
      <ApolloReactComponents.Query<ActivityGetManyQuery, ActivityGetManyQueryVariables> query={ActivityGetManyDocument} {...props} />
    );
    

/**
 * __useActivityGetManyQuery__
 *
 * To run a query within a React component, call `useActivityGetManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityGetManyQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityGetManyQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useActivityGetManyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ActivityGetManyQuery, ActivityGetManyQueryVariables>) {
        return ApolloReactHooks.useQuery<ActivityGetManyQuery, ActivityGetManyQueryVariables>(ActivityGetManyDocument, baseOptions);
      }
export function useActivityGetManyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ActivityGetManyQuery, ActivityGetManyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ActivityGetManyQuery, ActivityGetManyQueryVariables>(ActivityGetManyDocument, baseOptions);
        }
export type ActivityGetManyQueryHookResult = ReturnType<typeof useActivityGetManyQuery>;
export type ActivityGetManyLazyQueryHookResult = ReturnType<typeof useActivityGetManyLazyQuery>;
export type ActivityGetManyQueryResult = ApolloReactCommon.QueryResult<ActivityGetManyQuery, ActivityGetManyQueryVariables>;
export const ActivityUpdateDocument = gql`
    mutation ActivityUpdate($input: ActivityUpdateInput!) {
  data: activityUpdate(input: $input) {
    data: activity {
      ...ActivityFragment
    }
  }
}
    ${ActivityFragmentFragmentDoc}`;
export type ActivityUpdateMutationFn = ApolloReactCommon.MutationFunction<ActivityUpdateMutation, ActivityUpdateMutationVariables>;
export type ActivityUpdateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ActivityUpdateMutation, ActivityUpdateMutationVariables>, 'mutation'>;

    export const ActivityUpdateComponent = (props: ActivityUpdateComponentProps) => (
      <ApolloReactComponents.Mutation<ActivityUpdateMutation, ActivityUpdateMutationVariables> mutation={ActivityUpdateDocument} {...props} />
    );
    

/**
 * __useActivityUpdateMutation__
 *
 * To run a mutation, you first call `useActivityUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivityUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activityUpdateMutation, { data, loading, error }] = useActivityUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivityUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActivityUpdateMutation, ActivityUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<ActivityUpdateMutation, ActivityUpdateMutationVariables>(ActivityUpdateDocument, baseOptions);
      }
export type ActivityUpdateMutationHookResult = ReturnType<typeof useActivityUpdateMutation>;
export type ActivityUpdateMutationResult = ApolloReactCommon.MutationResult<ActivityUpdateMutation>;
export type ActivityUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivityUpdateMutation, ActivityUpdateMutationVariables>;
export const ActivitiesStatusUpdateDocument = gql`
    mutation ActivitiesStatusUpdate($input: ActivitiesStatusUpdateInput!) {
  data: activitiesStatusUpdate(input: $input) {
    data: count
  }
}
    `;
export type ActivitiesStatusUpdateMutationFn = ApolloReactCommon.MutationFunction<ActivitiesStatusUpdateMutation, ActivitiesStatusUpdateMutationVariables>;
export type ActivitiesStatusUpdateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ActivitiesStatusUpdateMutation, ActivitiesStatusUpdateMutationVariables>, 'mutation'>;

    export const ActivitiesStatusUpdateComponent = (props: ActivitiesStatusUpdateComponentProps) => (
      <ApolloReactComponents.Mutation<ActivitiesStatusUpdateMutation, ActivitiesStatusUpdateMutationVariables> mutation={ActivitiesStatusUpdateDocument} {...props} />
    );
    

/**
 * __useActivitiesStatusUpdateMutation__
 *
 * To run a mutation, you first call `useActivitiesStatusUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivitiesStatusUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activitiesStatusUpdateMutation, { data, loading, error }] = useActivitiesStatusUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivitiesStatusUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActivitiesStatusUpdateMutation, ActivitiesStatusUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<ActivitiesStatusUpdateMutation, ActivitiesStatusUpdateMutationVariables>(ActivitiesStatusUpdateDocument, baseOptions);
      }
export type ActivitiesStatusUpdateMutationHookResult = ReturnType<typeof useActivitiesStatusUpdateMutation>;
export type ActivitiesStatusUpdateMutationResult = ApolloReactCommon.MutationResult<ActivitiesStatusUpdateMutation>;
export type ActivitiesStatusUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivitiesStatusUpdateMutation, ActivitiesStatusUpdateMutationVariables>;
export const ActivityDeleteDocument = gql`
    mutation ActivityDelete($id: ID!) {
  data: activityDelete(id: $id) {
    data: activity {
      ...ActivityFragment
    }
  }
}
    ${ActivityFragmentFragmentDoc}`;
export type ActivityDeleteMutationFn = ApolloReactCommon.MutationFunction<ActivityDeleteMutation, ActivityDeleteMutationVariables>;
export type ActivityDeleteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ActivityDeleteMutation, ActivityDeleteMutationVariables>, 'mutation'>;

    export const ActivityDeleteComponent = (props: ActivityDeleteComponentProps) => (
      <ApolloReactComponents.Mutation<ActivityDeleteMutation, ActivityDeleteMutationVariables> mutation={ActivityDeleteDocument} {...props} />
    );
    

/**
 * __useActivityDeleteMutation__
 *
 * To run a mutation, you first call `useActivityDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivityDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activityDeleteMutation, { data, loading, error }] = useActivityDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActivityDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActivityDeleteMutation, ActivityDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<ActivityDeleteMutation, ActivityDeleteMutationVariables>(ActivityDeleteDocument, baseOptions);
      }
export type ActivityDeleteMutationHookResult = ReturnType<typeof useActivityDeleteMutation>;
export type ActivityDeleteMutationResult = ApolloReactCommon.MutationResult<ActivityDeleteMutation>;
export type ActivityDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivityDeleteMutation, ActivityDeleteMutationVariables>;
export const ActivitiesDeleteDocument = gql`
    mutation ActivitiesDelete($ids: [ID!]!) {
  data: activitiesDelete(ids: $ids) {
    data: activities {
      id
    }
  }
}
    `;
export type ActivitiesDeleteMutationFn = ApolloReactCommon.MutationFunction<ActivitiesDeleteMutation, ActivitiesDeleteMutationVariables>;
export type ActivitiesDeleteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ActivitiesDeleteMutation, ActivitiesDeleteMutationVariables>, 'mutation'>;

    export const ActivitiesDeleteComponent = (props: ActivitiesDeleteComponentProps) => (
      <ApolloReactComponents.Mutation<ActivitiesDeleteMutation, ActivitiesDeleteMutationVariables> mutation={ActivitiesDeleteDocument} {...props} />
    );
    

/**
 * __useActivitiesDeleteMutation__
 *
 * To run a mutation, you first call `useActivitiesDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivitiesDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activitiesDeleteMutation, { data, loading, error }] = useActivitiesDeleteMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useActivitiesDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActivitiesDeleteMutation, ActivitiesDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<ActivitiesDeleteMutation, ActivitiesDeleteMutationVariables>(ActivitiesDeleteDocument, baseOptions);
      }
export type ActivitiesDeleteMutationHookResult = ReturnType<typeof useActivitiesDeleteMutation>;
export type ActivitiesDeleteMutationResult = ApolloReactCommon.MutationResult<ActivitiesDeleteMutation>;
export type ActivitiesDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivitiesDeleteMutation, ActivitiesDeleteMutationVariables>;
export const AdvertisementGetDocument = gql`
    query AdvertisementGet($id: ID!) {
  data: advertisement(id: $id) {
    ...Advertisement
  }
}
    ${AdvertisementFragmentDoc}`;
export type AdvertisementGetComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AdvertisementGetQuery, AdvertisementGetQueryVariables>, 'query'> & ({ variables: AdvertisementGetQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AdvertisementGetComponent = (props: AdvertisementGetComponentProps) => (
      <ApolloReactComponents.Query<AdvertisementGetQuery, AdvertisementGetQueryVariables> query={AdvertisementGetDocument} {...props} />
    );
    

/**
 * __useAdvertisementGetQuery__
 *
 * To run a query within a React component, call `useAdvertisementGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdvertisementGetQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdvertisementGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdvertisementGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AdvertisementGetQuery, AdvertisementGetQueryVariables>) {
        return ApolloReactHooks.useQuery<AdvertisementGetQuery, AdvertisementGetQueryVariables>(AdvertisementGetDocument, baseOptions);
      }
export function useAdvertisementGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdvertisementGetQuery, AdvertisementGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AdvertisementGetQuery, AdvertisementGetQueryVariables>(AdvertisementGetDocument, baseOptions);
        }
export type AdvertisementGetQueryHookResult = ReturnType<typeof useAdvertisementGetQuery>;
export type AdvertisementGetLazyQueryHookResult = ReturnType<typeof useAdvertisementGetLazyQuery>;
export type AdvertisementGetQueryResult = ApolloReactCommon.QueryResult<AdvertisementGetQuery, AdvertisementGetQueryVariables>;
export const AdvertisementGetListDocument = gql`
    query AdvertisementGetList($filterBy: AdvertisementFilter!, $orderBy: AdvertisementOrder, $pagination: Pagination) {
  data: advertisementList(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    data: advertisements {
      ...Advertisement
    }
    total: totalCount
  }
}
    ${AdvertisementFragmentDoc}`;
export type AdvertisementGetListComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AdvertisementGetListQuery, AdvertisementGetListQueryVariables>, 'query'> & ({ variables: AdvertisementGetListQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AdvertisementGetListComponent = (props: AdvertisementGetListComponentProps) => (
      <ApolloReactComponents.Query<AdvertisementGetListQuery, AdvertisementGetListQueryVariables> query={AdvertisementGetListDocument} {...props} />
    );
    

/**
 * __useAdvertisementGetListQuery__
 *
 * To run a query within a React component, call `useAdvertisementGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdvertisementGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdvertisementGetListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useAdvertisementGetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AdvertisementGetListQuery, AdvertisementGetListQueryVariables>) {
        return ApolloReactHooks.useQuery<AdvertisementGetListQuery, AdvertisementGetListQueryVariables>(AdvertisementGetListDocument, baseOptions);
      }
export function useAdvertisementGetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdvertisementGetListQuery, AdvertisementGetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AdvertisementGetListQuery, AdvertisementGetListQueryVariables>(AdvertisementGetListDocument, baseOptions);
        }
export type AdvertisementGetListQueryHookResult = ReturnType<typeof useAdvertisementGetListQuery>;
export type AdvertisementGetListLazyQueryHookResult = ReturnType<typeof useAdvertisementGetListLazyQuery>;
export type AdvertisementGetListQueryResult = ApolloReactCommon.QueryResult<AdvertisementGetListQuery, AdvertisementGetListQueryVariables>;
export const AdvertisementDeleteDocument = gql`
    mutation AdvertisementDelete($id: ID!) {
  data: advertisementDelete(id: $id) {
    data: advertisement {
      ...Advertisement
    }
  }
}
    ${AdvertisementFragmentDoc}`;
export type AdvertisementDeleteMutationFn = ApolloReactCommon.MutationFunction<AdvertisementDeleteMutation, AdvertisementDeleteMutationVariables>;
export type AdvertisementDeleteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AdvertisementDeleteMutation, AdvertisementDeleteMutationVariables>, 'mutation'>;

    export const AdvertisementDeleteComponent = (props: AdvertisementDeleteComponentProps) => (
      <ApolloReactComponents.Mutation<AdvertisementDeleteMutation, AdvertisementDeleteMutationVariables> mutation={AdvertisementDeleteDocument} {...props} />
    );
    

/**
 * __useAdvertisementDeleteMutation__
 *
 * To run a mutation, you first call `useAdvertisementDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdvertisementDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [advertisementDeleteMutation, { data, loading, error }] = useAdvertisementDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdvertisementDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AdvertisementDeleteMutation, AdvertisementDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<AdvertisementDeleteMutation, AdvertisementDeleteMutationVariables>(AdvertisementDeleteDocument, baseOptions);
      }
export type AdvertisementDeleteMutationHookResult = ReturnType<typeof useAdvertisementDeleteMutation>;
export type AdvertisementDeleteMutationResult = ApolloReactCommon.MutationResult<AdvertisementDeleteMutation>;
export type AdvertisementDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<AdvertisementDeleteMutation, AdvertisementDeleteMutationVariables>;
export const AdvertisementDeleteManyDocument = gql`
    mutation AdvertisementDeleteMany($ids: [ID!]!) {
  data: advertisementBulkDelete(ids: $ids) {
    data: advertisements {
      ...Advertisement
    }
  }
}
    ${AdvertisementFragmentDoc}`;
export type AdvertisementDeleteManyMutationFn = ApolloReactCommon.MutationFunction<AdvertisementDeleteManyMutation, AdvertisementDeleteManyMutationVariables>;
export type AdvertisementDeleteManyComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AdvertisementDeleteManyMutation, AdvertisementDeleteManyMutationVariables>, 'mutation'>;

    export const AdvertisementDeleteManyComponent = (props: AdvertisementDeleteManyComponentProps) => (
      <ApolloReactComponents.Mutation<AdvertisementDeleteManyMutation, AdvertisementDeleteManyMutationVariables> mutation={AdvertisementDeleteManyDocument} {...props} />
    );
    

/**
 * __useAdvertisementDeleteManyMutation__
 *
 * To run a mutation, you first call `useAdvertisementDeleteManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdvertisementDeleteManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [advertisementDeleteManyMutation, { data, loading, error }] = useAdvertisementDeleteManyMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useAdvertisementDeleteManyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AdvertisementDeleteManyMutation, AdvertisementDeleteManyMutationVariables>) {
        return ApolloReactHooks.useMutation<AdvertisementDeleteManyMutation, AdvertisementDeleteManyMutationVariables>(AdvertisementDeleteManyDocument, baseOptions);
      }
export type AdvertisementDeleteManyMutationHookResult = ReturnType<typeof useAdvertisementDeleteManyMutation>;
export type AdvertisementDeleteManyMutationResult = ApolloReactCommon.MutationResult<AdvertisementDeleteManyMutation>;
export type AdvertisementDeleteManyMutationOptions = ApolloReactCommon.BaseMutationOptions<AdvertisementDeleteManyMutation, AdvertisementDeleteManyMutationVariables>;
export const SearchExactCoverLetterDocument = gql`
    query SearchExactCoverLetter($query: String!, $field: CoverLetterField!) {
  data: searchExactCoverLetter(query: $query, field: $field) {
    count
    query
  }
}
    `;
export type SearchExactCoverLetterComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SearchExactCoverLetterQuery, SearchExactCoverLetterQueryVariables>, 'query'> & ({ variables: SearchExactCoverLetterQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SearchExactCoverLetterComponent = (props: SearchExactCoverLetterComponentProps) => (
      <ApolloReactComponents.Query<SearchExactCoverLetterQuery, SearchExactCoverLetterQueryVariables> query={SearchExactCoverLetterDocument} {...props} />
    );
    

/**
 * __useSearchExactCoverLetterQuery__
 *
 * To run a query within a React component, call `useSearchExactCoverLetterQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchExactCoverLetterQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchExactCoverLetterQuery({
 *   variables: {
 *      query: // value for 'query'
 *      field: // value for 'field'
 *   },
 * });
 */
export function useSearchExactCoverLetterQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchExactCoverLetterQuery, SearchExactCoverLetterQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchExactCoverLetterQuery, SearchExactCoverLetterQueryVariables>(SearchExactCoverLetterDocument, baseOptions);
      }
export function useSearchExactCoverLetterLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchExactCoverLetterQuery, SearchExactCoverLetterQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchExactCoverLetterQuery, SearchExactCoverLetterQueryVariables>(SearchExactCoverLetterDocument, baseOptions);
        }
export type SearchExactCoverLetterQueryHookResult = ReturnType<typeof useSearchExactCoverLetterQuery>;
export type SearchExactCoverLetterLazyQueryHookResult = ReturnType<typeof useSearchExactCoverLetterLazyQuery>;
export type SearchExactCoverLetterQueryResult = ApolloReactCommon.QueryResult<SearchExactCoverLetterQuery, SearchExactCoverLetterQueryVariables>;
export const UpdateCoverLetterFieldByQueryDocument = gql`
    mutation UpdateCoverLetterFieldByQuery($input: UpdateCoverLetterFieldByQueryInput!) {
  updateCoverLetterFieldByQuery(input: $input) {
    updatedCoverLetters {
      organizationName
      role
    }
  }
}
    `;
export type UpdateCoverLetterFieldByQueryMutationFn = ApolloReactCommon.MutationFunction<UpdateCoverLetterFieldByQueryMutation, UpdateCoverLetterFieldByQueryMutationVariables>;
export type UpdateCoverLetterFieldByQueryComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateCoverLetterFieldByQueryMutation, UpdateCoverLetterFieldByQueryMutationVariables>, 'mutation'>;

    export const UpdateCoverLetterFieldByQueryComponent = (props: UpdateCoverLetterFieldByQueryComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateCoverLetterFieldByQueryMutation, UpdateCoverLetterFieldByQueryMutationVariables> mutation={UpdateCoverLetterFieldByQueryDocument} {...props} />
    );
    

/**
 * __useUpdateCoverLetterFieldByQueryMutation__
 *
 * To run a mutation, you first call `useUpdateCoverLetterFieldByQueryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCoverLetterFieldByQueryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCoverLetterFieldByQueryMutation, { data, loading, error }] = useUpdateCoverLetterFieldByQueryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCoverLetterFieldByQueryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCoverLetterFieldByQueryMutation, UpdateCoverLetterFieldByQueryMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCoverLetterFieldByQueryMutation, UpdateCoverLetterFieldByQueryMutationVariables>(UpdateCoverLetterFieldByQueryDocument, baseOptions);
      }
export type UpdateCoverLetterFieldByQueryMutationHookResult = ReturnType<typeof useUpdateCoverLetterFieldByQueryMutation>;
export type UpdateCoverLetterFieldByQueryMutationResult = ApolloReactCommon.MutationResult<UpdateCoverLetterFieldByQueryMutation>;
export type UpdateCoverLetterFieldByQueryMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCoverLetterFieldByQueryMutation, UpdateCoverLetterFieldByQueryMutationVariables>;
export const CoverLetterGetDocument = gql`
    query CoverLetterGet($id: ID!) {
  data: coverLetter(id: $id) {
    ...CoverLetterFragment
  }
}
    ${CoverLetterFragmentFragmentDoc}`;
export type CoverLetterGetComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CoverLetterGetQuery, CoverLetterGetQueryVariables>, 'query'> & ({ variables: CoverLetterGetQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CoverLetterGetComponent = (props: CoverLetterGetComponentProps) => (
      <ApolloReactComponents.Query<CoverLetterGetQuery, CoverLetterGetQueryVariables> query={CoverLetterGetDocument} {...props} />
    );
    

/**
 * __useCoverLetterGetQuery__
 *
 * To run a query within a React component, call `useCoverLetterGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoverLetterGetQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoverLetterGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCoverLetterGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CoverLetterGetQuery, CoverLetterGetQueryVariables>) {
        return ApolloReactHooks.useQuery<CoverLetterGetQuery, CoverLetterGetQueryVariables>(CoverLetterGetDocument, baseOptions);
      }
export function useCoverLetterGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CoverLetterGetQuery, CoverLetterGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CoverLetterGetQuery, CoverLetterGetQueryVariables>(CoverLetterGetDocument, baseOptions);
        }
export type CoverLetterGetQueryHookResult = ReturnType<typeof useCoverLetterGetQuery>;
export type CoverLetterGetLazyQueryHookResult = ReturnType<typeof useCoverLetterGetLazyQuery>;
export type CoverLetterGetQueryResult = ApolloReactCommon.QueryResult<CoverLetterGetQuery, CoverLetterGetQueryVariables>;
export const CoverLetterGetListDocument = gql`
    query CoverLetterGetList($filterBy: CoverLetterFilter!, $orderBy: CoverLetterOrder, $pagination: Pagination) {
  data: coverLetters(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    data: edges {
      node {
        id
        ...CoverLetterFragment
      }
      highlight
    }
    total: totalCount
  }
}
    ${CoverLetterFragmentFragmentDoc}`;
export type CoverLetterGetListComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CoverLetterGetListQuery, CoverLetterGetListQueryVariables>, 'query'> & ({ variables: CoverLetterGetListQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CoverLetterGetListComponent = (props: CoverLetterGetListComponentProps) => (
      <ApolloReactComponents.Query<CoverLetterGetListQuery, CoverLetterGetListQueryVariables> query={CoverLetterGetListDocument} {...props} />
    );
    

/**
 * __useCoverLetterGetListQuery__
 *
 * To run a query within a React component, call `useCoverLetterGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoverLetterGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoverLetterGetListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useCoverLetterGetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CoverLetterGetListQuery, CoverLetterGetListQueryVariables>) {
        return ApolloReactHooks.useQuery<CoverLetterGetListQuery, CoverLetterGetListQueryVariables>(CoverLetterGetListDocument, baseOptions);
      }
export function useCoverLetterGetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CoverLetterGetListQuery, CoverLetterGetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CoverLetterGetListQuery, CoverLetterGetListQueryVariables>(CoverLetterGetListDocument, baseOptions);
        }
export type CoverLetterGetListQueryHookResult = ReturnType<typeof useCoverLetterGetListQuery>;
export type CoverLetterGetListLazyQueryHookResult = ReturnType<typeof useCoverLetterGetListLazyQuery>;
export type CoverLetterGetListQueryResult = ApolloReactCommon.QueryResult<CoverLetterGetListQuery, CoverLetterGetListQueryVariables>;
export const CoverLetterCreateDocument = gql`
    mutation CoverLetterCreate($input: CoverLetterCreateInput!) {
  data: coverLetterCreate(input: $input) {
    data: coverLetter {
      ...CoverLetterFragment
    }
  }
}
    ${CoverLetterFragmentFragmentDoc}`;
export type CoverLetterCreateMutationFn = ApolloReactCommon.MutationFunction<CoverLetterCreateMutation, CoverLetterCreateMutationVariables>;
export type CoverLetterCreateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CoverLetterCreateMutation, CoverLetterCreateMutationVariables>, 'mutation'>;

    export const CoverLetterCreateComponent = (props: CoverLetterCreateComponentProps) => (
      <ApolloReactComponents.Mutation<CoverLetterCreateMutation, CoverLetterCreateMutationVariables> mutation={CoverLetterCreateDocument} {...props} />
    );
    

/**
 * __useCoverLetterCreateMutation__
 *
 * To run a mutation, you first call `useCoverLetterCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCoverLetterCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [coverLetterCreateMutation, { data, loading, error }] = useCoverLetterCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCoverLetterCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CoverLetterCreateMutation, CoverLetterCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<CoverLetterCreateMutation, CoverLetterCreateMutationVariables>(CoverLetterCreateDocument, baseOptions);
      }
export type CoverLetterCreateMutationHookResult = ReturnType<typeof useCoverLetterCreateMutation>;
export type CoverLetterCreateMutationResult = ApolloReactCommon.MutationResult<CoverLetterCreateMutation>;
export type CoverLetterCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<CoverLetterCreateMutation, CoverLetterCreateMutationVariables>;
export const CoverLetterUpdateDocument = gql`
    mutation CoverLetterUpdate($input: CoverLetterUpdateInput!) {
  data: coverLetterUpdate(input: $input) {
    data: coverLetter {
      ...CoverLetterFragment
    }
  }
}
    ${CoverLetterFragmentFragmentDoc}`;
export type CoverLetterUpdateMutationFn = ApolloReactCommon.MutationFunction<CoverLetterUpdateMutation, CoverLetterUpdateMutationVariables>;
export type CoverLetterUpdateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CoverLetterUpdateMutation, CoverLetterUpdateMutationVariables>, 'mutation'>;

    export const CoverLetterUpdateComponent = (props: CoverLetterUpdateComponentProps) => (
      <ApolloReactComponents.Mutation<CoverLetterUpdateMutation, CoverLetterUpdateMutationVariables> mutation={CoverLetterUpdateDocument} {...props} />
    );
    

/**
 * __useCoverLetterUpdateMutation__
 *
 * To run a mutation, you first call `useCoverLetterUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCoverLetterUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [coverLetterUpdateMutation, { data, loading, error }] = useCoverLetterUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCoverLetterUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CoverLetterUpdateMutation, CoverLetterUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<CoverLetterUpdateMutation, CoverLetterUpdateMutationVariables>(CoverLetterUpdateDocument, baseOptions);
      }
export type CoverLetterUpdateMutationHookResult = ReturnType<typeof useCoverLetterUpdateMutation>;
export type CoverLetterUpdateMutationResult = ApolloReactCommon.MutationResult<CoverLetterUpdateMutation>;
export type CoverLetterUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<CoverLetterUpdateMutation, CoverLetterUpdateMutationVariables>;
export const CoverLetterDeleteManyDocument = gql`
    mutation CoverLetterDeleteMany($ids: [ID!]!) {
  data: coverLetterDeleteMany(ids: $ids) {
    data: coverLetters {
      ...CoverLetterFragment
    }
  }
}
    ${CoverLetterFragmentFragmentDoc}`;
export type CoverLetterDeleteManyMutationFn = ApolloReactCommon.MutationFunction<CoverLetterDeleteManyMutation, CoverLetterDeleteManyMutationVariables>;
export type CoverLetterDeleteManyComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CoverLetterDeleteManyMutation, CoverLetterDeleteManyMutationVariables>, 'mutation'>;

    export const CoverLetterDeleteManyComponent = (props: CoverLetterDeleteManyComponentProps) => (
      <ApolloReactComponents.Mutation<CoverLetterDeleteManyMutation, CoverLetterDeleteManyMutationVariables> mutation={CoverLetterDeleteManyDocument} {...props} />
    );
    

/**
 * __useCoverLetterDeleteManyMutation__
 *
 * To run a mutation, you first call `useCoverLetterDeleteManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCoverLetterDeleteManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [coverLetterDeleteManyMutation, { data, loading, error }] = useCoverLetterDeleteManyMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useCoverLetterDeleteManyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CoverLetterDeleteManyMutation, CoverLetterDeleteManyMutationVariables>) {
        return ApolloReactHooks.useMutation<CoverLetterDeleteManyMutation, CoverLetterDeleteManyMutationVariables>(CoverLetterDeleteManyDocument, baseOptions);
      }
export type CoverLetterDeleteManyMutationHookResult = ReturnType<typeof useCoverLetterDeleteManyMutation>;
export type CoverLetterDeleteManyMutationResult = ApolloReactCommon.MutationResult<CoverLetterDeleteManyMutation>;
export type CoverLetterDeleteManyMutationOptions = ApolloReactCommon.BaseMutationOptions<CoverLetterDeleteManyMutation, CoverLetterDeleteManyMutationVariables>;
export const CoverLetterSyncWithElasticDocument = gql`
    mutation CoverLetterSyncWithElastic {
  syncCoverLetterWithElastic
}
    `;
export type CoverLetterSyncWithElasticMutationFn = ApolloReactCommon.MutationFunction<CoverLetterSyncWithElasticMutation, CoverLetterSyncWithElasticMutationVariables>;
export type CoverLetterSyncWithElasticComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CoverLetterSyncWithElasticMutation, CoverLetterSyncWithElasticMutationVariables>, 'mutation'>;

    export const CoverLetterSyncWithElasticComponent = (props: CoverLetterSyncWithElasticComponentProps) => (
      <ApolloReactComponents.Mutation<CoverLetterSyncWithElasticMutation, CoverLetterSyncWithElasticMutationVariables> mutation={CoverLetterSyncWithElasticDocument} {...props} />
    );
    

/**
 * __useCoverLetterSyncWithElasticMutation__
 *
 * To run a mutation, you first call `useCoverLetterSyncWithElasticMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCoverLetterSyncWithElasticMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [coverLetterSyncWithElasticMutation, { data, loading, error }] = useCoverLetterSyncWithElasticMutation({
 *   variables: {
 *   },
 * });
 */
export function useCoverLetterSyncWithElasticMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CoverLetterSyncWithElasticMutation, CoverLetterSyncWithElasticMutationVariables>) {
        return ApolloReactHooks.useMutation<CoverLetterSyncWithElasticMutation, CoverLetterSyncWithElasticMutationVariables>(CoverLetterSyncWithElasticDocument, baseOptions);
      }
export type CoverLetterSyncWithElasticMutationHookResult = ReturnType<typeof useCoverLetterSyncWithElasticMutation>;
export type CoverLetterSyncWithElasticMutationResult = ApolloReactCommon.MutationResult<CoverLetterSyncWithElasticMutation>;
export type CoverLetterSyncWithElasticMutationOptions = ApolloReactCommon.BaseMutationOptions<CoverLetterSyncWithElasticMutation, CoverLetterSyncWithElasticMutationVariables>;
export const CurationGetListDocument = gql`
    query CurationGetList {
  data: curationList {
    data: curations {
      ...CurationFragment
    }
    total: totalCount
  }
}
    ${CurationFragmentFragmentDoc}`;
export type CurationGetListComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CurationGetListQuery, CurationGetListQueryVariables>, 'query'>;

    export const CurationGetListComponent = (props: CurationGetListComponentProps) => (
      <ApolloReactComponents.Query<CurationGetListQuery, CurationGetListQueryVariables> query={CurationGetListDocument} {...props} />
    );
    

/**
 * __useCurationGetListQuery__
 *
 * To run a query within a React component, call `useCurationGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurationGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurationGetListQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurationGetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CurationGetListQuery, CurationGetListQueryVariables>) {
        return ApolloReactHooks.useQuery<CurationGetListQuery, CurationGetListQueryVariables>(CurationGetListDocument, baseOptions);
      }
export function useCurationGetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CurationGetListQuery, CurationGetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CurationGetListQuery, CurationGetListQueryVariables>(CurationGetListDocument, baseOptions);
        }
export type CurationGetListQueryHookResult = ReturnType<typeof useCurationGetListQuery>;
export type CurationGetListLazyQueryHookResult = ReturnType<typeof useCurationGetListLazyQuery>;
export type CurationGetListQueryResult = ApolloReactCommon.QueryResult<CurationGetListQuery, CurationGetListQueryVariables>;
export const CurationGetDocument = gql`
    query CurationGet($id: ID!) {
  data: curation(id: $id) {
    ...CurationFragment
  }
}
    ${CurationFragmentFragmentDoc}`;
export type CurationGetComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CurationGetQuery, CurationGetQueryVariables>, 'query'> & ({ variables: CurationGetQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CurationGetComponent = (props: CurationGetComponentProps) => (
      <ApolloReactComponents.Query<CurationGetQuery, CurationGetQueryVariables> query={CurationGetDocument} {...props} />
    );
    

/**
 * __useCurationGetQuery__
 *
 * To run a query within a React component, call `useCurationGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurationGetQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurationGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCurationGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CurationGetQuery, CurationGetQueryVariables>) {
        return ApolloReactHooks.useQuery<CurationGetQuery, CurationGetQueryVariables>(CurationGetDocument, baseOptions);
      }
export function useCurationGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CurationGetQuery, CurationGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CurationGetQuery, CurationGetQueryVariables>(CurationGetDocument, baseOptions);
        }
export type CurationGetQueryHookResult = ReturnType<typeof useCurationGetQuery>;
export type CurationGetLazyQueryHookResult = ReturnType<typeof useCurationGetLazyQuery>;
export type CurationGetQueryResult = ApolloReactCommon.QueryResult<CurationGetQuery, CurationGetQueryVariables>;
export const CurationCreateDocument = gql`
    mutation CurationCreate($input: CurationCreateInput!) {
  data: curationCreate(input: $input) {
    data: curation {
      ...CurationFragment
    }
  }
}
    ${CurationFragmentFragmentDoc}`;
export type CurationCreateMutationFn = ApolloReactCommon.MutationFunction<CurationCreateMutation, CurationCreateMutationVariables>;
export type CurationCreateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CurationCreateMutation, CurationCreateMutationVariables>, 'mutation'>;

    export const CurationCreateComponent = (props: CurationCreateComponentProps) => (
      <ApolloReactComponents.Mutation<CurationCreateMutation, CurationCreateMutationVariables> mutation={CurationCreateDocument} {...props} />
    );
    

/**
 * __useCurationCreateMutation__
 *
 * To run a mutation, you first call `useCurationCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCurationCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [curationCreateMutation, { data, loading, error }] = useCurationCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCurationCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CurationCreateMutation, CurationCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<CurationCreateMutation, CurationCreateMutationVariables>(CurationCreateDocument, baseOptions);
      }
export type CurationCreateMutationHookResult = ReturnType<typeof useCurationCreateMutation>;
export type CurationCreateMutationResult = ApolloReactCommon.MutationResult<CurationCreateMutation>;
export type CurationCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<CurationCreateMutation, CurationCreateMutationVariables>;
export const CurationUpdateDocument = gql`
    mutation CurationUpdate($id: ID!, $input: CurationUpdateInput!) {
  data: curationUpdate(id: $id, input: $input) {
    data: curation {
      ...CurationFragment
    }
  }
}
    ${CurationFragmentFragmentDoc}`;
export type CurationUpdateMutationFn = ApolloReactCommon.MutationFunction<CurationUpdateMutation, CurationUpdateMutationVariables>;
export type CurationUpdateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CurationUpdateMutation, CurationUpdateMutationVariables>, 'mutation'>;

    export const CurationUpdateComponent = (props: CurationUpdateComponentProps) => (
      <ApolloReactComponents.Mutation<CurationUpdateMutation, CurationUpdateMutationVariables> mutation={CurationUpdateDocument} {...props} />
    );
    

/**
 * __useCurationUpdateMutation__
 *
 * To run a mutation, you first call `useCurationUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCurationUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [curationUpdateMutation, { data, loading, error }] = useCurationUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCurationUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CurationUpdateMutation, CurationUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<CurationUpdateMutation, CurationUpdateMutationVariables>(CurationUpdateDocument, baseOptions);
      }
export type CurationUpdateMutationHookResult = ReturnType<typeof useCurationUpdateMutation>;
export type CurationUpdateMutationResult = ApolloReactCommon.MutationResult<CurationUpdateMutation>;
export type CurationUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<CurationUpdateMutation, CurationUpdateMutationVariables>;
export const UsersByEmailDocument = gql`
    query usersByEmail($email: String!) {
  usersByEmail(email: $email) {
    ...UsersByEmail
  }
}
    ${UsersByEmailFragmentDoc}`;
export type UsersByEmailComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UsersByEmailQuery, UsersByEmailQueryVariables>, 'query'> & ({ variables: UsersByEmailQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const UsersByEmailComponent = (props: UsersByEmailComponentProps) => (
      <ApolloReactComponents.Query<UsersByEmailQuery, UsersByEmailQueryVariables> query={UsersByEmailDocument} {...props} />
    );
    

/**
 * __useUsersByEmailQuery__
 *
 * To run a query within a React component, call `useUsersByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUsersByEmailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UsersByEmailQuery, UsersByEmailQueryVariables>) {
        return ApolloReactHooks.useQuery<UsersByEmailQuery, UsersByEmailQueryVariables>(UsersByEmailDocument, baseOptions);
      }
export function useUsersByEmailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UsersByEmailQuery, UsersByEmailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UsersByEmailQuery, UsersByEmailQueryVariables>(UsersByEmailDocument, baseOptions);
        }
export type UsersByEmailQueryHookResult = ReturnType<typeof useUsersByEmailQuery>;
export type UsersByEmailLazyQueryHookResult = ReturnType<typeof useUsersByEmailLazyQuery>;
export type UsersByEmailQueryResult = ApolloReactCommon.QueryResult<UsersByEmailQuery, UsersByEmailQueryVariables>;
export const UserProfileImageDeleteDocument = gql`
    mutation UserProfileImageDelete($id: ID!) {
  userProfileImageDelete(id: $id) {
    id
    url
  }
}
    `;
export type UserProfileImageDeleteMutationFn = ApolloReactCommon.MutationFunction<UserProfileImageDeleteMutation, UserProfileImageDeleteMutationVariables>;
export type UserProfileImageDeleteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UserProfileImageDeleteMutation, UserProfileImageDeleteMutationVariables>, 'mutation'>;

    export const UserProfileImageDeleteComponent = (props: UserProfileImageDeleteComponentProps) => (
      <ApolloReactComponents.Mutation<UserProfileImageDeleteMutation, UserProfileImageDeleteMutationVariables> mutation={UserProfileImageDeleteDocument} {...props} />
    );
    

/**
 * __useUserProfileImageDeleteMutation__
 *
 * To run a mutation, you first call `useUserProfileImageDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserProfileImageDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userProfileImageDeleteMutation, { data, loading, error }] = useUserProfileImageDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserProfileImageDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UserProfileImageDeleteMutation, UserProfileImageDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<UserProfileImageDeleteMutation, UserProfileImageDeleteMutationVariables>(UserProfileImageDeleteDocument, baseOptions);
      }
export type UserProfileImageDeleteMutationHookResult = ReturnType<typeof useUserProfileImageDeleteMutation>;
export type UserProfileImageDeleteMutationResult = ApolloReactCommon.MutationResult<UserProfileImageDeleteMutation>;
export type UserProfileImageDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<UserProfileImageDeleteMutation, UserProfileImageDeleteMutationVariables>;
export const SendJobPostingDataToNaverDocument = gql`
    mutation SendJobPostingDataToNaver {
  sendJobPostingDataToNaver {
    data
    totalCount
  }
}
    `;
export type SendJobPostingDataToNaverMutationFn = ApolloReactCommon.MutationFunction<SendJobPostingDataToNaverMutation, SendJobPostingDataToNaverMutationVariables>;
export type SendJobPostingDataToNaverComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SendJobPostingDataToNaverMutation, SendJobPostingDataToNaverMutationVariables>, 'mutation'>;

    export const SendJobPostingDataToNaverComponent = (props: SendJobPostingDataToNaverComponentProps) => (
      <ApolloReactComponents.Mutation<SendJobPostingDataToNaverMutation, SendJobPostingDataToNaverMutationVariables> mutation={SendJobPostingDataToNaverDocument} {...props} />
    );
    

/**
 * __useSendJobPostingDataToNaverMutation__
 *
 * To run a mutation, you first call `useSendJobPostingDataToNaverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendJobPostingDataToNaverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendJobPostingDataToNaverMutation, { data, loading, error }] = useSendJobPostingDataToNaverMutation({
 *   variables: {
 *   },
 * });
 */
export function useSendJobPostingDataToNaverMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendJobPostingDataToNaverMutation, SendJobPostingDataToNaverMutationVariables>) {
        return ApolloReactHooks.useMutation<SendJobPostingDataToNaverMutation, SendJobPostingDataToNaverMutationVariables>(SendJobPostingDataToNaverDocument, baseOptions);
      }
export type SendJobPostingDataToNaverMutationHookResult = ReturnType<typeof useSendJobPostingDataToNaverMutation>;
export type SendJobPostingDataToNaverMutationResult = ApolloReactCommon.MutationResult<SendJobPostingDataToNaverMutation>;
export type SendJobPostingDataToNaverMutationOptions = ApolloReactCommon.BaseMutationOptions<SendJobPostingDataToNaverMutation, SendJobPostingDataToNaverMutationVariables>;
export const RecommendKeywordGetListDocument = gql`
    query RecommendKeywordGetList($type: RecommendSearchKeywordType) {
  data: recommendSearchKeywordList(type: $type) {
    data: recommendSearchKeywords {
      ...RecommendKeywordFragment
    }
    total: totalCount
  }
}
    ${RecommendKeywordFragmentFragmentDoc}`;
export type RecommendKeywordGetListComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RecommendKeywordGetListQuery, RecommendKeywordGetListQueryVariables>, 'query'>;

    export const RecommendKeywordGetListComponent = (props: RecommendKeywordGetListComponentProps) => (
      <ApolloReactComponents.Query<RecommendKeywordGetListQuery, RecommendKeywordGetListQueryVariables> query={RecommendKeywordGetListDocument} {...props} />
    );
    

/**
 * __useRecommendKeywordGetListQuery__
 *
 * To run a query within a React component, call `useRecommendKeywordGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecommendKeywordGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecommendKeywordGetListQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useRecommendKeywordGetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RecommendKeywordGetListQuery, RecommendKeywordGetListQueryVariables>) {
        return ApolloReactHooks.useQuery<RecommendKeywordGetListQuery, RecommendKeywordGetListQueryVariables>(RecommendKeywordGetListDocument, baseOptions);
      }
export function useRecommendKeywordGetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RecommendKeywordGetListQuery, RecommendKeywordGetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RecommendKeywordGetListQuery, RecommendKeywordGetListQueryVariables>(RecommendKeywordGetListDocument, baseOptions);
        }
export type RecommendKeywordGetListQueryHookResult = ReturnType<typeof useRecommendKeywordGetListQuery>;
export type RecommendKeywordGetListLazyQueryHookResult = ReturnType<typeof useRecommendKeywordGetListLazyQuery>;
export type RecommendKeywordGetListQueryResult = ApolloReactCommon.QueryResult<RecommendKeywordGetListQuery, RecommendKeywordGetListQueryVariables>;
export const RecommendKeywordGetDocument = gql`
    query RecommendKeywordGet($id: ID!) {
  data: recommendSearchKeyword(id: $id) {
    ...RecommendKeywordFragment
  }
}
    ${RecommendKeywordFragmentFragmentDoc}`;
export type RecommendKeywordGetComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RecommendKeywordGetQuery, RecommendKeywordGetQueryVariables>, 'query'> & ({ variables: RecommendKeywordGetQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RecommendKeywordGetComponent = (props: RecommendKeywordGetComponentProps) => (
      <ApolloReactComponents.Query<RecommendKeywordGetQuery, RecommendKeywordGetQueryVariables> query={RecommendKeywordGetDocument} {...props} />
    );
    

/**
 * __useRecommendKeywordGetQuery__
 *
 * To run a query within a React component, call `useRecommendKeywordGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecommendKeywordGetQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecommendKeywordGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRecommendKeywordGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RecommendKeywordGetQuery, RecommendKeywordGetQueryVariables>) {
        return ApolloReactHooks.useQuery<RecommendKeywordGetQuery, RecommendKeywordGetQueryVariables>(RecommendKeywordGetDocument, baseOptions);
      }
export function useRecommendKeywordGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RecommendKeywordGetQuery, RecommendKeywordGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RecommendKeywordGetQuery, RecommendKeywordGetQueryVariables>(RecommendKeywordGetDocument, baseOptions);
        }
export type RecommendKeywordGetQueryHookResult = ReturnType<typeof useRecommendKeywordGetQuery>;
export type RecommendKeywordGetLazyQueryHookResult = ReturnType<typeof useRecommendKeywordGetLazyQuery>;
export type RecommendKeywordGetQueryResult = ApolloReactCommon.QueryResult<RecommendKeywordGetQuery, RecommendKeywordGetQueryVariables>;
export const RecommendKeywordCreateDocument = gql`
    mutation RecommendKeywordCreate($input: RecommendSearchKeywordCreateInput!) {
  data: recommendSearchKeywordCreate(input: $input) {
    data: recommendSearchKeyword {
      ...RecommendKeywordFragment
    }
  }
}
    ${RecommendKeywordFragmentFragmentDoc}`;
export type RecommendKeywordCreateMutationFn = ApolloReactCommon.MutationFunction<RecommendKeywordCreateMutation, RecommendKeywordCreateMutationVariables>;
export type RecommendKeywordCreateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RecommendKeywordCreateMutation, RecommendKeywordCreateMutationVariables>, 'mutation'>;

    export const RecommendKeywordCreateComponent = (props: RecommendKeywordCreateComponentProps) => (
      <ApolloReactComponents.Mutation<RecommendKeywordCreateMutation, RecommendKeywordCreateMutationVariables> mutation={RecommendKeywordCreateDocument} {...props} />
    );
    

/**
 * __useRecommendKeywordCreateMutation__
 *
 * To run a mutation, you first call `useRecommendKeywordCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecommendKeywordCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recommendKeywordCreateMutation, { data, loading, error }] = useRecommendKeywordCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecommendKeywordCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RecommendKeywordCreateMutation, RecommendKeywordCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<RecommendKeywordCreateMutation, RecommendKeywordCreateMutationVariables>(RecommendKeywordCreateDocument, baseOptions);
      }
export type RecommendKeywordCreateMutationHookResult = ReturnType<typeof useRecommendKeywordCreateMutation>;
export type RecommendKeywordCreateMutationResult = ApolloReactCommon.MutationResult<RecommendKeywordCreateMutation>;
export type RecommendKeywordCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<RecommendKeywordCreateMutation, RecommendKeywordCreateMutationVariables>;
export const RecommendKeywordUpdateDocument = gql`
    mutation RecommendKeywordUpdate($id: ID!, $input: RecommendSearchKeywordUpdateInput!) {
  data: recommendSearchKeywordUpdate(id: $id, input: $input) {
    data: recommendSearchKeyword {
      ...RecommendKeywordFragment
    }
  }
}
    ${RecommendKeywordFragmentFragmentDoc}`;
export type RecommendKeywordUpdateMutationFn = ApolloReactCommon.MutationFunction<RecommendKeywordUpdateMutation, RecommendKeywordUpdateMutationVariables>;
export type RecommendKeywordUpdateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RecommendKeywordUpdateMutation, RecommendKeywordUpdateMutationVariables>, 'mutation'>;

    export const RecommendKeywordUpdateComponent = (props: RecommendKeywordUpdateComponentProps) => (
      <ApolloReactComponents.Mutation<RecommendKeywordUpdateMutation, RecommendKeywordUpdateMutationVariables> mutation={RecommendKeywordUpdateDocument} {...props} />
    );
    

/**
 * __useRecommendKeywordUpdateMutation__
 *
 * To run a mutation, you first call `useRecommendKeywordUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecommendKeywordUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recommendKeywordUpdateMutation, { data, loading, error }] = useRecommendKeywordUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecommendKeywordUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RecommendKeywordUpdateMutation, RecommendKeywordUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<RecommendKeywordUpdateMutation, RecommendKeywordUpdateMutationVariables>(RecommendKeywordUpdateDocument, baseOptions);
      }
export type RecommendKeywordUpdateMutationHookResult = ReturnType<typeof useRecommendKeywordUpdateMutation>;
export type RecommendKeywordUpdateMutationResult = ApolloReactCommon.MutationResult<RecommendKeywordUpdateMutation>;
export type RecommendKeywordUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<RecommendKeywordUpdateMutation, RecommendKeywordUpdateMutationVariables>;
export const ReplyGetOneDocument = gql`
    query ReplyGetOne($id: ID!) {
  data: reply(id: $id) {
    ...ReplyFragment
  }
}
    ${ReplyFragmentFragmentDoc}`;
export type ReplyGetOneComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ReplyGetOneQuery, ReplyGetOneQueryVariables>, 'query'> & ({ variables: ReplyGetOneQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ReplyGetOneComponent = (props: ReplyGetOneComponentProps) => (
      <ApolloReactComponents.Query<ReplyGetOneQuery, ReplyGetOneQueryVariables> query={ReplyGetOneDocument} {...props} />
    );
    

/**
 * __useReplyGetOneQuery__
 *
 * To run a query within a React component, call `useReplyGetOneQuery` and pass it any options that fit your needs.
 * When your component renders, `useReplyGetOneQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReplyGetOneQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReplyGetOneQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReplyGetOneQuery, ReplyGetOneQueryVariables>) {
        return ApolloReactHooks.useQuery<ReplyGetOneQuery, ReplyGetOneQueryVariables>(ReplyGetOneDocument, baseOptions);
      }
export function useReplyGetOneLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReplyGetOneQuery, ReplyGetOneQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReplyGetOneQuery, ReplyGetOneQueryVariables>(ReplyGetOneDocument, baseOptions);
        }
export type ReplyGetOneQueryHookResult = ReturnType<typeof useReplyGetOneQuery>;
export type ReplyGetOneLazyQueryHookResult = ReturnType<typeof useReplyGetOneLazyQuery>;
export type ReplyGetOneQueryResult = ApolloReactCommon.QueryResult<ReplyGetOneQuery, ReplyGetOneQueryVariables>;
export const ReplyGetListDocument = gql`
    query ReplyGetList($filterBy: ReplyFilters, $orderBy: ReplyOrder, $pagination: Pagination) {
  data: replies(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    data: nodes {
      ...ReplyFragment
    }
    total: totalCount
  }
}
    ${ReplyFragmentFragmentDoc}`;
export type ReplyGetListComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ReplyGetListQuery, ReplyGetListQueryVariables>, 'query'>;

    export const ReplyGetListComponent = (props: ReplyGetListComponentProps) => (
      <ApolloReactComponents.Query<ReplyGetListQuery, ReplyGetListQueryVariables> query={ReplyGetListDocument} {...props} />
    );
    

/**
 * __useReplyGetListQuery__
 *
 * To run a query within a React component, call `useReplyGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useReplyGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReplyGetListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useReplyGetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReplyGetListQuery, ReplyGetListQueryVariables>) {
        return ApolloReactHooks.useQuery<ReplyGetListQuery, ReplyGetListQueryVariables>(ReplyGetListDocument, baseOptions);
      }
export function useReplyGetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReplyGetListQuery, ReplyGetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReplyGetListQuery, ReplyGetListQueryVariables>(ReplyGetListDocument, baseOptions);
        }
export type ReplyGetListQueryHookResult = ReturnType<typeof useReplyGetListQuery>;
export type ReplyGetListLazyQueryHookResult = ReturnType<typeof useReplyGetListLazyQuery>;
export type ReplyGetListQueryResult = ApolloReactCommon.QueryResult<ReplyGetListQuery, ReplyGetListQueryVariables>;
export const ReplyDeleteDocument = gql`
    mutation ReplyDelete($id: ID!) {
  data: replyDeleteByAdministrator(id: $id) {
    data: reply {
      ...ReplyFragment
    }
  }
}
    ${ReplyFragmentFragmentDoc}`;
export type ReplyDeleteMutationFn = ApolloReactCommon.MutationFunction<ReplyDeleteMutation, ReplyDeleteMutationVariables>;
export type ReplyDeleteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ReplyDeleteMutation, ReplyDeleteMutationVariables>, 'mutation'>;

    export const ReplyDeleteComponent = (props: ReplyDeleteComponentProps) => (
      <ApolloReactComponents.Mutation<ReplyDeleteMutation, ReplyDeleteMutationVariables> mutation={ReplyDeleteDocument} {...props} />
    );
    

/**
 * __useReplyDeleteMutation__
 *
 * To run a mutation, you first call `useReplyDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplyDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replyDeleteMutation, { data, loading, error }] = useReplyDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReplyDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReplyDeleteMutation, ReplyDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<ReplyDeleteMutation, ReplyDeleteMutationVariables>(ReplyDeleteDocument, baseOptions);
      }
export type ReplyDeleteMutationHookResult = ReturnType<typeof useReplyDeleteMutation>;
export type ReplyDeleteMutationResult = ApolloReactCommon.MutationResult<ReplyDeleteMutation>;
export type ReplyDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<ReplyDeleteMutation, ReplyDeleteMutationVariables>;
export const SignupSessionGetListDocument = gql`
    query SignupSessionGetList($filterBy: SignupSessionFilters, $orderBy: SignupSessionOrder, $pagination: Pagination) {
  data: signupSessions(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    data: nodes {
      ...SignupSessionFragment
    }
    total: totalCount
  }
}
    ${SignupSessionFragmentFragmentDoc}`;
export type SignupSessionGetListComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SignupSessionGetListQuery, SignupSessionGetListQueryVariables>, 'query'>;

    export const SignupSessionGetListComponent = (props: SignupSessionGetListComponentProps) => (
      <ApolloReactComponents.Query<SignupSessionGetListQuery, SignupSessionGetListQueryVariables> query={SignupSessionGetListDocument} {...props} />
    );
    

/**
 * __useSignupSessionGetListQuery__
 *
 * To run a query within a React component, call `useSignupSessionGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignupSessionGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignupSessionGetListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useSignupSessionGetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SignupSessionGetListQuery, SignupSessionGetListQueryVariables>) {
        return ApolloReactHooks.useQuery<SignupSessionGetListQuery, SignupSessionGetListQueryVariables>(SignupSessionGetListDocument, baseOptions);
      }
export function useSignupSessionGetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SignupSessionGetListQuery, SignupSessionGetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SignupSessionGetListQuery, SignupSessionGetListQueryVariables>(SignupSessionGetListDocument, baseOptions);
        }
export type SignupSessionGetListQueryHookResult = ReturnType<typeof useSignupSessionGetListQuery>;
export type SignupSessionGetListLazyQueryHookResult = ReturnType<typeof useSignupSessionGetListLazyQuery>;
export type SignupSessionGetListQueryResult = ApolloReactCommon.QueryResult<SignupSessionGetListQuery, SignupSessionGetListQueryVariables>;
export const SignupSessionGetDocument = gql`
    query SignupSessionGet($id: ID!) {
  data: signupSession(token: $id) {
    ...SignupSessionDetailFragment
  }
}
    ${SignupSessionDetailFragmentFragmentDoc}`;
export type SignupSessionGetComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SignupSessionGetQuery, SignupSessionGetQueryVariables>, 'query'> & ({ variables: SignupSessionGetQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SignupSessionGetComponent = (props: SignupSessionGetComponentProps) => (
      <ApolloReactComponents.Query<SignupSessionGetQuery, SignupSessionGetQueryVariables> query={SignupSessionGetDocument} {...props} />
    );
    

/**
 * __useSignupSessionGetQuery__
 *
 * To run a query within a React component, call `useSignupSessionGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignupSessionGetQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignupSessionGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSignupSessionGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SignupSessionGetQuery, SignupSessionGetQueryVariables>) {
        return ApolloReactHooks.useQuery<SignupSessionGetQuery, SignupSessionGetQueryVariables>(SignupSessionGetDocument, baseOptions);
      }
export function useSignupSessionGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SignupSessionGetQuery, SignupSessionGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SignupSessionGetQuery, SignupSessionGetQueryVariables>(SignupSessionGetDocument, baseOptions);
        }
export type SignupSessionGetQueryHookResult = ReturnType<typeof useSignupSessionGetQuery>;
export type SignupSessionGetLazyQueryHookResult = ReturnType<typeof useSignupSessionGetLazyQuery>;
export type SignupSessionGetQueryResult = ApolloReactCommon.QueryResult<SignupSessionGetQuery, SignupSessionGetQueryVariables>;
export const SignupSessionGetManyDocument = gql`
    query SignupSessionGetMany($ids: [ID!]!) {
  data: signupSessionsByToken(tokens: $ids) {
    ...SignupSessionFragment
  }
}
    ${SignupSessionFragmentFragmentDoc}`;
export type SignupSessionGetManyComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SignupSessionGetManyQuery, SignupSessionGetManyQueryVariables>, 'query'> & ({ variables: SignupSessionGetManyQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SignupSessionGetManyComponent = (props: SignupSessionGetManyComponentProps) => (
      <ApolloReactComponents.Query<SignupSessionGetManyQuery, SignupSessionGetManyQueryVariables> query={SignupSessionGetManyDocument} {...props} />
    );
    

/**
 * __useSignupSessionGetManyQuery__
 *
 * To run a query within a React component, call `useSignupSessionGetManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignupSessionGetManyQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignupSessionGetManyQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useSignupSessionGetManyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SignupSessionGetManyQuery, SignupSessionGetManyQueryVariables>) {
        return ApolloReactHooks.useQuery<SignupSessionGetManyQuery, SignupSessionGetManyQueryVariables>(SignupSessionGetManyDocument, baseOptions);
      }
export function useSignupSessionGetManyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SignupSessionGetManyQuery, SignupSessionGetManyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SignupSessionGetManyQuery, SignupSessionGetManyQueryVariables>(SignupSessionGetManyDocument, baseOptions);
        }
export type SignupSessionGetManyQueryHookResult = ReturnType<typeof useSignupSessionGetManyQuery>;
export type SignupSessionGetManyLazyQueryHookResult = ReturnType<typeof useSignupSessionGetManyLazyQuery>;
export type SignupSessionGetManyQueryResult = ApolloReactCommon.QueryResult<SignupSessionGetManyQuery, SignupSessionGetManyQueryVariables>;
export const SignupUserGetListDocument = gql`
    query SignupUserGetList($filterBy: UserFilters, $orderBy: UserOrder, $pagination: Pagination) {
  data: users(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    data: nodes {
      ...SignupUserFragment
    }
    total: totalCount
  }
}
    ${SignupUserFragmentFragmentDoc}`;
export type SignupUserGetListComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SignupUserGetListQuery, SignupUserGetListQueryVariables>, 'query'>;

    export const SignupUserGetListComponent = (props: SignupUserGetListComponentProps) => (
      <ApolloReactComponents.Query<SignupUserGetListQuery, SignupUserGetListQueryVariables> query={SignupUserGetListDocument} {...props} />
    );
    

/**
 * __useSignupUserGetListQuery__
 *
 * To run a query within a React component, call `useSignupUserGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignupUserGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignupUserGetListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useSignupUserGetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SignupUserGetListQuery, SignupUserGetListQueryVariables>) {
        return ApolloReactHooks.useQuery<SignupUserGetListQuery, SignupUserGetListQueryVariables>(SignupUserGetListDocument, baseOptions);
      }
export function useSignupUserGetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SignupUserGetListQuery, SignupUserGetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SignupUserGetListQuery, SignupUserGetListQueryVariables>(SignupUserGetListDocument, baseOptions);
        }
export type SignupUserGetListQueryHookResult = ReturnType<typeof useSignupUserGetListQuery>;
export type SignupUserGetListLazyQueryHookResult = ReturnType<typeof useSignupUserGetListLazyQuery>;
export type SignupUserGetListQueryResult = ApolloReactCommon.QueryResult<SignupUserGetListQuery, SignupUserGetListQueryVariables>;
export const SignupUserGetDocument = gql`
    query SignupUserGet($id: ID!) {
  data: user(id: $id) {
    ...SignupUserDetailFragment
  }
}
    ${SignupUserDetailFragmentFragmentDoc}`;
export type SignupUserGetComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SignupUserGetQuery, SignupUserGetQueryVariables>, 'query'> & ({ variables: SignupUserGetQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SignupUserGetComponent = (props: SignupUserGetComponentProps) => (
      <ApolloReactComponents.Query<SignupUserGetQuery, SignupUserGetQueryVariables> query={SignupUserGetDocument} {...props} />
    );
    

/**
 * __useSignupUserGetQuery__
 *
 * To run a query within a React component, call `useSignupUserGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignupUserGetQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignupUserGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSignupUserGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SignupUserGetQuery, SignupUserGetQueryVariables>) {
        return ApolloReactHooks.useQuery<SignupUserGetQuery, SignupUserGetQueryVariables>(SignupUserGetDocument, baseOptions);
      }
export function useSignupUserGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SignupUserGetQuery, SignupUserGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SignupUserGetQuery, SignupUserGetQueryVariables>(SignupUserGetDocument, baseOptions);
        }
export type SignupUserGetQueryHookResult = ReturnType<typeof useSignupUserGetQuery>;
export type SignupUserGetLazyQueryHookResult = ReturnType<typeof useSignupUserGetLazyQuery>;
export type SignupUserGetQueryResult = ApolloReactCommon.QueryResult<SignupUserGetQuery, SignupUserGetQueryVariables>;
export const SignupUserGetManyDocument = gql`
    query SignupUserGetMany($ids: [ID!]!) {
  data: usersByID(ids: $ids) {
    ...SignupUserFragment
  }
}
    ${SignupUserFragmentFragmentDoc}`;
export type SignupUserGetManyComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SignupUserGetManyQuery, SignupUserGetManyQueryVariables>, 'query'> & ({ variables: SignupUserGetManyQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SignupUserGetManyComponent = (props: SignupUserGetManyComponentProps) => (
      <ApolloReactComponents.Query<SignupUserGetManyQuery, SignupUserGetManyQueryVariables> query={SignupUserGetManyDocument} {...props} />
    );
    

/**
 * __useSignupUserGetManyQuery__
 *
 * To run a query within a React component, call `useSignupUserGetManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignupUserGetManyQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignupUserGetManyQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useSignupUserGetManyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SignupUserGetManyQuery, SignupUserGetManyQueryVariables>) {
        return ApolloReactHooks.useQuery<SignupUserGetManyQuery, SignupUserGetManyQueryVariables>(SignupUserGetManyDocument, baseOptions);
      }
export function useSignupUserGetManyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SignupUserGetManyQuery, SignupUserGetManyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SignupUserGetManyQuery, SignupUserGetManyQueryVariables>(SignupUserGetManyDocument, baseOptions);
        }
export type SignupUserGetManyQueryHookResult = ReturnType<typeof useSignupUserGetManyQuery>;
export type SignupUserGetManyLazyQueryHookResult = ReturnType<typeof useSignupUserGetManyLazyQuery>;
export type SignupUserGetManyQueryResult = ApolloReactCommon.QueryResult<SignupUserGetManyQuery, SignupUserGetManyQueryVariables>;
export const SignupUserUpdateDocument = gql`
    mutation SignupUserUpdate($id: ID!, $input: UserInput!) {
  data: userUpdate(id: $id, input: $input) {
    data: user {
      ...SignupUserDetailFragment
    }
  }
}
    ${SignupUserDetailFragmentFragmentDoc}`;
export type SignupUserUpdateMutationFn = ApolloReactCommon.MutationFunction<SignupUserUpdateMutation, SignupUserUpdateMutationVariables>;
export type SignupUserUpdateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SignupUserUpdateMutation, SignupUserUpdateMutationVariables>, 'mutation'>;

    export const SignupUserUpdateComponent = (props: SignupUserUpdateComponentProps) => (
      <ApolloReactComponents.Mutation<SignupUserUpdateMutation, SignupUserUpdateMutationVariables> mutation={SignupUserUpdateDocument} {...props} />
    );
    

/**
 * __useSignupUserUpdateMutation__
 *
 * To run a mutation, you first call `useSignupUserUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupUserUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupUserUpdateMutation, { data, loading, error }] = useSignupUserUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignupUserUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SignupUserUpdateMutation, SignupUserUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<SignupUserUpdateMutation, SignupUserUpdateMutationVariables>(SignupUserUpdateDocument, baseOptions);
      }
export type SignupUserUpdateMutationHookResult = ReturnType<typeof useSignupUserUpdateMutation>;
export type SignupUserUpdateMutationResult = ApolloReactCommon.MutationResult<SignupUserUpdateMutation>;
export type SignupUserUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<SignupUserUpdateMutation, SignupUserUpdateMutationVariables>;
export const SignupUserDeleteDocument = gql`
    mutation SignupUserDelete($id: ID!) {
  data: userDelete(id: $id) {
    data: user {
      ...SignupUserFragment
    }
  }
}
    ${SignupUserFragmentFragmentDoc}`;
export type SignupUserDeleteMutationFn = ApolloReactCommon.MutationFunction<SignupUserDeleteMutation, SignupUserDeleteMutationVariables>;
export type SignupUserDeleteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SignupUserDeleteMutation, SignupUserDeleteMutationVariables>, 'mutation'>;

    export const SignupUserDeleteComponent = (props: SignupUserDeleteComponentProps) => (
      <ApolloReactComponents.Mutation<SignupUserDeleteMutation, SignupUserDeleteMutationVariables> mutation={SignupUserDeleteDocument} {...props} />
    );
    

/**
 * __useSignupUserDeleteMutation__
 *
 * To run a mutation, you first call `useSignupUserDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupUserDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupUserDeleteMutation, { data, loading, error }] = useSignupUserDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSignupUserDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SignupUserDeleteMutation, SignupUserDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<SignupUserDeleteMutation, SignupUserDeleteMutationVariables>(SignupUserDeleteDocument, baseOptions);
      }
export type SignupUserDeleteMutationHookResult = ReturnType<typeof useSignupUserDeleteMutation>;
export type SignupUserDeleteMutationResult = ApolloReactCommon.MutationResult<SignupUserDeleteMutation>;
export type SignupUserDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<SignupUserDeleteMutation, SignupUserDeleteMutationVariables>;
export const SignupUserDeleteManyDocument = gql`
    mutation SignupUserDeleteMany($ids: [ID!]!) {
  data: userDeleteMany(ids: $ids) {
    data: users {
      ...SignupUserFragment
    }
  }
}
    ${SignupUserFragmentFragmentDoc}`;
export type SignupUserDeleteManyMutationFn = ApolloReactCommon.MutationFunction<SignupUserDeleteManyMutation, SignupUserDeleteManyMutationVariables>;
export type SignupUserDeleteManyComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SignupUserDeleteManyMutation, SignupUserDeleteManyMutationVariables>, 'mutation'>;

    export const SignupUserDeleteManyComponent = (props: SignupUserDeleteManyComponentProps) => (
      <ApolloReactComponents.Mutation<SignupUserDeleteManyMutation, SignupUserDeleteManyMutationVariables> mutation={SignupUserDeleteManyDocument} {...props} />
    );
    

/**
 * __useSignupUserDeleteManyMutation__
 *
 * To run a mutation, you first call `useSignupUserDeleteManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupUserDeleteManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupUserDeleteManyMutation, { data, loading, error }] = useSignupUserDeleteManyMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useSignupUserDeleteManyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SignupUserDeleteManyMutation, SignupUserDeleteManyMutationVariables>) {
        return ApolloReactHooks.useMutation<SignupUserDeleteManyMutation, SignupUserDeleteManyMutationVariables>(SignupUserDeleteManyDocument, baseOptions);
      }
export type SignupUserDeleteManyMutationHookResult = ReturnType<typeof useSignupUserDeleteManyMutation>;
export type SignupUserDeleteManyMutationResult = ApolloReactCommon.MutationResult<SignupUserDeleteManyMutation>;
export type SignupUserDeleteManyMutationOptions = ApolloReactCommon.BaseMutationOptions<SignupUserDeleteManyMutation, SignupUserDeleteManyMutationVariables>;
export const UserGetListDocument = gql`
    query UserGetList($filterBy: UserFilters, $orderBy: UserOrder, $pagination: Pagination) {
  data: users(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    data: nodes {
      ...UserFragment
    }
    total: totalCount
  }
}
    ${UserFragmentFragmentDoc}`;
export type UserGetListComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserGetListQuery, UserGetListQueryVariables>, 'query'>;

    export const UserGetListComponent = (props: UserGetListComponentProps) => (
      <ApolloReactComponents.Query<UserGetListQuery, UserGetListQueryVariables> query={UserGetListDocument} {...props} />
    );
    

/**
 * __useUserGetListQuery__
 *
 * To run a query within a React component, call `useUserGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGetListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useUserGetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserGetListQuery, UserGetListQueryVariables>) {
        return ApolloReactHooks.useQuery<UserGetListQuery, UserGetListQueryVariables>(UserGetListDocument, baseOptions);
      }
export function useUserGetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserGetListQuery, UserGetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserGetListQuery, UserGetListQueryVariables>(UserGetListDocument, baseOptions);
        }
export type UserGetListQueryHookResult = ReturnType<typeof useUserGetListQuery>;
export type UserGetListLazyQueryHookResult = ReturnType<typeof useUserGetListLazyQuery>;
export type UserGetListQueryResult = ApolloReactCommon.QueryResult<UserGetListQuery, UserGetListQueryVariables>;
export const UserGetDocument = gql`
    query UserGet($id: ID!) {
  data: user(id: $id) {
    ...UserDetailFragment
  }
}
    ${UserDetailFragmentFragmentDoc}`;
export type UserGetComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserGetQuery, UserGetQueryVariables>, 'query'> & ({ variables: UserGetQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const UserGetComponent = (props: UserGetComponentProps) => (
      <ApolloReactComponents.Query<UserGetQuery, UserGetQueryVariables> query={UserGetDocument} {...props} />
    );
    

/**
 * __useUserGetQuery__
 *
 * To run a query within a React component, call `useUserGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGetQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserGetQuery, UserGetQueryVariables>) {
        return ApolloReactHooks.useQuery<UserGetQuery, UserGetQueryVariables>(UserGetDocument, baseOptions);
      }
export function useUserGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserGetQuery, UserGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserGetQuery, UserGetQueryVariables>(UserGetDocument, baseOptions);
        }
export type UserGetQueryHookResult = ReturnType<typeof useUserGetQuery>;
export type UserGetLazyQueryHookResult = ReturnType<typeof useUserGetLazyQuery>;
export type UserGetQueryResult = ApolloReactCommon.QueryResult<UserGetQuery, UserGetQueryVariables>;
export const UserGetManyDocument = gql`
    query UserGetMany($ids: [ID!]!) {
  data: usersByID(ids: $ids) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;
export type UserGetManyComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserGetManyQuery, UserGetManyQueryVariables>, 'query'> & ({ variables: UserGetManyQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const UserGetManyComponent = (props: UserGetManyComponentProps) => (
      <ApolloReactComponents.Query<UserGetManyQuery, UserGetManyQueryVariables> query={UserGetManyDocument} {...props} />
    );
    

/**
 * __useUserGetManyQuery__
 *
 * To run a query within a React component, call `useUserGetManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGetManyQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGetManyQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useUserGetManyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserGetManyQuery, UserGetManyQueryVariables>) {
        return ApolloReactHooks.useQuery<UserGetManyQuery, UserGetManyQueryVariables>(UserGetManyDocument, baseOptions);
      }
export function useUserGetManyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserGetManyQuery, UserGetManyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserGetManyQuery, UserGetManyQueryVariables>(UserGetManyDocument, baseOptions);
        }
export type UserGetManyQueryHookResult = ReturnType<typeof useUserGetManyQuery>;
export type UserGetManyLazyQueryHookResult = ReturnType<typeof useUserGetManyLazyQuery>;
export type UserGetManyQueryResult = ApolloReactCommon.QueryResult<UserGetManyQuery, UserGetManyQueryVariables>;
export const UserUpdateDocument = gql`
    mutation UserUpdate($id: ID!, $input: UserInput!) {
  data: userUpdate(id: $id, input: $input) {
    data: user {
      ...UserDetailFragment
    }
  }
}
    ${UserDetailFragmentFragmentDoc}`;
export type UserUpdateMutationFn = ApolloReactCommon.MutationFunction<UserUpdateMutation, UserUpdateMutationVariables>;
export type UserUpdateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UserUpdateMutation, UserUpdateMutationVariables>, 'mutation'>;

    export const UserUpdateComponent = (props: UserUpdateComponentProps) => (
      <ApolloReactComponents.Mutation<UserUpdateMutation, UserUpdateMutationVariables> mutation={UserUpdateDocument} {...props} />
    );
    

/**
 * __useUserUpdateMutation__
 *
 * To run a mutation, you first call `useUserUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdateMutation, { data, loading, error }] = useUserUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UserUpdateMutation, UserUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<UserUpdateMutation, UserUpdateMutationVariables>(UserUpdateDocument, baseOptions);
      }
export type UserUpdateMutationHookResult = ReturnType<typeof useUserUpdateMutation>;
export type UserUpdateMutationResult = ApolloReactCommon.MutationResult<UserUpdateMutation>;
export type UserUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<UserUpdateMutation, UserUpdateMutationVariables>;
export const UserDeleteDocument = gql`
    mutation UserDelete($id: ID!) {
  data: deleteUserAccount(id: $id) {
    data: user {
      ...UserFragment
    }
  }
}
    ${UserFragmentFragmentDoc}`;
export type UserDeleteMutationFn = ApolloReactCommon.MutationFunction<UserDeleteMutation, UserDeleteMutationVariables>;
export type UserDeleteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UserDeleteMutation, UserDeleteMutationVariables>, 'mutation'>;

    export const UserDeleteComponent = (props: UserDeleteComponentProps) => (
      <ApolloReactComponents.Mutation<UserDeleteMutation, UserDeleteMutationVariables> mutation={UserDeleteDocument} {...props} />
    );
    

/**
 * __useUserDeleteMutation__
 *
 * To run a mutation, you first call `useUserDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userDeleteMutation, { data, loading, error }] = useUserDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UserDeleteMutation, UserDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<UserDeleteMutation, UserDeleteMutationVariables>(UserDeleteDocument, baseOptions);
      }
export type UserDeleteMutationHookResult = ReturnType<typeof useUserDeleteMutation>;
export type UserDeleteMutationResult = ApolloReactCommon.MutationResult<UserDeleteMutation>;
export type UserDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<UserDeleteMutation, UserDeleteMutationVariables>;
export const UserDeleteManyDocument = gql`
    mutation UserDeleteMany($ids: [ID!]!) {
  data: deleteManyUserAccounts(ids: $ids) {
    data: users {
      ...UserFragment
    }
  }
}
    ${UserFragmentFragmentDoc}`;
export type UserDeleteManyMutationFn = ApolloReactCommon.MutationFunction<UserDeleteManyMutation, UserDeleteManyMutationVariables>;
export type UserDeleteManyComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UserDeleteManyMutation, UserDeleteManyMutationVariables>, 'mutation'>;

    export const UserDeleteManyComponent = (props: UserDeleteManyComponentProps) => (
      <ApolloReactComponents.Mutation<UserDeleteManyMutation, UserDeleteManyMutationVariables> mutation={UserDeleteManyDocument} {...props} />
    );
    

/**
 * __useUserDeleteManyMutation__
 *
 * To run a mutation, you first call `useUserDeleteManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserDeleteManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userDeleteManyMutation, { data, loading, error }] = useUserDeleteManyMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useUserDeleteManyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UserDeleteManyMutation, UserDeleteManyMutationVariables>) {
        return ApolloReactHooks.useMutation<UserDeleteManyMutation, UserDeleteManyMutationVariables>(UserDeleteManyDocument, baseOptions);
      }
export type UserDeleteManyMutationHookResult = ReturnType<typeof useUserDeleteManyMutation>;
export type UserDeleteManyMutationResult = ApolloReactCommon.MutationResult<UserDeleteManyMutation>;
export type UserDeleteManyMutationOptions = ApolloReactCommon.BaseMutationOptions<UserDeleteManyMutation, UserDeleteManyMutationVariables>;
export const ActivityJudgeDocument = gql`
    mutation ActivityJudge($input: ActivitiesStatusUpdateInput!) {
  activitiesStatusUpdate(input: $input) {
    activities {
      ...ActivityFragment
    }
    count
  }
}
    ${ActivityFragmentFragmentDoc}`;
export type ActivityJudgeMutationFn = ApolloReactCommon.MutationFunction<ActivityJudgeMutation, ActivityJudgeMutationVariables>;
export type ActivityJudgeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ActivityJudgeMutation, ActivityJudgeMutationVariables>, 'mutation'>;

    export const ActivityJudgeComponent = (props: ActivityJudgeComponentProps) => (
      <ApolloReactComponents.Mutation<ActivityJudgeMutation, ActivityJudgeMutationVariables> mutation={ActivityJudgeDocument} {...props} />
    );
    

/**
 * __useActivityJudgeMutation__
 *
 * To run a mutation, you first call `useActivityJudgeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivityJudgeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activityJudgeMutation, { data, loading, error }] = useActivityJudgeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivityJudgeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActivityJudgeMutation, ActivityJudgeMutationVariables>) {
        return ApolloReactHooks.useMutation<ActivityJudgeMutation, ActivityJudgeMutationVariables>(ActivityJudgeDocument, baseOptions);
      }
export type ActivityJudgeMutationHookResult = ReturnType<typeof useActivityJudgeMutation>;
export type ActivityJudgeMutationResult = ApolloReactCommon.MutationResult<ActivityJudgeMutation>;
export type ActivityJudgeMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivityJudgeMutation, ActivityJudgeMutationVariables>;
export const InvalidateActivityCacheDocument = gql`
    mutation InvalidateActivityCache($id: ID!) {
  invalidateActivityCache(id: $id) {
    id
  }
}
    `;
export type InvalidateActivityCacheMutationFn = ApolloReactCommon.MutationFunction<InvalidateActivityCacheMutation, InvalidateActivityCacheMutationVariables>;
export type InvalidateActivityCacheComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InvalidateActivityCacheMutation, InvalidateActivityCacheMutationVariables>, 'mutation'>;

    export const InvalidateActivityCacheComponent = (props: InvalidateActivityCacheComponentProps) => (
      <ApolloReactComponents.Mutation<InvalidateActivityCacheMutation, InvalidateActivityCacheMutationVariables> mutation={InvalidateActivityCacheDocument} {...props} />
    );
    

/**
 * __useInvalidateActivityCacheMutation__
 *
 * To run a mutation, you first call `useInvalidateActivityCacheMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvalidateActivityCacheMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invalidateActivityCacheMutation, { data, loading, error }] = useInvalidateActivityCacheMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInvalidateActivityCacheMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InvalidateActivityCacheMutation, InvalidateActivityCacheMutationVariables>) {
        return ApolloReactHooks.useMutation<InvalidateActivityCacheMutation, InvalidateActivityCacheMutationVariables>(InvalidateActivityCacheDocument, baseOptions);
      }
export type InvalidateActivityCacheMutationHookResult = ReturnType<typeof useInvalidateActivityCacheMutation>;
export type InvalidateActivityCacheMutationResult = ApolloReactCommon.MutationResult<InvalidateActivityCacheMutation>;
export type InvalidateActivityCacheMutationOptions = ApolloReactCommon.BaseMutationOptions<InvalidateActivityCacheMutation, InvalidateActivityCacheMutationVariables>;
export const AdvertisementForm_ActivityIdField_ActivityDocument = gql`
    query AdvertisementForm_ActivityIDField_activity($id: ID!) {
  activity(id: $id) {
    status
  }
}
    `;
export type AdvertisementForm_ActivityIdField_ActivityComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AdvertisementForm_ActivityIdField_ActivityQuery, AdvertisementForm_ActivityIdField_ActivityQueryVariables>, 'query'> & ({ variables: AdvertisementForm_ActivityIdField_ActivityQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AdvertisementForm_ActivityIdField_ActivityComponent = (props: AdvertisementForm_ActivityIdField_ActivityComponentProps) => (
      <ApolloReactComponents.Query<AdvertisementForm_ActivityIdField_ActivityQuery, AdvertisementForm_ActivityIdField_ActivityQueryVariables> query={AdvertisementForm_ActivityIdField_ActivityDocument} {...props} />
    );
    

/**
 * __useAdvertisementForm_ActivityIdField_ActivityQuery__
 *
 * To run a query within a React component, call `useAdvertisementForm_ActivityIdField_ActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdvertisementForm_ActivityIdField_ActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdvertisementForm_ActivityIdField_ActivityQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdvertisementForm_ActivityIdField_ActivityQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AdvertisementForm_ActivityIdField_ActivityQuery, AdvertisementForm_ActivityIdField_ActivityQueryVariables>) {
        return ApolloReactHooks.useQuery<AdvertisementForm_ActivityIdField_ActivityQuery, AdvertisementForm_ActivityIdField_ActivityQueryVariables>(AdvertisementForm_ActivityIdField_ActivityDocument, baseOptions);
      }
export function useAdvertisementForm_ActivityIdField_ActivityLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdvertisementForm_ActivityIdField_ActivityQuery, AdvertisementForm_ActivityIdField_ActivityQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AdvertisementForm_ActivityIdField_ActivityQuery, AdvertisementForm_ActivityIdField_ActivityQueryVariables>(AdvertisementForm_ActivityIdField_ActivityDocument, baseOptions);
        }
export type AdvertisementForm_ActivityIdField_ActivityQueryHookResult = ReturnType<typeof useAdvertisementForm_ActivityIdField_ActivityQuery>;
export type AdvertisementForm_ActivityIdField_ActivityLazyQueryHookResult = ReturnType<typeof useAdvertisementForm_ActivityIdField_ActivityLazyQuery>;
export type AdvertisementForm_ActivityIdField_ActivityQueryResult = ApolloReactCommon.QueryResult<AdvertisementForm_ActivityIdField_ActivityQuery, AdvertisementForm_ActivityIdField_ActivityQueryVariables>;
export const AdvertisementForm_AdvertisementDocument = gql`
    query AdvertisementForm_advertisement($id: ID!) {
  advertisement(id: $id) {
    ...AdvertisementForm_advertisement
  }
}
    ${AdvertisementForm_AdvertisementFragmentDoc}`;
export type AdvertisementForm_AdvertisementComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AdvertisementForm_AdvertisementQuery, AdvertisementForm_AdvertisementQueryVariables>, 'query'> & ({ variables: AdvertisementForm_AdvertisementQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AdvertisementForm_AdvertisementComponent = (props: AdvertisementForm_AdvertisementComponentProps) => (
      <ApolloReactComponents.Query<AdvertisementForm_AdvertisementQuery, AdvertisementForm_AdvertisementQueryVariables> query={AdvertisementForm_AdvertisementDocument} {...props} />
    );
    

/**
 * __useAdvertisementForm_AdvertisementQuery__
 *
 * To run a query within a React component, call `useAdvertisementForm_AdvertisementQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdvertisementForm_AdvertisementQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdvertisementForm_AdvertisementQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdvertisementForm_AdvertisementQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AdvertisementForm_AdvertisementQuery, AdvertisementForm_AdvertisementQueryVariables>) {
        return ApolloReactHooks.useQuery<AdvertisementForm_AdvertisementQuery, AdvertisementForm_AdvertisementQueryVariables>(AdvertisementForm_AdvertisementDocument, baseOptions);
      }
export function useAdvertisementForm_AdvertisementLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdvertisementForm_AdvertisementQuery, AdvertisementForm_AdvertisementQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AdvertisementForm_AdvertisementQuery, AdvertisementForm_AdvertisementQueryVariables>(AdvertisementForm_AdvertisementDocument, baseOptions);
        }
export type AdvertisementForm_AdvertisementQueryHookResult = ReturnType<typeof useAdvertisementForm_AdvertisementQuery>;
export type AdvertisementForm_AdvertisementLazyQueryHookResult = ReturnType<typeof useAdvertisementForm_AdvertisementLazyQuery>;
export type AdvertisementForm_AdvertisementQueryResult = ApolloReactCommon.QueryResult<AdvertisementForm_AdvertisementQuery, AdvertisementForm_AdvertisementQueryVariables>;
export const AdvertisementForm_UpdateDocument = gql`
    mutation AdvertisementForm_update($input: AdvertisementUpdateInput!) {
  advertisementUpdate(input: $input) {
    advertisement {
      ...AdvertisementForm_advertisement
    }
  }
}
    ${AdvertisementForm_AdvertisementFragmentDoc}`;
export type AdvertisementForm_UpdateMutationFn = ApolloReactCommon.MutationFunction<AdvertisementForm_UpdateMutation, AdvertisementForm_UpdateMutationVariables>;
export type AdvertisementForm_UpdateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AdvertisementForm_UpdateMutation, AdvertisementForm_UpdateMutationVariables>, 'mutation'>;

    export const AdvertisementForm_UpdateComponent = (props: AdvertisementForm_UpdateComponentProps) => (
      <ApolloReactComponents.Mutation<AdvertisementForm_UpdateMutation, AdvertisementForm_UpdateMutationVariables> mutation={AdvertisementForm_UpdateDocument} {...props} />
    );
    

/**
 * __useAdvertisementForm_UpdateMutation__
 *
 * To run a mutation, you first call `useAdvertisementForm_UpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdvertisementForm_UpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [advertisementFormUpdateMutation, { data, loading, error }] = useAdvertisementForm_UpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdvertisementForm_UpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AdvertisementForm_UpdateMutation, AdvertisementForm_UpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<AdvertisementForm_UpdateMutation, AdvertisementForm_UpdateMutationVariables>(AdvertisementForm_UpdateDocument, baseOptions);
      }
export type AdvertisementForm_UpdateMutationHookResult = ReturnType<typeof useAdvertisementForm_UpdateMutation>;
export type AdvertisementForm_UpdateMutationResult = ApolloReactCommon.MutationResult<AdvertisementForm_UpdateMutation>;
export type AdvertisementForm_UpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<AdvertisementForm_UpdateMutation, AdvertisementForm_UpdateMutationVariables>;
export const AdvertisementForm_CreateDocument = gql`
    mutation AdvertisementForm_create($input: AdvertisementCreateInput!) {
  advertisementCreate(input: $input) {
    advertisement {
      ...AdvertisementForm_advertisement
    }
  }
}
    ${AdvertisementForm_AdvertisementFragmentDoc}`;
export type AdvertisementForm_CreateMutationFn = ApolloReactCommon.MutationFunction<AdvertisementForm_CreateMutation, AdvertisementForm_CreateMutationVariables>;
export type AdvertisementForm_CreateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AdvertisementForm_CreateMutation, AdvertisementForm_CreateMutationVariables>, 'mutation'>;

    export const AdvertisementForm_CreateComponent = (props: AdvertisementForm_CreateComponentProps) => (
      <ApolloReactComponents.Mutation<AdvertisementForm_CreateMutation, AdvertisementForm_CreateMutationVariables> mutation={AdvertisementForm_CreateDocument} {...props} />
    );
    

/**
 * __useAdvertisementForm_CreateMutation__
 *
 * To run a mutation, you first call `useAdvertisementForm_CreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdvertisementForm_CreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [advertisementFormCreateMutation, { data, loading, error }] = useAdvertisementForm_CreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdvertisementForm_CreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AdvertisementForm_CreateMutation, AdvertisementForm_CreateMutationVariables>) {
        return ApolloReactHooks.useMutation<AdvertisementForm_CreateMutation, AdvertisementForm_CreateMutationVariables>(AdvertisementForm_CreateDocument, baseOptions);
      }
export type AdvertisementForm_CreateMutationHookResult = ReturnType<typeof useAdvertisementForm_CreateMutation>;
export type AdvertisementForm_CreateMutationResult = ApolloReactCommon.MutationResult<AdvertisementForm_CreateMutation>;
export type AdvertisementForm_CreateMutationOptions = ApolloReactCommon.BaseMutationOptions<AdvertisementForm_CreateMutation, AdvertisementForm_CreateMutationVariables>;
export const AdvertisementForm_DeleteDocument = gql`
    mutation AdvertisementForm_delete($id: ID!) {
  advertisementDelete(id: $id) {
    advertisement {
      ...AdvertisementForm_advertisement
    }
  }
}
    ${AdvertisementForm_AdvertisementFragmentDoc}`;
export type AdvertisementForm_DeleteMutationFn = ApolloReactCommon.MutationFunction<AdvertisementForm_DeleteMutation, AdvertisementForm_DeleteMutationVariables>;
export type AdvertisementForm_DeleteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AdvertisementForm_DeleteMutation, AdvertisementForm_DeleteMutationVariables>, 'mutation'>;

    export const AdvertisementForm_DeleteComponent = (props: AdvertisementForm_DeleteComponentProps) => (
      <ApolloReactComponents.Mutation<AdvertisementForm_DeleteMutation, AdvertisementForm_DeleteMutationVariables> mutation={AdvertisementForm_DeleteDocument} {...props} />
    );
    

/**
 * __useAdvertisementForm_DeleteMutation__
 *
 * To run a mutation, you first call `useAdvertisementForm_DeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdvertisementForm_DeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [advertisementFormDeleteMutation, { data, loading, error }] = useAdvertisementForm_DeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdvertisementForm_DeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AdvertisementForm_DeleteMutation, AdvertisementForm_DeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<AdvertisementForm_DeleteMutation, AdvertisementForm_DeleteMutationVariables>(AdvertisementForm_DeleteDocument, baseOptions);
      }
export type AdvertisementForm_DeleteMutationHookResult = ReturnType<typeof useAdvertisementForm_DeleteMutation>;
export type AdvertisementForm_DeleteMutationResult = ApolloReactCommon.MutationResult<AdvertisementForm_DeleteMutation>;
export type AdvertisementForm_DeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<AdvertisementForm_DeleteMutation, AdvertisementForm_DeleteMutationVariables>;
export const AdvertisementList_AdvertisementsDocument = gql`
    query AdvertisementList_advertisements($filterBy: AdvertisementFilter!) {
  advertisementList(filterBy: $filterBy) {
    advertisements {
      status
    }
  }
}
    `;
export type AdvertisementList_AdvertisementsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AdvertisementList_AdvertisementsQuery, AdvertisementList_AdvertisementsQueryVariables>, 'query'> & ({ variables: AdvertisementList_AdvertisementsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AdvertisementList_AdvertisementsComponent = (props: AdvertisementList_AdvertisementsComponentProps) => (
      <ApolloReactComponents.Query<AdvertisementList_AdvertisementsQuery, AdvertisementList_AdvertisementsQueryVariables> query={AdvertisementList_AdvertisementsDocument} {...props} />
    );
    

/**
 * __useAdvertisementList_AdvertisementsQuery__
 *
 * To run a query within a React component, call `useAdvertisementList_AdvertisementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdvertisementList_AdvertisementsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdvertisementList_AdvertisementsQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useAdvertisementList_AdvertisementsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AdvertisementList_AdvertisementsQuery, AdvertisementList_AdvertisementsQueryVariables>) {
        return ApolloReactHooks.useQuery<AdvertisementList_AdvertisementsQuery, AdvertisementList_AdvertisementsQueryVariables>(AdvertisementList_AdvertisementsDocument, baseOptions);
      }
export function useAdvertisementList_AdvertisementsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdvertisementList_AdvertisementsQuery, AdvertisementList_AdvertisementsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AdvertisementList_AdvertisementsQuery, AdvertisementList_AdvertisementsQueryVariables>(AdvertisementList_AdvertisementsDocument, baseOptions);
        }
export type AdvertisementList_AdvertisementsQueryHookResult = ReturnType<typeof useAdvertisementList_AdvertisementsQuery>;
export type AdvertisementList_AdvertisementsLazyQueryHookResult = ReturnType<typeof useAdvertisementList_AdvertisementsLazyQuery>;
export type AdvertisementList_AdvertisementsQueryResult = ApolloReactCommon.QueryResult<AdvertisementList_AdvertisementsQuery, AdvertisementList_AdvertisementsQueryVariables>;
export const AdvertisementInput_PlacementListDocument = gql`
    query AdvertisementInput_PlacementList {
  adPlacements(filterBy: {isActive: true}, pagination: {page: 1, pageSize: 999}) {
    nodes {
      ...AdvertisementInput_adPlacement
    }
  }
}
    ${AdvertisementInput_AdPlacementFragmentDoc}`;
export type AdvertisementInput_PlacementListComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AdvertisementInput_PlacementListQuery, AdvertisementInput_PlacementListQueryVariables>, 'query'>;

    export const AdvertisementInput_PlacementListComponent = (props: AdvertisementInput_PlacementListComponentProps) => (
      <ApolloReactComponents.Query<AdvertisementInput_PlacementListQuery, AdvertisementInput_PlacementListQueryVariables> query={AdvertisementInput_PlacementListDocument} {...props} />
    );
    

/**
 * __useAdvertisementInput_PlacementListQuery__
 *
 * To run a query within a React component, call `useAdvertisementInput_PlacementListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdvertisementInput_PlacementListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdvertisementInput_PlacementListQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdvertisementInput_PlacementListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AdvertisementInput_PlacementListQuery, AdvertisementInput_PlacementListQueryVariables>) {
        return ApolloReactHooks.useQuery<AdvertisementInput_PlacementListQuery, AdvertisementInput_PlacementListQueryVariables>(AdvertisementInput_PlacementListDocument, baseOptions);
      }
export function useAdvertisementInput_PlacementListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdvertisementInput_PlacementListQuery, AdvertisementInput_PlacementListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AdvertisementInput_PlacementListQuery, AdvertisementInput_PlacementListQueryVariables>(AdvertisementInput_PlacementListDocument, baseOptions);
        }
export type AdvertisementInput_PlacementListQueryHookResult = ReturnType<typeof useAdvertisementInput_PlacementListQuery>;
export type AdvertisementInput_PlacementListLazyQueryHookResult = ReturnType<typeof useAdvertisementInput_PlacementListLazyQuery>;
export type AdvertisementInput_PlacementListQueryResult = ApolloReactCommon.QueryResult<AdvertisementInput_PlacementListQuery, AdvertisementInput_PlacementListQueryVariables>;
export const CampaignCreate_CampaignCreateDocument = gql`
    mutation CampaignCreate_campaignCreate($input: CampaignCreateInput!) {
  campaignCreate(input: $input) {
    campaign {
      id
    }
  }
}
    `;
export type CampaignCreate_CampaignCreateMutationFn = ApolloReactCommon.MutationFunction<CampaignCreate_CampaignCreateMutation, CampaignCreate_CampaignCreateMutationVariables>;
export type CampaignCreate_CampaignCreateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CampaignCreate_CampaignCreateMutation, CampaignCreate_CampaignCreateMutationVariables>, 'mutation'>;

    export const CampaignCreate_CampaignCreateComponent = (props: CampaignCreate_CampaignCreateComponentProps) => (
      <ApolloReactComponents.Mutation<CampaignCreate_CampaignCreateMutation, CampaignCreate_CampaignCreateMutationVariables> mutation={CampaignCreate_CampaignCreateDocument} {...props} />
    );
    

/**
 * __useCampaignCreate_CampaignCreateMutation__
 *
 * To run a mutation, you first call `useCampaignCreate_CampaignCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignCreate_CampaignCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignCreateCampaignCreateMutation, { data, loading, error }] = useCampaignCreate_CampaignCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCampaignCreate_CampaignCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CampaignCreate_CampaignCreateMutation, CampaignCreate_CampaignCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<CampaignCreate_CampaignCreateMutation, CampaignCreate_CampaignCreateMutationVariables>(CampaignCreate_CampaignCreateDocument, baseOptions);
      }
export type CampaignCreate_CampaignCreateMutationHookResult = ReturnType<typeof useCampaignCreate_CampaignCreateMutation>;
export type CampaignCreate_CampaignCreateMutationResult = ApolloReactCommon.MutationResult<CampaignCreate_CampaignCreateMutation>;
export type CampaignCreate_CampaignCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<CampaignCreate_CampaignCreateMutation, CampaignCreate_CampaignCreateMutationVariables>;
export const CampaignEdit_CampaignUpdateDocument = gql`
    mutation CampaignEdit_campaignUpdate($id: ID!, $input: CampaignUpdateInput!) {
  campaignUpdate(id: $id, input: $input) {
    campaign {
      id
    }
  }
}
    `;
export type CampaignEdit_CampaignUpdateMutationFn = ApolloReactCommon.MutationFunction<CampaignEdit_CampaignUpdateMutation, CampaignEdit_CampaignUpdateMutationVariables>;
export type CampaignEdit_CampaignUpdateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CampaignEdit_CampaignUpdateMutation, CampaignEdit_CampaignUpdateMutationVariables>, 'mutation'>;

    export const CampaignEdit_CampaignUpdateComponent = (props: CampaignEdit_CampaignUpdateComponentProps) => (
      <ApolloReactComponents.Mutation<CampaignEdit_CampaignUpdateMutation, CampaignEdit_CampaignUpdateMutationVariables> mutation={CampaignEdit_CampaignUpdateDocument} {...props} />
    );
    

/**
 * __useCampaignEdit_CampaignUpdateMutation__
 *
 * To run a mutation, you first call `useCampaignEdit_CampaignUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignEdit_CampaignUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignEditCampaignUpdateMutation, { data, loading, error }] = useCampaignEdit_CampaignUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCampaignEdit_CampaignUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CampaignEdit_CampaignUpdateMutation, CampaignEdit_CampaignUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<CampaignEdit_CampaignUpdateMutation, CampaignEdit_CampaignUpdateMutationVariables>(CampaignEdit_CampaignUpdateDocument, baseOptions);
      }
export type CampaignEdit_CampaignUpdateMutationHookResult = ReturnType<typeof useCampaignEdit_CampaignUpdateMutation>;
export type CampaignEdit_CampaignUpdateMutationResult = ApolloReactCommon.MutationResult<CampaignEdit_CampaignUpdateMutation>;
export type CampaignEdit_CampaignUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<CampaignEdit_CampaignUpdateMutation, CampaignEdit_CampaignUpdateMutationVariables>;
export const CampaignEdit_CampaignDeleteDocument = gql`
    mutation CampaignEdit_campaignDelete($id: ID!) {
  campaignDelete(id: $id) {
    campaign {
      id
    }
  }
}
    `;
export type CampaignEdit_CampaignDeleteMutationFn = ApolloReactCommon.MutationFunction<CampaignEdit_CampaignDeleteMutation, CampaignEdit_CampaignDeleteMutationVariables>;
export type CampaignEdit_CampaignDeleteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CampaignEdit_CampaignDeleteMutation, CampaignEdit_CampaignDeleteMutationVariables>, 'mutation'>;

    export const CampaignEdit_CampaignDeleteComponent = (props: CampaignEdit_CampaignDeleteComponentProps) => (
      <ApolloReactComponents.Mutation<CampaignEdit_CampaignDeleteMutation, CampaignEdit_CampaignDeleteMutationVariables> mutation={CampaignEdit_CampaignDeleteDocument} {...props} />
    );
    

/**
 * __useCampaignEdit_CampaignDeleteMutation__
 *
 * To run a mutation, you first call `useCampaignEdit_CampaignDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignEdit_CampaignDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignEditCampaignDeleteMutation, { data, loading, error }] = useCampaignEdit_CampaignDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCampaignEdit_CampaignDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CampaignEdit_CampaignDeleteMutation, CampaignEdit_CampaignDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<CampaignEdit_CampaignDeleteMutation, CampaignEdit_CampaignDeleteMutationVariables>(CampaignEdit_CampaignDeleteDocument, baseOptions);
      }
export type CampaignEdit_CampaignDeleteMutationHookResult = ReturnType<typeof useCampaignEdit_CampaignDeleteMutation>;
export type CampaignEdit_CampaignDeleteMutationResult = ApolloReactCommon.MutationResult<CampaignEdit_CampaignDeleteMutation>;
export type CampaignEdit_CampaignDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<CampaignEdit_CampaignDeleteMutation, CampaignEdit_CampaignDeleteMutationVariables>;
export const CampaignListDocument = gql`
    query CampaignList($filterBy: CampaignFilters, $orderBy: CampaignOrder, $pagination: Pagination) {
  campaigns(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    nodes {
      ...CampaignList_campaign
    }
    totalCount
  }
}
    ${CampaignList_CampaignFragmentDoc}`;
export type CampaignListComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CampaignListQuery, CampaignListQueryVariables>, 'query'>;

    export const CampaignListComponent = (props: CampaignListComponentProps) => (
      <ApolloReactComponents.Query<CampaignListQuery, CampaignListQueryVariables> query={CampaignListDocument} {...props} />
    );
    

/**
 * __useCampaignListQuery__
 *
 * To run a query within a React component, call `useCampaignListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useCampaignListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CampaignListQuery, CampaignListQueryVariables>) {
        return ApolloReactHooks.useQuery<CampaignListQuery, CampaignListQueryVariables>(CampaignListDocument, baseOptions);
      }
export function useCampaignListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CampaignListQuery, CampaignListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CampaignListQuery, CampaignListQueryVariables>(CampaignListDocument, baseOptions);
        }
export type CampaignListQueryHookResult = ReturnType<typeof useCampaignListQuery>;
export type CampaignListLazyQueryHookResult = ReturnType<typeof useCampaignListLazyQuery>;
export type CampaignListQueryResult = ApolloReactCommon.QueryResult<CampaignListQuery, CampaignListQueryVariables>;
export const CampaignReport_CampaignStatisticDocument = gql`
    query CampaignReport_CampaignStatistic($filterBy: CampaignStatisticFilters) {
  campaignStatistics(filterBy: $filterBy) {
    totalImpressionCount
    totalClickCount
    nodes {
      id
      date
      adPlacement {
        id
        name
      }
      impressionCount
      clickCount
      ctr
    }
  }
}
    `;
export type CampaignReport_CampaignStatisticComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CampaignReport_CampaignStatisticQuery, CampaignReport_CampaignStatisticQueryVariables>, 'query'>;

    export const CampaignReport_CampaignStatisticComponent = (props: CampaignReport_CampaignStatisticComponentProps) => (
      <ApolloReactComponents.Query<CampaignReport_CampaignStatisticQuery, CampaignReport_CampaignStatisticQueryVariables> query={CampaignReport_CampaignStatisticDocument} {...props} />
    );
    

/**
 * __useCampaignReport_CampaignStatisticQuery__
 *
 * To run a query within a React component, call `useCampaignReport_CampaignStatisticQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignReport_CampaignStatisticQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignReport_CampaignStatisticQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useCampaignReport_CampaignStatisticQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CampaignReport_CampaignStatisticQuery, CampaignReport_CampaignStatisticQueryVariables>) {
        return ApolloReactHooks.useQuery<CampaignReport_CampaignStatisticQuery, CampaignReport_CampaignStatisticQueryVariables>(CampaignReport_CampaignStatisticDocument, baseOptions);
      }
export function useCampaignReport_CampaignStatisticLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CampaignReport_CampaignStatisticQuery, CampaignReport_CampaignStatisticQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CampaignReport_CampaignStatisticQuery, CampaignReport_CampaignStatisticQueryVariables>(CampaignReport_CampaignStatisticDocument, baseOptions);
        }
export type CampaignReport_CampaignStatisticQueryHookResult = ReturnType<typeof useCampaignReport_CampaignStatisticQuery>;
export type CampaignReport_CampaignStatisticLazyQueryHookResult = ReturnType<typeof useCampaignReport_CampaignStatisticLazyQuery>;
export type CampaignReport_CampaignStatisticQueryResult = ApolloReactCommon.QueryResult<CampaignReport_CampaignStatisticQuery, CampaignReport_CampaignStatisticQueryVariables>;
export const CampaignReportDialog_CampaignDocument = gql`
    query CampaignReportDialog_Campaign($id: ID!) {
  campaign(id: $id) {
    id
    name
    ads {
      adPlacement {
        id
        name
      }
    }
  }
}
    `;
export type CampaignReportDialog_CampaignComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CampaignReportDialog_CampaignQuery, CampaignReportDialog_CampaignQueryVariables>, 'query'> & ({ variables: CampaignReportDialog_CampaignQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CampaignReportDialog_CampaignComponent = (props: CampaignReportDialog_CampaignComponentProps) => (
      <ApolloReactComponents.Query<CampaignReportDialog_CampaignQuery, CampaignReportDialog_CampaignQueryVariables> query={CampaignReportDialog_CampaignDocument} {...props} />
    );
    

/**
 * __useCampaignReportDialog_CampaignQuery__
 *
 * To run a query within a React component, call `useCampaignReportDialog_CampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignReportDialog_CampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignReportDialog_CampaignQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCampaignReportDialog_CampaignQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CampaignReportDialog_CampaignQuery, CampaignReportDialog_CampaignQueryVariables>) {
        return ApolloReactHooks.useQuery<CampaignReportDialog_CampaignQuery, CampaignReportDialog_CampaignQueryVariables>(CampaignReportDialog_CampaignDocument, baseOptions);
      }
export function useCampaignReportDialog_CampaignLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CampaignReportDialog_CampaignQuery, CampaignReportDialog_CampaignQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CampaignReportDialog_CampaignQuery, CampaignReportDialog_CampaignQueryVariables>(CampaignReportDialog_CampaignDocument, baseOptions);
        }
export type CampaignReportDialog_CampaignQueryHookResult = ReturnType<typeof useCampaignReportDialog_CampaignQuery>;
export type CampaignReportDialog_CampaignLazyQueryHookResult = ReturnType<typeof useCampaignReportDialog_CampaignLazyQuery>;
export type CampaignReportDialog_CampaignQueryResult = ApolloReactCommon.QueryResult<CampaignReportDialog_CampaignQuery, CampaignReportDialog_CampaignQueryVariables>;
export const CampaignGetOneDocument = gql`
    query CampaignGetOne($id: ID!) {
  campaign(id: $id) {
    ...Campaign_campaign
  }
}
    ${Campaign_CampaignFragmentDoc}`;
export type CampaignGetOneComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CampaignGetOneQuery, CampaignGetOneQueryVariables>, 'query'> & ({ variables: CampaignGetOneQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CampaignGetOneComponent = (props: CampaignGetOneComponentProps) => (
      <ApolloReactComponents.Query<CampaignGetOneQuery, CampaignGetOneQueryVariables> query={CampaignGetOneDocument} {...props} />
    );
    

/**
 * __useCampaignGetOneQuery__
 *
 * To run a query within a React component, call `useCampaignGetOneQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignGetOneQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignGetOneQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCampaignGetOneQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CampaignGetOneQuery, CampaignGetOneQueryVariables>) {
        return ApolloReactHooks.useQuery<CampaignGetOneQuery, CampaignGetOneQueryVariables>(CampaignGetOneDocument, baseOptions);
      }
export function useCampaignGetOneLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CampaignGetOneQuery, CampaignGetOneQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CampaignGetOneQuery, CampaignGetOneQueryVariables>(CampaignGetOneDocument, baseOptions);
        }
export type CampaignGetOneQueryHookResult = ReturnType<typeof useCampaignGetOneQuery>;
export type CampaignGetOneLazyQueryHookResult = ReturnType<typeof useCampaignGetOneLazyQuery>;
export type CampaignGetOneQueryResult = ApolloReactCommon.QueryResult<CampaignGetOneQuery, CampaignGetOneQueryVariables>;
export const PlacementCreate_AdPlacementCreateDocument = gql`
    mutation PlacementCreate_adPlacementCreate($input: AdPlacementCreateInput!) {
  adPlacementCreate(input: $input) {
    adPlacement {
      id
    }
  }
}
    `;
export type PlacementCreate_AdPlacementCreateMutationFn = ApolloReactCommon.MutationFunction<PlacementCreate_AdPlacementCreateMutation, PlacementCreate_AdPlacementCreateMutationVariables>;
export type PlacementCreate_AdPlacementCreateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<PlacementCreate_AdPlacementCreateMutation, PlacementCreate_AdPlacementCreateMutationVariables>, 'mutation'>;

    export const PlacementCreate_AdPlacementCreateComponent = (props: PlacementCreate_AdPlacementCreateComponentProps) => (
      <ApolloReactComponents.Mutation<PlacementCreate_AdPlacementCreateMutation, PlacementCreate_AdPlacementCreateMutationVariables> mutation={PlacementCreate_AdPlacementCreateDocument} {...props} />
    );
    

/**
 * __usePlacementCreate_AdPlacementCreateMutation__
 *
 * To run a mutation, you first call `usePlacementCreate_AdPlacementCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlacementCreate_AdPlacementCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [placementCreateAdPlacementCreateMutation, { data, loading, error }] = usePlacementCreate_AdPlacementCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePlacementCreate_AdPlacementCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PlacementCreate_AdPlacementCreateMutation, PlacementCreate_AdPlacementCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<PlacementCreate_AdPlacementCreateMutation, PlacementCreate_AdPlacementCreateMutationVariables>(PlacementCreate_AdPlacementCreateDocument, baseOptions);
      }
export type PlacementCreate_AdPlacementCreateMutationHookResult = ReturnType<typeof usePlacementCreate_AdPlacementCreateMutation>;
export type PlacementCreate_AdPlacementCreateMutationResult = ApolloReactCommon.MutationResult<PlacementCreate_AdPlacementCreateMutation>;
export type PlacementCreate_AdPlacementCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<PlacementCreate_AdPlacementCreateMutation, PlacementCreate_AdPlacementCreateMutationVariables>;
export const PlacementEdit_AdPlacementUpdateDocument = gql`
    mutation PlacementEdit_adPlacementUpdate($id: ID!, $input: AdPlacementUpdateInput!) {
  adPlacementUpdate(id: $id, input: $input) {
    adPlacement {
      id
    }
  }
}
    `;
export type PlacementEdit_AdPlacementUpdateMutationFn = ApolloReactCommon.MutationFunction<PlacementEdit_AdPlacementUpdateMutation, PlacementEdit_AdPlacementUpdateMutationVariables>;
export type PlacementEdit_AdPlacementUpdateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<PlacementEdit_AdPlacementUpdateMutation, PlacementEdit_AdPlacementUpdateMutationVariables>, 'mutation'>;

    export const PlacementEdit_AdPlacementUpdateComponent = (props: PlacementEdit_AdPlacementUpdateComponentProps) => (
      <ApolloReactComponents.Mutation<PlacementEdit_AdPlacementUpdateMutation, PlacementEdit_AdPlacementUpdateMutationVariables> mutation={PlacementEdit_AdPlacementUpdateDocument} {...props} />
    );
    

/**
 * __usePlacementEdit_AdPlacementUpdateMutation__
 *
 * To run a mutation, you first call `usePlacementEdit_AdPlacementUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlacementEdit_AdPlacementUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [placementEditAdPlacementUpdateMutation, { data, loading, error }] = usePlacementEdit_AdPlacementUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePlacementEdit_AdPlacementUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PlacementEdit_AdPlacementUpdateMutation, PlacementEdit_AdPlacementUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<PlacementEdit_AdPlacementUpdateMutation, PlacementEdit_AdPlacementUpdateMutationVariables>(PlacementEdit_AdPlacementUpdateDocument, baseOptions);
      }
export type PlacementEdit_AdPlacementUpdateMutationHookResult = ReturnType<typeof usePlacementEdit_AdPlacementUpdateMutation>;
export type PlacementEdit_AdPlacementUpdateMutationResult = ApolloReactCommon.MutationResult<PlacementEdit_AdPlacementUpdateMutation>;
export type PlacementEdit_AdPlacementUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<PlacementEdit_AdPlacementUpdateMutation, PlacementEdit_AdPlacementUpdateMutationVariables>;
export const PlacementListDocument = gql`
    query PlacementList($filterBy: AdPlacementFilters, $orderBy: AdPlacementOrder, $pagination: Pagination) {
  adPlacements(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    nodes {
      ...PlacementList_adPlacement
    }
    totalCount
  }
}
    ${PlacementList_AdPlacementFragmentDoc}`;
export type PlacementListComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PlacementListQuery, PlacementListQueryVariables>, 'query'>;

    export const PlacementListComponent = (props: PlacementListComponentProps) => (
      <ApolloReactComponents.Query<PlacementListQuery, PlacementListQueryVariables> query={PlacementListDocument} {...props} />
    );
    

/**
 * __usePlacementListQuery__
 *
 * To run a query within a React component, call `usePlacementListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlacementListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlacementListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function usePlacementListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PlacementListQuery, PlacementListQueryVariables>) {
        return ApolloReactHooks.useQuery<PlacementListQuery, PlacementListQueryVariables>(PlacementListDocument, baseOptions);
      }
export function usePlacementListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PlacementListQuery, PlacementListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PlacementListQuery, PlacementListQueryVariables>(PlacementListDocument, baseOptions);
        }
export type PlacementListQueryHookResult = ReturnType<typeof usePlacementListQuery>;
export type PlacementListLazyQueryHookResult = ReturnType<typeof usePlacementListLazyQuery>;
export type PlacementListQueryResult = ApolloReactCommon.QueryResult<PlacementListQuery, PlacementListQueryVariables>;
export const PlacementGetOneDocument = gql`
    query PlacementGetOne($id: ID!) {
  adPlacement(id: $id) {
    ...Placement_adPlacement
  }
}
    ${Placement_AdPlacementFragmentDoc}`;
export type PlacementGetOneComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PlacementGetOneQuery, PlacementGetOneQueryVariables>, 'query'> & ({ variables: PlacementGetOneQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const PlacementGetOneComponent = (props: PlacementGetOneComponentProps) => (
      <ApolloReactComponents.Query<PlacementGetOneQuery, PlacementGetOneQueryVariables> query={PlacementGetOneDocument} {...props} />
    );
    

/**
 * __usePlacementGetOneQuery__
 *
 * To run a query within a React component, call `usePlacementGetOneQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlacementGetOneQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlacementGetOneQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePlacementGetOneQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PlacementGetOneQuery, PlacementGetOneQueryVariables>) {
        return ApolloReactHooks.useQuery<PlacementGetOneQuery, PlacementGetOneQueryVariables>(PlacementGetOneDocument, baseOptions);
      }
export function usePlacementGetOneLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PlacementGetOneQuery, PlacementGetOneQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PlacementGetOneQuery, PlacementGetOneQueryVariables>(PlacementGetOneDocument, baseOptions);
        }
export type PlacementGetOneQueryHookResult = ReturnType<typeof usePlacementGetOneQuery>;
export type PlacementGetOneLazyQueryHookResult = ReturnType<typeof usePlacementGetOneLazyQuery>;
export type PlacementGetOneQueryResult = ApolloReactCommon.QueryResult<PlacementGetOneQuery, PlacementGetOneQueryVariables>;
export const BulkCoverLetterTypeAddDocument = gql`
    mutation BulkCoverLetterTypeAdd($input: CoverLetterManyAddTypeInput!) {
  coverLetterManyAddType(input: $input) {
    updatedCoverLetters {
      ...CoverLetterFragment
    }
  }
}
    ${CoverLetterFragmentFragmentDoc}`;
export type BulkCoverLetterTypeAddMutationFn = ApolloReactCommon.MutationFunction<BulkCoverLetterTypeAddMutation, BulkCoverLetterTypeAddMutationVariables>;
export type BulkCoverLetterTypeAddComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<BulkCoverLetterTypeAddMutation, BulkCoverLetterTypeAddMutationVariables>, 'mutation'>;

    export const BulkCoverLetterTypeAddComponent = (props: BulkCoverLetterTypeAddComponentProps) => (
      <ApolloReactComponents.Mutation<BulkCoverLetterTypeAddMutation, BulkCoverLetterTypeAddMutationVariables> mutation={BulkCoverLetterTypeAddDocument} {...props} />
    );
    

/**
 * __useBulkCoverLetterTypeAddMutation__
 *
 * To run a mutation, you first call `useBulkCoverLetterTypeAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkCoverLetterTypeAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkCoverLetterTypeAddMutation, { data, loading, error }] = useBulkCoverLetterTypeAddMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkCoverLetterTypeAddMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BulkCoverLetterTypeAddMutation, BulkCoverLetterTypeAddMutationVariables>) {
        return ApolloReactHooks.useMutation<BulkCoverLetterTypeAddMutation, BulkCoverLetterTypeAddMutationVariables>(BulkCoverLetterTypeAddDocument, baseOptions);
      }
export type BulkCoverLetterTypeAddMutationHookResult = ReturnType<typeof useBulkCoverLetterTypeAddMutation>;
export type BulkCoverLetterTypeAddMutationResult = ApolloReactCommon.MutationResult<BulkCoverLetterTypeAddMutation>;
export type BulkCoverLetterTypeAddMutationOptions = ApolloReactCommon.BaseMutationOptions<BulkCoverLetterTypeAddMutation, BulkCoverLetterTypeAddMutationVariables>;
export const CoverLettersPlagiarismDocument = gql`
    query CoverLettersPlagiarism($id: ID!) {
  coverLetterPlagiarismList(id: $id) {
    nodes {
      id
      content
      score
    }
    totalCount
  }
}
    `;
export type CoverLettersPlagiarismComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CoverLettersPlagiarismQuery, CoverLettersPlagiarismQueryVariables>, 'query'> & ({ variables: CoverLettersPlagiarismQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CoverLettersPlagiarismComponent = (props: CoverLettersPlagiarismComponentProps) => (
      <ApolloReactComponents.Query<CoverLettersPlagiarismQuery, CoverLettersPlagiarismQueryVariables> query={CoverLettersPlagiarismDocument} {...props} />
    );
    

/**
 * __useCoverLettersPlagiarismQuery__
 *
 * To run a query within a React component, call `useCoverLettersPlagiarismQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoverLettersPlagiarismQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoverLettersPlagiarismQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCoverLettersPlagiarismQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CoverLettersPlagiarismQuery, CoverLettersPlagiarismQueryVariables>) {
        return ApolloReactHooks.useQuery<CoverLettersPlagiarismQuery, CoverLettersPlagiarismQueryVariables>(CoverLettersPlagiarismDocument, baseOptions);
      }
export function useCoverLettersPlagiarismLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CoverLettersPlagiarismQuery, CoverLettersPlagiarismQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CoverLettersPlagiarismQuery, CoverLettersPlagiarismQueryVariables>(CoverLettersPlagiarismDocument, baseOptions);
        }
export type CoverLettersPlagiarismQueryHookResult = ReturnType<typeof useCoverLettersPlagiarismQuery>;
export type CoverLettersPlagiarismLazyQueryHookResult = ReturnType<typeof useCoverLettersPlagiarismLazyQuery>;
export type CoverLettersPlagiarismQueryResult = ApolloReactCommon.QueryResult<CoverLettersPlagiarismQuery, CoverLettersPlagiarismQueryVariables>;
export const OrganizationDetailUserDocument = gql`
    query OrganizationDetailUser($id: ID!) {
  user(id: $id) {
    ...OrganizationDetail_user
  }
}
    ${OrganizationDetail_UserFragmentDoc}`;
export type OrganizationDetailUserComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrganizationDetailUserQuery, OrganizationDetailUserQueryVariables>, 'query'> & ({ variables: OrganizationDetailUserQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OrganizationDetailUserComponent = (props: OrganizationDetailUserComponentProps) => (
      <ApolloReactComponents.Query<OrganizationDetailUserQuery, OrganizationDetailUserQueryVariables> query={OrganizationDetailUserDocument} {...props} />
    );
    

/**
 * __useOrganizationDetailUserQuery__
 *
 * To run a query within a React component, call `useOrganizationDetailUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationDetailUserQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationDetailUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationDetailUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationDetailUserQuery, OrganizationDetailUserQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganizationDetailUserQuery, OrganizationDetailUserQueryVariables>(OrganizationDetailUserDocument, baseOptions);
      }
export function useOrganizationDetailUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationDetailUserQuery, OrganizationDetailUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganizationDetailUserQuery, OrganizationDetailUserQueryVariables>(OrganizationDetailUserDocument, baseOptions);
        }
export type OrganizationDetailUserQueryHookResult = ReturnType<typeof useOrganizationDetailUserQuery>;
export type OrganizationDetailUserLazyQueryHookResult = ReturnType<typeof useOrganizationDetailUserLazyQuery>;
export type OrganizationDetailUserQueryResult = ApolloReactCommon.QueryResult<OrganizationDetailUserQuery, OrganizationDetailUserQueryVariables>;
export const OrganizationDetailOrganizationTypesDocument = gql`
    query OrganizationDetailOrganizationTypes {
  organizationTypes {
    ...OrganizationDetail_organizationType
  }
}
    ${OrganizationDetail_OrganizationTypeFragmentDoc}`;
export type OrganizationDetailOrganizationTypesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrganizationDetailOrganizationTypesQuery, OrganizationDetailOrganizationTypesQueryVariables>, 'query'>;

    export const OrganizationDetailOrganizationTypesComponent = (props: OrganizationDetailOrganizationTypesComponentProps) => (
      <ApolloReactComponents.Query<OrganizationDetailOrganizationTypesQuery, OrganizationDetailOrganizationTypesQueryVariables> query={OrganizationDetailOrganizationTypesDocument} {...props} />
    );
    

/**
 * __useOrganizationDetailOrganizationTypesQuery__
 *
 * To run a query within a React component, call `useOrganizationDetailOrganizationTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationDetailOrganizationTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationDetailOrganizationTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationDetailOrganizationTypesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationDetailOrganizationTypesQuery, OrganizationDetailOrganizationTypesQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganizationDetailOrganizationTypesQuery, OrganizationDetailOrganizationTypesQueryVariables>(OrganizationDetailOrganizationTypesDocument, baseOptions);
      }
export function useOrganizationDetailOrganizationTypesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationDetailOrganizationTypesQuery, OrganizationDetailOrganizationTypesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganizationDetailOrganizationTypesQuery, OrganizationDetailOrganizationTypesQueryVariables>(OrganizationDetailOrganizationTypesDocument, baseOptions);
        }
export type OrganizationDetailOrganizationTypesQueryHookResult = ReturnType<typeof useOrganizationDetailOrganizationTypesQuery>;
export type OrganizationDetailOrganizationTypesLazyQueryHookResult = ReturnType<typeof useOrganizationDetailOrganizationTypesLazyQuery>;
export type OrganizationDetailOrganizationTypesQueryResult = ApolloReactCommon.QueryResult<OrganizationDetailOrganizationTypesQuery, OrganizationDetailOrganizationTypesQueryVariables>;
export const OrganizationDetail_UserUpdateDocument = gql`
    mutation OrganizationDetail_userUpdate($id: ID!, $input: UserInput!) {
  userUpdate(id: $id, input: $input) {
    user {
      ...OrganizationDetail_user
    }
  }
}
    ${OrganizationDetail_UserFragmentDoc}`;
export type OrganizationDetail_UserUpdateMutationFn = ApolloReactCommon.MutationFunction<OrganizationDetail_UserUpdateMutation, OrganizationDetail_UserUpdateMutationVariables>;
export type OrganizationDetail_UserUpdateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<OrganizationDetail_UserUpdateMutation, OrganizationDetail_UserUpdateMutationVariables>, 'mutation'>;

    export const OrganizationDetail_UserUpdateComponent = (props: OrganizationDetail_UserUpdateComponentProps) => (
      <ApolloReactComponents.Mutation<OrganizationDetail_UserUpdateMutation, OrganizationDetail_UserUpdateMutationVariables> mutation={OrganizationDetail_UserUpdateDocument} {...props} />
    );
    

/**
 * __useOrganizationDetail_UserUpdateMutation__
 *
 * To run a mutation, you first call `useOrganizationDetail_UserUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationDetail_UserUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationDetailUserUpdateMutation, { data, loading, error }] = useOrganizationDetail_UserUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrganizationDetail_UserUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OrganizationDetail_UserUpdateMutation, OrganizationDetail_UserUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<OrganizationDetail_UserUpdateMutation, OrganizationDetail_UserUpdateMutationVariables>(OrganizationDetail_UserUpdateDocument, baseOptions);
      }
export type OrganizationDetail_UserUpdateMutationHookResult = ReturnType<typeof useOrganizationDetail_UserUpdateMutation>;
export type OrganizationDetail_UserUpdateMutationResult = ApolloReactCommon.MutationResult<OrganizationDetail_UserUpdateMutation>;
export type OrganizationDetail_UserUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<OrganizationDetail_UserUpdateMutation, OrganizationDetail_UserUpdateMutationVariables>;
export const CurationDeleteDocument = gql`
    mutation CurationDelete($id: ID!) {
  curationDelete(id: $id) {
    curation {
      id
    }
  }
}
    `;
export type CurationDeleteMutationFn = ApolloReactCommon.MutationFunction<CurationDeleteMutation, CurationDeleteMutationVariables>;
export type CurationDeleteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CurationDeleteMutation, CurationDeleteMutationVariables>, 'mutation'>;

    export const CurationDeleteComponent = (props: CurationDeleteComponentProps) => (
      <ApolloReactComponents.Mutation<CurationDeleteMutation, CurationDeleteMutationVariables> mutation={CurationDeleteDocument} {...props} />
    );
    

/**
 * __useCurationDeleteMutation__
 *
 * To run a mutation, you first call `useCurationDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCurationDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [curationDeleteMutation, { data, loading, error }] = useCurationDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCurationDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CurationDeleteMutation, CurationDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<CurationDeleteMutation, CurationDeleteMutationVariables>(CurationDeleteDocument, baseOptions);
      }
export type CurationDeleteMutationHookResult = ReturnType<typeof useCurationDeleteMutation>;
export type CurationDeleteMutationResult = ApolloReactCommon.MutationResult<CurationDeleteMutation>;
export type CurationDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<CurationDeleteMutation, CurationDeleteMutationVariables>;
export const RecommendKeywordDeleteDocument = gql`
    mutation RecommendKeywordDelete($id: ID!) {
  recommendSearchKeywordDelete(id: $id) {
    recommendSearchKeyword {
      id
    }
  }
}
    `;
export type RecommendKeywordDeleteMutationFn = ApolloReactCommon.MutationFunction<RecommendKeywordDeleteMutation, RecommendKeywordDeleteMutationVariables>;
export type RecommendKeywordDeleteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RecommendKeywordDeleteMutation, RecommendKeywordDeleteMutationVariables>, 'mutation'>;

    export const RecommendKeywordDeleteComponent = (props: RecommendKeywordDeleteComponentProps) => (
      <ApolloReactComponents.Mutation<RecommendKeywordDeleteMutation, RecommendKeywordDeleteMutationVariables> mutation={RecommendKeywordDeleteDocument} {...props} />
    );
    

/**
 * __useRecommendKeywordDeleteMutation__
 *
 * To run a mutation, you first call `useRecommendKeywordDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecommendKeywordDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recommendKeywordDeleteMutation, { data, loading, error }] = useRecommendKeywordDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRecommendKeywordDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RecommendKeywordDeleteMutation, RecommendKeywordDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<RecommendKeywordDeleteMutation, RecommendKeywordDeleteMutationVariables>(RecommendKeywordDeleteDocument, baseOptions);
      }
export type RecommendKeywordDeleteMutationHookResult = ReturnType<typeof useRecommendKeywordDeleteMutation>;
export type RecommendKeywordDeleteMutationResult = ApolloReactCommon.MutationResult<RecommendKeywordDeleteMutation>;
export type RecommendKeywordDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<RecommendKeywordDeleteMutation, RecommendKeywordDeleteMutationVariables>;
export const UserUnlinkTimespreadDocument = gql`
    mutation UserUnlinkTimespread($userID: ID!) {
  userUnlinkTimespread(userID: $userID) {
    user {
      id
    }
  }
}
    `;
export type UserUnlinkTimespreadMutationFn = ApolloReactCommon.MutationFunction<UserUnlinkTimespreadMutation, UserUnlinkTimespreadMutationVariables>;
export type UserUnlinkTimespreadComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UserUnlinkTimespreadMutation, UserUnlinkTimespreadMutationVariables>, 'mutation'>;

    export const UserUnlinkTimespreadComponent = (props: UserUnlinkTimespreadComponentProps) => (
      <ApolloReactComponents.Mutation<UserUnlinkTimespreadMutation, UserUnlinkTimespreadMutationVariables> mutation={UserUnlinkTimespreadDocument} {...props} />
    );
    

/**
 * __useUserUnlinkTimespreadMutation__
 *
 * To run a mutation, you first call `useUserUnlinkTimespreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUnlinkTimespreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUnlinkTimespreadMutation, { data, loading, error }] = useUserUnlinkTimespreadMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useUserUnlinkTimespreadMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UserUnlinkTimespreadMutation, UserUnlinkTimespreadMutationVariables>) {
        return ApolloReactHooks.useMutation<UserUnlinkTimespreadMutation, UserUnlinkTimespreadMutationVariables>(UserUnlinkTimespreadDocument, baseOptions);
      }
export type UserUnlinkTimespreadMutationHookResult = ReturnType<typeof useUserUnlinkTimespreadMutation>;
export type UserUnlinkTimespreadMutationResult = ApolloReactCommon.MutationResult<UserUnlinkTimespreadMutation>;
export type UserUnlinkTimespreadMutationOptions = ApolloReactCommon.BaseMutationOptions<UserUnlinkTimespreadMutation, UserUnlinkTimespreadMutationVariables>;
export const UserDeactivateDocument = gql`
    mutation UserDeactivate($userID: ID!) {
  deleteUserAccount(id: $userID) {
    user {
      id
    }
  }
}
    `;
export type UserDeactivateMutationFn = ApolloReactCommon.MutationFunction<UserDeactivateMutation, UserDeactivateMutationVariables>;
export type UserDeactivateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UserDeactivateMutation, UserDeactivateMutationVariables>, 'mutation'>;

    export const UserDeactivateComponent = (props: UserDeactivateComponentProps) => (
      <ApolloReactComponents.Mutation<UserDeactivateMutation, UserDeactivateMutationVariables> mutation={UserDeactivateDocument} {...props} />
    );
    

/**
 * __useUserDeactivateMutation__
 *
 * To run a mutation, you first call `useUserDeactivateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserDeactivateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userDeactivateMutation, { data, loading, error }] = useUserDeactivateMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useUserDeactivateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UserDeactivateMutation, UserDeactivateMutationVariables>) {
        return ApolloReactHooks.useMutation<UserDeactivateMutation, UserDeactivateMutationVariables>(UserDeactivateDocument, baseOptions);
      }
export type UserDeactivateMutationHookResult = ReturnType<typeof useUserDeactivateMutation>;
export type UserDeactivateMutationResult = ApolloReactCommon.MutationResult<UserDeactivateMutation>;
export type UserDeactivateMutationOptions = ApolloReactCommon.BaseMutationOptions<UserDeactivateMutation, UserDeactivateMutationVariables>;
export const LoginByAdminDocument = gql`
    mutation LoginByAdmin($id: ID!) {
  loginByAdmin(id: $id) {
    user {
      id
    }
  }
}
    `;
export type LoginByAdminMutationFn = ApolloReactCommon.MutationFunction<LoginByAdminMutation, LoginByAdminMutationVariables>;
export type LoginByAdminComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<LoginByAdminMutation, LoginByAdminMutationVariables>, 'mutation'>;

    export const LoginByAdminComponent = (props: LoginByAdminComponentProps) => (
      <ApolloReactComponents.Mutation<LoginByAdminMutation, LoginByAdminMutationVariables> mutation={LoginByAdminDocument} {...props} />
    );
    

/**
 * __useLoginByAdminMutation__
 *
 * To run a mutation, you first call `useLoginByAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginByAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginByAdminMutation, { data, loading, error }] = useLoginByAdminMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoginByAdminMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginByAdminMutation, LoginByAdminMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginByAdminMutation, LoginByAdminMutationVariables>(LoginByAdminDocument, baseOptions);
      }
export type LoginByAdminMutationHookResult = ReturnType<typeof useLoginByAdminMutation>;
export type LoginByAdminMutationResult = ApolloReactCommon.MutationResult<LoginByAdminMutation>;
export type LoginByAdminMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginByAdminMutation, LoginByAdminMutationVariables>;
export const OrganizationTableUserDocument = gql`
    query OrganizationTableUser($filterBy: UserFilters, $orderBy: UserOrder, $pagination: Pagination) {
  users(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    ...OrganizationTable_userConnection
  }
}
    ${OrganizationTable_UserConnectionFragmentDoc}`;
export type OrganizationTableUserComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrganizationTableUserQuery, OrganizationTableUserQueryVariables>, 'query'>;

    export const OrganizationTableUserComponent = (props: OrganizationTableUserComponentProps) => (
      <ApolloReactComponents.Query<OrganizationTableUserQuery, OrganizationTableUserQueryVariables> query={OrganizationTableUserDocument} {...props} />
    );
    

/**
 * __useOrganizationTableUserQuery__
 *
 * To run a query within a React component, call `useOrganizationTableUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationTableUserQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationTableUserQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useOrganizationTableUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationTableUserQuery, OrganizationTableUserQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganizationTableUserQuery, OrganizationTableUserQueryVariables>(OrganizationTableUserDocument, baseOptions);
      }
export function useOrganizationTableUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationTableUserQuery, OrganizationTableUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganizationTableUserQuery, OrganizationTableUserQueryVariables>(OrganizationTableUserDocument, baseOptions);
        }
export type OrganizationTableUserQueryHookResult = ReturnType<typeof useOrganizationTableUserQuery>;
export type OrganizationTableUserLazyQueryHookResult = ReturnType<typeof useOrganizationTableUserLazyQuery>;
export type OrganizationTableUserQueryResult = ApolloReactCommon.QueryResult<OrganizationTableUserQuery, OrganizationTableUserQueryVariables>;
export const UserTableDocument = gql`
    query UserTable($filterBy: UserFilters, $orderBy: UserOrder, $pagination: Pagination) {
  users(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    ...UserTable_userConnection
  }
}
    ${UserTable_UserConnectionFragmentDoc}`;
export type UserTableComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserTableQuery, UserTableQueryVariables>, 'query'>;

    export const UserTableComponent = (props: UserTableComponentProps) => (
      <ApolloReactComponents.Query<UserTableQuery, UserTableQueryVariables> query={UserTableDocument} {...props} />
    );
    

/**
 * __useUserTableQuery__
 *
 * To run a query within a React component, call `useUserTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTableQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTableQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useUserTableQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserTableQuery, UserTableQueryVariables>) {
        return ApolloReactHooks.useQuery<UserTableQuery, UserTableQueryVariables>(UserTableDocument, baseOptions);
      }
export function useUserTableLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserTableQuery, UserTableQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserTableQuery, UserTableQueryVariables>(UserTableDocument, baseOptions);
        }
export type UserTableQueryHookResult = ReturnType<typeof useUserTableQuery>;
export type UserTableLazyQueryHookResult = ReturnType<typeof useUserTableLazyQuery>;
export type UserTableQueryResult = ApolloReactCommon.QueryResult<UserTableQuery, UserTableQueryVariables>;